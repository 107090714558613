import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router';
import axios from "axios"
import { Redirect, useHistory } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { connect } from "react-redux"
import { googleregister } from '../actions/index'
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  image: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginLeft: "20px"
  },
  gridcontainer: {
    backgroundColor: '#fff',
    width: 'auto',
    maxWidth: "750px",
    borderRadius: "25px",
    boxShadow: "0 1rem 3rem rgba(0,0,0,.175) !important",
  },
  divcomponent: {
    padding: 30,
    textAlign: "center",
  },
  textpadding: {
    padding: 10,
    color: " gray",
  },
  link: {
    textAlign: "center",
    margin: "5px",
  },
  hr: {
    marginTop: "10px",
    marginBottom: "10px",
    color: "#ffe",
    height: 0,
  },
}));

const StyledButton = withStyles({
  root: {
    position: 'static',
    background: '#4e73df',
    borderRadius: 25,
    border: 0,
    color: '#fff',
    height: 48,
    width: 200,
    padding: '0 30px',
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#2653d4',

    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `0 0 0 2px`,
      borderColor: '#f33',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const useStylesCustomizeTextField = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 25,
    height: '48px',
    marginTop: '5px',
    marginBottom: '5px',
    backgroundColor: '#fff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function CustomizeTextField(props) {
  const classes = useStylesCustomizeTextField();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}


function GoogleRegButton(props) {
  const classes = useStyles();
  const urlid = useQuery().get("token")
  let history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [msg, setMsg] = React.useState("Please fill your additional details below")
  const [isError, setIsError] = React.useState("gray")
  const [isLoad, setIsLoad] = React.useState(true)
  const [error, seterror] = React.useState(null)
  const [isSuccess, setIsSuccess] = React.useState(false)


  var CryptoJS = require("crypto-js");
  function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    encryptedData = encryptedData.ciphertext.toString();
    return encryptedData

  }

  const handleError = (e) => {
    seterror(e)
  }

    // const onChange = e => {
    //   seterror(null)
    //   setValues({ ...values, [e.target.name]: e.target.value })
    // }

    ;
  const handleSubmit = () => {
    var temp;
    if (values.companyName) {
      temp = values.companyName
    }
    else {
      const username = props.authReducer.googleobj.profileObj.email.split('@')[0].replace(/\./g, "-");

      // Generate a 4-digit alphanumeric string
      const randomString = Array.from({ length: 4 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'[Math.floor(Math.random() * 62)]).join('');

      // Create the final string
      const finalString = `${username}-${randomString}`;
      temp = finalString
    }
    const data = {
      "sessionId": urlid,
      "email": props.authReducer.googleobj.profileObj.email,
      "password": encodeAes(props.authReducer.googleobj.tokenObj.access_token),
      "companyName": temp,
      "phoneNumber": values.phoneNumber,
      "industry": "Undefined",
      //"billingAddress": values.billingAddress,
      "location": values.location,
      "firstName": props.authReducer.googleobj.profileObj.givenName,
      "lastName": props.authReducer.googleobj.profileObj.familyName,
      "ssoType": "google",
    }
    props.googleregister(data, handleError)


  }

  const [values, setValues] = useState({
    sessionId: '',
    firstName: '',
    lastName: '',
    emailId: '',
    password: '',
    companyName: '',
    phoneNumber: '',
    location: '',
    industry: '',
    //billingAddress: '',
    payment: '',
    cardDetails: '',
    cvv: '',
  })

  useEffect(() => {
    setValues({ ...values, sessionId: urlid });
    axios.get("https://api.ipify.org?format=json").then((res) => {
      axios.get(`https://ipapi.co/${res.data.ip}/json/`).then((res2) => {
        setValues({ ...values, phoneNumber: res2.data.country_calling_code, location: res2.data.city + ", " + res2.data.country_name })
      })

    })
    handleSubmit()
  }, []);

  return (
    <Container component="main" maxWidth="700px">
      <CssBaseline />
      <div className={classes.paper}>
        please Wait..

        {/* {props.authReducer.googleobj ? (
          <Grid container className={classes.gridcontainer}>

            <Grid item xs={12} sm={12} md={12} className={classes.divcomponent}>
              <Typography component="h1" variant="h5">
                Hi, {props.authReducer.googleobj.profileObj.name}
              </Typography>
              {isSuccess && <CheckCircleIcon style={{ color: "green", textAlign: "center", marginTop: "20px" }} />}
              <br />
              {error ? <div style={{ color: "red" }}> {error}</div> :
                <Typography style={{ color: isError, textAlign: "center" }} variant="Button" gutterBottom className={classes.textpadding}>
                  {msg}
                </Typography>}
              {!isSuccess &&
                (<div>
              please Wait..
                </div>)}

            </Grid>
          </Grid>)
          : (<Typography style={{ color: isError }} variant="body1" gutterBottom className={classes.textpadding}>
            Token expired
          </Typography>)
        } */}
      </div>

    </Container>
  );
}
const mapStateToProps = ({ authReducer }) => {

  return { authReducer }
}
export default connect(mapStateToProps, { googleregister })(GoogleRegButton);