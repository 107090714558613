import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router';
import { githubregister } from '../actions/index';
import { supabase } from "../client";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    image: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginLeft: "20px"
    },
    gridcontainer: {
        backgroundColor: '#fff',
        width: 'auto',
        maxWidth: "750px",
        borderRadius: "25px",
        boxShadow: "0 1rem 3rem rgba(0,0,0,.175) !important",
    },
    divcomponent: {
        padding: 30,
        textAlign: "center",
    },
    textpadding: {
        padding: 10,
        color: " gray",
    },
    link: {
        textAlign: "center",
        margin: "5px",
    },
    hr: {
        marginTop: "10px",
        marginBottom: "10px",
        color: "#ffe",
        height: 0,
    },
}));







function BitbucketRegButton(props) {
    const classes = useStyles();
    const urlid = useQuery().get("token")

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }


    const [isError] = React.useState("gray")
    const [isLoad, setIsLoad] = React.useState(false)
    const [isSuccess] = React.useState(false)
    const user = supabase.auth.user()

 
    const done = () => {
        //history.push("/plans");
        setIsLoad(true)
    }

    var CryptoJS = require("crypto-js");
    function encodeAes(plaintText) {
      var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
      var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      encryptedData = encryptedData.ciphertext.toString();
      return encryptedData
  
    }
    var temptoken = supabase.auth.currentSession.provider_token
    const handleSubmit = () => {
  
        var temp;
        if (values.companyName){
          temp = values.companyName
        }
        else{
          temp = user.email.split('@')[0].replace(".","-")
        }
        const data = {
            "sessionId": urlid,
            "email": user.email,
            "password": encodeAes(temptoken),
            "companyName": temp,
            "phoneNumber": values.phoneNumber,
            "industry": "Undefined",
            "location": values.location,
            "firstName": user.user_metadata.full_name,
            "lastName": "  ",
            "ssoType": "bitbucket",
        }
        props.githubregister(data,done)

    }

    const [values, setValues] = useState({
        sessionId: '',
        firstName: '',
        lastName: '',
        emailId: '',
        password: '',
        companyName: '',
        phoneNumber: '',
        location: '',
        industry: '',
    })

    useEffect(() => {
        setValues({ ...values, sessionId: urlid });
        axios.get("https://api.ipify.org?format=json").then((res) => {
          axios.get(`https://ipapi.co/${res.data.ip}/json/`).then((res2) => {
            setValues({ ...values, phoneNumber: res2.data.country_calling_code, location: res2.data.city + ", " + res2.data.country_name })
          })
    
        })
        handleSubmit()
      }, []);

    return (
        <Container component="main" maxWidth="700px">
            <CssBaseline />
            <div className={classes.paper}>

               
                    <Grid container className={classes.gridcontainer}>

                        <Grid item xs={12} sm={12} md={12} className={classes.divcomponent}>
                            <Typography component="h1" variant="h5">
                                {!isLoad ? "Hey there," : <><ErrorOutlineIcon style={{color:"#ff6363"}}/> Error: Github token expired</>}
                            </Typography>
                            <Typography style={{ color: isError, textAlign: "center" }} variant="Button" gutterBottom className={classes.textpadding}>
                                Please fill your additional details below
                            </Typography>
                            {!isSuccess &&
                                (<div>
                                 
                                    Please wait...
                               
                                </div>)}
                            <br />
                        </Grid>
                    </Grid>
                  
            </div>
        </Container>
    );
}
const mapStateToProps = ({ authReducer }) => {
    return { authReducer }
}
export default connect(mapStateToProps, { githubregister })(BitbucketRegButton);