import { gql, useMutation, useQuery } from '@apollo/client';
import { Close, Edit } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Avatar, AvatarGroup, Badge, Collapse, Fade, LinearProgress, ListItemAvatar, ListItemSecondaryAction, styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Slide from '@mui/material/Slide';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { StyledEngineProvider, ThemeProvider, adaptV4Theme, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Octokit } from "@octokit/rest";
import axios from "axios";
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { AiFillFileAdd, AiFillThunderbolt, AiOutlineArrowLeft, AiOutlineGithub } from "react-icons/ai";
import { BiArrowBack, BiCodeAlt, BiEditAlt, BiWorld } from "react-icons/bi";
import { BsFileTextFill, BsUpload } from 'react-icons/bs';
import { CgAddR } from "react-icons/cg";
import { FaLock } from 'react-icons/fa';
import { FiArrowUpLeft, FiBox, FiUpload } from "react-icons/fi";
import { IoIosArrowForward } from 'react-icons/io';
import { MdCloudUpload, MdContentPaste, MdOutlineCloudUpload } from "react-icons/md";
import { RiErrorWarningFill, RiSettingsFill } from "react-icons/ri";
import { SiDocker } from "react-icons/si";
import { TiWarningOutline } from "react-icons/ti";
import { VscGlobe } from "react-icons/vsc";
import Template from "../../../Assets/template.json";
import LoadingBuildApp from '../../../Containers/LoadingBuildApp';
import LoadingNife from '../../../Containers/LoadingNife';
import UpgradePlan from '../../../Containers/UpgradePlan';
import LogTrigger from '../../../LogTrigger';
import { HISTORY } from '../../Deployments/Activity/ActivityTable';
import { APP_LOCATION_LIST } from "../../Deployments/Deploy/Main";
import { APP } from "../../Deployments/Overview/Main";
import { REVERT_APP } from "../../Deployments/Services/Main";
import { USER_ACTIVITY } from '../../Settings/Nav';
import UserLevelPAT from '../../Settings/UserLevelPAT';
import { APP_LIST } from '../Apps';
import { APPS } from '../DeploymentTable';
import { CREATE_SECRETS, DELETE_SECRETS, SECRETS_LIST } from "../Scerets";
import AWS from 'aws-sdk';
import { useDropzone } from 'react-dropzone';

export const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },

    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        maxWidth: 500,
    },
}));

export const FETCH_ORG_FOR_APP = gql`
query AppforOrg($name: String!){
  app(name: $name){
    id
    organization{
        id
      }
  }
}`

export const BUILTIN_TYPES = gql`
query{
  GetAvailableBuiltIn(first:10)
}`

export const CONFIF_TEMPLATE_LIST = gql`
query{
    GetAppTemplates{
        id
        name
        config {
          definition
          services{
            description
            protocol
            internalPort
            ports{
              port
            }
          }
          valid
          build{
            builder
            builtin
            image
          }
          errors
        }
        envArgs
        routingPolicy
        volumeSize
        isActive
        createdBy
        createdAt
      }
}`

export const DEPLOY_IMAGE = gql`
  mutation deployImageMutation( 
  $appId: String!,
  $image: String! ,
  $definition: Map ,
  $strategy: String ,
  $services: [Map],
  $envMapArgs: [String],
  $archiveUrl: String,
  $envArgs: String,)
  {
    deployImage(input: { appId: $appId, image: $image, definition: $definition,strategy: $strategy,services: $services, envMapArgs: $envMapArgs, envArgs: $envArgs, archiveUrl : $archiveUrl}) {
      release {
            id
            version
            reason
            description
            deploymentStrategy
            user {
              id
              email
              name
            }
            createdAt
      }
    }
  }
`
export const UPDATE_REGISTRY = gql`
  mutation update_registry($appName:String!, $name: String!){
    updateRegistryIdToApp(
    appName:$appName
    name:$name
    ){
       message 
}}`


export const ADD_VOLUME = gql`
  mutation AddVolumeMutation($name: String,$appId: String, $path: String, $size: String){
    createDuploVolume(input: [{appId:$appId,accessMode:"ReadWriteOnce",name:$name,path:$path,size:$size, volumeTypeId: ""}]){
       message 
}}`

export const UPDATE_VOLUME = gql`
  mutation UpdateVolumeMutation($appId: String,$size: String){
    updateVolume(input: {appId:$appId, size:$size}){
       message 
}}`


export const START_BUILD = gql`
  mutation startBuildApp($appId:String!, $sourceUrl: String!, $sourceType: String!, $buildType: String!, $imageTag: String!, $fileExtension: String!, $dockerFile: String, $dockerFilePath: String){
  startBuild(input:{
    appId:$appId
    sourceUrl:$sourceUrl
    sourceType:$sourceType
    buildType:$buildType
    imageTag:$imageTag
    fileExtension:$fileExtension
    dockerFile: $dockerFile
    dockerFilePath: $dockerFilePath
  }){
    build {
      id
      inProgress
      status
      
      user {
        id
        name
        email
      }
      logs
      image
      createdAt
      updatedAt
    }
    buildLogs
  }
}
`

export const CONFIG_DEFINITION = gql`
  query AppQueryConfigDefinition($name: String!){
  app(name: $name){
    id
    imageName
    deployed
    config {
      definition
    }
  }
}
`

export const CHECK_VOLUME = gql`
  query CheckVolume($orgId: String!){
        getClusterDetailsByOrgId(orgId: $orgId) {
          interfaceType
        }
      
}
`

export const FETCH_USER_PAT = gql`
query FetchUserPAT{
    getUserPAT{
            id
            type
            patToken
            userId
            createdAt
            updatedAt
        }
      
}
`


export const UPDATE_APP = gql`
mutation($appId: String!,$internalPort: String!,$externalPort: String!, $build: String!, $routingPolicy: String!, $resource : String!, $replicas: Int!){
  updateApp(input: {appId:$appId,build: $build, internalPort:$internalPort, externalPort:$externalPort, routingPolicy: $routingPolicy, resource: $resource, replicas: $replicas}){
    id,
    name,
    status,
    deployed,
    hostname,
    appUrl,
    version,
    imageName,
    port,
    config{
      definition
    }
  }
}
`

export const CHECK_GITHUB_REPO_APP = gql`
mutation($githubUrl: String!){
    checkGithubRepoPrivateOrPublic(githubUrl:$githubUrl)
}
`



export const jsonToKeyValuePairs = (jsonObject) => {
    return Object.entries(jsonObject)
        .map(([key, value]) => `${key}=${value}`)
        .join('\n');
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    input: {
        "&:invalid": {
            border: "red solid 2px"
        }
    }
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: props.marginZero ? 0 : 3, pb: props.marginZero ? 3 : 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


export default function FullWidthGrid(props) {
    const classes = useStyles();
    const [hideDockerFileUpload, sethideDockerFileUpload] = useState(false)
    const [internalPortValidation, setinternalPortValidation] = useState(false)
    const [externalPortValidation, setexternalPortValidation] = useState(false)
    const [internalport, setinternalport] = useState("4000")
    const [externalport, setexternalport] = useState("80")
    const [dockerImageInput, setdockerImageInput] = useState("")
    const [def, setdef] = useState({})
    const [isErrorDockerImageInput, setisErrorDockerImageInput] = useState(false)
    const [dockerImageInputErrorMessage, setdockerImageInputErrorMessage] = useState("")
    const [envKeyError, setEnvKeyError] = useState("");
    const [fileUploaded, setfileUploaded] = useState(false)
    const [fileUploadedsuccessfully, setfileUploadedsuccessfully] = useState(false)
    const [fileUploadedError, setfileUploadedError] = useState(null)
    const [startBuildCompleted, setstartBuildCompleted] = useState(false)
    const [envMap, setEnvMap] = React.useState([])
    const [sourceType, setSourceType] = useState("")
    const [builtType, setBuiltType] = useState("")
    const [routingPolicy, setroutingPolicy] = useState(true)
    const [url, seturl] = useState("")
    const [secretvalue, setsecretvalue] = useState("")
    const [progressDockerImageInput, setprogressDockerImageInput] = useState(false)
    const [calldeployimage, setcalldeployimage] = useState(false)
    const [envfile, setenvfile] = useState("");
    const [envErrorMsg, setenvErrorMsg] = useState("");
    const [isEnvFileError, setisEnvFileError] = useState(false);
    const [filevalue, setfilevalue] = useState(3);
    const [inprogress, setinprogress] = useState(false);
    const [noOfEnvs, setnoOfEnvs] = useState(0);
    const [runtime, setruntime] = useState("");
    const [isQuickApps, setisQuickApps] = useState(false);
    const [quickApp, setquickApp] = useState(null);
    const [githubLink, setgithubLink] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [replica, setreplica] = useState(1);
    const [redeployBuilder, setredeployBuilder] = useState("");


    const [RequestCPU, setRequestCPU] = useState("");
    const [LimitCPU, setLimitCPU] = useState("");
    const [RequestMemory, setRequestMemory] = useState("");
    const [LimitMemory, setLimitMemory] = useState("");
    const [dockerfilevalue, setdockerfilevalue] = useState('');
    const [showdockerfilepath, setshowdockerfilepath] = useState(false);
    const [dockerFilePathValue, setdockerFilePathValue] = useState("");
    const [showgitprivate, setshowgitprivate] = useState(false);

    const [errorRequestCPU, seterrorRequestCPU] = useState(false);
    const [errorLimitCPU, seterrorLimitCPU] = useState(false);

    const [errorRequestMemory, seterrorRequestMemory] = useState(false);
    const [errorLimitMemory, seterrorLimitMemory] = useState(false);

    const [expanded, setExpanded] = React.useState('panel2');
    const [moreOption, setMoreOption] = React.useState('addtional');


    const [volumePath, setvolumePath] = useState(`/data/${props.name}`);
    const [volumeSize, setvolumeSize] = useState("");
    const [beforeTime, setbeforeTime] = useState("");
    const [afterTime, setafterTime] = useState("");
    const [volumeSizeLimit, setvolumeSizeLimit] = useState(10);

    const [listofrepo, setlistofrepo] = useState([]);
    const [age, setAge] = React.useState('');
    const [replicasize, setreplicasize] = React.useState(1)
    const [showAddUserLevelPAT, setshowAddUserLevelPAT] = React.useState(false)
    const [firstTimeGithubToken, setfirstTimeGithubToken] = React.useState("");
    const [showGithubFetchLoading, setshowGithubFetchLoading] = useState(false);

    const [templateList, settemplateList] = useState(null);
    const [fetchingTemplates, setfetchingTemplates] = useState(true);
    const handleVerifyResourceConfig = (input) => {

        // Check if the input is empty
        if (input === '') {
            return false; // It's valid if it's empty
        }

        // Check if the input is a positive number with optional decimal up to one digit
        const numericInput = parseFloat(input);
        if (!isNaN(numericInput) && numericInput !== 0 && numericInput >= 1 && numericInput <= JSON.parse(localStorage.getItem("access")).infrastructureConfiguration) {
            // Convert the numeric input to a string to check the decimal part
            const inputString = numericInput.toString();
            if (inputString.includes('.') && inputString.split('.')[1].length > 1) {
                // Decimal part has more than one digit, so it's invalid
                return true;
            }
            return false; // Valid
        }

        return true; // Invalid



    }

    const handleFetchGithubRepos = (tokenValue) => {
        if ((datalist && datalist.getUserPAT && datalist.getUserPAT[0] && datalist.getUserPAT[0].patToken) || tokenValue !== "") {
            const octokit = new Octokit({
                auth: `token ${tokenValue !== "" ? tokenValue : (datalist && datalist.getUserPAT && decodeAes(datalist.getUserPAT[0].patToken))}`,
            });
            setshowGithubFetchLoading(true)
            async function requestUser() {
                try {
                    const resp = await octokit.request('Get /user/repos', {});
                    setlistofrepo(resp.data)
                    setshowGithubFetchLoading(false)
                }
                catch (error) {
                    console.error('Error:', error);
                    setshowGithubFetchLoading(false)
                }

            }

            requestUser()

        }

    }


    const handleChangeGithub = (event, newValue) => {
        setAge(event.target.value);
        setshowGithubCheckError(false)
        updateRegistryId(
            {
                variables: {
                    appName: props.name,
                    name: event.target.value
                }
            }
        )

    };

    const handleGithubInputUrl = (event, newValue) => {
        setshowGithubCheckError(false)
        setgithubLink(event.target.value)
        valuevalidateGitUrl(event);
        if (!selectedfromgithublist) {
            checkGihubRepo({ variables: { githubUrl: event.target.value } })
        }


    }


    const handleChangeReplicas = (e) => {
        setreplica(e.target.value);
    }

    const [checked, setChecked] = React.useState([]);

    var CryptoJS = require("crypto-js");
    function decodeAes(encryptedDataStr) {
        var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
        var encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedDataStr);
        var encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

        var decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
            iv: key,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        decryptedData = decryptedData.toString(CryptoJS.enc.Utf8)
        return decryptedData

    }





    const { enqueueSnackbar } = useSnackbar();

    const [revertApp, { data: revertAppdata, loading: revertApploading, error: revertApperror }] = useMutation(REVERT_APP, {
        refetchQueries: [HISTORY, APP_LIST],
        onError: (err) => {
            props.handlePost()
            enqueueSnackbar("Error!", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        },
        onCompleted: () => {
            enqueueSnackbar("Successfully Completed!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

            props.handlePost()
            LogTrigger("Revert App Successful", "info", window.location.href, "App configuration")
        }
    });


    const handleChangeTab = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleChangeMore = () => {
        if (dockerfilevalue === "") {
            setdockerfilevalue('# Edit here!\n\nFROM node:4.2\nCOPY . /src\nRUN cd /src && npm install\nEXPOSE 4000\nCMD ["node", "/src/server.js"]')
            enqueueSnackbar("Added default dockerfile!", {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        }
        setMoreOption("dockerfile");
    };


    const handleCancelMore = () => {
        setdockerfilevalue("")
        setMoreOption("addtional");
        enqueueSnackbar("Discarded custom dockerfile!", {
            variant: 'info',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    };

    const handleSaveMore = () => {
        setMoreOption("addtional");
        enqueueSnackbar("Saved!", {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    };

    const showFile = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            setdockerfilevalue(text)
        };
        reader.readAsText(e.target.files[0]);
        enqueueSnackbar("Uploaded Successfully!", {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };


    const [githubBranch, setgithubBranch] = useState("main");
    const [templateVlaue, setTemplateVlaue] = React.useState(null);


    const [inputFields, setInputFields] = useState([
        { key: '', value: '' },
    ]);

    const [inputVolumeFields, setInputVolumeFields] = useState([
    ]);

    const [value, setValue] = React.useState(0);


    const quickAppsList = [
        { "name": "Komiser", "image": "mlabouardy/komiser:2.11.0", "iport": "3000", "eport": "3000", "env": null, "type": "bi" },
        { "name": "Kanboard", "image": "kanboard/kanboard:latest", "iport": "80", "eport": "80", "env": null, "type": "productivity" },
        { "name": "Nginx", "image": "nginx:latest", "iport": "80", "eport": "80", "env": null, "type": "deployment" },
        { "name": "To-do List", "image": "thoba/todo-list-app", "iport": "8080", "eport": "8080", "env": null, "type": "productivity" },
        { "name": "Super Mario", "image": "pengbai/docker-supermario:latest", "iport": "8080", "eport": "8080", "env": null, "type": "game" }

    ]

    const handleMarkePlaceDeployments = (e) => {
        setfilevalue(5)
        setTemplateVlaue(e);
        handleTeplateSelect(e);

        const values = [...inputFields];
        setdockerImageInput(e.image)
        setinternalport(e.iport)
        setexternalport(e.eport)
        setValue(1)
        if (e.volume) {
            setvolumeSize(e.volume)
        }
        if (e.volumePath) {
            setvolumePath(e.volumePath)
        }
        setisQuickApps(true)
        setquickApp(e)
        values.splice(0, 1);
        if (e.env) {
            e.env.forEach((i) => {
                values.push({ key: i.key, value: i.value ? i.value : '' });
            })
        }
        else {
            values.push({ key: '', value: '' });
        }
        setInputFields(values);
        handlePortUpdate(e.iport, e.eport)
    }

    const handleQuickDeployments = (e) => {
        const values = [...inputFields];


        setdockerImageInput(quickAppsList[e].image)
        setinternalport(quickAppsList[e].iport)
        setexternalport(quickAppsList[e].eport)
        setValue(1)
        setisQuickApps(true)
        setquickApp(quickAppsList[e])
        values.splice(0, 1);
        if (quickAppsList[e].env) {
            quickAppsList[e].env.forEach((i) => {
                values.push({ key: i.key, value: '' });

            })
        }
        else {
            values.push({ key: '', value: '' });
        }
        setInputFields(values);
        handlePortUpdate(quickAppsList[e].iport, quickAppsList[e].eport)
    }

    const handleTeplateSelect = (value) => {
        if (value && value.deploymentType === "docker") {
            setdockerImageInput(value.image)
            setinternalport(value.iport)
            setexternalport(value.eport)
            setValue(1)
            setisQuickApps(true)
            setquickApp(value)
            handlePortUpdate(value.iport, value.eport)
        }

    }

    const handleResetQuickDeployments = (e) => {
        setTemplateVlaue(null)
        const values = [...inputFields];
        setdockerImageInput("")
        setinternalport("4000")
        setexternalport("80")
        values.splice(0, values.length);
        values.push({ key: '', value: '' });
        setInputFields(values);
        setisQuickApps(false)

    }

    const [gitUrlValidation, setGitUrlValidation] = React.useState(false)
    const valuevalidateGitUrl = (e) => {

        const checkvalue = e.target.value.substring(0, 19) === "https://github.com/"

        if (checkvalue.length > 0) {
            setGitUrlValidation(false)
        }
        setGitUrlValidation((checkvalue) ? false : true)

        if (e.target.value === "") {
            setGitUrlValidation(false)
        }
    }



    var definitionMap = new Map();
    var temp = ""
    const { loading: loadlist, error: errlist, data: datalist, refetch: refetchrepolist } = useQuery(FETCH_USER_PAT, {
        onCompleted: (data) => {

            handleFetchGithubRepos("");
        }
    });



    const { data: appdata, error: apperr, loading: appload, refetch: apprefetch } = useQuery(CONFIG_DEFINITION, {
        variables: {
            name: props.name
        }, notifyOnNetworkStatusChange: true,
        onCompleted: () => {

            { appdata.app.config && definitionMap.set('definition', appdata.app.config.definition) }
            setdef(definitionMap.get("definition"))
        }
    })






    const { loading: loadforbulitintypes, error: errofbulitintypes, data: dataofbulitintypes } = useQuery(BUILTIN_TYPES);

    const { loading: loadfortemplate, error: erroftemplate, data: dataoftemplate } = useQuery(CONFIF_TEMPLATE_LIST);

    const { loading: loadOrgOfApp, error: errOrgOfApp, data: dataOrgOfApp } = useQuery(FETCH_ORG_FOR_APP, { variables: { name: props.name } });

    const { loading: load, error: err, data: appStatus, refetch: refetch } = useQuery(APP, { variables: { name: props.name } });


    const { data: checkVoldata, error: checkVolerr, loading: checkVolload } = useQuery(CHECK_VOLUME, {
        variables: {
            orgId: dataOrgOfApp && dataOrgOfApp.app.organization && dataOrgOfApp.app.organization.id
        },
        onCompleted: () => {

        }
    })

    const { loading: loadsec, error: errorsec, data: datasec } = useQuery(SECRETS_LIST, {
        variables: { name: "" },
    });

    const [updateApp, { data: updateData, loading: updateLoad, error: updateErr }] = useMutation(UPDATE_APP, {
        refetchQueries: [CONFIG_DEFINITION],
        onCompleted: () => {
            apprefetch()
        }
    });

    const [showGithubCheckError, setshowGithubCheckError] = useState(false);
    const [selectedfromgithublist, setselectedfromgithublist] = useState(false);

    const [checkGihubRepo, { data: gitrepo, loading: gitrepoLoad, error: gitrepoErr }] = useMutation(CHECK_GITHUB_REPO_APP, {

        onCompleted: () => {

            setshowGithubCheckError(false)
        }
        , onError: () => {
            setshowGithubCheckError(true)
        }
    });

    const onDrop = (acceptedFiles) => {
        handleFileUpload(acceptedFiles);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxSize: 100000000,
    });


    const [secname, setsecname] = useState("");
    const [secuser, setsecuser] = useState("");
    const [secpass, setsecpass] = useState("");
    const [secorgid, setsecorgid] = useState("");
    const [volumeSizeError, setvolumeSizeError] = useState(false);
    const [DBnamevalidation, setDBnamevalidation] = useState(false);

    const [createSecret, { data: secData, loading: secLoad, error: secErr }] = useMutation(CREATE_SECRETS, {
        refetchQueries: [SECRETS_LIST],
        onCompleted: () => {
            updateRegistryId(
                {
                    variables: {
                        appName: props.name, // app name
                        name: secname  //secret name
                    }
                }
            )
            // enqueueSnackbar(secErr ? secErr.message : "Saved!", {
            //     variant: 'success',
            //     anchorOrigin: {
            //         vertical: 'top',
            //         horizontal: 'right',
            //     },
            // })


        },
        onError: (err) => {
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            setsecname("")
            setsecuser("")
            setsecpass("")
            setsecorgid("")
        }
    });
    const [deleteSecret, { data: deleteData, loading: deketeLoad, error: deleteErr }] = useMutation(DELETE_SECRETS, {
        refetchQueries: [SECRETS_LIST],
        onCompleted: () => {
            // setOpen(false)
            LogTrigger(`Nife varibale deleted `, "info", window.location.href, "Nife Varibales")

        }
    });

    var CryptoJS = require("crypto-js");

    function encodeAes(plaintText) {
        var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
        var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
            iv: key,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        encryptedData = encryptedData.ciphertext.toString();

        return encryptedData

    }

    const handleDatabase = () => {
        if (runtime === "mysql") {
            setdockerImageInput("mysql:5.6")
        }
        if (runtime === "postgres") {
            setdockerImageInput("postgres:10.1")
        }

        createSecret({
            variables: {
                name: secname,
                organizationId: dataOrgOfApp && dataOrgOfApp.app.organization && dataOrgOfApp.app.organization.id,
                registryType: runtime,
                userName: secuser,
                password: encodeAes(secpass),
                url: "",
            }
        });
    }

    const handleCreatePATforGithub = () => {
        if (datalist && datalist.getUserPAT && datalist.getUserPAT[0] && datalist.getUserPAT[0].patToken) {
            var temp = props.name + "-secret-" + Math.floor(1000 + Math.random() * 9000)
            setsecname(temp)
            createSecret({
                variables: {
                    name: temp,
                    organizationId: dataOrgOfApp && dataOrgOfApp.app.organization && dataOrgOfApp.app.organization.id,
                    registryType: "PAT",
                    userName: "",
                    password: datalist.getUserPAT[0].patToken,
                    url: "",
                }
            });
        }
    }

    const [deployImage, { data: DPData, loading: DPLoad, error: DPErr }] = useMutation(DEPLOY_IMAGE, {
        refetchQueries: [APPS, APP_LIST, APP_LOCATION_LIST, USER_ACTIVITY, HISTORY],
        onCompleted: () => {
            var time = new Date()
            setafterTime(time)
            setisLoading(true)

            setcalldeployimage(false)
            if (selectedfromgithublist) {
                deleteSecret({ variables: { name: secname, id: "" } })
            }
            if (props.setdeployApp) {
                props.setdeployApp(false)
            }
            if (props.redeploy) {
                enqueueSnackbar("Redeployed Successfully!", {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }

        }
    });

    const [addVolume, { data: VolData, loading: VolLoad, error: VolErr }] = useMutation(ADD_VOLUME, {
        onCompleted: () => {
            enqueueSnackbar("Attached Volume Successfully!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    });


    const [startBuild, { data: buildData, loading: buildLoad, error: buildErr }] = useMutation(START_BUILD, {
        refetchQueries: [CONFIG_DEFINITION],
        onError: (e) => {
            setstartBuildCompleted(true)
            if (selectedfromgithublist) {
                deleteSecret({ variables: { name: secname, id: "" } })
            }
        },
        onCompleted: () => {
            setcalldeployimage(true)
        }
    });

    const [updateRegistryId, { data: regidData, loading: regidLoad, error: regidErr }] = useMutation(UPDATE_REGISTRY, {

        refetchQueries: [],
        onError: (e) => {

        },
        onCompleted: () => {
            setprogressDockerImageInput(false)
            if (selectedfromgithublist) {
                handleSubmitGithub();
            }
        }
    });

    const handlePortUpdate = (i, e, r) => {
        let debounceTimer;

        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {

            var name = props.name
            var image = ""
            var builtin = ""
            var builder = ["Deploy Image", "Remote Build", "Built-In", "Github", "Template", "Deploy Image"]
            if (dockerImageInput != "") {
                image = dockerImageInput
            }

            if (sourceType != "") {
                builtin = sourceType
            }

            var temp
            if (routingPolicy) {
                temp = "Latency"
            }
            else {
                temp = "Geolocation"
            }
            if (r) {
                temp = r
            }


            updateApp({
                variables: {
                    appId: name, build: `{"image": "${image}", "builder": "${builder[filevalue]}", "builtin": "${builtin}"}`, replicas: props.redeploy ? props.replicas : replica
                    , routingPolicy: temp, resource: `{"RequestRequirement":{"cpu":"${RequestCPU}","Memory":"${RequestMemory}"},"LimitRequirement":{"cpu":"${LimitCPU}","Memory":"${LimitMemory}"}}`, internalPort: i.toString(), externalPort: e.toString()
                }
            })


            apprefetch()
        }, 1000);


    }


    const handleAddFields = () => {
        const values = [...inputFields];
        values.push({ key: '', value: '' });
        setInputFields(values);

    };



    const handleAddVolumeFields = () => {


        addVolume({
            variables: {
                appId: props.name,
                name: props.name,
                size: volumeSize,
                path: volumePath
            }
        })




    };


    const handleRemoveFields = index => {
        if (Object.keys(inputFields).length > 1) {
            const values = [...inputFields];
            values.splice(index, 1);
            setInputFields(values);
        }

    };

    // const handleRemoveVolumeFields = index => {

    //     const values = [...inputVolumeFields];
    //     values.splice(index, 1);
    //     setInputVolumeFields(values);


    // };

    const handleInputEnvFile = (e) => {
        setisEnvFileError(false)
        setenvErrorMsg('')
        setenvfile(e.target.value)
    }

    const validateEnvFile = () => {
        setnoOfEnvs(0)
        var lines = envfile.split("\n")
        var regexp = /^[A-Za-z0-9]+(?:_[A-Za-z0-9]+)*$/
        for (var line = 0; line < lines.length; line++) {

            var key = lines[line].split("=")

            if (regexp.test(key[0])) {
                setnoOfEnvs(line + 1)

                var temp1 = key[0];
                var temp2 = key[1];
                inputFields.key = temp1;
                inputFields.value = temp2;
            }
            else {
                setisEnvFileError(true)
                setenvErrorMsg(`Format error at line ${line + 1}, input should be in KEY=VALUE format`);
            }

        }
    }

    const validateInternalPort = (e) => {
        var regexp = /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
        const checkvalue = regexp.test(e.target.value.toLowerCase())
        if (checkvalue.length > 0) {
            if (e.target.name = "externalPort") {
                setexternalPortValidation(false)
            }
            else {
                setinternalPortValidation(false)
            }
        }
        if (e.target.name == "externalPort") {
            if (checkvalue) {
                setexternalPortValidation(false)
            }
            else {
                setexternalPortValidation(true)
            }
        }
        else {
            if (checkvalue) {
                setinternalPortValidation(false)
            }
            else {
                setinternalPortValidation(true)
            }

        }
    }

    const [dockerImageVerifyError, setdockerImageVerifyError] = useState(false);

    const handleChangeSecret = (event, newValue) => {


        setsecretvalue(newValue)
        setdockerImageVerifyError(false)

        setisErrorDockerImageInput(false)
        const headers = {
            'Content-Type': 'application/json'
        }


        if (newValue && datasec && datasec.getSecret) {
            var regId = datasec.getSecret.find(o => o.name === newValue)
            setinprogress(true)
            const data = {
                "image": dockerImageInput,
                "secRegistryId": regId.id
            }
            setprogressDockerImageInput(true)
            axios.post(`${process.env.REACT_APP_API_LIVE}/api/v1/findDockerImage`, data, { headers: headers }).then((res) => {
                setinprogress(false)
                setprogressDockerImageInput(false)

                updateRegistryId(
                    {
                        variables: {
                            appName: props.name,
                            name: newValue.name
                        }
                    }
                )
            }, (err) => {

                setinprogress(false)
                setdockerImageInputErrorMessage(err.response)
                setprogressDockerImageInput(false)
                setsecretvalue("")
                // enqueueSnackbar(err.message, {
                //     variant: 'error',
                //     anchorOrigin: {
                //         vertical: 'top',
                //         horizontal: 'right',
                //     },
                // })
                setisErrorDockerImageInput(true)
            }
            )
        }
    }




    const handleImageName = (e) => {
        setdockerImageInput(e.target.value)
        setisErrorDockerImageInput(false)

        setinprogress(true)
        setdockerImageVerifyError(false)
        const headers = {
            'Content-Type': 'application/json'
        }
        const data = {
            "image": e.target.value,
            "secRegistryId": ""
        }
        setprogressDockerImageInput(true)
        axios.post(`${process.env.REACT_APP_API_LIVE}/api/v1/findDockerImage`, data, { headers: headers }).then((res) => {
            setinprogress(false)
            setprogressDockerImageInput(false)
        }, (err) => {

            setinprogress(false)
            setprogressDockerImageInput(false)
            setdockerImageVerifyError(true)

        })
    }

    const handleChangeBuiltInTypes = (e) => {
        setSourceType(e.target.value)
        setBuiltType("Builtin")
        setruntime(e.target.value)
    }

    function getFlagValue(command, flag) {
        // Split the command into an array of words
        const commandArray = command.split(' ');

        // Find all occurrences of the flag in the command array
        const flagIndices = commandArray.reduce((indices, word, index) => {
            if (word === flag) {
                indices.push(index);
            }
            return indices;
        }, []);

        // If the flag is not present, return an empty array
        if (flagIndices.length === 0) {
            return [];
        }

        // Get the values following each occurrence of the flag
        const values = flagIndices.map((flagIndex) => {
            const value = commandArray[flagIndex + 1];
            // If the value starts with '-', it might be another flag, so return null
            return (value && !value.startsWith('-')) ? value : null;
        });

        // Filter out null values and return the result
        return values.filter((value) => value !== null);
    }


    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        const envValue = [...envMap];

        if (event.target.name === "key") {
            temp = temp + event.target.value
            const newValue = event.target.value;
            if (!newValue.match(/[a-z]{1,15}/)) {
                setEnvKeyError("");
            } else {
                setEnvKeyError("Unforbidden character: %<>$'\"");
            }
            values[index].key = event.target.value;

        } else {
            const newValue = event.target.value;
            if (!newValue.match(/[a-z]{1,15}/)) {
                setEnvKeyError("");
            } else {
                setEnvKeyError("Unforbidden character: %<>$'\"");
            }
            values[index].value = event.target.value;
        }

        envValue[index] = temp + "=" + event.target.value
        setEnvMap(envValue)
        setInputFields(values);
    };


    const handleFocusDockerName = () => {
        sethideDockerFileUpload(true)
    }

    const handleBlurDockerName = () => {
    }

    const handleResetDockerImageName = () => {
        setstartBuildCompleted(false)
        setfileUploadedError(null)
        setfileUploaded(false)
        setBuiltType("")
        seturl(null)
        setSourceType("")
        setselectedfromgithublist(false)
        setruntime("")
        setsecretvalue("")
        setgithubBranch("main")
        setgithubLink("")
        sethideDockerFileUpload(false)
        setdockerImageInput("")
        setisErrorDockerImageInput(false)
    }

    const handleRestDockerFileUpload = () => {
        setstartBuildCompleted(false)
        setfileUploaded(false)
        setBuiltType("")
        seturl(null)
        setSourceType("")
    }
    const handleFileUpload = async (e) => {

        if (e[0]) {
            setfileUploaded(true)
            await axios
                .post(`${process.env.REACT_APP_API_LIVE}/api/v1/fileUpload/${props.name}?fileExtension=${e[0].name.split('.').pop()}`, e[0], { headers: { "Content-Type": e.type } })
                .then((response) => {
                    setfileUploadedsuccessfully(true)
                    seturl(response.data.s3Link)


                })
                .catch((error) => {
                    setfileUploadedError(error.message)
                });
        }
    };

    // const uploadFunction = async (file) => {
    //     if (!file) return;

    //     AWS.config.update({
    //         accessKeyId: 'AKIA33QERXVK7DSPF3HL',
    //         secretAccessKey: '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',
    //         region: 'ap-south-1'
    //     });


    //     const s3 = new AWS.S3();


    //     const fileName = `${Date.now()}-${file.name}`;

    //     // Set up parameters for the upload
    //     const params = {
    //         Bucket: 'react-nife-2024',
    //         Key: fileName,
    //         Body: file,
    //         ContentType: file.type,
    //         ACL: 'public-read'
    //     };


    //     try {
    //         // Get a pre-signed URL for the upload
    //         const signedUrl = await s3.getSignedUrlPromise('putObject', params);

    //         // Upload the file using the pre-signed URL
    //         await fetch(signedUrl, {
    //             method: 'PUT',
    //             body: file,
    //             headers: {
    //                 'Content-Type': file.type
    //             }
    //         });

    //         // Set the S3 URL for the uploaded file
    //         console.log(`https://react-nife-2024.s3.amazonaws.com/${fileName}`);
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //     }


    //     // const chunkSize = 100 * 1024 * 1024; // 100MB chunk size
    //     // const totalChunks = Math.ceil(file.size / chunkSize);

    //     // Iterate over each chunk and upload it
    //     // for (let i = 0; i < totalChunks; i++) {
    //     //   const start = i * chunkSize;
    //     //   const end = Math.min(file.size, start + chunkSize);
    //     //   const chunk = file.slice(start, end);

    //     //   try {
    //     //     const response = await axios.post(
    //     //       `${process.env.REACT_APP_API_LIVE}/api/v1/fileUpload/${props.name}?fileExtension=${file.name.split('.').pop()}`,
    //     //       chunk,
    //     //       {
    //     //         headers: {
    //     //           'Content-Type': file.type,
    //     //           // You may need to include additional headers here
    //     //         },
    //     //       }
    //     //     );

    //     //     // Handle response as needed
    //     //     console.log('Chunk uploaded successfully:', response.data);
    //     //   } catch (error) {
    //     //     console.error('Error uploading chunk:', error.message);
    //     //     // Handle error as needed
    //     //   }
    //     // }
    // };


    // const handleFileUpload = (event) => {
    //     const file = event[0];
    //     if (file) {
    //         setfileUploaded(true); // Assuming this sets a state variable indicating that the file upload has started
    //         uploadFunction(file)
    //             .then(() => {
    //                 setfileUploadedsuccessfully(true)
    //                 //  seturl(response.data.s3Link)

    //                 // Additional logic after successful upload
    //             })
    //             .catch((error) => {
    //                 setfileUploadedError(error.message);
    //                 // Additional error handling logic
    //             });
    //     }
    // };

    const handleConfigTemplate = (e) => {
        if (e.routingPolicy) {
            setroutingPolicy(e.routingPolicy)
        }
        if (e.volumeSize) {
            setvolumeSize(e.volumeSize.toString())
        }

        if (e.envArgs) {
            setenvfile(jsonToKeyValuePairs(JSON.parse(e.envArgs)))
        }

        setdockerImageInput(e.config.build.image)
        handlePortUpdate(e.config.definition.services[0].internal_port, e.config.definition.services[0].external_port)
        setinternalport(e.config.definition.services[0].internal_port)
        setexternalport(e.config.definition.services[0].external_port)
        setRequestCPU(e.config.definition.services[0].requests.cpu)
        setRequestMemory(e.config.definition.services[0].requests.memory)
        setLimitCPU(e.config.definition.services[0].limits.cpu)
        setLimitMemory(e.config.definition.services[0].limits.memory)
        enqueueSnackbar("Updated!", {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    }
    const [dockerFilePath, setdockerFilePath] = useState("react123/react-sample/Dockerfile");

    const handleSubmitDockerfile = () => {
        handlePortUpdate(internalport, externalport)
        temp = null
        if (dockerfilevalue) {
            temp = dockerfilevalue
        }

        startBuild({
            variables: {
                appId: props.name,
                sourceUrl: url,
                sourceType: sourceType,
                buildType: builtType,
                fileExtension: url.split('.').pop(),
                dockerFile: temp,
                dockerFilePath: dockerFilePathValue,
                imageTag: `nife123/${props.name}:deployment-${Math.random().toString(20).substr(2, 6)}`
            }
        })



    }

    const handleGithubDeploy = () => {

        if (selectedfromgithublist) {
            handleCreatePATforGithub();
        }
        else {
            handleSubmitGithub();
        }
    }

    const handleParseClick = (inputValue) => {
        var returnValue;
        const regex = /^(?:https?:\/\/)?(?:www\.)?github\.com\/([^/]+)\/([^/]+)(?:\.git)?$/;
        const matches = inputValue.match(regex);
        if (matches && matches.length === 3) {
            const owner = matches[1];
            const repo = matches[2];
            const token = datalist && datalist.getUserPAT && datalist.getUserPAT[0] && decodeAes(datalist.getUserPAT[0].patToken);
            returnValue = { owner, repo, token };
        } else {
            returnValue = null;
        }

        return returnValue;
    };

    const handleSubmitGithub = (value) => {
        var githubLinkValue = githubLink;

        if (value) {
            githubLinkValue = value;
        }
        handlePortUpdate(internalport, externalport)
        var temp;
        if (githubLinkValue.endsWith(".git")) {
            temp = `${githubLinkValue.substring(0, githubLinkValue.length - 4)}/archive/refs/heads/${githubBranch}.zip`
        }
        else {
            temp = `${githubLinkValue}/archive/refs/heads/${githubBranch}.zip`
        }
        var tempdockerFile = null
        if (dockerfilevalue) {
            tempdockerFile = dockerfilevalue
        }

        if (selectedfromgithublist) {
            const parseValue = handleParseClick(githubLinkValue)
            if (parseValue.token) {
                localStorage.setItem("githubCICDtoken", JSON.stringify(parseValue))
            }
            if (props.setdataCICDGithub) {
                props.setdataCICDGithub("Github")
            }
        }



        startBuild({
            variables: {
                appId: props.name,
                sourceUrl: temp,
                sourceType: sourceType,
                buildType: builtType,
                fileExtension: "zip",
                dockerFile: tempdockerFile,
                dockerFilePath: dockerFilePathValue ? `${dockerFilePathValue.split("/")[0]}-${githubBranch}${dockerFilePathValue.slice(-(dockerFilePathValue.length - dockerFilePathValue.indexOf("/", 1)))}` : "",
                imageTag: `nife123/${props.name}:deployment-${Math.random().toString(20).substr(2, 6)}`
            }
        })
    }

    const valuevalidateDBname = (e) => {
        const re = /^[a-z0-9_\-]+$/;
        const checkvalue = re.test(e.target.value)
        if (checkvalue.length > 0) {
            setDBnamevalidation(false)
        }
        setDBnamevalidation((checkvalue) ? false : true)
    }

    const parseDockerCommand = (dockerCommand) => {
        if (dockerCommand) {
            setdockerImageInput(dockerCommand.split(" ").pop())

            setisEnvFileError(false)
            setenvErrorMsg('')
            try {
                setenvfile(getFlagValue(dockerCommand, '-e').join("\n"))
                setnoOfEnvs(getFlagValue(dockerCommand, '-e').length)
                setvolumePath(getFlagValue(dockerCommand, '-v'))
                if (getFlagValue(dockerCommand, '-p')) {
                    setexternalport(getFlagValue(dockerCommand, '-p')[0].split(":")[0])
                    setinternalport(getFlagValue(dockerCommand, '-p')[0].split(":")[1])
                }
            } catch (error) {
                console.log(error)
            }


        } else {
            console.log('No match found');
        }
    };

    const handleSubmit = () => {
        apprefetch()
        handlePortUpdate(internalport, externalport)
        if (!props.redeploy && volumeSize && volumeSize !== 0) {
            handleAddVolumeFields()
        }

        var name = props.name
        var temp = []

        for (let [key, value] of Object.entries(inputFields)) {
            if (value["key"] !== "" && value["value"] !== "" && value["key"] !== "undefined" && value["value"] !== "undefined") {
                temp.push(value["key"] + "=" + value["value"])
            }
        }

        if (envfile !== "") {
            temp = temp.concat(envfile.split("\n"))
        }

        if (checked.length != 0) {
            checked.forEach(element => {
                let obj = datasec.getSecret.find(o => o.id === element)
                temp.push(obj.userName + "=" + decodeAes(obj.passWord))
            });
        }


        var time = new Date()
        setbeforeTime(time)

        temp = temp.filter((e) => {
            return e !== "undefined=undefined"
        })


        if (props.redeploy) {

            revertApp({ variables: { version: 0, appName: props.newname, appId: props.appdata, envMapArgs: temp, image: dockerImageInput, externalPort: externalport.toString(), internalPort: internalport.toString(), archiveUrl: url ? url : "" } })
        }
        else {
            deployImage({ variables: { appId: name, image: dockerImageInput, definition: def, envMapArgs: temp, envArgs: "", archiveUrl: url ? url : "" } });
            if (props.sethideChangeOrg) {
                props.sethideChangeOrg(true)
            }
        }

    }

    const handleMarkePlaceDeploymentsType = () => {
        if (templateVlaue.deploymentType === "github") {
            setgithubLink(templateVlaue.githubSourceCode)
            handleSubmitGithub(templateVlaue.githubSourceCode);
        }
        else {
            handleSubmit();
        }
    }



    if (calldeployimage) {
        setcalldeployimage(false)
        handlePortUpdate(internalport, externalport)
        var name = props.name
        var temp = []

        if (!props.redeploy && volumeSize && volumeSize !== 0) {
            handleAddVolumeFields()
        }

        for (let [key, value] of Object.entries(inputFields)) {
            if (value["key"] !== "" && value["value"] !== "" && value["key"] !== "undefined" && value["value"] !== "undefined") {
                temp.push(value["key"] + "=" + value["value"])
            }

        }

        if (checked.length != 0) {
            checked.forEach(element => {
                let obj = datasec.getSecret.find(o => o.id === element)
                temp.push(obj.userName + "=" + decodeAes(obj.passWord))
            });
        }

        if (envfile !== "") {
            temp = temp.concat(envfile.split("\n"))
        }

        var time = new Date()
        setbeforeTime(time)

        temp = temp.filter((e) => {
            return e !== "undefined=undefined"
        })


        if (props.redeploy) {

            revertApp({ variables: { version: 0, appName: props.newname, appId: props.appdata, envMapArgs: temp, image: buildData.startBuild.build.image, externalPort: externalport.toString(), internalPort: internalport.toString(), archiveUrl: url ? url : "" } })
        }
        else {
            deployImage({ variables: { appId: name, image: buildData.startBuild.build.image, definition: def, envMapArgs: temp, envArgs: "", archiveUrl: url ? url : "" } });
            if (props.sethideChangeOrg) {
                props.sethideChangeOrg(true)
            }
        }
    }

    if (appStatus && appStatus.app.deployType === 0) {
        setTimeout(() => {
            refetch()
        }, 10000)
    }



    if (DPData) {
        if (appStatus && appStatus.app.deployType === 1) {
            var Difference_In_Time = afterTime - beforeTime
            if (!props.redeploy) {
                props.handleLink(DPData.deployImage.release ? DPData.deployImage.release.reason : "Not generated..", Difference_In_Time)
                props.fun(2)
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName === "Enterprise") {
            setvolumeSizeLimit(15)
        }
        if (localStorage.getItem("access")) {
            if (JSON.parse(localStorage.getItem("access")).planName === "Enterprise") {
                setreplicasize(5)
            }
            if (JSON.parse(localStorage.getItem("access")).planName === "Premium") {
                setreplicasize(3)
            }

        }
        if (props.deployApp && templateList) {
            templateList.forEach((e, i) => {
                if (e.name == props.deployApp) {
                    handleMarkePlaceDeployments(e)
                }
            });
        }

        if (props.redeploy && props.app) {
            if (props.app.sourceUrl && props.app.sourceUrl.startsWith("https://github.com/")) {
                setfilevalue(3)
                setgithubLink(props.app.sourceUrl.split("archive")[0])
                setgithubBranch(props.app.sourceUrl.split("/heads/")[1].slice(0, -4))
            }
            else{
                setfilevalue(0)
            }
            if (props.app.config.build.builder) {
                setredeployBuilder(props.app.config.build.builder)
            }
            if (props.app.config.definition.services[0].internal_port) {
                setinternalport(props.app.config.definition.services[0].internal_port.toString())
            }

            if (props.app.config.definition.services[0].external_port) {
                setexternalport(props.app.config.definition.services[0].external_port.toString())
            }

            if (props.app.imageName) {
                setdockerImageInput(props.app.imageName)
            }
            if (props.app.envArgs) {
                setenvfile(props.app.envArgs.slice(1, -1).replace(/:/g, "=").replace(/"/g, '').replace(/,/g, '\n').toString())
            }
        }

        const fetchData = async () => {
            setfetchingTemplates(true)
            try {
                const response = await axios.get('https://api.npoint.io/429393ab56ea69fc2446');
                setfetchingTemplates(false)
                if (response.data) {
                    settemplateList(response.data)
                }
            } catch (error) {
                setfetchingTemplates(false)
                console.error('Error fetching data:', error);
            }
        };
        fetchData();


    }, [datalist]);


    if (DPData) {
        LogTrigger("App Deployed", "info", window.location.href, "App configuration")
    }
    if (buildData) {
        LogTrigger("StartBuild successful", "info", window.location.href, "App configuration")
    }

    if (err) {
        LogTrigger("App data loaded", "info", window.location.href, "App configuration")
    }
    if (updateData) {
        LogTrigger("config updated", "info", window.location.href, "App configuration")
    }
    if (appdata) {
        LogTrigger("App data successful", "info", window.location.href, "App configuration")
    }
    if (erroftemplate) {
        LogTrigger("Template loaded", "info", window.location.href, "App configuration")
    }



    if (DPErr) {
        LogTrigger(DPErr.message, "error", window.location.href, "App configuration")
    }
    if (buildErr) {
        LogTrigger(buildErr.message, "error", window.location.href, "App configuration")
    }

    if (err) {
        LogTrigger(err.message, "error", window.location.href, "App configuration")
    }
    if (updateErr) {
        LogTrigger(updateErr.message, "error", window.location.href, "App configuration")
    }
    if (apperr) {
        LogTrigger(apperr.message, "error", window.location.href, "App configuration")
    }
    if (erroftemplate) {
        LogTrigger(erroftemplate.message, "error", window.location.href, "App configuration")
    }
    if (errofbulitintypes) {
        LogTrigger(errofbulitintypes.message, "error", window.location.href, "App configuration")
    }

    if (revertApperror) {
        LogTrigger(revertApperror.message, "error", window.location.href, "Revert App Mutation")
    }
    if (DPErr) return `Error: ${DPErr.message}`
    if (errofbulitintypes) return errofbulitintypes.message
    if (loadforbulitintypes || fetchingTemplates || loadfortemplate || checkVolload || loadsec) {
        return (
            <div>
                <Skeleton style={{ margin: "10px" }} />
                <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
            </div>
        );
    }
    if (erroftemplate) return erroftemplate.message
    if (apperr && apperr.message !== "sql: Rows are closed") return `Error:: ${apperr.message}`

    return (
        <div className={classes.root}>
            <Collapse in={(DPLoad || revertApploading) ? true : false} timeout="auto" unmountOnExit>
                <Box>
                    <LoadingNife title={"App"} />
                    {buildData && <>
                        <Slide direction="left" in={buildData ? true : false} mountOnEnter unmountOnExit>
                            <div>
                                <Typography> <IconButton><BsFileTextFill /></IconButton> Deployment Log</Typography>
                                <Box sx={{ borderRadius: "12px", p: 1, background: "#111", color: "white", mt: 1 }}>

                                    {buildData.startBuild.buildLogs.map((e, i) => {
                                        return <Fade
                                            key={i}
                                            in={true}
                                            style={{ transformOrigin: '0 0 0' }}
                                            {...(true ? { timeout: 100 * i } : {})}
                                        >

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Typography style={{
                                                    background: "#f5f5f5", color: "#111", paddingLeft: "5px", paddingRight: "5px", minWidth: "30px", marginRight: "5px",
                                                    borderTopLeftRadius: i === 0 ? "12px" : "0px",
                                                    borderTopLeftRadius: i === buildData.startBuild.buildLogs.length ? "12px" : "0px"
                                                }}> {i} </Typography>

                                                <Typography style={{ paddingRight: "5px", background: "#111", color: "white" }} noWrap>{buildData.startBuild.buildLogs[i]}</Typography>

                                            </Grid>

                                        </Fade>
                                    })

                                    }</Box>
                            </div>
                        </Slide>
                    </>}
                </Box>


            </Collapse>
            <Collapse in={(DPLoad || revertApploading) ? false : true} timeout="auto" unmountOnExit>
                <Grid container spacing={3}>
                    {props.redeploy && (redeployBuilder !== "MarketPlace") && <Grid item={12}>
                        <Typography variant='body2' sx={{ p: 2, borderRadius: "12px", bgcolor: "#fff8e1", color: "#ffc107" }}><IconButton><RiErrorWarningFill style={{ color: "#ffc107" }} /></IconButton>Warning previously you have used marketplace to deploy this app, if you use any other method your app configuration will overide and cannot revert back!</Typography>
                    </Grid>}
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                            <Grid item xs={9} sm={9} md={9}>
                                <Tooltip placement="top-end" arrow title={"Use Market Place to deploy app from Nife Market Place."}>
                                    <span>
                                        <Button sx={{ justifyContent: "flex-start", textAlign: "left", textTransform: "capitalize" }} onClick={() => setfilevalue(5)} disabled={isQuickApps ? true : url ? true : buildLoad ? true : (revertApploading ? true : revertApploading ? true : DPLoad ? true : false)} fullWidth={true} endIcon={filevalue !== 5 && <IoIosArrowForward />} startIcon={<StorefrontIcon />}>{filevalue === 5 ? "Market Place" : "Deploy from market place"}</Button>
                                    </span>

                                </Tooltip>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                                {filevalue === 5 ?
                                    <Button onClick={() => setfilevalue(3)} disabled={isQuickApps ? true : url ? true : buildLoad ? true : (revertApploading ? true : revertApploading ? true : DPLoad ? true : false)} fullWidth={true} startIcon={<BiArrowBack />}>go back</Button>
                                    : <AvatarGroup total={20} sx={{
                                        '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 },
                                    }}>
                                        <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src="https://v1.docusaurus.io/img/docusaurus_keytar.svg" />
                                        <Avatar sx={{ width: 24, height: 24 }} alt="Travis Howard" src="https://reactjs.org/logo-og.png" />
                                        <Avatar sx={{ width: 24, height: 24 }} alt="Cindy Baker" src="https://1000logos.net/wp-content/uploads/2020/08/Nginx-Symbol-768x480.jpg" />
                                        <Avatar sx={{ width: 24, height: 24 }} alt="Agnes Walker" src="https://uploads-ssl.webflow.com/6313a191baace77db2f6b604/63726b91550ba5f32ea9904a_komiser-webclip.png" />

                                    </AvatarGroup>
                                }
                            </Grid>


                            <Grid item xs={2} sm={4} md={4}>
                                <Collapse in={filevalue !== 5 ? true : false}>
                                    <Box>
                                        <Tooltip placement="bottom-start" arrow title={"Use Git when your project is already available on Github."}>
                                            <span>
                                                <Button onClick={() => setfilevalue(3)} disabled={isQuickApps ? true : url ? true : buildLoad ? true : (revertApploading ? true : revertApploading ? true : DPLoad ? true : false)} fullWidth={true} variant={filevalue === 3 ? "contained" : "standard"} startIcon={<BiCodeAlt />}>Code Deployment</Button>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </Collapse>

                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <Collapse in={filevalue !== 5 ? true : false}>
                                    <Box>
                                        <Tooltip placement="top-start" arrow title={"Use Deploy with image name when your project is already available on dockerhub."}>
                                            <span>
                                                <Button onClick={() => setfilevalue(0)} disabled={isQuickApps ? true : url ? true : buildLoad ? true : (revertApploading ? true : revertApploading ? true : DPLoad ? true : false)} fullWidth={true} variant={filevalue === 0 ? "contained" : "standard"} startIcon={<SiDocker />}>Image Deployment</Button>
                                            </span>  </Tooltip>
                                    </Box>
                                </Collapse>

                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <Collapse in={filevalue !== 5 ? true : false}>
                                    <Box>
                                        <Tooltip placement="top" arrow title={"Use Remote Build when you have the source code and your project is dockerized, upload your project to deploy"}>
                                            <span>

                                                <Button onClick={() => setfilevalue(1)} disabled={isQuickApps ? true : url ? true : buildLoad ? true : (revertApploading ? true : revertApploading ? true : DPLoad ? true : false)} fullWidth={true} variant={filevalue === 1 ? "contained" : "standard"} startIcon={<RiSettingsFill />}>Custom Deployment</Button>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </Collapse>

                            </Grid>












                        </Grid>





                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >

                            <p style={{ color: "grey", fontSize: "12px", marginLeft: "20px", marginBottom: "0px" }}> We support different types of deployment, <a href='https://www.youtube.com/embed/DvWsKfaVB5I?si=MG8BXVglvZiEQ647' target='_blank' >Learn More</a> </p>
                            <Tooltip placement="bottom" arrow title={"Use Template to deploy app from saved config details."}>
                                <span>

                                    <Button variant={filevalue === 4 ? "contained" : "text"} onClick={() => setfilevalue(4)} sx={{ justifyContent: "flex-start", textAlign: "left", textTransform: "capitalize", marginRight: "20px", marginTop: "5px", fontSize: "12px" }} size='small'>my saved Templates</Button>
                                </span> </Tooltip>
                        </Grid>


                        <TabPanel value={filevalue} index={4}>

                            <TextField
                                disabled={isQuickApps ? true : regidLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                variant="outlined" value={dockerImageInput}
                                required fullWidth style={{ marginBottom: "10px" }} label="Docker Image Name"
                                error={dockerImageVerifyError}
                                helperText={dockerImageVerifyError && "Image not found"}

                                onFocus={handleFocusDockerName} onBlur={handleBlurDockerName} onChange={handleImageName} />

                            {/* <TextField
                                label="Paste Docker Run Command"
                                multiline
                                fullWidth

                                onChange={(e) => parseDockerCommand(e.target.value)}
                                variant="outlined"
                            /> */}
                            {dataoftemplate && dataoftemplate && <div>
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    aria-label="contacts"
                                >


                                    {dataoftemplate.GetAppTemplates.map((e, index) => (
                                        <ListItem key={index} disablePadding onClick={() => handleConfigTemplate(e)}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <BookmarkIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={e.name} />
                                            </ListItemButton>
                                        </ListItem>

                                    ))}
                                </List></div>}
                        </TabPanel>
                        <TabPanel value={filevalue} index={0}>
                            <TextField disabled={isQuickApps ? true : regidLoad ? true : (revertApploading ? true : DPLoad ? true : false)} variant="outlined"
                                error={dockerImageVerifyError}
                                helperText={dockerImageVerifyError && "Invalid image or private image, use secrets"}
                                value={dockerImageInput} required fullWidth style={{ marginBottom: "10px" }} label="Docker Image Name" onFocus={handleFocusDockerName} onBlur={handleBlurDockerName} onChange={handleImageName} />
                            {inprogress && <span style={{ paddingBottom: "10px" }}><CircularProgress size={10} /> Verifying..</span>}


                            {datasec.getSecret ? <Autocomplete
                                id="combo-box-demo"
                                disabled={isQuickApps ? true : regidLoad ? true : (revertApploading ? true : DPLoad ? true : dockerImageInput === "" ? true : false)}
                                options={datasec.getSecret.filter((e, i) => {
                                    return e.registryType === "docker_hub_registry"
                                }) || []}
                                inputValue={secretvalue}
                                onInputChange={handleChangeSecret}
                                getOptionLabel={(option) => option.name}

                                renderInput={(params) => <TextField {...params} label="Choose Secret" variant="outlined" />}
                            /> : <p onClick={() => window.open("/settings/secrets", '_blank')}>To deploy with private image, you need to <b style={{ color: "blue", cursor: "pointer" }}>Create Secret.</b></p>}

                            <Typography variant='body2' sx={{ mt: 2, mb: 1 }}>Paste your docker run command to auto fill the app configuration <b style={{ background: "rgb(227, 242, 253)", color: "rgb(33, 150, 243)", border: "1px solid rgb(33, 150, 243)", padding: "2px", fontSize: "12px", borderRadius: "5px" }}>Beta</b></Typography>
                            <TextField
                                helperText="Make sure your command does not have any space."
                                label="Docker Run Command"
                                placeholder='Paste Docker Run Command'
                                multiline
                                fullWidth
                                sx={{ mt: 1 }}
                                onChange={(e) => parseDockerCommand(e.target.value)}
                                onPaste={(e) => parseDockerCommand(e.target.value)}
                                variant="outlined"
                            />

                            {!props.redeploy &&
                                <div>
                                    {isQuickApps ?
                                        <div style={{ marginTop: "20px" }}>
                                            <IconButton size="large"><CheckCircleIcon style={{ color: "green" }} /> </IconButton> Ready to deploy <b>{quickApp.name} </b>
                                            <br />
                                            {quickApp.env && <Typography style={{ background: "rgb(255, 248, 225)", color: "#111", margin: "5px 0px 10px 0px", borderRadius: "12px" }} variant="body2"><IconButton size="large"><TiWarningOutline /></IconButton> This app requires {quickApp.env.length} environment variable(s)</Typography>}

                                            <Typography variant="body2">Please select Routing Policy and Environment Variables based on your need.</Typography>


                                            <br />
                                            <Button style={{ background: "#f8f8f8", color: "#000" }} disabled={buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} variant='contained' onClick={handleResetQuickDeployments}>Reset! </Button>
                                        </div>
                                        :
                                        <div style={{ marginTop: "20px" }}>
                                            <Typography variant="h6">Popular apps <IconButton size="large"><AiFillThunderbolt /></IconButton></Typography>
                                            {quickAppsList.map((value, index) => {
                                                return <Chip key={index} icon={<FiBox />} style={{ marginRight: "5px", marginBottom: "5px" }} onClick={() => handleQuickDeployments(index)} label={value.name} />
                                            })}



                                        </div>
                                    }
                                </div>
                            }

                        </TabPanel>

                        <TabPanel value={filevalue} index={5}>

                            <div style={{ marginTop: "20px" }}>
                                <Autocomplete
                                    id="grouped-demo"
                                    value={templateVlaue}

                                    disableClearable={true}
                                    onChange={(event, newValue) => {
                                        setTemplateVlaue(newValue);
                                        handleTeplateSelect(newValue);
                                    }}

                                    options={templateList && templateList.sort((a, b) => -b.name.localeCompare(a.name))}
                                    // groupBy={(option) => option.type}

                                    getOptionLabel={(option) => `${option.name}  (${option.type})`}
                                    getOptionDisabled={(option) =>
                                        option.isRequest === true
                                    }
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} placeholder="Search App.." />}
                                />
                                {templateVlaue && <div>
                                    <Typography className={classes.title} style={{ marginTop: "10px" }} variant="body2" component="p" color="textSecondary" >
                                        {templateVlaue.type}
                                    </Typography>
                                    <Typography variant="h5" component="h2" style={{ fontSize: "15px", fontWeight: "bold" }} noWrap={true}>
                                        <IconButton> <FiBox /></IconButton> {templateVlaue.name}
                                    </Typography>
                                    {templateVlaue.image && <Typography variant="body2" component="p" >
                                        Image name: {templateVlaue.image}
                                    </Typography>}
                                    <Typography variant="body2" component="p" >
                                        <span style={{ color: "grey" }}>{templateVlaue.desc}</span>
                                    </Typography>

                                    <Button style={{ marginTop: "10px" }} variant='outlined' onClick={handleResetQuickDeployments}>Reset</Button>

                                    {startBuildCompleted && buildErr && githubLink !== "" &&
                                        <>


                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ background: "rgb(253, 246, 246)", color: "rgb(211, 47, 47)", p: 3, borderRadius: "12px", mt: 2 }}
                                            >

                                                <ErrorOutlineIcon size={5} color='error' />
                                                <Typography sx={{ ml: 1 }}>
                                                    Build error: {buildErr.message}
                                                </Typography>
                                            </Grid>


                                        </>}

                                </div>}



                            </div>
                        </TabPanel>

                        <TabPanel value={filevalue} index={1}>

                            {!fileUploaded &&
                                <>

                                    <div style={{
                                        padding: "20px",
                                        height: "300px",
                                        border: "2px dashed #ccc",
                                        borderRadius: "5px",
                                        textAlign: "center"
                                    }}

                                        {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>{"Drag 'n' drop Your File here, or click to select"}</p>
                                        <AiFillFileAdd style={{ fontSize: "30px", marginTop: "10px" }} />
                                    </div>


                                    {/* <StyledEngineProvider injectFirst>


                                        <DropzoneArea
                                            clearOnUnmount={false}
                                            maxFileSize={500000000}
                                            Icon={AiFillFileAdd}
                                            filesLimit={10}
                                            showFileNamesInPreview={false}
                                            acceptedFiles={filevalue == 1 ? ['application/*'] : ['application/*']}
                                            useChipsForPreview={false}
                                            showPreviewsInDropzone={false}
                                            dropzoneText={"Drag 'n' drop Your File here, or click to select"}
                                            onChange={(e) => { handleFileUpload(e) }}
                                            style={{ marginBottom: "10px", color: "grey" }}
                                        />

                                    </StyledEngineProvider> */}
                                </>

                            }



                        </TabPanel>

                        <TabPanel value={filevalue} index={2}>
                            {!fileUploaded &&
                                <StyledEngineProvider injectFirst>

                                    <FormControl variant="outlined" fullWidth style={{ marginBottom: "15px" }} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-filled-label">Choose Runtime</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            label="Choose Runtime"
                                            onChange={handleChangeBuiltInTypes}
                                            value={runtime}
                                        >

                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {dataofbulitintypes.GetAvailableBuiltIn.filter((e) => {
                                                if (props.redeploy && props.imageName && (props.imageName.includes("mysql") || props.imageName.includes("postgres"))) {
                                                    return (e === "mysql") || (e === "postgres")
                                                }
                                                else {

                                                    return (e !== "mysql") && (e !== "postgres")
                                                }

                                            }).map((value, index) => {

                                                return (
                                                    <MenuItem key={index} value={value}>{value}</MenuItem>
                                                )


                                            })}

                                        </Select>
                                    </FormControl>
                                    {runtime === "mysql" || runtime === "postgres" ? <div>
                                        <TextField sx={{ mb: "10px" }} size="small"
                                            error={DBnamevalidation}
                                            helperText={DBnamevalidation && "Invalid name, You can only use small leters,(.)period and (-)hyphen "}
                                            value={secname} onChange={e => {
                                                setsecname(e.target.value);
                                                valuevalidateDBname(e);
                                            }} disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} fullWidth variant='outlined' label={"Database Name"} />
                                        <TextField sx={{ mb: "10px" }} size="small" value={secuser} onChange={e => setsecuser(e.target.value)} disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} fullWidth variant='outlined' label={"User Name"} />
                                        <TextField sx={{ mb: "10px" }} size="small" value={secpass} onChange={e => setsecpass(e.target.value)} disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} fullWidth variant='outlined' label={"Password"} />
                                        <Button onClick={handleDatabase} variant='contained'>Save</Button>
                                    </div>
                                        :
                                        <div>
                                            {runtime != "" &&
                                                <div style={{
                                                    padding: "20px",
                                                    height: "300px",
                                                    border: "2px dashed #ccc",
                                                    borderRadius: "5px",
                                                    textAlign: "center"
                                                }}

                                                    {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p>{"Drag 'n' drop Your File here, or click to select"}</p>
                                                </div>
                                            }
                                            {/* {runtime != "" &&

                                                <DropzoneArea
                                                    clearOnUnmount={false}
                                                    maxFileSize={500000000}
                                                    Icon={AiFillFileAdd}
                                                    filesLimit={10}
                                                    disabled={true}
                                                    //getFileAddedMessage={nu}
                                                    showFileNamesInPreview={false}
                                                    acceptedFiles={filevalue == 1 ? ['application/*'] : ['application/*']}
                                                    useChipsForPreview={false}
                                                    showPreviewsInDropzone={false}
                                                    dropzoneText={"Drag 'n' drop Your File here, or click to select"}
                                                    onChange={(e) => { handleFileUpload(e) }}
                                                    style={{ marginBottom: "10px", color: "grey" }}
                                                />} */}
                                        </div>}

                                </StyledEngineProvider>}
                        </TabPanel>


                        <TabPanel value={filevalue} index={3}>
                            {!(datalist && datalist.getUserPAT && datalist.getUserPAT[0] && datalist.getUserPAT[0].patToken) && !showAddUserLevelPAT ? <>
                                <Button startIcon={<Close />} size='small' onClick={() => setshowAddUserLevelPAT(true)}>Dismiss, I will add later</Button>
                                <UserLevelPAT setfirstTimeGithubToken={setfirstTimeGithubToken} refetch={refetchrepolist} handleFetchGithubRepos={handleFetchGithubRepos} />
                            </>
                                :
                                <>


                                    {selectedfromgithublist ? <ListItem
                                        secondaryAction={
                                            <IconButton onClick={() => {
                                                setgithubLink("");
                                                setselectedfromgithublist(false);
                                            }}>
                                                <Close />
                                            </IconButton>


                                        }>
                                        <ListItemIcon>
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={
                                                    <CheckCircleIcon style={{ color: "green", fontSize: "15px" }} />
                                                }
                                            >
                                                <AiOutlineGithub style={{ fontSize: "30px" }} />
                                            </Badge>
                                        </ListItemIcon>
                                        <ListItemText primary={
                                            githubLink.split("https://github.com/")[1]
                                        }
                                            secondary={<>{githubBranch} branch</>}

                                        />
                                    </ListItem> :
                                        <TextField value={githubLink}
                                            onChange={handleGithubInputUrl}
                                            helperText={gitUrlValidation && "Not Github URL, It should start with 'https://github.com/...' "}
                                            error={gitUrlValidation}

                                            disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : (selectedfromgithublist ? true : false))} style={{ marginBottom: "10px" }} fullWidth variant='outlined' placeholder='ex. https://github.com/prose/gatekeeper.git' label={"Github link"} />}

                                    {githubLink && <Box sx={{ borderRadius: "12px", background: "#f5f5f5" }}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            {gitrepoLoad && <Typography color="primary" sx={{ p: "10px", color: "#111" }}>Checking...</Typography>}

                                            {gitrepoErr && showGithubCheckError && !selectedfromgithublist && !gitUrlValidation && <Typography color="error" style={{ fontSize: "13px", padding: "10px" }}> {gitrepoErr && gitrepoErr.message}, Please use <b>PAT</b> from below for private repository</Typography>}
                                        </Grid>
                                    </Box>}

                                    {!selectedfromgithublist && <TextField value={githubBranch}
                                        onChange={(e) => {
                                            setgithubBranch(e.target.value);

                                        }}
                                        disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}

                                        fullWidth variant='outlined' label={"Branch name"} placeholder="master" />}
                                    <Fade in={showGithubFetchLoading}>
                                        <LinearProgress />
                                    </Fade>

                                    <br />

                                    {githubLink !== "" && !buildLoad && selectedfromgithublist && listofrepo && listofrepo.length > 0 && <Button sx={{ mt: 1 }} onClick={() => { setselectedfromgithublist(false); setgithubLink(""); setgithubBranch("main") }} startIcon={<AiOutlineArrowLeft />} fullWidth size='small'>Back to list</Button>}

                                    {githubLink === "" && <Box>
                                        <Typography>{listofrepo.length} repositories</Typography>
                                        <List dense={true}>
                                            {listofrepo && listofrepo.sort((a, b) => {
                                                return new Date(b.updated_at) - new Date(a.updated_at);
                                            }).map((e) => {
                                                var lastUpdated = moment(new Date(e.updated_at), "YYYY MM DD hh:mm:ss").fromNow()
                                                return (<Tooltip key={e.id} arrow title={e.description}>
                                                    <ListItemButton key={e.id} onClick={() => {
                                                        setgithubBranch(e.default_branch);
                                                        setgithubLink(e.html_url);
                                                        setshowGithubCheckError(false)
                                                        setselectedfromgithublist(true)

                                                    }} secondaryAction={
                                                        <IconButton edge="end" aria-label="delete">
                                                            <FiArrowUpLeft />
                                                        </IconButton>
                                                    }>
                                                        <ListItemIcon>
                                                            <AiOutlineGithub size={20} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={
                                                            e.full_name
                                                        }
                                                            secondary={<>Last updated {lastUpdated} • {e.private ? "Private" : "Public"}{e.private ? <FaLock size={10} style={{ marginLeft: "5px" }} /> : <BiWorld size={10} style={{ marginLeft: "5px" }} />} </>} />
                                                    </ListItemButton>
                                                </Tooltip>)
                                            })}
                                        </List>
                                    </Box>}

                                    {githubLink !== "" && githubBranch !== "" && !buildLoad && !gitUrlValidation && <div style={{ textAlign: "center", marginTop: "20px" }}>

                                        <Paper sx={{ backgroundColor: (theme) => theme.palette.action.hover, borderRadius: "12px", padding: "10px", margin: "10px" }}>
                                            <Typography variant='body2' sx={{ m: 2, mb: 1, mt: 1, fontWeight: "bold" }}>Optional configuration</Typography>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >

                                                <List dense={true}>

                                                    {!selectedfromgithublist && <ListItemButton onClick={() => setshowgitprivate(!showgitprivate)}>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                {age ? <CheckCircleIcon /> : <FaLock />}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Private repository. " secondary="select the personal access token and click on deploy" />
                                                    </ListItemButton>}

                                                    {showgitprivate && <Box style={{ borderRadius: "12px", margin: "10px 0px", padding: "10px" }}>
                                                        <p style={{ fontSize: "12px", color: "grey" }}>If your repository is private, you have to select github personal access token (PAT)</p>

                                                        {datasec && datasec.getSecret && datasec.getSecret.filter((e) => {
                                                            return e.registryType === "PAT";
                                                        }).length > 0 ? <Box sx={{ minWidth: 120 }}>
                                                            <FormControl size='small' fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Github PAT (For Private Repository)</InputLabel>
                                                                <Select
                                                                    disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={age}
                                                                    label="Github PAT (For Private Repository)"
                                                                    onChange={handleChangeGithub}
                                                                >
                                                                    <MenuItem value={""}>None</MenuItem>
                                                                    {datasec && datasec.getSecret && datasec.getSecret.map((value, index) => {
                                                                        if (value.registryType === "PAT") {
                                                                            return (
                                                                                <MenuItem key={index} value={value.name}>{value.name}</MenuItem>
                                                                            )
                                                                        }
                                                                    })}

                                                                </Select>
                                                            </FormControl>
                                                        </Box> : <p onClick={() => window.open("/settings/secrets", '_blank')}>To deploy with private repository, you need to <b style={{ color: "blue", cursor: "pointer" }}>Create PAT.</b></p>}
                                                    </Box>}

                                                    <Collapse in={showgitprivate ? false : true}>
                                                        <ListItemButton>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    {runtime === "" ? <SiDocker /> : <CheckCircleIcon />}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary="Automatically dockerize your application. " secondary="select the runtime and click on deploy" />
                                                        </ListItemButton>
                                                    </Collapse>

                                                    {!showgitprivate && <Box sx={{ minWidth: 120, p: "10px" }}>
                                                        <FormControl size='small' fullWidth>
                                                            <InputLabel id="demo-simple-select-filled-label">Choose Runtime</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-filled-label"
                                                                id="demo-simple-select-filled"
                                                                label="Choose Runtime"
                                                                onChange={handleChangeBuiltInTypes}
                                                                value={runtime}
                                                            >

                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {dataofbulitintypes.GetAvailableBuiltIn.filter((e) => {
                                                                    if (props.redeploy && props.imageName && (props.imageName.includes("mysql") || props.imageName.includes("postgres"))) {
                                                                        return (e === "mysql") || (e === "postgres")
                                                                    }
                                                                    else {
                                                                        return (e !== "mysql") && (e !== "postgres")
                                                                    }
                                                                }).map((value, index) => {

                                                                    return (
                                                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                                                    )
                                                                })}

                                                            </Select>
                                                        </FormControl>
                                                    </Box>}

                                                    <Collapse in={showgitprivate ? false : runtime ? false : true}>
                                                        <ListItemButton
                                                            onClick={handleChangeMore}
                                                            disabled={isQuickApps || buildLoad || revertApploading || DPLoad || dockerFilePathValue}
                                                        >
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    {dockerfilevalue === "" ? <BsUpload /> : <CheckCircleIcon />}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={dockerfilevalue ? "Using custom dockerfile" : "Add/upload custom dockerfile"} secondary="select runtime and done" />
                                                        </ListItemButton>
                                                    </Collapse>

                                                    <Collapse in={showgitprivate ? false : runtime ? false : true}>
                                                        <ListItemButton

                                                            disabled={isQuickApps || buildLoad || revertApploading || DPLoad || dockerfilevalue}

                                                            onClick={() => setshowdockerfilepath(!showdockerfilepath)}>
                                                            {showdockerfilepath && <ListItemSecondaryAction>
                                                                <IconButton onClick={() => setshowdockerfilepath(!showdockerfilepath)} edge="end" aria-label="delete">
                                                                    <Close />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>}
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    {dockerFilePathValue ? <CheckCircleIcon /> : <Edit />}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={dockerFilePathValue ? "Using custom dockerfile path" : "Update dockerfile path"} secondary="select runtime and done" />
                                                        </ListItemButton>
                                                    </Collapse>

                                                </List>
                                            </Grid>
                                            {showdockerfilepath && <Box style={{ borderRadius: "12px", margin: "10px 0px", padding: "10px" }}>
                                                <p style={{ fontSize: "12px", borderRadius: "24px", color: "grey" }}>Enter the full relative path of your dockerfile from your project folder starting with repo name, eg. <pre>RepositoryName/ProjectName/frontend/src/dockerfile</pre> </p>

                                                <TextField fullWidth disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} size='small' sx={{ mt: 1, mb: 2 }} onChange={(e) => setdockerFilePathValue(e.target.value)} value={dockerFilePathValue} label="Enter Full Path" placeholder="projectname/file path" />
                                                {/* <Button disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} sx={{ mt: 1, mb: 2 }} onClick={() => {
                                                        setshowdockerfilepath(false);
                                                        setdockerFilePathValue("");
                                                    }}>Cancel</Button> */}

                                            </Box>}
                                        </Paper>

                                    </div>}



                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ m: 2 }}
                                    >


                                        {buildLoad && <LoadingBuildApp />}


                                    </Grid>

                                    <br />
                                    {startBuildCompleted && buildErr && githubLink !== "" &&
                                        <>


                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ background: "rgb(253, 246, 246)", color: "rgb(211, 47, 47)", p: 3, borderRadius: "12px" }}
                                            >

                                                <ErrorOutlineIcon size={5} color='error' />
                                                <Typography sx={{ ml: 1 }}>
                                                    Build error: {buildErr.message}
                                                </Typography>
                                            </Grid>


                                        </>}
                                </>}

                        </TabPanel>

                        {props.redeploy &&
                            <div>




                                <Slide direction="left" in={moreOption === "dockerfile"} mountOnEnter unmountOnExit>

                                    <div>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"

                                        >

                                            <Typography style={{ marginBottom: "20px" }} variant='h6'><IconButton><BiEditAlt /></IconButton>Custom Dockerfile</Typography>

                                            <div>
                                                <Button disabled={dockerfilevalue === "" ? true : false} size={"small"} onClick={handleSaveMore} style={{ marginRight: "10px", cursor: "pointer", marginBottom: "20px" }} variant="contained" disableElevation>Save</Button>
                                                <Button size={"small"} style={{ cursor: "pointer", marginBottom: "20px" }} variant="outlined" onClick={handleCancelMore} disableElevation>Cancel</Button>
                                            </div>
                                        </Grid>
                                        <TextField
                                            id="filled-multiline-flexible"
                                            label="Edit dockerfile"
                                            multiline
                                            maxRows={10}
                                            size="small"
                                            value={dockerfilevalue}
                                            onChange={(e) => setdockerfilevalue(e.target.value)}
                                            fullWidth
                                            style={{ marginBottom: "20px" }}
                                            defaultValue={'# Paste your dockerfile and modify\n\nFROM node:4.2\nCOPY . /src\nRUN cd /src && npm install\nEXPOSE 4000\nCMD ["node", "/src/server.js"]'}

                                            variant="outlined"
                                            color='primary'
                                        />
                                        <p style={{ background: "#f8f8f8", fontSize: "12px", borderRadius: "24px", color: "grey" }}> <IconButton><InfoOutlinedIcon color="primary" /></IconButton>You can use this default dockerfile and modify as per your need OR upload your dockerfile. </p>
                                        <input
                                            onChange={showFile}

                                            style={{ display: "none" }}
                                            id="file-upload"
                                            type="file"
                                        />

                                        <label
                                            htmlFor="file-upload"


                                        >
                                            <p style={{ marginBottom: "20px", color: "#5d86f9", cursor: "pointer" }} ><IconButton><FiUpload color='primary' /></IconButton>Upload your dockerfile</p>
                                        </label>

                                    </div>
                                </Slide>



                            </div>}




                        <div style={{ textAlign: "center" }}>
                            {fileUploaded && <>
                                {fileUploadedError && <span>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <ErrorOutlineIcon style={{ color: "red" }} />  <span>
                                            Error: {fileUploadedError}</span>
                                    </Grid>

                                    <br /> <Button variant='contained' disableElevation size="small" onClick={handleResetDockerImageName} style={{ marginTop: "10px" }} >Try Again</Button></span>}
                                {!fileUploadedsuccessfully && !fileUploadedError &&

                                    <Box sx={{ textAlign: "center" }}>
                                        <MdCloudUpload style={{ fontSize: '6em' }} />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CircularProgress size={20} />
                                            <Typography sx={{ fontWeight: "bold", fontSize: "15px", ml: 1 }}>  Uploading...</Typography>

                                        </Grid>
                                        <Typography variant='body2' sx={{ fontSize: "12px", my: 2 }}>Processing speed will be affected by network and file size.</Typography>
                                    </Box>}
                                {fileUploadedsuccessfully && <>


                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <CheckCircleIcon style={{ color: "green", marginRight: "5px", fontSize: "30px" }} /> File Uploaded Successfully!
                                    </Grid>
                                    <br />
                                    {buildData && <>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CheckCircleIcon size={10} style={{ color: "green" }} />Successfully build!</Grid>
                                    </>}
                                    <br />
                                    {DPLoad && <>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CircularProgress size={15} />Deploying your app...

                                        </Grid>
                                    </>}
                                    <br />
                                    {(filevalue === 1) && !buildData && !buildLoad &&
                                        <Paper sx={{ backgroundColor: (theme) => theme.palette.action.hover, borderRadius: "12px", padding: "10px", margin: "10px" }}>
                                            <Typography variant='body2' sx={{ m: 2, mb: 1, mt: 1, fontWeight: "bold" }}>Optional configuration</Typography>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >

                                                <List dense={true}>

                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                {runtime === "" ? <SiDocker /> : <CheckCircleIcon />}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Automatically dockerize your application. " secondary="select the runtime and click on deploy" />
                                                    </ListItemButton>

                                                    {true && <Box>
                                                        <FormControl size='small' variant="outlined" fullWidth style={{ margin: "15px" }} className={classes.formControl}>
                                                            <InputLabel id="demo-simple-select-filled-label">Choose Runtime</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-filled-label"
                                                                id="demo-simple-select-filled"
                                                                label="Choose Runtime"
                                                                onChange={handleChangeBuiltInTypes}
                                                                value={runtime}
                                                            >

                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {dataofbulitintypes.GetAvailableBuiltIn.filter((e) => {
                                                                    if (props.redeploy && props.imageName && (props.imageName.includes("mysql") || props.imageName.includes("postgres"))) {
                                                                        return (e === "mysql") || (e === "postgres")
                                                                    }
                                                                    else {
                                                                        return (e !== "mysql") && (e !== "postgres")
                                                                    }
                                                                }).map((value, index) => {

                                                                    return (
                                                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                                                    )
                                                                })}

                                                            </Select>
                                                        </FormControl>
                                                    </Box>}
                                                    {runtime && <Typography variant='body2' sx={{ fontSize: "0.7rem" }}>If you want to use custom dockerfile or change docker path, select runtime to <i>None</i></Typography>}
                                                    <Collapse in={runtime ? false : true}>
                                                        <ListItemButton
                                                            onClick={handleChangeMore}
                                                            disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                                        >
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    {dockerfilevalue === "" ? <BsUpload /> : <CheckCircleIcon />}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={dockerfilevalue ? "Using custom dockerfile" : "Add/upload custom dockerfile"} secondary="select runtime and done" />
                                                        </ListItemButton>
                                                    </Collapse>

                                                    <Collapse in={runtime ? false : true}>
                                                        <ListItemButton
                                                            disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}

                                                            onClick={() => setshowdockerfilepath(true)}>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    {dockerFilePathValue ? <CheckCircleIcon /> : <Edit />}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={dockerFilePathValue ? "Using custom dockerfile path" : "Update dockerfile path"} secondary="select runtime and done" />
                                                        </ListItemButton>
                                                    </Collapse>

                                                </List>
                                            </Grid>
                                            {/* {dockerfilevalue === "" ? <Button disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} startIcon={<SiDocker />} onClick={handleChangeMore}>Auto dockerize for runtime </Button> : <Button disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} onClick={handleChangeMore} startIcon={<CheckCircleIcon size={10} />}> Using custom dockerfile </Button>}
                                            <br />
                                            {dockerfilevalue === "" ? <Button disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} startIcon={<BsUpload />} onClick={handleChangeMore}>add Custom dockerfile </Button> : <Button disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} onClick={handleChangeMore} startIcon={<CheckCircleIcon size={10} />}> Using custom dockerfile </Button>}
                                            <br />
                                            <Button disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} startIcon={dockerFilePathValue ? <CheckCircleIcon size={10} /> : <Edit />} onClick={() => setshowdockerfilepath(true)}>{dockerFilePathValue ? "Using custom dockerfile path" : "add dockerfile path"}</Button>
                                            <br /> */}
                                            {showdockerfilepath && <>
                                                <Box>
                                                    <p style={{ fontSize: "12px", borderRadius: "24px", color: "grey" }}>Enter the full relative path of your dockerfile from your project folder, eg. <pre>projectName/frontend/src/dockerfile</pre> </p>
                                                    <TextField disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} size='small' sx={{ mt: 1, mb: 2 }} onChange={(e) => setdockerFilePathValue(e.target.value)} value={dockerFilePathValue} label="Enter Full Path" placeholder="projectname/file path" />
                                                    <Button disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} sx={{ mt: 1, mb: 2 }} onClick={() => {
                                                        setshowdockerfilepath(false);
                                                        setdockerFilePathValue("");
                                                    }}>Cancel</Button>
                                                </Box>
                                            </>}
                                        </Paper>}
                                </>}
                                <br />

                                {buildLoad && <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ m: 2 }}
                                >
                                    <LoadingBuildApp />
                                </Grid>}


                                <br />
                                {startBuildCompleted && <>{buildErr &&
                                    <>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ background: "rgb(253, 246, 246)", color: "rgb(211, 47, 47)", p: 3, borderRadius: "12px", mb: 2 }}
                                        >

                                            <ErrorOutlineIcon size={5} color='error' />
                                            <Typography sx={{ ml: 1 }}>
                                                Build error: {buildErr.message}
                                            </Typography>
                                        </Grid>


                                    </>}</>}



                            </>}
                        </div>




                    </Grid>

                    {/* Redeploy Additional Tab */}

                    {props.redeploy &&
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Ports</p>
                                <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>Ports allow communication between containers and host. {updateLoad && <i>Updating...</i>}</span>
                                <br />

                                <TextField
                                    disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                    variant="outlined"
                                    sx={{ my: 1 }}
                                    label={"Internal Port"}
                                    value={internalport}
                                    name="internalPort"
                                    size='small'
                                    onChange={(e) => {
                                        setinternalport(e.target.value)
                                        validateInternalPort(e);
                                        handlePortUpdate(e.target.value, externalport)

                                    }}

                                    error={internalPortValidation && true}
                                    helperText={internalPortValidation && "Invalid Port Number, Range is (1-65535)"}
                                />

                                <TextField
                                    disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                    label={"External Port"}
                                    variant="outlined"
                                    value={externalport}
                                    name="externalPort"
                                    size='small'
                                    onChange={(e) => {
                                        setexternalport(e.target.value)
                                        validateInternalPort(e);
                                        handlePortUpdate(internalport, e.target.value)

                                    }}

                                    sx={{ mt: 1 }}
                                    error={externalPortValidation && true}
                                    helperText={externalPortValidation && "Invalid Port Number, Range is (1-65535)"}
                                />




                                <div>
                                    <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Environment Variables (Optional)</p>
                                    <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>Dynamic values outside of the app's code, containing configuration settings or system information.</span>

                                </div>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{ mb: 2, mt: 1 }}

                                >
                                    <Button onClick={() => setValue(0)} startIcon={value === 0 ? <CheckCircleIcon /> : <MdContentPaste />} variant={value === 0 ? 'outlined' : 'text'} sx={{ p: "5px 15px" }}>Paste</Button>
                                    <Button onClick={() => setValue(1)} startIcon={value === 1 ? <CheckCircleIcon /> : <CgAddR />} variant={value === 1 ? 'outlined' : 'text'} sx={{ ml: 1, p: "5px 15px" }}>Add</Button>
                                    <Button onClick={() => setValue(2)} startIcon={value === 2 ? <CheckCircleIcon /> : <VscGlobe />} variant={value === 2 ? 'outlined' : 'text'} sx={{ ml: 1, p: "5px 15px" }}>Global Variables</Button>

                                </Grid>

                                <TabPanel marginZero={true} value={value} index={0}>
                                    <TextField
                                        multiline

                                        error={isEnvFileError}
                                        helperText={envErrorMsg}
                                        value={envfile}
                                        fullWidth
                                        disabled={buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                        onChange={handleInputEnvFile}
                                        onBlur={() => { envfile != "" ? validateEnvFile() : setnoOfEnvs(0) }}
                                        label='Environment Variables'
                                        variant="outlined"
                                        placeholder=' PORT=8080
    ...'
                                        minRows={2} />
                                    {!isEnvFileError && (noOfEnvs != 0 &&
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            Added {noOfEnvs} variables
                                        </Grid>)}
                                </TabPanel>



                                <TabPanel marginZero={true} value={value} index={1}>
                                    <Paper variant='outlined' disableElevation>

                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="left">Value</TableCell>
                                                    <TableCell align="right"><Button onClick={() => handleAddFields()} style={{ color: "#4A047A" }} startIcon={<AddIcon />}> Add</Button></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {inputFields.map((inputField, index) => (
                                                    <Fragment key={`${inputField}~${index}`}>
                                                        <TableRow key={1}>
                                                            <TableCell component="th" scope="row">
                                                                <TextField size={"small"} disabled={revertApploading ? true : DPLoad ? true : false} value={inputField.key} inputProps={{ className: classes.input, pattern: "^[A-Za-z]+(?:_[A-Za-z]+)*$" }} name="key" onChange={event => handleInputChange(index, event)} placeholder="PORT" />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <TextField size={"small"} disabled={revertApploading ? true : DPLoad ? true : false} value={inputField.value} name="value" onChange={event => handleInputChange(index, event)} placeholder="8080" />
                                                            </TableCell>
                                                            <TableCell align="right"><Button disabled={revertApploading ? true : DPLoad ? true : false} onClick={() => handleRemoveFields(index)}><DeleteIcon /></Button></TableCell>

                                                        </TableRow>
                                                    </Fragment>
                                                ))}
                                            </TableBody>
                                        </Table>

                                    </Paper>



                                </TabPanel>

                                <TabPanel value={value} index={2}>


                                    {datasec && datasec.getSecret ?
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            {datasec.getSecret.map((value, index) => {
                                                const labelId = `checkbox-list-secondary-label-${value.name}`;
                                                if (value.registryType === "env")
                                                    return (
                                                        <>
                                                            <ListItem
                                                                key={index}
                                                                secondaryAction={
                                                                    <Checkbox
                                                                        edge="end"
                                                                        onChange={handleToggle(value.id)}
                                                                        checked={checked.indexOf(value.id) !== -1}
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                    />
                                                                }
                                                                disablePadding
                                                            >


                                                                <ListItemText id={labelId} primary={`${value.userName}`} />
                                                                <ListItemText id={value.userName} primary={`${decodeAes(value.passWord)}`} />



                                                            </ListItem>
                                                            <Divider />
                                                        </>
                                                    );
                                            })}
                                        </List> : <p onClick={() => window.open("/settings/secrets", '_blank')}>No Global variables found, Click here to <b style={{ color: "blue", cursor: "pointer" }}>Create Global Variable.</b></p>}
                                </TabPanel>

                            </Box>    </Grid>}



                    {!props.redeploy &&
                        <Grid item xs={12} sm={6}>

                            <div>






                                <Slide direction="left" in={moreOption === "dockerfile"} mountOnEnter unmountOnExit>
                                    <div>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"

                                        >

                                            <Typography style={{ marginBottom: "20px" }} variant='h6'><IconButton><BiEditAlt /></IconButton>Custom Dockerfile</Typography>

                                            <div>
                                                <Button disabled={dockerfilevalue === "" ? true : (isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false))} size={"small"} onClick={handleSaveMore} style={{ marginRight: "10px", cursor: "pointer", marginBottom: "20px" }} variant="contained" disableElevation>Save</Button>
                                                <Button size={"small"} disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)} style={{ cursor: "pointer", marginBottom: "20px" }} variant="outlined" onClick={handleCancelMore} disableElevation>Cancel</Button>
                                            </div>
                                        </Grid>
                                        <TextField
                                            id="filled-multiline-flexible"
                                            label="Edit dockerfile"
                                            multiline
                                            maxRows={10}
                                            disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                            size="small"
                                            value={dockerfilevalue}
                                            onChange={(e) => setdockerfilevalue(e.target.value)}
                                            fullWidth
                                            style={{ marginBottom: "20px" }}
                                            defaultValue={'# Paste your dockerfile and modify\n\nFROM node:4.2\nCOPY . /src\nRUN cd /src && npm install\nEXPOSE 4000\nCMD ["node", "/src/server.js"]'}

                                            variant="outlined"
                                            color='primary'
                                        />


                                        {!buildLoad && !revertApploading && !DPLoad && <>


                                            <p style={{ background: "#f8f8f8", fontSize: "12px", borderRadius: "24px", color: "grey" }}> <IconButton><InfoOutlinedIcon color="primary" /></IconButton>You can use this default dockerfile and modify as per your need OR upload your dockerfile. </p>
                                            <input
                                                onChange={showFile}

                                                style={{ display: "none" }}
                                                id="file-upload"
                                                type="file"
                                            />

                                            <label htmlFor="file-upload" >
                                                <p style={{ marginBottom: "20px", color: "#5d86f9", cursor: "pointer" }} ><IconButton><FiUpload color='primary' /></IconButton>Upload your dockerfile</p>
                                            </label>
                                        </>}

                                    </div>
                                </Slide>


                                <Slide direction="left" in={moreOption === "addtional"} mountOnEnter unmountOnExit>
                                    <div>



                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChangeTab('panel2')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{ background: "rgba(0, 0, 0, .03)" }}
                                            >
                                                <Typography variant="subtitle1" component="p" sx={{ width: '40%', flexShrink: 0 }} style={{ marginBottom: "10px" }}>Additional Information</Typography>
                                                <Typography variant='subtitle2'>Routing Policy, Ports, Environment variables</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={0}>
                                                    <Grid item sm={12}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <div>
                                                                <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Routing Policy</p>
                                                                <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>We support two types of Routing Policy, Geo location and Latency . {updateLoad && <i>Updating...</i>}</span>

                                                            </div>


                                                            <HtmlTooltip title={<>
                                                                <h3>
                                                                    We support two types of Routing Policy.
                                                                </h3>


                                                                <b>1. Latency </b>
                                                                <br />
                                                                <span>
                                                                    A latency based routing policy is a way of directing traffic to the resource that has the lowest latency for the user. Latency is the time it takes for a request to travel from the source to the destination and back. For example, if you have a website that is hosted in multiple regions, you can use a latency based routing policy to send users to the region that is closest to them in terms of network speed. This can improve the performance and user experience of your website.
                                                                </span>
                                                                <br />
                                                                <br />
                                                                <b>2. Geo Location</b>
                                                                <br />
                                                                <span>
                                                                    A geolocation routing policy is a way of directing traffic to the resources that serve your traffic based on the location that users’ DNS queries originate from. With geolocation routing, you can localize content and restrict the distribution of content to only the locations in which you are able or allowed to distribute . For example, if you have a website that is hosted in multiple regions, you can use a geolocation routing policy to send users to the region that is closest to them in terms of geographic distance. This can improve the user experience and reduce the network latency for your website.
                                                                </span>
                                                            </>

                                                            }>


                                                                <IconButton><InfoOutlinedIcon fontSize='small' /></IconButton>
                                                            </HtmlTooltip>

                                                        </Grid>

                                                        <FormControlLabel
                                                            value="end"
                                                            disabled={buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                                            control={<CustomSwitch color="primary" />}
                                                            label={routingPolicy ? "Latency" : "Geo Location"}
                                                            labelPlacement="end"
                                                            onClick={(e) => {

                                                                setroutingPolicy(!routingPolicy);
                                                                handlePortUpdate(internalport, externalport, !routingPolicy ? "Latency" : "Geolocation")
                                                            }}
                                                        />



                                                    </Grid>



                                                </Grid>




                                                <Box>


                                                    <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Ports</p>
                                                    <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>Ports allow communication between containers and host. {updateLoad && <i>Updating...</i>}</span>
                                                    <br />
                                                    <TextField
                                                        disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                                        variant="outlined"
                                                        label={"Internal Port"}
                                                        value={internalport}
                                                        name="internalPort"
                                                        size='small'
                                                        onChange={(e) => {
                                                            setinternalport(e.target.value)
                                                            validateInternalPort(e);
                                                            handlePortUpdate(e.target.value, externalport)

                                                        }}
                                                        sx={{ my: 1, mr: 2, width: '20ch' }}
                                                        error={internalPortValidation && true}
                                                        helperText={internalPortValidation && "Invalid Port Number, Range is (1-65535)"}
                                                    />

                                                    <TextField
                                                        disabled={isQuickApps ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                                        label={"External Port"}
                                                        variant="outlined"
                                                        value={externalport}
                                                        name="externalPort"
                                                        size='small'
                                                        onChange={(e) => {
                                                            setexternalport(e.target.value)
                                                            validateInternalPort(e);
                                                            handlePortUpdate(internalport, e.target.value)

                                                        }}

                                                        sx={{
                                                            my: 1,
                                                            width: '20ch', // Set width to 30 characters
                                                        }}
                                                        error={externalPortValidation && true}
                                                        helperText={externalPortValidation && "Invalid Port Number, Range is (1-65535)"}
                                                    />



                                                    <div>
                                                        <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Environment Variables (Optional)</p>
                                                        <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>Dynamic values outside of the app's code, containing configuration settings or system information.</span>

                                                    </div>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        sx={{ mb: 2, mt: 1 }}

                                                    >
                                                        <Button onClick={() => setValue(0)} startIcon={value === 0 ? <CheckCircleIcon /> : <MdContentPaste />} variant={value === 0 ? 'outlined' : 'text'} sx={{ p: "5px 15px" }}>Paste</Button>
                                                        <Button onClick={() => setValue(1)} startIcon={value === 1 ? <CheckCircleIcon /> : <CgAddR />} variant={value === 1 ? 'outlined' : 'text'} sx={{ ml: 1, p: "5px 15px" }}>Add</Button>
                                                        <Button onClick={() => setValue(2)} startIcon={value === 2 ? <CheckCircleIcon /> : <VscGlobe />} variant={value === 2 ? 'outlined' : 'text'} sx={{ ml: 1, p: "5px 15px" }}>Global Variables</Button>

                                                    </Grid>



                                                    <TabPanel marginZero={true} value={value} index={0}>
                                                        <TextField
                                                            multiline
                                                            error={isEnvFileError}
                                                            helperText={envErrorMsg}
                                                            value={envfile}
                                                            fullWidth
                                                            disabled={buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)}
                                                            onChange={handleInputEnvFile}
                                                            onBlur={() => { envfile != "" ? validateEnvFile() : setnoOfEnvs(0) }}
                                                            label='Environment Variables'
                                                            variant="outlined"
                                                            placeholder=' PORT=8080
                                                    ...'
                                                            minRows={2} />
                                                        {!isEnvFileError && (noOfEnvs != 0 &&
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                            >
                                                                <CheckCircleIcon style={{ color: "green", fontSize: "15px", marginRight: "5px" }} />
                                                                Added {noOfEnvs} variables
                                                            </Grid>)}
                                                    </TabPanel>
                                                    <TabPanel marginZero={true} value={value} index={1}>
                                                        <Paper variant='outlined' disableElevation>

                                                            <Table className={classes.table} aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ py: 0 }}>Name</TableCell>
                                                                        <TableCell sx={{ py: 0 }} align="left">Value</TableCell>
                                                                        <TableCell sx={{ py: 0 }} align="right"><Button onClick={() => handleAddFields()} style={{ color: "#4A047A" }} startIcon={<AddIcon />}> Add</Button></TableCell>

                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {inputFields.map((inputField, index) => (
                                                                        <Fragment key={`${inputField}~${index}`}>
                                                                            <TableRow key={1}>
                                                                                <TableCell component="th" scope="row">
                                                                                    <TextField size={"small"} disabled={revertApploading ? true : DPLoad ? true : false} value={inputField.key} inputProps={{ className: classes.input, pattern: "^[A-Za-z]+(?:_[A-Za-z]+)*$" }} name="key" onChange={event => handleInputChange(index, event)} placeholder="PORT" />
                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    <TextField size={"small"} disabled={revertApploading ? true : DPLoad ? true : false} value={inputField.value} name="value" onChange={event => handleInputChange(index, event)} placeholder="8080" />
                                                                                </TableCell>
                                                                                <TableCell align="right"><Button disabled={revertApploading ? true : DPLoad ? true : false} onClick={() => handleRemoveFields(index)}><DeleteIcon /></Button></TableCell>

                                                                            </TableRow>
                                                                        </Fragment>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>

                                                        </Paper>



                                                    </TabPanel>

                                                    <TabPanel value={value} index={2}>


                                                        {datasec && datasec.getSecret ?
                                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                                {datasec.getSecret.map((value, index) => {
                                                                    const labelId = `checkbox-list-secondary-label-${value.name}`;
                                                                    if (value.registryType === "env")
                                                                        return (
                                                                            <>
                                                                                <ListItem
                                                                                    key={index}
                                                                                    secondaryAction={
                                                                                        <Checkbox
                                                                                            color="success"

                                                                                            onChange={handleToggle(value.id)}
                                                                                            checked={checked.indexOf(value.id) !== -1}
                                                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                                                        />
                                                                                    }
                                                                                    disablePadding
                                                                                >


                                                                                    <ListItemText id={labelId} primary={`${value.userName}`} />
                                                                                    <ListItemText id={value.userName} primary={`${decodeAes(value.passWord)}`} />



                                                                                </ListItem>
                                                                                <Divider />
                                                                            </>
                                                                        );
                                                                })}
                                                            </List> : <p onClick={() => window.open("/settings/secrets", '_blank')}>No Global variables found, Click here to <b style={{ color: "blue", cursor: "pointer" }}>Create Global Variable.</b></p>}
                                                    </TabPanel>
                                                </Box>

                                            </AccordionDetails>
                                        </Accordion>


                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChangeTab('panel1')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{ background: "rgba(0, 0, 0, .03)" }}
                                            >

                                                <Typography variant="subtitle1" component="p" sx={{ width: '40%', flexShrink: 0 }} style={{ marginBottom: "10px" }}>Add-ons</Typography>
                                                <Typography variant="subtitle2">Volume, Replica, vCPU, Memory Limits and Requirement </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Typography variant="subtitle2">  <IconButton color='primary'><InfoOutlinedIcon /></IconButton>  You can also pass empty for default values </Typography>
                                                <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Attach volume</p>
                                                <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>Volumes are often a better choice than persisting data.</span>
                                                <br />
                                                {localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).workloadManagement ?
                                                    <div>




                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justifyContent="center"
                                                            alignItems="flex-start"
                                                        >


                                                            <Grid item xs={12}>
                                                                <Typography sx={{ fontSize: "12px" }}>Maximum size limit for  <span style={{ color: "#5d86f9" }}>{(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName && JSON.parse(localStorage.getItem("access")).planName !== "free plan" ?
                                                                    JSON.parse(localStorage.getItem("access")).planName : "Hobby plan")}
                                                                    {localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName !== "free plan" && "plan"}</span> is {volumeSizeLimit && volumeSizeLimit}
                                                                </Typography>

                                                                <TextField value={volumeSize}

                                                                    InputProps={{
                                                                        inputProps: { min: 0 }
                                                                    }}
                                                                    type="number"
                                                                    onInput={(e) => {

                                                                        if (volumeSizeLimit && e.target.value <= volumeSizeLimit && e.target.value >= 0) {
                                                                            setvolumeSizeError(false)
                                                                            setvolumeSize(e.target.value)
                                                                        }
                                                                        else {
                                                                            setvolumeSizeError(true)
                                                                            setvolumeSize(e.target.value)
                                                                        }


                                                                    }}
                                                                    error={volumeSizeError}
                                                                    sx={{ mr: "10px" }}
                                                                    helperText={volumeSizeError && "Maximum limit exceed or Invalid format"}
                                                                    size="small" margin="dense" label='Size (in Gibibyte)' placeholder='5' />

                                                                <TextField value={volumePath} error={volumePath === "" && true} helperText={volumePath === "" && "Volume Path cannot be empty"} onChange={(e) => setvolumePath(e.target.value)} size="small" margin='dense' label="Volume Path" placeholder='/data/appname' />
                                                            </Grid>


                                                        </Grid>


                                                    </div> : <UpgradePlan />}

                                                <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Replica</p>
                                                <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>A number of replicas indicating how many Pods it should be maintaining at any given time.</span>
                                                <br />


                                                <TextField
                                                    size="small"
                                                    value={replica}
                                                    sx={{ my: 1 }}
                                                    error={(replica > replicasize) || (replica < 1)}
                                                    helperText={((replica > replicasize) || (replica < 1)) && `Replica limit is between 1-${replicasize}`}
                                                    type='number'
                                                    onChange={handleChangeReplicas} label="Select Replica"

                                                />
                                                <Typography sx={{ fontSize: "12px" }}>Maximum replica size limit for  <span style={{ color: "#5d86f9" }}>{(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName && JSON.parse(localStorage.getItem("access")).planName !== "free plan" ?
                                                    JSON.parse(localStorage.getItem("access")).planName : "Hobby plan")}
                                                    {localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName !== "free plan" && "plan"}</span> is {replicasize}
                                                </Typography>
                                                {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).infrastructureConfiguration) ?
                                                    <div>
                                                        <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Request Requirement</p>
                                                        <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>Requests define the minimum amount of resources that containers need.</span>
                                                        <br />
                                                        <TextField value={RequestCPU}
                                                            error={errorRequestCPU}
                                                            size="small"
                                                            sx={{ mt: 1 }}
                                                            helperText={errorRequestCPU && `Request CPU limit is bewtween 1-${localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).infrastructureConfiguration}`}

                                                            style={{ marginRight: "10px" }}
                                                            onChange={(e) => {
                                                                setRequestCPU(e.target.value)
                                                                seterrorRequestCPU(() => handleVerifyResourceConfig(e.target.value))



                                                            }}
                                                            label='vCPU' placeholder='0.5' />
                                                        <TextField value={RequestMemory}
                                                            error={errorRequestMemory}
                                                            size="small"
                                                            sx={{ mt: 1 }}
                                                            helperText={errorRequestMemory && `Request Memory limit is bewtween 1Gb-${localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).infrastructureConfiguration}Gb`}
                                                            onChange={(e) => {

                                                                setRequestMemory(e.target.value);

                                                                seterrorRequestMemory(() => handleVerifyResourceConfig(e.target.value))


                                                            }


                                                            }
                                                            label='Memory (in Gb)' placeholder='12' />
                                                        <br />
                                                        <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Limit Requirement</p>
                                                        <span style={{ marginTop: "0px", fontSize: "12px", color: "grey", marginBottom: "10px" }}> limits define the max amount of resources that the container can consume.</span>
                                                        <br />
                                                        <TextField value={LimitCPU} style={{ marginRight: "10px" }}
                                                            helperText={errorLimitCPU && `Limit CPU limit is bewtween 1-${localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).infrastructureConfiguration}`}
                                                            error={errorLimitCPU}
                                                            size="small"
                                                            sx={{ mt: 1 }}
                                                            onChange={(e) => {
                                                                setLimitCPU(e.target.value)
                                                                seterrorLimitCPU(() => handleVerifyResourceConfig(e.target.value))


                                                            }}

                                                            label='vCPU' placeholder='1' />
                                                        <TextField value={LimitMemory}
                                                            error={errorLimitMemory}
                                                            size="small"
                                                            sx={{ my: 1 }}
                                                            helperText={errorLimitMemory && `Request Memory limit is bewtween 1Gb-${localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).infrastructureConfiguration}Gb`}
                                                            onChange={(e) => {

                                                                setLimitMemory(e.target.value)
                                                                seterrorLimitMemory(() => handleVerifyResourceConfig(e.target.value))
                                                            }} label='Memory (in Gb)' placeholder='12' />

                                                    </div>

                                                    :
                                                    <UpgradePlan />}
                                            </AccordionDetails>
                                        </Accordion>






                                    </div>
                                </Slide>


                            </div>

                        </Grid>}

                </Grid>
            </Collapse>
            <Collapse in={(DPLoad || revertApploading) ? false : true} timeout="auto" unmountOnExit>
                <Box>



                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >



                        <div>

                            {filevalue === 0 &&
                                <Button disabled={internalPortValidation || externalPortValidation ? true : (dockerImageInput === "" ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : updateLoad ? true : appload ? true : false))}
                                    onClick={handleSubmit} color="primary" style={{ marginTop: "10px" }} variant="contained" disableElevation>
                                    {progressDockerImageInput ? <CircularProgress style={{ color: "white" }} size={20} /> : (DPLoad || revertApploading ? <> deploying with default region..<CircularProgress size={20} style={{ color: "grey" }} /> </>
                                        : "Deploy Image")}</Button>
                            }


                            {filevalue === 1 &&
                                <>
                                    <Button disabled={internalPortValidation || externalPortValidation ? true : ((!url ? true : updateLoad ? true : appload ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : false)))}
                                        onClick={handleSubmitDockerfile} color="primary" style={{ marginTop: "10px" }} variant="contained" disableElevation>{DPLoad || revertApploading ? <>deploying with default region..<CircularProgress size={20} style={{ color: "grey" }} /> </>
                                            : "Deploy app"}</Button>
                                    {startBuildCompleted && buildErr && <Button disableElevation variant='outlined' onClick={handleResetDockerImageName} style={{ marginTop: "10px", marginLeft: "10px" }} >Reset</Button>}
                                </>

                            }

                            {filevalue === 2 &&
                                <div>
                                    {isErrorDockerImageInput
                                        ?
                                        <div style={{ marginTop: "20px" }} color="red" disableElevation variant="outlined"> <ErrorOutlineIcon style={{ color: "red" }} /> {dockerImageInputErrorMessage.data.message}, please try again</div>
                                        :
                                        ((runtime === "mysql" || runtime === "postgres") ? <Button disabled={internalPortValidation || externalPortValidation ? true : (buildLoad ? true : (revertApploading ? true : DPLoad ? true : updateLoad ? true : appload ? true : false))} onClick={handleSubmit} color="primary" style={{ marginTop: "10px" }} variant="contained" disableElevation>{progressDockerImageInput ? <CircularProgress style={{ color: "white" }} size={20} /> : (DPLoad || revertApploading ? <> deploying with default region..<CircularProgress size={20} style={{ color: "grey" }} /> </>
                                            : "Deploy Db")}</Button> :
                                            <Button disabled={internalPortValidation || externalPortValidation ? true : (runtime === "" ? true : !url ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : updateLoad ? true : appload ? true : false))} onClick={handleSubmitDockerfile} color="primary" style={{ marginTop: "10px" }} variant="contained" disableElevation>{progressDockerImageInput ? <CircularProgress style={{ color: "white" }} size={20} /> : (DPLoad || revertApploading ? <> deploying with default region..<CircularProgress size={20} style={{ color: "grey" }} /> </>
                                                : "Deploy Built-in App")}</Button>)
                                    }
                                </div>
                            }

                            {filevalue === 3 &&
                                <>
                                    <Button disabled={internalPortValidation || externalPortValidation ? true : (githubLink === "" || githubBranch === "" ? true : (gitUrlValidation ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : updateLoad ? true : appload ? true : showGithubCheckError ? true : false)))} onClick={handleGithubDeploy} color="primary" style={{ marginTop: "10px", marginRight: "10px" }} variant="contained" disableElevation>{DPLoad || revertApploading ? <>deploying with default region..<CircularProgress size={20} style={{ color: "grey" }} /> </> : "Deploy App"}
                                    </Button>
                                    {startBuildCompleted && buildErr && <Button variant='outlined' disableElevation onClick={handleResetDockerImageName} style={{ marginTop: "10px" }} >reset</Button>}
                                </>
                            }


                            {filevalue === 4 &&
                                <Button disabled={internalPortValidation || externalPortValidation ? true : (dockerImageInput === "" ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : updateLoad ? true : appload ? true : false))} onClick={handleSubmit} color="primary" style={{ marginTop: "10px" }} variant="contained" disableElevation>{progressDockerImageInput ? <CircularProgress style={{ color: "white" }} size={20} /> : (DPLoad || revertApploading ? <> deploying with default region..<CircularProgress size={20} style={{ color: "grey" }} /> </>
                                    : "Deploy Template")}</Button>
                            }

                            {filevalue === 5 &&
                                <>
                                    <Button disabled={internalPortValidation || externalPortValidation ? true : (!templateVlaue ? true : buildLoad ? true : (revertApploading ? true : DPLoad ? true : updateLoad ? true : appload ? true : false))} onClick={handleMarkePlaceDeploymentsType} color="primary" style={{ marginTop: "10px" }} variant="contained" disableElevation>{progressDockerImageInput ? <CircularProgress style={{ color: "white" }} size={20} /> : (DPLoad || revertApploading ? <> deploying with default region..<CircularProgress size={20} style={{ color: "grey" }} /> </>
                                        : "Deploy MP")}</Button>

                                </>

                            }


                        </div>
                        <div>
                            <Fade in={updateLoad}>
                                <Typography variant='body2' sx={{ fontWeight: "bold", mx: 2 }}>Updating...</Typography>
                            </Fade>
                        </div>

                    </Grid>

                </Box>
            </Collapse>


        </div>
    );
}
