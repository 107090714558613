import React from 'react';
import Grid from '@mui/material/Grid';
import {Typography,FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { TOKEN_1000 } from '../Home/CreateNewApp/DeployCopy';
import { useMutation } from '@apollo/client';


function Security(props) {
    const [userPAT, setuserPAT] = React.useState(1);
    const [showtoken, setshowtoken] = React.useState(true)
    const [token1000Value, settoken1000Value] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();


    const [token1000] = useMutation(TOKEN_1000, {
        onCompleted: (data) => {
          settoken1000Value(data.setUserTokenExpireTime)
        },
        onError: (err) => {
          enqueueSnackbar(`Couldn't fetch token, ${err.message}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          })
        }
      });


    const handleChange = (event) => {
        setuserPAT(event.target.value);
        if(event.target.value === 10000){
            token1000()
        }
    };
    const handleCopy = () => {
        navigator.clipboard.writeText(userPAT === 10000 ? token1000Value :  localStorage.getItem("token"))
        enqueueSnackbar("Copied to clipboard!", {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    }
    return (
        <div style={{ padding: "16px", paddingTop: "0px" }}>
            <Grid container spacing={2}>


                <Grid item xs={12}>
                <Typography variant='h6' style={{ fontWeight: "bold", margin: "15px 0px" }}> Access Token </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="end"
                        style={{ marginBottom: "2rem" }}

                    >
                        <div>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                           
                        >
                        <FormControl sx={{ mr: 2 }} size="small">
                                <InputLabel id="demo-select-small-label">Expire time</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={userPAT}
                                    label="Expire time"
                                    onChange={handleChange}
                                >

                                    <MenuItem value={1}>1 Hour</MenuItem>
                                    <MenuItem value={10000}>Lifetime</MenuItem>
                                 
                                </Select>
                            </FormControl>


                            {showtoken ? (
                                <Typography variant="body2" gutterBottom noWrap={true}>{localStorage.getItem("token").slice(0, 2)}**********************</Typography>)
                                : ( <Typography
                                sx={{
                                    fontSize: {
                                        lg: 12,
                                        md: 11,
                                        sm: 10,
                                        xs: 10
                                    },
                                    wordWrap: 'break-word',
                                    width: '30rem',
                                }}>
                                <div style={{ whiteSpace: 'normal' }}>
                                    {userPAT === 10000 ? token1000Value :  localStorage.getItem("token")}
                                </div>
                            </Typography>)
                            }
                            </Grid>
                        </div>

                        <div>
                            <IconButton size='small' variant="outlined" color="primary" onClick={handleCopy}>
                                <FileCopyIcon />
                            </IconButton>
                            <IconButton size='small' variant="outlined" color="primary" onClick={() => { setshowtoken(!showtoken) }} >
                                {showtoken ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </div>
                    </Grid>

                </Grid>

            </Grid>
        </div>
    );
}

export default Security;