export const LOGIN = 'LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const REGISTER = 'REGISTER'
export const REGISTER_ERROR = 'REGISTER_ERROR'
export const CENTER = 'CENTER'
export const LOGOUT = 'LOGOUT'
export const REGENERATE = 'REGENERATE'
export const GOOGLEOBJ = 'GOOGLEOBJ'
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT'
export const LOGIN_AGAIN = 'LOGIN_AGAIN'
export const RESET_MSG = 'RESET_MSG'
export const GITHUBOBJ = 'GITHUBOBJ'