import { Add } from '@mui/icons-material'
import { Button, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { CustomTooltip } from '../Settings/Organization'


export default function EmptyPageRenderCustom({mainTitle,secondaryTitle,primaryButtonAction,primaryButtonText,iframeLink,imageList,imageListTitle,learnMoreLink}) {
    const role = localStorage.getItem("role")

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={7}>
                    <Grid container >

                        <Grid item xs={12} sm={12} sx={{ mt: 5, ml: 1 }}>
                            <Typography variant='h3' sx={{ my: 3, fontWeight: "lighter" }} color={"primary"}>{mainTitle}</Typography>
                            <Typography variant='p' sx={{ my: 2 }}>{secondaryTitle}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ mt: 2 }}>

                        <Button disabled={role==="Viewer"} onClick={()=>primaryButtonAction()} startIcon={<Add/>} variant='contained' sx={{ borderRadius: "24px", boxShadow:"rgba(17, 12, 46, 0.04) 0px 20px 30px 0px" }}>{primaryButtonText}</Button>
                       
                            <Button onClick={()=>window.open(learnMoreLink, '_blank').focus()} variant='outlined' sx={{ borderRadius: "24px",ml:2}}>Learn more</Button>
                        </Grid>
                       




                    </Grid>
                </Grid>



                <Grid item xs={12} sm={5}>

                    <iframe
                        src={iframeLink}
                        frameBorder="0"
                        webkitallowFullScreen
                        mozallowFullScreen
                        allowFullScreen
                        allow="fullscreen"
                        style={{ width: "100%", height: "100%", border:"1px solid rgba(0, 0, 0, 0.12)",borderRadius:"12px"}}>
                    </iframe>
                    {imageListTitle && <Typography sx={{color:"text.primary"}} variant='body2'>{imageListTitle}</Typography>}
                    {imageList && imageList.map((e) => {
                        return (
                            <CustomTooltip title={e.name} key={e.name}>

                           {e.type ==="svg" ? e.image : <img src={e.image}  height={"20px"} width="auto" style={{ margin: "10px 5px", filter: e.class ? "grayscale(1)" :undefined}} />}
                            
                            </CustomTooltip>)
                    })}



                </Grid>
            </Grid>
        </div>
    )
}
