import React from 'react';
import './LoadingBuildApp.css'; // Import your CSS file
import { Grid } from '@mui/material';

function LoadingBuildApp() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <div className="gears" id="two-gears">
        <div className="gears-container">
          <div className="gear-rotate"></div>
          <div className="gear-rotate-left"></div>
        </div>
      </div>
      <span>Building your app..</span>
    </Grid>

  );
}

export default LoadingBuildApp;
