import { createTheme } from '@mui/material/styles'

const currentMode = localStorage.getItem("mode");
export const theme = createTheme(
  {
    palette:
    {
      mode: currentMode ? currentMode : "light",
      primary: { main: currentMode === "dark" ? "#e3f2fd" : "#160044"}, //
      secondary: { main: "#ffcc80" },
    },
    typography: {
      fontFamily: "Rubik",
      color:"#111",
    },
    shape: {
      borderRadius: 4,
      borderBottomRightRadius: 0,
    },
    // components: {
    //   MuiPaper: {
    //     styleOverrides: {
    //       root: {
    //         backgroundColor: 'red',
    //       },
    //     },
    //   },
    // },


  }
);

export const yellowtheme = createTheme(
  {
    palette:
    {
      mode:"light",
      primary: { main:"#00FF00"}, //
      secondary: { main: "#ffcc80" },
    },
    typography: {
      fontFamily: "Rubik",
      color:"#00FF00",
    },
    shape: {
      borderRadius: 4,
      borderBottomRightRadius: 0,
    },



  }
);