
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import SitesTable from './SitesTable';
import Nav from '../../Settings/Nav';
import { CssBaseline } from '@mui/material';
import ExpiredPlanAlert from '../../../Tools/ExpiredPlanAlert';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 3,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        paddingLeft: "10%",
        padding: 5,
        paddingTop: "3%",
    },
    subtitle: {
        flexGrow: 1,
        paddingLeft: "10%",
        padding: 5,
    },
    content: {
        flexGrow: 1,
        height: '99.3vh',
        overflow: 'auto',
    },
    container: {
        paddingBottom: theme.spacing(4),
    },
    fixedHeight: {
        height: "auto",
    },
    header: {
        borderRadius: "12px",
        margin: "10px 24px 24px 24px",
        boxShadow: "rgba(17, 12, 46, 0.05) 0px 48px 100px 5px",
        paddingRight: "10px",
        paddingLeft: "10px",
    },
    headline: {

        fontSize: "18px",
        margin: "10px"
    },
    link: {
        display: 'flex',
        textDecoration: "none",
        color: '#5d86f9'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },

    card: {
        margin: "10px",
    }

}));

export default function SitesDashboard() {
    const classes = useStyles();

    useEffect(() => {
    }, []);
    if ( localStorage.getItem("expiredaccount")) {
        return (<>
            <Nav />
            <ExpiredPlanAlert />
        </>)
      }
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <main className={classes.content}>



                <Nav />
                <Container maxWidth={false} className={classes.container}>
                    <Typography variant='body2' sx={{ fontWeight: "lighter", mb: 1 }} color={"primary"}>Manage sites, redeploy and delete </Typography>
                    <SitesTable />
                </Container>
            </main>
        </div>
    );
}