import { IconButton } from '@mui/material'
import React from 'react'
import { FaLock } from 'react-icons/fa'

export default function UpgradeToUnlock() {
  return (
    <div><IconButton sx={{my:1,}}>

    <FaLock style={{fill:'linear-gradient(to right, #4dfce0, #a977f1)'}}/>
    </IconButton> Upgrade to Unlock</div>
  )
}
