import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from "react";
import ActivityTable from './ActivityTable';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },}))

export default function Main() {
  useEffect(() => {

  }, []);
  return (
    <div style={{margin:"25px"}}>
   
      <ActivityTable/>
    </div>
  );
}
