import React, { useEffect } from 'react'
import DeleteApp from "./DeleteApp"
import MoveApp from "./MoveApp"
import Divider from '@mui/material/Divider';
import SuspendApp from "./SuspendApp"
import useMediaQuery from '@mui/material/useMediaQuery';
import AddWorkload from './AddWorkload';
import { gql, useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

export const APP_STATUS = gql`
query AppStatus($name: String!){
  app(name: $name){
    id
    name
    status
}}`

export default function Main() {
	const permissions = JSON.parse(localStorage.getItem('permissions'))
	const match = useRouteMatch();
	const size = useMediaQuery('(min-width:600px)')
	const { loading, error, data } = useQuery(APP_STATUS, { variables: { name: match.params.id } });
	if (loading) {
		return "Loading.."
	}
	return (
		<div>

			<MoveApp size={size} />
			<Divider variant="middle" light={true} />
			{data && data.app && data.app.status !== "Suspended" && <>
				<AddWorkload />
				<Divider variant="middle" light={true} />
			</>}


			<SuspendApp status={data && data.app && data.app.status} />
			{permissions[0].delete &&
				<>
					<Divider variant="middle" light={true} />
					<DeleteApp />
				</>}


		</div>
	)
}