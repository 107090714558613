import React from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Line
} from "react-simple-maps";

const geoUrl = "/features.json";


const MoveMap = (props) => {
    const markers = [
        { markerOffset: -15, name: props.type[0], coordinates: props.src, type:props.names[0] },
        { markerOffset: -15, name: props.type[1], coordinates: props.dest, type: props.names[1] },
    ];
    
    return (
        <ComposableMap
            projection="geoEquirectangular"
            width={650}
            height={330}
            projectionConfig={{
                center: props.dest,
                scale: 200,
            }}
        >
            <Geographies geography={geoUrl}>
                {({ geographies }) =>
                    geographies.map((geo) => (
                        <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            fill="#EAEAEC"
                            stroke="#D6D6DA"
                        />
                    ))
                }
            </Geographies>
            {markers.map(({ name, coordinates,type, markerOffset }) => (
                <Marker key={name} coordinates={coordinates}>
                    <circle r={5} fill="#2dd881" height={100} width={100}>
                        <animate attributeName="opacity" dur="2s" values="0.5;1;0.5;0.3,0.0" repeatCount="indefinite" begin="0.1" />
                    </circle>

                    <text
                        textAnchor="middle"
                        y={15}
                        style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                    >
                        {type}
                       
                    </text>
                    <text
                        textAnchor="middle"
                        y={markerOffset}
                        style={{ fontFamily: "system-ui", fill: "#5d86f9",fontWeight:"bold" }}
                    >
                       
                       {name}
                    </text>
                </Marker>
            ))}
            <Line
                from={props.src}
                to={props.dest}
                stroke="#2dd881"
                strokeWidth={3}
                strokeLinecap="round"

            />
        </ComposableMap>
    );
};

export default MoveMap;
