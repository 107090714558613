import { Close } from '@mui/icons-material';
import { Box, Button, Collapse, Grid, Typography } from '@mui/material'
import React from 'react'
import { BiWorld } from 'react-icons/bi';

export default function CustomDomain({data}) {
    const [showDetails, setshowDetails] = React.useState(false);
    return (
        <div>
            <h3>Custom Domain</h3>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >

                <Typography> {showDetails ? <><b>Please Note:</b>You need to make sure that the following records are added to your domain's DNS records</>  : "Please click on the button to add your custom domain."}</Typography>
                <Button variant='contained' startIcon={showDetails ? <Close/> : <BiWorld/>} onClick={() => setshowDetails(!showDetails)}> {showDetails ? "Close" : "Add Custom Domain"}</Button>
            </Grid>
            <Collapse in={showDetails}>
                <Box sx={{ borderRadius: "12px", bgcolor: "#ededed",color: '#000000' , px:2,py:0.5,my:1 }}>
                    <pre>{`Record Type: CNAME

Name: You can add any name

Value: ${data}

TTL (optional): doesn't matter
`}</pre>
                </Box>
            </Collapse>
        </div>
    )
}
