import { gql, useQuery } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import DailogSettngs from "../Deployments/AppSettings/DailogSettngs";

import CancelIcon from '@mui/icons-material/Cancel';
import { Box, InputAdornment, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Moment from "moment";
import { FiBox } from "react-icons/fi";
import { columnsApp } from '../Home/Apps';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    boxShadow: "rgba(17, 12, 46, 0.05) 0px 08px 80px 02px",
    borderRadius: "12px"
  },
}));

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 180,
    align: "left",
    renderCell: (cellValues) => {

      return (<><IconButton style={{ padding: "0px", margin: "0px 5px 0px 0px" }} aria-label="delete" size="small">
        <FiBox style={{ color: "gray", margin: "0px" }} />
      </IconButton>
        {cellValues.value}</>)

    }
  },
  {
    field: "status",
    headerName: "Status",
    align: "center",
    renderCell: (cellValues) => {
      if (cellValues.value === "Active") {
        return (<div style={{ color: "green", background: "rgb(229, 252, 235)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
      }

      if (cellValues.value === "New") {
        return (<div style={{ color: "rgb(33, 150, 243)", background: "rgb(227, 242, 253)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
      }



    }
  },
  { field: "version", headerName: "Version", width: 100, align: "center" },


  {
    field: "deployed",
    headerName: "Deployed",

    align: "center",
    renderCell: (cellValues) => {
      return (
        <div>
          {cellValues.value === false ? (<CancelIcon style={{ color: "gray" }} />)
            : (<CheckCircleIcon style={{ color: "green" }} />)}
        </div>);
    }
  },
  { field: "organizationName", width: 150, headerName: "Organization", valueFormatter: ({ value }) => value, align: "center" },
  {
    field: "hostname", headerName: "Link", align: "left"
    , renderCell: (cellValues) => {
      return (
        <div
          style={{
            textAlign: "left"
          }}
        >
          {cellValues.value === "nife.io" ? ("Not Deployed") : (<Button endIcon={<OpenInNewIcon />}
            onClick={() => { window.open(cellValues.value + ":" + cellValues.row.config.definition.services[0].external_port.toString(), '_blank') }}
          >open</Button>)}
        </div>
      );
    }
  },

  {
    field: "action",
    headerName: "Action",

    align: "center",
    renderCell: (cellValues) => {
      var name = cellValues.row.name;
      return (<DailogSettngs appId={name} />);
    }
  },
  {
    field: "createdAt", headerName: "Date", align: "right", renderCell: (cellValues) => {
      var date = Moment(new Date(cellValues.row.createdAt), "YYYY MM DD hh:mm:ss").fromNow();
      return <div style={{ color: "grey" }}> {date}</div>
    }
  },

];

export const SUB_ORG_BASED_APP_LIST = gql`
query suborgList($subOrgSlug: String!){
  appsSubOrg(type: "", first:62, region:"", subOrgSlug:$subOrgSlug){
    nodes{
      id,
      name,
      deployed,
      status,
      version,
      hostname,
      createdAt,
      organizationName
      config{
        definition
      },
      regions{
        name
        },
      organization{
        slug
      }
    }
  }
}`

export const BU_BASED_APP_LIST = gql`
query buorgList($businessUnit: String!){
  appsBusinessUnit(type: "", first:62, region:"", businessUnit:$businessUnit){
    nodes{
      id,
      name,
      deployed,
      status,
      version,
      hostname,
      createdAt,
      organizationName
      config{
        definition
      },
      regions{
        name
        },
      organization{
        slug
      }
    }
  }
}`


export const REGION_BASED_APP_LIST = gql`
query orgList($orgSlug: String!){
  apps(type: "", first:62, region:"", orgSlug:$orgSlug){
    nodes{
      id,
      name,
      deployed,
      status,
      version,
      hostname,
      createdAt,
      imageName,
      builtinType,
      organizationName,
      userDetails {
        id
        email
        firstName
        lastName
        profileImageUrl 
       },
      config{
        definition
      },
      regions{
        name
        },
      organization{
        slug
      }
    }
  }
}`

export default function Dashboard(props) {
  const [searched, setSearched] = React.useState("")

  const match = useRouteMatch();


  const [rows, setRows] = React.useState([])

  var tempname = props.org
  if (tempname === null) {
    tempname = match.params.id
  }
  const classes = useStyles();
  const { loading, error, data } = useQuery(REGION_BASED_APP_LIST, {
    variables: { orgSlug: tempname.replaceAll(/\s/g, '') },
  });

  const { data: dataso, refetch: refetchsub } = useQuery(SUB_ORG_BASED_APP_LIST, {
    variables: { subOrgSlug: props.soname ? props.soname.replaceAll(/\s/g, '') : "" },
  });

  const { data: databu } = useQuery(BU_BASED_APP_LIST, {
    variables: { businessUnit: props.buname ? props.buname : "" },
  });



  useEffect(() => {
    document.title = "Org"
    refetchsub()
    if (!error && !loading) {
      setRows(
        data.apps.nodes
      );
    }



  }, [data, databu, dataso]);

  const [redirectTo, setRedirectTo] = React.useState(null)
  const handleCellClick = (param, event) => {
    if (param.colDef.field !== "action") {
      if (param.colDef.field !== "hostname") {
        setRedirectTo(param.row.name)
      }
    }

    if (param.colIndex === 2) {
      event.stopPropagation();
    }

  };

  if (loading) {
    return (
      <div>
        <Skeleton variant="rectangular" height={30} width="30%" style={{ margin: "10px", borderRadius: "5px" }} />
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      </div>
    );
  }
  if (error) return `Error! ${error.message}`;

  if (redirectTo) {

    return <Redirect to={`/deployments/overview/${redirectTo}`} />
  }


  return (

    <div>
      <TextField
        value={searched}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searched !== "" &&
                <IconButton onClick={() => setSearched("")}>
                  <CloseIcon />
                </IconButton>}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}

        size={"small"}
        sx={{ mb: 2 }}
        placeholder='Search for App'
        onChange={(e) => setSearched(e.target.value)}
      />

      {!props.buname && !props.soname && <Paper variant='outlined' className={classes.card}>
        {data && data.apps && data.apps.nodes !== null ?
          <Box style={{ width: "100%", margin: "0px 0px 0px 0px", padding: "10px" }}>
            {data.apps.nodes && <DataGrid
              rowHeight={70}
              autoHeight={true}
              density={"compact"}
              scrollbarSize={1}
              className={classes.root}
              rows={rows.filter((row) => {
                return row.name.toLowerCase().includes(searched) || row.organizationName.toLowerCase().includes(searched)
              })}
              columns={columnsApp}
              pageSize={10}
              onCellClick={handleCellClick}
              style={{ border: "2px" }}
            />
            }

          </Box> : <div style={{ margin: "10px", color: "grey" }}><b style={{ color: "black" }}>No App </b> <br /> No Apps found for this Organization </div>
        }
      </Paper>}


      {props.soname && <Paper variant='outlined' className={classes.card}>
        {dataso && dataso.appsSubOrg && dataso.appsSubOrg.nodes ?
          <div style={{ height: 600, width: "100%", marginTop: "10px" }}>
            {dataso.appsSubOrg.nodes && <DataGrid
              rowHeight={60}
              className={classes.root}
              rows={dataso.appsSubOrg.nodes.filter((row) => {
                return row.name.toLowerCase().includes(searched) || row.organizationName.toLowerCase().includes(searched)
              })}
              columns={columns}
              density={"compact"}
              pageSize={8}
              onCellClick={handleCellClick}
              style={{ border: "2px" }}
            />
            }

          </div>
          : <div style={{ margin: "10px", color: "grey" }}><b style={{ color: "black" }}>No App </b> <br /> No Apps found for this Sub-Organization </div>
        }
      </Paper>}


      {props.buname && <Paper variant='outlined' className={classes.card}>
        {databu && databu.appsBusinessUnit && databu.appsBusinessUnit.nodes !== null ?
          <div style={{ height: 600, width: "100%", marginTop: "10px" }}>
            {databu.appsBusinessUnit.nodes && <DataGrid
              rowHeight={60}
              className={classes.root}
              rows={databu.appsBusinessUnit.nodes.filter((row) => {
                return row.name.toLowerCase().includes(searched) || row.organizationName.toLowerCase().includes(searched)
              })}
              columns={columns}
              density={"compact"}
              pageSize={8}
              onCellClick={handleCellClick}
              style={{ border: "2px" }}
            />
            }

          </div> : <div style={{ margin: "10px", color: "grey" }}><b style={{ color: "black" }}>No App </b> <br /> No Apps found for this Business Unit </div>
        }
      </Paper>}



    </div>

  );
}