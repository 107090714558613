import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Autocomplete, Avatar, Box, Button, Container, Divider, Grid, Grow, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Skeleton, TextField, Typography, Zoom } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from "react";
import { AiFillCheckCircle, AiOutlineAppstoreAdd } from 'react-icons/ai';
import { BsSquareFill } from 'react-icons/bs';
import 'react-datepicker/dist/react-datepicker.css'
import { CgDatabase } from 'react-icons/cg';
import { GrFormAdd } from 'react-icons/gr';
import { MdDelete } from 'react-icons/md';
import { VscDebugRestart } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { RiAddLine, RiLinksLine } from 'react-icons/ri';
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import UpgradePlan from '../../Containers/UpgradePlan';
import OrgGraph from '../Home/OrgGraph';
import Title from '../Home/Title';
import { INSTANCE_LIST } from '../Settings/ManageInstances';
import Nav from '../Settings/Nav';
import { BiTimeFive } from 'react-icons/bi';
import DatePicker from 'react-datepicker';
import LocationRadarChart from '../Home/LocationsRadarChart';
import WorkloadBarChart, { WORKLOAD_BAR_CHART } from '../Home/WorkloadBarChart';
import { SiWheniwork } from 'react-icons/si';
import { IoIosPeople } from 'react-icons/io';
import ExpiredPlanAlert from '../../Tools/ExpiredPlanAlert';

export const FETCH_ALL_MEMBERS = gql`
query {
    getUserByAdmin {
          id
          email
          firstName
          lastName
          profileImageUrl
          userProfileCreated
          roleId
          
    }
}`

export const USER_ACTIVITY_TIME_BAESD = gql`
query UserActivityTimeBasedQuery($startDate: String,$endDate: String){
    userActivitiesByDate(startDate: $startDate, endDate: $endDate) {
      id
      type
      userId
      activities
      isRead
      createdAt
    }
}`

export const ORG_TOTAL_COUNT = gql`
query{
  organizations{
    nodes{
      id
      parentId
    }
}
}
`

export const FETCH_ANALYTICS = gql`
	query fetchAnalytics($startDate: String,$endDate: String){
        userDeploymentCountDetails(startDate: $startDate, endDate: $endDate){
            userName
            email
            companyName
            roleId
            totalDeployed
            totalReDeployed
            deployData {
                date
                deployed
              }
            reDeployData {
                date
                reDeployed
            }
              
  }
}
`

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 3,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        paddingLeft: "10%",
        padding: 5,
        paddingTop: "3%",
        marginBottom: "0px",
    },
    drawerPaper: {
        background: "linear-gradient(0deg,#4e73df 10%,#618aff 100%)",
        color: "#fff",
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '99.3vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    paper: {
        padding: theme.spacing(0),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: "auto",
    },
    header: {
        borderRadius: "12px",
        margin: "10px 24px 24px 24px",
        boxShadow: "rgba(17, 12, 46, 0.05) 0px 48px 100px 5px",
        paddingRight: "10px",
        paddingLeft: "10px",
    },

    formControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
    },
    activeText: {
        margin: 0,
        paddingLeft: 10,
        fontWeght: "bold",
    },
    inactiveText: {
        margin: 0,
        paddingLeft: 10,
        color: "#939393",
    },
    headline: {
        color: theme.palette.text.primary,
        fontSize: "18px",
        margin: "10px"
    },
    link: {
        display: 'flex',
        textDecoration: "none",
        color: '#5d86f9'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    text: {
        color: theme.palette.text.primary,
    },
}));

export default function Analytics() {
    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState(null);
    const [value, setValue] = React.useState(0);
    const [months, setmonths] = useState("Today");
    const [toDate, settoDate] = useState(moment().add(1, 'days').format('YY-MM-DD')); // end date
    const [fromDate, setfromDate] = useState(moment().subtract(1, 'days').format('YY-MM-DD')); // start date
    let history = useHistory()
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate1, endDate1] = dateRange;
    const [showCustomDate, setshowCustomDate] = useState(false);
    const [memberValue, setmemberValue] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [firstTimeUpdateUser, setfirstTimeUpdateUser] = useState(true);
    const [totalOrgCount, settotalOrgCount] = useState(0);
    const openforDate = Boolean(anchorEl);


    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleClickforDate = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseforDate = () => {
        setAnchorEl(null);
    };

    const handleClick = useCallback(
        (entry, index) => {

            setActiveIndex(entry);
        },
        [setActiveIndex]
    );

    const [fetchOrgCount, { loading: orgCountLoading, data: orgCountData }] = useLazyQuery(ORG_TOTAL_COUNT);
    const [fetchWlCount, { loading: wlCountLoading, data: wlCountData }] = useLazyQuery(WORKLOAD_BAR_CHART);

    const [fetchMembers, { loading: memberload, error: membererror, data: memberdata }] = useLazyQuery(FETCH_ALL_MEMBERS, {
        onCompleted: (data) => {
            if (data && data.getUserByAdmin.length > 0 && firstTimeUpdateUser) {
                setmemberValue(data.getUserByAdmin[0])
                setfirstTimeUpdateUser(false)
            }

        }
    })
    const { loading: loadTable, error: errTabler, data: dataTable, refetch: refetchTable } = useQuery(FETCH_ANALYTICS, {
        variables: { startDate: fromDate, endDate: toDate },
        onCompleted: (data) => {
            if (data) {
                setActiveIndex(data.userDeploymentCountDetails[0])
            }
        }
    });
    const { loading: loadact, error: erract, data: userdataact, refetch: refetchaact } = useQuery(USER_ACTIVITY_TIME_BAESD, {
        variables: { startDate: fromDate, endDate: toDate },
        onCompleted: () => {

        },
        onError: () => {

        }
    });

    const handleSeeMore = () => {
        history.push("/activity")
    }


    useEffect(() => {
        fetchOrgCount();
        fetchWlCount();
        fetchMembers();
        refetchTable();
        refetchaact();
    }, [fromDate, toDate, totalOrgCount]);

    if ( localStorage.getItem("expiredaccount")) {
        return (<>
            <Nav />
            <ExpiredPlanAlert/>
        </>)
      }

    if (erract) {
        return (erract.message)
    }


    return (
        <div><div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Nav />

                <div>
                    <Container maxWidth={false} className={classes.container}>
                    <Typography variant='body2' sx={{fontWeight:"lighter",mb:1}} color={"primary"}>Statistics at user account level </Typography>
                        <Box sx={{ width: '100%' }}>

                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline"

                            >

                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} aria-label="basic tabs example">
                                        <Tab label="Apps" {...a11yProps(0)} onClick={() => handleChange(0)} />
                                        <Tab label="Organizations" {...a11yProps(1)} onClick={() => handleChange(1)} />
                                        <Tab label="Workloads" {...a11yProps(2)} onClick={() => handleChange(2)} />
                                        <Tab label="Locations" {...a11yProps(3)} onClick={() => handleChange(3)} />

                                    </Tabs>
                                </Box>


                                <Box>


                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"

                                    >
                                        <Autocomplete
                                            id="country-select-demo"
                                            value={memberValue}
                                            disabled={(memberload || loadTable || loadact)}
                                            onChange={(event, newValue) => {

                                                setmemberValue(newValue);
                                                setActiveIndex(dataTable.userDeploymentCountDetails.find((e) => {
                                                    return e.email === newValue.email;
                                                }))
                                            }}
                                            sx={{ minWidth: 200, mr: 2 }}
                                            size='small'
                                            options={(memberdata && memberdata.getUserByAdmin.filter((e) => {
                                                return (e.userProfileCreated === "1") || (e.id === localStorage.getItem("userId"))
                                            })) || []}
                                            autoHighlight
                                            getOptionLabel={(option) => `${option.firstName} (${option.email})`}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <Avatar src={option.profileImageUrl} sx={{ ml: 2 }} />

                                                    {option.firstName} ({option.email})
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose a member"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />



                                        <Button
                                            aria-controls={openforDate ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openforDate ? 'true' : undefined}

                                            startIcon={<BiTimeFive />}

                                            onClick={handleClickforDate}
                                            variant='contained' disableElevation style={{ borderRadius: "24px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}>
                                            {months}

                                        </Button>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={openforDate}
                                            onClose={handleCloseforDate}
                                            id="fade-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'fade-button',
                                            }}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    minWidth: 200,
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    '&:before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                                            TransitionComponent={Zoom}
                                        >




                                            {showCustomDate ? <Box>
                                                <DatePicker
                                                    selectsRange
                                                    inline
                                                    startDate={startDate1}
                                                    endDate={endDate1}
                                                    onChange={(update) => {
                                                        setDateRange(update);
                                                        if ((moment(update[0]).add(1, 'days').format('YY-MM-DD') !== "Invalid date") && (moment(update[1]).subtract(1, 'days').format('YY-MM-DD') !== "Invalid date")) {
                                                            setfromDate(moment(update[0]).subtract(1, 'days').format('YY-MM-DD'))
                                                            settoDate(moment(update[1]).add(1, 'days').format('YY-MM-DD'))
                                                            setmonths("custom")
                                                            handleCloseforDate();
                                                        }
                                                    }}

                                                    maxDate={new Date()}
                                                />
                                            </Box> : <MenuItem onClick={() => {
                                                setshowCustomDate(true);
                                            }}>Custom</MenuItem>}
                                            <Divider />
                                            <MenuItem onClick={() => {
                                                setfromDate(moment().subtract(1, 'days').format('YY-MM-DD'));
                                                setmonths("Today");
                                                setshowCustomDate(false)
                                                handleCloseforDate();
                                            }}>Today</MenuItem>
                                            <MenuItem onClick={() => {
                                                setfromDate(moment().subtract(1, 'weeks').format('YY-MM-DD'));
                                                setmonths("Last 1 Week ");
                                                handleCloseforDate();
                                                setshowCustomDate(false)
                                            }}>Last 1 Week</MenuItem>
                                            <MenuItem onClick={() => {
                                                setfromDate(moment().subtract(1, 'months').format('YY-MM-DD'));
                                                setmonths("Last 1 Month");
                                                handleCloseforDate();
                                                setshowCustomDate(false)
                                            }}>Last 1 Month</MenuItem>
                                            <MenuItem onClick={() => {
                                                setfromDate(moment().subtract(6, 'months').format('YY-MM-DD'));
                                                setmonths("Last 6 Month");
                                                handleCloseforDate();
                                                setshowCustomDate(false)
                                            }}>Last 6 Month </MenuItem>
                                            <MenuItem onClick={() => {
                                                setfromDate(moment().subtract(1, 'years').format('YY-MM-DD'));
                                                setmonths("Last 1 Year");
                                                handleCloseforDate();
                                                setshowCustomDate(false)
                                            }}>Last 1 Year </MenuItem>
                                            <MenuItem onClick={() => {
                                                setfromDate(moment().subtract(5, 'years').format('YY-MM-DD'));
                                                setmonths("Life Time");
                                                handleCloseforDate();
                                                setshowCustomDate(false)
                                            }}>Life Time </MenuItem>


                                        </Menu>
                                    </Grid>
                                </Box>

                            </Grid>

                            <Typography variant='body2' sx={{ py: 1.5, color: "grey" }}>Showing results from {fromDate} to {toDate}, <a href="https://nife-demo-videos.s3.ap-south-1.amazonaws.com/UserAnalytics.mp4" style={{ color: "grey" }} >learn more.</a></Typography>

                            <TabPanel value={value} index={0}>
                                {loadact || loadTable ? <Box sx={{ m: 2 }}>
                                    <Skeleton style={{ margin: "10px" }} />
                                    <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
                                </Box> : <div>


                                    {userdataact && userdataact.userActivitiesByDate && memberValue && <Grid container spacing={2}>
                                        <Grid item xs={6} lg={3}>
                                            <Grow
                                                in={userdataact}
                                                style={{ transformOrigin: '0 0 0' }}
                                                {...(userdataact ? { timeout: 500 } : {})}
                                            >
                                                <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px" }}>

                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        alignItems="left"
                                                    >
                                                        <IconButton style={{ maxWidth: "40px", background: "#e5fceb", color: "green", borderRadius: "12px", margin: "10px" }}><AiOutlineAppstoreAdd /></IconButton>
                                                        <Typography variant='body2' style={{ paddingLeft: "10px" }}>App Created</Typography>
                                                        <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                            {userdataact && userdataact.userActivitiesByDate.filter((e) => {

                                                                return e.activities === "CREATED" && e.type === "APP" && e.userId === memberValue.id
                                                            }).length}

                                                        </h4>
                                                        <br />
                                                        <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>

                                                    </Grid>

                                                </Paper>
                                            </Grow>
                                        </Grid>

                                        <Grid item xs={6} lg={3}>
                                            <Grow
                                                in={userdataact}
                                                style={{ transformOrigin: '0 0 0' }}
                                                {...(userdataact ? { timeout: 1000 } : {})}
                                            >
                                                <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px" }}>
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        alignItems="left"
                                                    >
                                                        <IconButton style={{ maxWidth: "40px", background: "#e3f2fd", color: "#5d86f9", borderRadius: "12px", margin: "10px" }}><AiFillCheckCircle /></IconButton>
                                                        <Typography variant='body2' style={{ paddingLeft: "10px" }}>App Deployed</Typography>
                                                        <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                            {userdataact && userdataact.userActivitiesByDate.filter((e) => {
                                                                return e.activities === "DEPLOYED" && e.type === "APP" && e.userId === memberValue.id
                                                            }).length}

                                                        </h4>
                                                        <br />
                                                        <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>

                                                    </Grid>

                                                </Paper>
                                            </Grow>
                                        </Grid>

                                        <Grid item xs={6} lg={3}>
                                            <Grow
                                                in={userdataact}
                                                style={{ transformOrigin: '0 0 0' }}
                                                {...(userdataact ? { timeout: 1500 } : {})}
                                            >
                                                <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px" }}>
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        alignItems="left"
                                                    >
                                                        <IconButton style={{ maxWidth: "40px", background: "#fff8e1", color: "rgb(255, 193, 7)", borderRadius: "12px", margin: "10px" }}><VscDebugRestart /></IconButton>
                                                        <Typography variant='body2' style={{ paddingLeft: "10px" }}>App Redeployed</Typography>
                                                        <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                            {userdataact && userdataact.userActivitiesByDate.filter((e) => {
                                                                return e.activities === "REDEPLOYED" && e.type === "APP" && e.userId === memberValue.id
                                                            }).length}

                                                        </h4>
                                                        <br />
                                                        <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>

                                                    </Grid>

                                                </Paper>
                                            </Grow>
                                        </Grid>

                                        <Grid item xs={6} lg={3}>
                                            <Grow
                                                in={userdataact}
                                                style={{ transformOrigin: '0 0 0' }}
                                                {...(userdataact ? { timeout: 2000 } : {})}
                                            >
                                                <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px" }}>
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        alignItems="left"
                                                    >
                                                        <IconButton sx={{ maxWidth: "40px", background: "#f6f7fb",color: (theme) => theme.palette.mode === 'dark' ? '#000000' : '#FFFFFF',  borderRadius: "12px", margin: "10px" }}><MdDelete /></IconButton>
                                                        <Typography variant='body2' style={{ paddingLeft: "10px" }}>App Deleted</Typography>
                                                        <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                            {userdataact && userdataact.userActivitiesByDate.filter((e) => {
                                                                return e.activities === "DELETED" && e.type === "APP" && e.userId === memberValue.id
                                                            }).length}

                                                        </h4>
                                                        <br />
                                                        <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>

                                                    </Grid>

                                                </Paper>
                                            </Grow>
                                        </Grid>
                                    </Grid>}

                                    <Grid container>

                                        {dataTable &&

                                            <Paper variant='outlined' style={{ width: "100%", padding: "10px", borderRadius: "12px", marginBottom: "15px", marginTop: "16px" }}>
                                                <Grid container style={{ width: "100%" }}>
                                                    {dataTable && dataTable.userDeploymentCountDetails &&
                                                        <Grid item xs={12} lg={4} style={{ width: "99%", height: "100%" }}>
                                                            <span style={{ textAlign: "center", margin: "10px", fontSize: "13px" }}>Deployments based on Users
                                                            </span>
                                                            <br />
                                                            <span style={{ textAlign: "center", margin: "10px", fontSize: "13px", color: "grey" }}>Click on the User to see more details
                                                            </span>
                                                            <ResponsiveContainer width={'99%'} height={300}>

                                                                <BarChart data={dataTable.userDeploymentCountDetails.filter((e, i) => {
                                                                    return (e.totalDeployed) || (e.totalReDeployed)
                                                                })} margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5
                                                                }}>
                                                                    <CartesianGrid strokeDasharray="3 3" />
                                                                    <XAxis dataKey="userName" />
                                                                    <YAxis />
                                                                    <Tooltip />
                                                                    <Bar name='Total Deployed' dataKey="totalDeployed" fill='#35a2eb' onClick={handleClick} />
                                                                    <Bar name='Total Redeployed' dataKey="totalReDeployed" fill='#4bc0c0' onClick={handleClick} />

                                                                    {/* <Bar name='Total Deployed' dataKey="totalDeployed" onClick={handleClick}>
                                                                        {dataTable.userDeploymentCountDetails.filter((e,i)=>{
                                                                            return (e.totalDeployed===0?false:true)
                                                                        }).map((entry, index) => (
                                                                            <Cell
                                                                                cursor="pointer"
                                                                                fill={index === activeIndex ? "#35a2eb" : "rgba(53, 162, 235, 0.5)"}
                                                                                key={`cell-${index}`}
                                                                            />
                                                                        ))}
                                                                    </Bar> */}
                                                                    {/* <Bar label name='Total Redeployed' dataKey="totalReDeployed" onClick={handleClick}>
                                                                        {dataTable.userDeploymentCountDetails.filter((e,i)=>{
                                                                            return(e.totalReDeployed===0?false:true)
                                                                        }).map((entry, index) => (
                                                                            <Cell
                                                                                cursor="pointer"
                                                                                fill={index === activeIndex ? "#4bc0c0" : "rgba(40, 247, 102, 0.5)"}
                                                                                key={`cell-${index}`}
                                                                            />
                                                                        ))}
                                                                    </Bar> */}
                                                                </BarChart>
                                                            </ResponsiveContainer>

                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                Deploy  <BsSquareFill style={{ color: "#35a2eb", marginRight: "10px", marginLeft: "2px" }} />
                                                                Redeploy <BsSquareFill style={{ color: "#4bc0c0", marginLeft: "2px" }} />
                                                            </Grid>

                                                        </Grid>}

                                                    {dataTable &&
                                                        activeIndex &&
                                                        activeIndex.deployData &&
                                                        <Grid item xs={12} lg={4} style={{ width: "33%", height: "100%" }}>
                                                            <span style={{ textAlign: "center", margin: "10px", fontSize: "13px" }}>
                                                                Showing details for <b>{dataTable && activeIndex.userName}</b>
                                                            </span>
                                                            <br />
                                                            <span style={{ textAlign: "center", margin: "10px", fontSize: "13px", color: "grey" }}>
                                                                Deployment details
                                                            </span>
                                                            <ResponsiveContainer width={'99%'} height={300}>
                                                                <AreaChart
                                                                    data={activeIndex.deployData}
                                                                    margin={{
                                                                        top: 10,
                                                                        right: 30,
                                                                        left: 0,
                                                                        bottom: 0
                                                                    }}
                                                                >
                                                                    <CartesianGrid strokeDasharray="3 3" />
                                                                    <XAxis dataKey="date" />
                                                                    <YAxis />
                                                                    <Tooltip />
                                                                    <Area
                                                                        name='Deployed'
                                                                        type="monotone"
                                                                        dataKey="deployed"
                                                                        syncId="anyId"
                                                                        stackId="1"
                                                                        stroke="#35a2eb"
                                                                        fill="#35a2ebb5"
                                                                    />
                                                                </AreaChart>
                                                            </ResponsiveContainer>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                Deploy  <BsSquareFill style={{ color: "#35a2ebb5", marginRight: "10px", marginLeft: "2px" }} />

                                                            </Grid>
                                                        </Grid>}

                                                    {dataTable &&
                                                        activeIndex &&
                                                        activeIndex.reDeployData &&
                                                        <Grid item xs={12} lg={4} style={{ width: "33%", height: "100%" }}>

                                                            <br />
                                                            <span style={{ textAlign: "center", margin: "10px", fontSize: "13px", color: "grey" }}>
                                                                Redeployment details
                                                            </span>
                                                            <ResponsiveContainer width={'99%'} height={300}>
                                                                <AreaChart
                                                                    data={activeIndex.reDeployData}
                                                                    margin={{
                                                                        top: 10,
                                                                        right: 30,
                                                                        left: 0,
                                                                        bottom: 0
                                                                    }}
                                                                >
                                                                    <CartesianGrid strokeDasharray="3 3" />
                                                                    <XAxis dataKey="date" />
                                                                    <YAxis />
                                                                    <Tooltip />
                                                                    <Area
                                                                        name='Redeployed'
                                                                        type="monotone"
                                                                        syncId="anyId"
                                                                        dataKey="reDeployed"
                                                                        stackId="1"
                                                                        stroke="#8884d8"
                                                                        fill="#8884d8"
                                                                    />


                                                                </AreaChart>
                                                            </ResponsiveContainer>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                Redeploy  <BsSquareFill style={{ color: "#8884d8", marginRight: "10px", marginLeft: "2px" }} />

                                                            </Grid>

                                                        </Grid>}



                                                </Grid>
                                            </Paper>}
                                    </Grid>
                                </div>}

                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                {loadact || loadTable ? <Box sx={{ m: 2 }}>
                                    <Skeleton style={{ margin: "10px" }} />
                                    <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
                                </Box> :
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={3}>
                                                <Grow
                                                    in={userdataact}
                                                    style={{ transformOrigin: '0 0 0' }}
                                                    {...(userdataact ? { timeout: 500 } : {})}
                                                >
                                                    <Box>
                                                        <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginBottom: "15px" }}>
                                                            <Grow
                                                                in={userdataact}
                                                                style={{ transformOrigin: '0 0 0' }}

                                                            >
                                                                <Box>

                                                                    <Grid
                                                                        container
                                                                        direction="column"
                                                                        justifyContent="flex-start"
                                                                        alignItems="left"
                                                                    >
                                                                        <IconButton style={{ maxWidth: "40px", background: "#f5ecff", color: "rgb(133, 27, 192)", borderRadius: "12px", margin: "10px" }}><IoIosPeople /></IconButton>
                                                                        <Typography variant='body2' style={{ paddingLeft: "10px" }}>Total Organizations</Typography>
                                                                        <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                                            {orgCountData && orgCountData.organizations.nodes && orgCountData.organizations.nodes.filter((e) => { return e.parentId === "" }).length}
                                                                            <span style={{ fontWeight: "normal", fontSize: "12px" }}> in this account</span>
                                                                        </h4>
                                                                        <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>
                                                                    </Grid>
                                                                </Box>
                                                            </Grow>
                                                        </Paper>
                                                    </Box>
                                                </Grow>
                                            </Grid>
                                            <Grid item xs={12} lg={3}>
                                                <Grow
                                                    in={userdataact}
                                                    style={{ transformOrigin: '0 0 0' }}
                                                    {...(userdataact ? { timeout: 500 } : {})}
                                                >
                                                    <Box>
                                                        <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginBottom: "15px" }}>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="flex-start"
                                                                alignItems="left"
                                                            >
                                                                <IconButton style={{ maxWidth: "40px", background: "#f5ecff", color: "rgb(133, 27, 192)", borderRadius: "12px", margin: "10px" }}><GrFormAdd /></IconButton>
                                                                <Typography variant='body2' style={{ paddingLeft: "10px" }}>Organization Created</Typography>
                                                                <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                                    {userdataact && userdataact.userActivitiesByDate && userdataact.userActivitiesByDate.filter((e) => {
                                                                        return e.activities === "CREATED" && e.type === "ORGANIZATION"
                                                                    }).length}<span style={{ fontSize: "12px", marginLeft: "5px" }}></span>

                                                                </h4>
                                                                <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>
                                                            </Grid>
                                                        </Paper>
                                                    </Box>
                                                </Grow>
                                            </Grid>
                                            <Grid item xs={12} lg={3}>
                                                <Grow
                                                    in={userdataact}
                                                    style={{ transformOrigin: '0 0 0' }}
                                                    {...(userdataact ? { timeout: 1000 } : {})}
                                                >
                                                    <Box>
                                                        <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginBottom: "15px" }}>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="flex-start"
                                                                alignItems="left"
                                                            >
                                                                <IconButton style={{ maxWidth: "40px", background: "#f5ecff", color: "rgb(133, 27, 192)", borderRadius: "12px", margin: "10px" }}><RiLinksLine /></IconButton>
                                                                <Typography variant='body2' style={{ paddingLeft: "10px" }}>Organization Migrated</Typography>
                                                                <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                                    {userdataact && userdataact.userActivitiesByDate && userdataact.userActivitiesByDate.filter((e) => {
                                                                        return e.activities === "MIGRATED" && e.type === "ORGANIZATION"
                                                                    }).length}<span style={{ fontSize: "12px", marginLeft: "5px" }}></span>

                                                                </h4>
                                                                <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>
                                                            </Grid>
                                                        </Paper>
                                                    </Box>
                                                </Grow>
                                            </Grid>
                                            <Grid item xs={12} lg={3}>
                                                <Grow
                                                    in={userdataact}
                                                    style={{ transformOrigin: '0 0 0' }}
                                                    {...(userdataact ? { timeout: 1500 } : {})}
                                                >
                                                    <Box>
                                                        <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginBottom: "15px" }}>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="flex-start"
                                                                alignItems="left"
                                                            >
                                                                <IconButton style={{ maxWidth: "40px", background: "#f5ecff", color: "rgb(133, 27, 192)", borderRadius: "12px", margin: "10px" }}><MdDelete /></IconButton>
                                                                <Typography variant='body2' style={{ paddingLeft: "10px" }}>Organization Deleted</Typography>
                                                                <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                                    {userdataact && userdataact.userActivitiesByDate && userdataact.userActivitiesByDate.filter((e) => {
                                                                        return e.activities === "DELETED" && e.type === "ORGANIZATION"
                                                                    }).length}<span style={{ fontSize: "12px", marginLeft: "5px" }}></span>

                                                                </h4>
                                                                <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>
                                                            </Grid>
                                                        </Paper>
                                                    </Box>
                                                </Grow>
                                            </Grid>

                                        </Grid>
                                    </Box>}
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                                {loadact || loadTable ? <Box sx={{ m: 2 }}>
                                    <Skeleton style={{ margin: "10px" }} />
                                    <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
                                </Box> :
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={4}>
                                                <Grow
                                                    in={userdataact}
                                                    style={{ transformOrigin: '0 0 0' }}
                                                    {...(userdataact ? { timeout: 500 } : {})}
                                                >
                                                    <Box>
                                                        <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginBottom: "15px" }}>
                                                            <Grow
                                                                in={userdataact}
                                                                style={{ transformOrigin: '0 0 0' }}

                                                            >
                                                                <Box>

                                                                    <Grid
                                                                        container
                                                                        direction="column"
                                                                        justifyContent="flex-start"
                                                                        alignItems="left"
                                                                    >
                                                                        <IconButton style={{ maxWidth: "40px", background: "#e3f2fd", color: "#5d86f9", borderRadius: "12px", margin: "10px" }}><SiWheniwork /></IconButton>
                                                                        <Typography variant='body2' style={{ paddingLeft: "10px" }}>Total Workload</Typography>
                                                                        <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                                            {wlCountData && wlCountData.getWorkloadMangementByUser && wlCountData.getWorkloadMangementByUser.length} <span style={{ fontWeight: "normal", fontSize: "12px" }}>in this account</span>
                                                                        </h4>
                                                                        <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>
                                                                    </Grid>
                                                                </Box>
                                                            </Grow>
                                                        </Paper>
                                                    </Box>
                                                </Grow>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Grow
                                                    in={userdataact}
                                                    style={{ transformOrigin: '0 0 0' }}
                                                    {...(userdataact ? { timeout: 500 } : {})}
                                                >
                                                    <Box>
                                                        <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginBottom: "15px" }}>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="flex-start"
                                                                alignItems="left"
                                                            >
                                                                <IconButton style={{ maxWidth: "40px", background: "#e3f2fd", color: "#5d86f9", borderRadius: "12px", margin: "10px" }}><GrFormAdd /></IconButton>
                                                                <Typography variant='body2' style={{ paddingLeft: "10px" }}>Workload Created</Typography>
                                                                <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                                {userdataact && userdataact.userActivitiesByDate && userdataact.userActivitiesByDate.filter((e) => {
                                                                        return e.activities === "CREATED" && e.type === "WORKLOAD"
                                                                    }).length}
                                                                </h4>
                                                                <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>
                                                            </Grid>
                                                        </Paper>
                                                    </Box>
                                                </Grow>
                                            </Grid>
                                          
                                            <Grid item xs={12} lg={4}>
                                                <Grow
                                                    in={userdataact}
                                                    style={{ transformOrigin: '0 0 0' }}
                                                    {...(userdataact ? { timeout: 1000 } : {})}
                                                >
                                                    <Box>
                                                        <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginBottom: "15px" }}>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="flex-start"
                                                                alignItems="left"
                                                            >
                                                                <IconButton style={{ maxWidth: "40px", background: "#e3f2fd", color: "#5d86f9", borderRadius: "12px", margin: "10px" }}><MdDelete /></IconButton>
                                                                <Typography variant='body2' style={{ paddingLeft: "10px" }}>Workload Deleted</Typography>
                                                                <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                                    {userdataact && userdataact.userActivitiesByDate && userdataact.userActivitiesByDate.filter((e) => {
                                                                        return e.activities === "DELETED" && e.type === "WORKLOAD"
                                                                    }).length}<span style={{ fontSize: "12px", marginLeft: "5px" }}></span>

                                                                </h4>
                                                                <Button onClick={handleSeeMore} fullWidth={false} size='small'>See More</Button>
                                                            </Grid>
                                                        </Paper>
                                                    </Box>
                                                </Grow>
                                            </Grid>

                                        </Grid>
                                    </Box>
                             }



                            </TabPanel>

                            <TabPanel value={value} index={3}>

                                {loadact || loadTable ? <Box sx={{ m: 2 }}>
                                    <Skeleton style={{ margin: "10px" }} />
                                    <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
                                </Box> : <Grid container spacing={2}>


                                    {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).applicationHealthDashboard) ?
                                        <>


                                            <Grid item xs={12} lg={4}>

                                                {userdataact && <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginLeft: "0px", marginBottom: "20px" }}>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Zoom
                                                            in={true}
                                                            style={{ transformOrigin: '0 0 0' }}
                                                            {...(true ? { timeout: 500 } : {})}
                                                        >
                                                            <div>
                                                                <Typography variant='body2' style={{ paddingLeft: "10px" }}>BYOC Created</Typography>
                                                                <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                                    {userdataact && userdataact.userActivitiesByDate && userdataact.userActivitiesByDate.filter((e) => {
                                                                        return e.activities === "ADDED" && e.type === "BYOC REGION"
                                                                    }).length}

                                                                </h4>
                                                            </div></Zoom>
                                                        <IconButton style={{ maxWidth: "40px", borderRadius: "50%", margin: "10px", cursor: "default" }}><GrFormAdd /></IconButton>


                                                    </Grid>
                                                    <Divider />
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Zoom
                                                            in={true}
                                                            style={{ transformOrigin: '0 0 0' }}
                                                            {...(true ? { timeout: 500 } : {})}
                                                        >
                                                            <div>
                                                                <Typography variant='body2' style={{ paddingLeft: "10px" }}>BYOC Removed</Typography>
                                                                <h4 style={{ paddingLeft: "10px", margin: "0px" }}>
                                                                    {userdataact && userdataact.userActivitiesByDate && userdataact.userActivitiesByDate.filter((e) => {
                                                                        return e.activities === "REMOVED" && e.type === "BYOC REGION"
                                                                    }).length}

                                                                </h4>
                                                            </div></Zoom>
                                                        <IconButton style={{ maxWidth: "40px", borderRadius: "50%", margin: "10px", cursor: "default" }}><MdDelete /></IconButton>


                                                    </Grid>


                                                </Paper>}



                                            </Grid>
                                            {/* <Grid item xs={12} lg={8}>
                                                <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginLeft: "0px", marginBottom: "20px" }}>
                                                    <LocationRadarChart />
                                                </Paper>
                                            </Grid> */}



                                        </> : <Paper variant='outlined' style={{ padding: "10px", borderRadius: "12px", marginBottom: "20px" }}> <UpgradePlan /></Paper>}

                                </Grid>}
                            </TabPanel>



                        </Box>






                    </Container>
                </div>
            </main>

        </div >

        </div >
    )


}
