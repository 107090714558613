import { gql, useMutation, useQuery } from '@apollo/client';
import { Add, CheckCircle, Close, FileCopy, InfoOutlined, MoreVert, Room, Search, Visibility, VisibilityOff } from '@mui/icons-material';
import { AppBar, Button, CircularProgress, Collapse, Container, Divider, Fade, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, Menu, MenuItem, Paper, Select, TextField, Toolbar, Tooltip, Typography, Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsCloudHaze2Fill } from 'react-icons/bs';
import { FaAws } from 'react-icons/fa';
import { SiDatadog, SiGooglecloud } from 'react-icons/si';
import { VscAzure } from 'react-icons/vsc';
import { Transition } from '../Databases/DatabaseTable';
import EmptyPageRenderCustom from '../NewPages/EmptyPageRenderCustom';
import { ORG_LIST } from '../Settings/Organization';

export const ADD_KUBE_CONFIG_REGION = gql`
  mutation AddKubeConfigRegionMutation($regionName:String!, $clusterConfigUrl: String!,$providerType: String, $regionCode: String){
    addRegionUsingKubeConfig(input: {
    regionName:$regionName
    clusterConfigUrl:$clusterConfigUrl
    providerType: $providerType
    regionCode: $regionCode
    }
)}`

export const SYNC_BYOC = gql`
  mutation addUserAddedregionsToOrganizatiomMutation($organizationId:[String]){
    addUserAddedregionsToOrganizatiom(
    organizationId:$organizationId
)}`


export const DELETE_KUBE_CONFIG_REGION = gql`
  mutation DeleteKubeConfigRegionMutation($id:String!){
    deleteKubeConfigRegion(
    id:$id
)}`

export const REMOVE_DATADOG_BYOC = gql`
  mutation RemoveDatadogBYOC($dataDogId:String!){
    deleteDataDogByoc(
    dataDogId:$dataDogId
)}`



export const ADD_DATA_DOG_TO_BYOC = gql`
mutation AddDataDogToBYOC($apiKey: String,$appKey: String, $apiEndpoint: String, $clusterId: String){
    addDataDogBYOC(input: {
      apiKey:$apiKey,
      appKey:$appKey,
      apiEndpoint:$apiEndpoint,
      clusterId:$clusterId
    })
  }
`

export const UPDATE_DATA_DOG_TO_BYOC = gql`
mutation UpdateDataDogToBYOC($apiKey: String,$appKey: String, $apiEndpoint: String, $id: String){
    updateDataDogByoc(input: {
      apiKey:$apiKey,
      appKey:$appKey,
      apiEndpoint:$apiEndpoint,
      id:$id
    })
  }
`


export const FIND_DATADOG_FOR_BYOC = gql`
query{
    getDataDogByUserId{
    id
    clusterId
    apiKey
    appKey
    apiEndpoint

}
}
`

export const LIST_KUBE_REGIONS = gql`
query{
    getUserAddedRegions{
        id
        regionCode
        providerType
        clusterType
        regionName
        externalBaseAddress
        externalAgentPlatForm
        externalLBType
        externalCloudType
        interfaceType
        route53countryCode
        tenantId
        allocationTag
        loadBalancerURL
        isDefault
        clusterConfigUrl
    }
}`

export const generateUUID = () => {
    let
        d = new Date().getTime(),
        d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
};
export const awslocations = [
    { 'code': 'us-east-1', 'name': 'US East (N. Virginia)' },
    { 'code': 'us-east-2', 'name': 'US East (Ohio)' },
    { 'code': 'us-west-1', 'name': 'US West (N. California)' },
    { 'code': 'us-west-1', 'name': 'US West (Oregon)' },
    { 'code': 'ca-central-1', 'name': 'Canada (Central)' },
    { 'code': 'eu-west-1', 'name': 'EU (Ireland)' },
    { 'code': 'eu-west-2', 'name': 'EU (London)' },
    { 'code': 'eu-west-3', 'name': 'EU (Paris)' },
    { 'code': 'eu-central-1', 'name': 'EU (Frankfurt)' },
    { 'code': 'eu-north-1', 'name': 'EU (Stockholm)' },
    { 'code': 'ap-northeast-1', 'name': 'Asia Pacific (Tokyo)' },
    { 'code': 'ap-northeast-2', 'name': 'Asia Pacific (Seoul)' },
    { 'code': 'ap-southeast-1', 'name': 'Asia Pacific (Singapore)' },
    { 'code': 'ap-southeast-2', 'name': 'Asia Pacific (Sydney)' },
    { 'code': 'ap-south-1', 'name': 'Asia Pacific (Mumbai)' },
    { 'code': 'sa-east-1', 'name': 'South America (São Paulo)' }
]
export function BYOCInstructions(props) {
    const [showAwsScript, setshowAwsScript] = useState(false);

    const [fileUploaded, setfileUploaded] = useState(false);
    const [isFileUploading, setisFileUploading] = useState(false);
    const [showAddBYOC, setshowAddBYOC] = useState(true);
    const [selectedCloud, setselectedCloud] = useState(props.isCluster ? "StandAlone" : "AWS");

    const [awsAccountId, setawsAccountId] = useState("");
    const [userRegionName, setuserRegionName] = useState("");

    const [standAloneRegionCode, setstandAloneRegionCode] = useState("");
    const [age, setAge] = React.useState('');

    const { enqueueSnackbar } = useSnackbar();

    const awsScript = `# Go to home directory
    cd ~
    
    mv ~/.kube/config ~/.kube/config.backup
    
    # Create a policy
    cat >${userRegionName}-trust-policy.json <<EOF
    {
        "Version": "2012-10-17",
        "Statement": [
            {
                "Effect": "Allow",
                "Principal": {
                    "Service": [
                        "ec2.amazonaws.com",
                        "eks.amazonaws.com"
                    ]
                },
                "Action": "sts:AssumeRole"
            }
        ]
    }
    EOF
    
    # Create a new role and assign it a policy
    aws iam create-role --role-name ${userRegionName}-role --assume-role-policy-document file://"${userRegionName}-trust-policy.json"
    aws iam attach-role-policy --policy-arn arn:aws:iam::aws:policy/AmazonEKSClusterPolicy --role-name ${userRegionName}-role
    aws iam attach-role-policy --policy-arn arn:aws:iam::aws:policy/AmazonEKSWorkerNodePolicy --role-name ${userRegionName}-role
    aws iam attach-role-policy --policy-arn arn:aws:iam::aws:policy/AmazonEC2ContainerRegistryReadOnly --role-name ${userRegionName}-role
    aws iam attach-role-policy --policy-arn arn:aws:iam::aws:policy/AmazonEKS_CNI_Policy --role-name ${userRegionName}-role
    
    # Creating a cluster with a role
    vpc_id=$(aws ec2 describe-vpcs --filters "Name=isDefault,Values=true" --query "Vpcs[0].VpcId" --output text)
    subnet_id1=$(aws ec2 describe-subnets --filters "Name=vpc-id,Values=\${vpc_id}" --query "Subnets[0].SubnetId" --output text)
    subnet_id2=$(aws ec2 describe-subnets --filters "Name=vpc-id,Values=\${vpc_id}" --query "Subnets[1].SubnetId" --output text)
    
    aws eks create-cluster --region ${age} --name ${userRegionName} --kubernetes-version 1.24 \
      --role-arn arn:aws:iam::${awsAccountId}:role/${userRegionName}-role \
      --resources-vpc-config subnetIds=\${subnet_id1},\${subnet_id2}
    
    echo "waiting for cluster to get created"
    aws eks wait cluster-active --name ${userRegionName}
    
    # Creating a node pool and assigning it to the cluster
    ## COMMAND for 4CPU-8MEMaws
    aws eks create-nodegroup --cluster-name ${userRegionName} --nodegroup-name ${userRegionName}-nodegroup-1 \
        --subnets "\${subnet_id1}" "\${subnet_id2}" \
        --instance-types c5a.xlarge \
        --ami-type AL2_x86_64 \
        --capacity-type SPOT \
        --scaling-config minSize=1,maxSize=8,desiredSize=1 \
        --update-config maxUnavailable=1 \
        --disk-size 160 \
        --node-role arn:aws:iam::${awsAccountId}:role/${userRegionName}-role
    
    ## COMMAND FOR 8CPU-16MEMaws
    aws eks create-nodegroup --cluster-name ${userRegionName} --nodegroup-name ${userRegionName}-nodegroup-2 \
        --subnets "\${subnet_id1}" "\${subnet_id2}" \
        --instance-types c5a.2xlarge \
        --ami-type AL2_x86_64 \
        --capacity-type SPOT \
        --scaling-config minSize=0,maxSize=4,desiredSize=0 \
        --update-config maxUnavailable=1 \
        --disk-size 320 \
        --node-role arn:aws:iam::${awsAccountId}:role/${userRegionName}-role
    
    echo "waiting for nodegroup to get created"
    aws eks wait nodegroup-active --cluster-name ${userRegionName} --nodegroup-name ${userRegionName}-nodegroup-1
    
    # Adding add-ons
    # EBS CSI
    aws eks create-addon --cluster-name ${userRegionName} --addon-name aws-ebs-csi-driver \
        --service-account-role-arn arn:aws:iam::${awsAccountId}:role/${userRegionName}-role
    
    # kube-proxy
    aws eks create-addon --cluster-name ${userRegionName} --addon-name kube-proxy \
        --service-account-role-arn arn:aws:iam::${awsAccountId}:role/${userRegionName}-role
    
    # coredns
    aws eks create-addon --cluster-name ${userRegionName} --addon-name coredns \
        --service-account-role-arn arn:aws:iam::${awsAccountId}:role/${userRegionName}-role
    
    # Create a service account for nife with appropriate permissons
    kubectl create serviceaccount nife-cluster-admin-sa
    kubectl create clusterrolebinding nife-cluster-admin-crb --clusterrole=cluster-admin --serviceaccount=default:nife-cluster-admin-sa
    kubectl apply -f - <<EOF
    apiVersion: v1
    kind: Secret
    type: kubernetes.io/service-account-token
    metadata:
      name: nife-cluster-admin-sa-token
      annotations:
        kubernetes.io/service-account.name: nife-cluster-admin-sa
    EOF
    
    # Updating config file of cluster
    aws eks update-kubeconfig --region ${age} --name ${userRegionName}
    
    # Creating config file to be exported
    user_token=$(kubectl get secrets nife-cluster-admin-sa-token -o=jsonpath='{.data.token}' | base64 -d)
    
    cluster_endpoint=$(aws eks describe-cluster \
        --region ${age} \
        --name ${userRegionName} \
        --query "cluster.endpoint" \
        --output text)
    
    certificate_data=$(aws eks describe-cluster \
        --region ${age} \
        --name ${userRegionName} \
        --query "cluster.certificateAuthority.data" \
        --output text)
    
    read -r -d '' KUBECONFIG <<EOF
    apiVersion: v1
    kind: Config
    clusters:
    - name: ${age}
      cluster:
        certificate-authority-data: \${certificate_data}
        server: \${cluster_endpoint}
    contexts:
    - name: ${age}
      context:
        cluster: ${age}
        namespace: default
        user: ${age}
    current-context: ${age}
    users:
    - name: ${age}
      user:
        token: \${user_token}
    EOF
    echo "\${KUBECONFIG}" >~/nife_config_template
    
    rm ~/.kube/config
    mv ~/.kube/config.backup ~/.kube/config`

    const azureScript = `# Go to home directory
    cd ~
    
    mv ~/.kube/config ~/.kube/config.backup
    
    # register Microsoft.OperationsManagement and Microsoft.OperationalInsights using the following commands:
    echo "Registering for OperationsManagement and OperationsInsights..."
    az provider register --namespace Microsoft.OperationsManagement
    az provider register --namespace Microsoft.OperationalInsights
    
    # Create a resource group, TODO change location
    echo "Creating resource group..."
    az group create --name nife-resource-group --location ${age}
    
    # Create a cluster
    echo "Creating AKS cluster..."
    az aks create -g nife-resource-group -n ${userRegionName} --enable-managed-identity --node-count 1 --enable-addons monitoring --enable-msi-auth-for-monitoring  --generate-ssh-keys
    
    echo "Cluster created"
    
    echo "Installing aks cli..."
    sudo az aks install-cli
    
    # Create a nife service account
    echo "Creating service account..."
    kubectl create serviceaccount nife-cluster-admin-sa
    
    echo "Creating clusterrolebinding..."
    kubectl create clusterrolebinding nife-cluster-admin-crb --clusterrole=cluster-admin --serviceaccount=default:nife-cluster-admin-sa
    
    echo "Create a token secret"
    kubectl apply -f - <<EOF
    apiVersion: v1
    kind: Secret
    type: kubernetes.io/service-account-token
    metadata:
      name: nife-cluster-admin-sa-token
      annotations:
        kubernetes.io/service-account.name: nife-cluster-admin-sa
    EOF
    
    echo "Getting kubeconfig data...."
    $certificate_data = az aks get-credentials --resource-group nife-resource-group --name ${userRegionName} --file - | grep certificate-authority-data | awk '{print $2}'
    $cluster_endpoint = az aks show --resource-group nife-resource-group --name ${userRegionName} --query "fqdn" -o tsv
    $user_token = kubectl get secrets nife-cluster-admin-sa-token -o=jsonpath='{.data.token}' | base64 --decode
    
    read -r -d '' KUBECONFIG <<EOF
    apiVersion: v1
    kind: Config
    clusters:
    - name: ${age}
      cluster:
        certificate-authority-data: \${certificate_data}
        server: \${cluster_endpoint}
    contexts:
    - name: ${age}
      context:
        cluster: ${age}
        namespace: default
        user: ${age}
    current-context: ${age}
    users:
    - name: ${age}
      user:
        token: \${user_token}
    EOF
    echo "\${KUBECONFIG}" >~/nife_config_template
    
    rm ~/.kube/config
    mv ~/.kube/config.backup ~/.kube/config`

    const gcpScript = `# Go to home directory
    cd ~
    
    mv ~/.kube/config ~/.kube/config.backup
    
    # uncomment below line to set project_id in which user want to get there cluster to run in
    gcloud config set project ${awsAccountId}
    
    # Configure the gcloud command-line tool with your Google Cloud credentials
    gcloud auth login --no-launch-browser
    
    gcloud services enable container.googleapis.com
    
    # Create a new GKE cluster with default settings
    gcloud container clusters create ${userRegionName} --zone ${age}
    
    # Confirm that the cluster was created
    gcloud container clusters list
    
    # Create a nife service account
    kubectl create serviceaccount nife-cluster-admin-sa
    
    kubectl create clusterrolebinding nife-cluster-admin-crb --clusterrole=cluster-admin --serviceaccount=default:nife-cluster-admin-sa
    
    kubectl apply -f - <<EOF
    apiVersion: v1
    kind: Secret
    type: kubernetes.io/service-account-token
    metadata:
      name: nife-cluster-admin-sa-token
      annotations:
        kubernetes.io/service-account.name: nife-cluster-admin-sa
    EOF
    
    certificate_data=$(gcloud container clusters describe ${userRegionName} --zone=${age} --format='value(masterAuth.clusterCaCertificate)')
    cluster_endpoint=$(gcloud container clusters describe ${userRegionName} --zone=${age} --format='value(endpoint)')
    user_token=$(kubectl get secrets nife-cluster-admin-sa-token -o=jsonpath='{.data.token}' | base64 -d)
    
    read -r -d '' KUBECONFIG <<EOF
    apiVersion: v1
    kind: Config
    clusters:
    - name: ${age}
      cluster:
        certificate-authority-data: \${certificate_data}
        server: \${cluster_endpoint}
    contexts:
    - name: ${age}
      context:
        cluster: ${age}
        namespace: default
        user: ${age}
    current-context: ${age}
    users:
    - name: ${age}
      user:
        token: \${user_token}
    EOF
    echo "\${KUBECONFIG}" >~/nife_config_template
    
    rm ~/.kube/config
    mv ~/.kube/config.backup ~/.kube/config`

    const gcplocations = [
        { 'code': 'asia-east1', 'name': '(Asia Pacific)Changhua County, Taiwan' },
        { 'code': 'asia-east2', 'name': '(Asia Pacific)Hong Kong' },
        { 'code': 'asia-northeast1', 'name': '(Asia Pacific)Tokyo, Japan' },
        { 'code': 'asia-south1', 'name': '(Asia Pacific)Mumbai, India' },
        { 'code': 'asia-southeast1', 'name': '(Asia Pacific)Jurong West, Singapore' },
        { 'code': 'australia-southeast1', 'name': '(Asia Pacific)Sydney, Australia' },
        { 'code': 'europe-north1', 'name': '(EU)Hamina, Finland' },
        { 'code': 'europe-west1', 'name': '(EU)St. Ghislain, Belgium' },
        { 'code': 'europe-west2', 'name': '(EU)London, England, UK' },
        { 'code': 'europe-west3', 'name': '(EU)Frankfurt, Germany' },
        { 'code': 'europe-west4', 'name': '(EU)Eemshaven, Netherlands' },
        { 'code': 'northamerica-northeast1', 'name': '(North America)Montréal, Québec, Canada' },
        { 'code': 'southamerica-east1', 'name': '(South America)São Paulo, Brazil' },
        { 'code': 'us-central1', 'name': '(US Central)Council Bluffs, Iowa, USA' },
        { 'code': 'us-east1', 'name': '(US East)Moncks Corner, South Carolina, USA' },
        { 'code': 'us-east4', 'name': '(US East)Ashburn, Northern Virginia, USA' },
        { 'code': 'us-west1', 'name': '(US West)The Dalles, Oregon, USA' },
        { 'code': 'us-west2', 'name': '(US West)Los Angeles, California, USA' }]

    const azurelocations = [
        { 'code': 'eastus', 'name': '(US) East US' },
        { 'code': 'eastus2', 'name': '(US) East US 2' },
        { 'code': 'southcentralus', 'name': '(US) South Central US' },
        { 'code': 'westus2', 'name': '(US) West US 2' },
        { 'code': 'westus3', 'name': '(US) West US 3' },
        { 'code': 'australiaeast', 'name': '(Asia Pacific) Australia East' },
        { 'code': 'southeastasia', 'name': '(Asia Pacific) Southeast Asia' },
        { 'code': 'northeurope', 'name': '(Europe) North Europe' },
        { 'code': 'swedencentral', 'name': '(Europe) Sweden Central' },
        { 'code': 'uksouth', 'name': '(Europe) UK South' },
        { 'code': 'westeurope', 'name': '(Europe) West Europe' },
        { 'code': 'centralus', 'name': '(US) Central US' },
        { 'code': 'southafricanorth', 'name': '(Africa) South Africa North' },
        { 'code': 'centralindia', 'name': '(Asia Pacific) Central India' },
        { 'code': 'eastasia', 'name': '(Asia Pacific) East Asia' },
        { 'code': 'japaneast', 'name': '(Asia Pacific) Japan East' },
        { 'code': 'koreacentral', 'name': '(Asia Pacific) Korea Central' },
        { 'code': 'canadacentral', 'name': '(Canada) Canada Central' },
        { 'code': 'francecentral', 'name': '(Europe) France Central' },
        { 'code': 'germanywestcentral', 'name': '(Europe) Germany West Central' },
        { 'code': 'norwayeast', 'name': '(Europe) Norway East' },
        { 'code': 'switzerlandnorth', 'name': '(Europe) Switzerland North' },
        { 'code': 'uaenorth', 'name': '(Middle East) UAE North' },
        { 'code': 'brazilsouth', 'name': '(South America) Brazil South' },
        { 'code': 'centraluseuap', 'name': '(US) Central US EUAP' },
        { 'code': 'eastus2euap', 'name': '(US) East US 2 EUAP' },
        { 'code': 'qatarcentral', 'name': '(Middle East) Qatar Central' },
        { 'code': 'centralusstage', 'name': '(US) Central US (Stage)' },
        { 'code': 'eastusstage', 'name': '(US) East US (Stage)' },
        { 'code': 'eastus2stage', 'name': '(US) East US 2 (Stage)' },
        { 'code': 'northcentralusstage', 'name': '(US) North Central US (Stage)' },
        { 'code': 'southcentralusstage', 'name': '(US) South Central US (Stage)' },
        { 'code': 'westusstage', 'name': '(US) West US (Stage)' },
        { 'code': 'westus2stage', 'name': '(US) West US 2 (Stage)' },
        { 'code': 'eastasiastage', 'name': '(Asia Pacific) East Asia (Stage)' },
        { 'code': 'southeastasiastage', 'name': '(Asia Pacific) Southeast Asia (Stage)' },
        { 'code': 'brazilus', 'name': '(South America) Brazil US' },
        { 'code': 'eastusstg', 'name': '(US) East US STG' },
        { 'code': 'northcentralus', 'name': '(US) North Central US' },
        { 'code': 'westus', 'name': '(US) West US' },
        { 'code': 'jioindiawest', 'name': '(Asia Pacific) Jio India West' },
        { 'code': 'devfabric', 'name': '(US) devfabric' },
        { 'code': 'westcentralus', 'name': '(US) West Central US' },
        { 'code': 'southafricawest', 'name': '(Africa) South Africa West' },
        { 'code': 'australiacentral', 'name': '(Asia Pacific) Australia Central' },
        { 'code': 'australiacentral2', 'name': '(Asia Pacific) Australia Central 2' },
        { 'code': 'australiasoutheast', 'name': '(Asia Pacific) Australia Southeast' },
        { 'code': 'japanwest', 'name': '(Asia Pacific) Japan West' },
        { 'code': 'jioindiacentral', 'name': '(Asia Pacific) Jio India Central' },
        { 'code': 'koreasouth', 'name': '(Asia Pacific) Korea South' },
        { 'code': 'southindia', 'name': '(Asia Pacific) South India' },
        { 'code': 'westindia', 'name': '(Asia Pacific) West India' },
        { 'code': 'canadaeast', 'name': '(Canada) Canada East' },
        { 'code': 'francesouth', 'name': '(Europe) France South' },
        { 'code': 'germanynorth', 'name': '(Europe) Germany North' },
        { 'code': 'norwaywest', 'name': '(Europe) Norway West' },
        { 'code': 'switzerlandwest', 'name': '(Europe) Switzerland West' },
        { 'code': 'ukwest', 'name': '(Europe) UK West' },
        { 'code': 'uaecentral', 'name': '(Middle East) UAE Central' },
        { 'code': 'brazilsoutheast', 'name': '(South America) Brazil Southeast' }
    ]

    const [syncBYOC, { loading: syncLoad }] = useMutation(SYNC_BYOC, {
        refetchQueries: [LIST_KUBE_REGIONS],
        onError: (err) => {

            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        },
        onCompleted: () => {
            handleClose()
            enqueueSnackbar("Successfully Synced!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        }
    });


    const handleClose = () => {
        setselectedCloud("")
        setfileUploaded(false)
        setisFileUploading(false)
        setAge('')
        setawsAccountId('')
        setshowAwsScript(false)
        setuserRegionName('')
    };


    const handleResetAll = () => {
        setselectedCloud("")
        setfileUploaded(false)
        setisFileUploading(false)
        setAge('')
        setawsAccountId('')
        setshowAwsScript(false)
        setuserRegionName('')
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };





    const uploadFunction = async (e) => {
        console.log(e)
        if (e[0]) {
            setfileUploaded(true)
            await axios
                .post(`${process.env.REACT_APP_API_LIVE}/api/v1/kubeCongfigFileUpload/${generateUUID()}`, e[0], { headers: { "Content-Type": e.type } })
                .then((response) => {
                    setisFileUploading(true)
                    if (selectedCloud === "StandAlone") {
                        AddKubeConfigRegion({
                            variables: {
                                clusterConfigUrl: response.data.s3Link,
                                regionCode: standAloneRegionCode,
                                regionName: userRegionName,
                                providerType: "other",
                            }
                        })
                    }
                    else {
                        AddKubeConfigRegion({
                            variables: {
                                clusterConfigUrl: response.data.s3Link,
                                providerType: selectedCloud.toLowerCase(),
                                regionName: userRegionName,
                            }
                        })
                    }

                })
                .catch((error) => {
                    setisFileUploading(true)
                    enqueueSnackbar(error.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                    })
                });
        }
    };


    const { loading: loadorg, error: errorg, data: orgdata, refetch: orgRefech } = useQuery(ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
    });


    const [AddKubeConfigRegion, { loading: addLoad, error: addErr }] = useMutation(ADD_KUBE_CONFIG_REGION, {
        refetchQueries: [LIST_KUBE_REGIONS],
        onError: (err) => {
            handleResetAll()
            enqueueSnackbar(`${err.message}, please try agian!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        },
        onCompleted: () => {
            handleClose()
            if (props.handleCloseDialog) {
                props.handleCloseDialog()
            }
            if (orgdata && orgdata.organizations.nodes) {
                var tempIds = [];
                orgdata.organizations.nodes.forEach(element => {
                    tempIds.push(element.id)
                });

                syncBYOC({
                    variables: { organizationId: tempIds }
                })
            }

            enqueueSnackbar("Successfully Completed!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        }
    });

    const onDrop = (acceptedFiles) => {
        uploadFunction(acceptedFiles);
    };

    const isDisabled = selectedCloud === "StandAlone" && standAloneRegionCode !== "" && userRegionName !== "" ? false : (userRegionName === "" || age === "");

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxSize: 100000000,
        disabled: isDisabled
    });

    useEffect(() => {
        if (props.userSelectedCloud) {

            setselectedCloud(props.userSelectedCloud)
        }
        if (props.data) {
            setselectedCloud(props.data.type)
            setAge(props.data.regionCode)
            setuserRegionName(props.data.regionName)
            console.log(props.data.awsAccountId)
            if (props.data.type === "AWS") {
                setawsAccountId(props.data.awsAccountId)
            }

            if (props.data.type === "GCP") {
                setawsAccountId(props.data.gcpProjectName)
            }
            setshowAddBYOC(false)
        }
    }, []);



    return (
        <Container sx={{ mt: 10 }}>


            {showAwsScript ? <Box>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >

                    <Button sx={{ m: 2 }} disabled={!age || userRegionName === ""} variant="contained" startIcon={<FileCopy />} onClick={() => {
                        if (selectedCloud === "AWS") {
                            navigator.clipboard.writeText(awsScript);
                        }
                        if (selectedCloud === "GCP") {
                            navigator.clipboard.writeText(gcpScript);
                        }
                        if (selectedCloud === "Azure") {
                            navigator.clipboard.writeText(azureScript);
                        }

                        enqueueSnackbar("Copied to clipboard!", {
                            variant: 'success',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                        });
                    }}>Copy to clipboard </Button>

                    <Button disabled={!age} onClick={() => setshowAwsScript(!showAwsScript)}
                        startIcon={showAwsScript ? <VisibilityOff /> : <Visibility />}
                        variant='outlined'>{showAwsScript ? "Hide script" : "show Script"}
                    </Button>
                </Grid>
                <Box>
                    {selectedCloud === "AWS" && <pre>
                        {awsScript}
                    </pre>}
                    {selectedCloud === "GCP" && <pre>
                        {gcpScript}
                    </pre>}
                    {selectedCloud === "Azure" && <pre>
                        {azureScript}
                    </pre>}

                </Box>

            </Box>
                :
                <Grid container spacing={2}>
                    {showAddBYOC && <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Paper variant='outlined' style={{ padding: "15px" }}>

                            {!addLoad &&
                                <Grid container spacing={2}>

                                    {!props.isCluster && <Grid item lg={4} sm={4} md={4} xs={12}>
                                        <Paper variant='outlined' sx={{ minHeight: "50px", p: 2, border: selectedCloud === "AWS" ? "2px solid #160044" : undefined, boxShadow: selectedCloud === "AWS" ? "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" : undefined, cursor: "pointer" }} onClick={() => setselectedCloud("AWS")}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >

                                                <img style={{ width: 80, height: 40 }} src={"https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg"} />
                                                <span style={{ marginTop: 5, fontSize: "12px" }}>Amazon Web <br />Services</span>
                                                <Zoom in={selectedCloud === "AWS" ? true : false}>
                                                    <IconButton sx={{ mt: 1, p: 0 }}> <CheckCircle color='primary' /> </IconButton>
                                                </Zoom>

                                            </Grid>
                                        </Paper>
                                    </Grid>}

                                    {!props.isCluster && <Grid item lg={4} sm={4} md={4} xs={12}>
                                        <Paper variant='outlined' sx={{ minHeight: "50px", p: 2, border: selectedCloud === "GCP" ? "2px solid #160044" : undefined, boxShadow: selectedCloud === "GCP" ? "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" : undefined, cursor: "pointer" }} onClick={() => setselectedCloud("GCP")}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <img style={{ width: 80, height: 40 }} src={"https://brandeps.com/logo-download/G/Google-Cloud-logo-vector-01.svg"} />
                                                <span style={{ marginTop: 5, fontSize: "12px" }}>Google Cloud <br />Platform</span>
                                                <Zoom in={selectedCloud === "GCP" ? true : false}>
                                                    <IconButton sx={{ mt: 1, p: 0 }}> <CheckCircle color='primary' /> </IconButton>
                                                </Zoom>

                                            </Grid>
                                        </Paper>
                                    </Grid>}

                                    {!props.isCluster && <Grid item lg={4} sm={4} md={4} xs={12}>
                                        <Paper variant='outlined' sx={{ minHeight: "50px", p: 2, border: selectedCloud === "Azure" ? "2px solid #160044" : undefined, boxShadow: selectedCloud === "Azure" ? "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" : undefined, cursor: "pointer" }} onClick={() => setselectedCloud("Azure")}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <img style={{ width: 80, height: 40, marginRight: "5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg"} />
                                                <span style={{ marginTop: 5, fontSize: "12px" }}>Microsoft Azure <br/> Cloud</span>
                                                <Zoom in={selectedCloud === "Azure" ? true : false}>
                                                    <IconButton sx={{ mt: 1, p: 0 }}>
                                                        <CheckCircle color='primary' />
                                                    </IconButton>
                                                </Zoom>

                                            </Grid>
                                        </Paper>
                                    </Grid>}

                                   {props.isCluster && <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <Paper variant='outlined' sx={{ minHeight: "50px", p: 2, border: selectedCloud === "StandAlone" ? "2px solid #160044" : undefined, boxShadow: selectedCloud === "StandAlone" ? "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" : undefined, cursor: "pointer" }} onClick={() => setselectedCloud("StandAlone")}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                         
                                            <BsCloudHaze2Fill style={{ width: 80, height: 40, marginRight: "5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg"} />
                                            <span style={{ fontSize: "16px" }}>Stand Alone Cluster</span>

                                            </Grid>
                                        </Paper>
                                    </Grid>}

                                </Grid>}

                            {!addLoad && selectedCloud !== "StandAlone" && <Box sx={{ minWidth: 120, mt: 2 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select {selectedCloud} Region</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        disabled={addLoad}
                                        label={`Select ${selectedCloud} Region`}
                                        onChange={handleChange}

                                    >
                                        {selectedCloud === "AWS" && awslocations.map((e, i) => {
                                            return <MenuItem value={e.code}>{e.name}</MenuItem>
                                        })}

                                        {selectedCloud === "GCP" && gcplocations.map((e, i) => {
                                            return <MenuItem value={e.code}>{e.name}</MenuItem>
                                        })}

                                        {selectedCloud === "Azure" && azurelocations.map((e, i) => {
                                            return <MenuItem value={e.code}>{e.name}</MenuItem>
                                        })}

                                    </Select>
                                </FormControl>
                            </Box>}
                            {!addLoad && <TextField sx={{ mt: 2 }} value={userRegionName} fullWidth label="Region Name" placeholder="Enter Region Name" onChange={(e) => setuserRegionName(e.target.value)} />}

                            {!addLoad && selectedCloud === "StandAlone" && <TextField sx={{ mt: 2 }} value={standAloneRegionCode} fullWidth label="Region Code" placeholder="Enter Region Code" onChange={(e) => setstandAloneRegionCode(e.target.value)} />}

                            <Collapse in={(!addLoad && selectedCloud === "AWS") ? true : false}>
                                <TextField sx={{ my: 2 }} value={awsAccountId} fullWidth label="AWS Account Id" placeholder="Enter 12 digit AWS Account Id" onChange={(e) => setawsAccountId(e.target.value)} />
                            </Collapse>
                            <Collapse in={(!addLoad && selectedCloud === "GCP") ? true : false}>
                                <TextField sx={{ my: 2 }} value={awsAccountId} fullWidth label="GCP Project Name" placeholder="Enter GCP Project Name" onChange={(e) => setawsAccountId(e.target.value)} />
                            </Collapse>


                            <div style={{ marginTop: "10px" }}>

                                {fileUploaded && <>
                                    {!isFileUploading && <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ m: 2 }}
                                    ><CircularProgress size={15} />Uploading file...please wait </Grid>}
                                    {addLoad && <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ m: 2 }}
                                    ><CircularProgress size={15} />Adding.. </Grid>}
                                    {isFileUploading && !addErr && <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ m: 2 }}
                                    ><CheckCircle size={10} style={{ color: "green" }} />
                                        <Typography> File Uploaded Successfully!</Typography>

                                    </Grid>}

                                </>
                                }
                                {selectedCloud !== "StandAlone" && <Box sx={{ mb: 2 }}>
                                    <Typography variant='body2' >How to get config file? Please check right hand side for step by step guide.</Typography>
                                </Box>}

                                {!fileUploaded && (
                                    <div style={{
                                        padding: "20px",
                                        border: "2px dashed #ccc",
                                        borderRadius: "5px",
                                        textAlign: "center"
                                    }} {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>{true === 1 ? "Drag 'n' drop Config File here, or click to select" : "Drag 'n' drop Your File here, or click to select"}</p>
                                    </div>
                                )}
                            </div>


                        </Paper>
                    </Grid>}
                    {selectedCloud !== "StandAlone" && <Grid item lg={showAddBYOC ? 6 : 12} md={showAddBYOC ? 6 : 12} sm={showAddBYOC ? 6 : 12} xs={showAddBYOC ? 6 : 12}>
                        <Paper variant='outlined' sx={{ p: 2 }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >

                                <h3 style={{ margin: "0px 0px 10px 0px", fontSize: "18px" }}>Steps to create an {selectedCloud && selectedCloud} cluster</h3>


                            </Grid>
                            <Divider />

                            {selectedCloud === "AWS" && <AnimatePresence exitBeforeEnter>
                                <motion.div
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                >

                                    <Box>

                                        <Typography style={{ marginTop: "10px" }}>  <b>Step 1: </b> Login to your <a target="_blank" href={`https://${awsAccountId}.signin.aws.amazon.com/console/`}>AWS console</a></Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }}
                                            src="https://storage.googleapis.com/user-profileimage/aws1.png" />

                                        <b>Step 2: </b>
                                        <span style={{ marginTop: "10px" }}>Open <a target="_blank" href={`https://${age}.console.aws.amazon.com/cloudshell/`}>AWS CloudShell</a>from the top navigation</span>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }}
                                            src="https://storage.googleapis.com/user-profileimage/aws2.png" />


                                        <Typography sx={{ mt: 2 }}> <b>Step 3: </b>Copy the script from below <b style={{ color: "#160044" }}> Copy to clipboard </b>Button and <b>Paste</b> onto the CloudShell terminal</Typography>
                                        <Box sx={{ background: "#f5f5f5", color: "#111", p: 2, mt: 2, borderRadius: "12px" }}>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <InfoOutlined fontSize='small' />
                                                <Typography variant="body2">This script will create a cluster in the selected region with default values</Typography>
                                            </Grid>
                                        </Box>
                                        {(!age || awsAccountId === "" || userRegionName === "") && <Typography sx={{ mt: 2 }} color="error">Please provide region and AWS Account ID to enable this Button</Typography>}

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"

                                        >
                                            <Button sx={{ m: 2 }} disabled={!age || !awsAccountId || userRegionName === ""} variant="contained" startIcon={<FileCopy />} onClick={() => {
                                                navigator.clipboard.writeText(awsScript);
                                                enqueueSnackbar("Copied to clipboard!", {
                                                    variant: 'success',
                                                    anchorOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    },
                                                });
                                            }}>Copy to clipboard </Button>

                                            <Button disabled={!age || !awsAccountId || userRegionName === ""} onClick={() => setshowAwsScript(!showAwsScript)}
                                                startIcon={showAwsScript ? <VisibilityOff /> : <Visibility />}
                                                variant='outlined'>{showAwsScript ? "Hide script" : "show Script"}
                                            </Button>
                                        </Grid>


                                        <Typography style={{ marginTop: "10px" }}><b>Step 4: </b>Once the CLI commands finish executing, your Kubeconfig file will be ready to download.
                                            Click on <b>Action</b> from top right corner of the CLI window.</Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/aws3.png" />


                                        <Typography style={{ marginTop: "10px" }}><b>Step 5: </b>Click on  <b>Download</b>.</Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/aws4.png" />

                                        <Typography style={{ marginTop: "10px" }}><b>Step 6: </b>Enter
                                            <span style={{ background: "#f5f5f5", borderRadius: "12px", color: "#160044", margin: "0px 10px", padding: "0px 5px" }}>~/nife_config_template</span>in the required field and click <b>Download</b>.</Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }}
                                            src="https://storage.googleapis.com/user-profileimage/aws5.png" />

                                        <Typography style={{ marginTop: "10px" }}><b>Step 7: </b>Once the download is complete, upload the config file as shown below</Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }}
                                            src="https://storage.googleapis.com/user-profileimage/upload-ss.png" />

                                    </Box></motion.div></AnimatePresence>}


                            {selectedCloud === "GCP" &&
                                <AnimatePresence exitBeforeEnter>
                                    <motion.div
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <Box>
                                            <Typography style={{ marginTop: "10px" }}>  <b>Step 1: </b> Login to your <a target="_blank" href={`https://console.cloud.google.com/`}>Google Cloud Provider console</a></Typography>
                                            <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/GCP.png" />

                                            <Typography style={{ marginTop: "10px" }}>  <b>Step 2: </b> Open <a target="_blank" href={`https://console.cloud.google.com/`}>Google Cloud Provider Cloud Shell</a> from the top navigation</Typography>
                                            <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/GCP1.png" />
                                            <Typography sx={{ mt: 2 }}> <b>Step 3: </b>Copy the script from below <b style={{ color: "#160044" }}> Copy to clipboard </b>Button and <b>Paste</b> onto the CloudShell terminal</Typography>
                                            <Box sx={{ background: "#f5f5f5", color: "#111", p: 2, mt: 2, borderRadius: "12px" }}>

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <InfoOutlined fontSize='small' />
                                                    <Typography variant="body2">This script will create a cluster in the selected region with default values</Typography>
                                                </Grid>
                                            </Box>
                                            {(!age || awsAccountId === "" || userRegionName === "") && <Typography sx={{ mt: 2 }} color="error">Please provide region and Project name to enable this Button</Typography>}

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"

                                            >
                                                <Button sx={{ m: 2 }} disabled={!age || !awsAccountId || userRegionName === ""} variant="contained" startIcon={<FileCopy />} onClick={() => {
                                                    navigator.clipboard.writeText(gcpScript);
                                                    enqueueSnackbar("Copied to clipboard!", {
                                                        variant: 'success',
                                                        anchorOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        },
                                                    });
                                                }}>Copy to clipboard </Button>

                                                <Button disabled={!age || !awsAccountId || userRegionName === ""} onClick={() => setshowAwsScript(!showAwsScript)}
                                                    startIcon={showAwsScript ? <VisibilityOff /> : <Visibility />}
                                                    variant='outlined'>{showAwsScript ? "Hide script" : "show Script"}
                                                </Button>
                                            </Grid>


                                            <Typography style={{ marginTop: "10px" }}>  <b>Step 4: </b>Once the CLI commands finish executing, your Kubeconfig file will be ready to download. Click on the ellipses from top right corner of the CLI window.</Typography>
                                            <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/GCP2.png" />

                                            <Typography style={{ marginTop: "10px" }}>  <b>Step 5: </b> Click on Download</Typography>
                                            <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/GCP3.png" />

                                            <Typography style={{ marginTop: "10px" }}><b>Step 6: </b>Enter
                                                <span style={{ background: "#f5f5f5", borderRadius: "12px", color: "#160044", margin: "0px 10px", padding: "0px 5px" }}>~/nife_config_template</span>in the required field and click <b>Download</b>.</Typography>
                                            <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }}
                                                src="https://storage.googleapis.com/user-profileimage/GCP4.png" />

                                            <Typography style={{ marginTop: "10px" }}>  <b>Step 7: </b> Once the download is complete, upload the config file as shown below</Typography>
                                            <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/upload-ss.png" />
                                        </Box></motion.div></AnimatePresence>}


                            {selectedCloud === "Azure" && <AnimatePresence exitBeforeEnter>
                                <motion.div
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                >

                                    <Box>

                                        <Typography style={{ marginTop: "10px" }}>  <b>Step 1: </b> Login to your <a target="_blank" href={`https://portal.azure.com/`}>Azure console</a></Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/Azure1.png" />

                                        <Typography style={{ marginTop: "10px" }}>  <b>Step 2: </b> Open <a target="_blank" href={`https://shell.azure.com/`}>Azure CloudShell</a> from the top navigation</Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }}
                                            src="https://storage.googleapis.com/user-profileimage/Azure2.png" />


                                        {/* Script part starts here */}
                                        <Typography sx={{ mt: 2 }}> <b>Step 3: </b>Copy the script from below <b style={{ color: "#160044" }}> Copy to clipboard </b>Button and <b>Paste</b> onto the CloudShell terminal</Typography>
                                        <Box sx={{ background: "#f5f5f5", color: "#111", p: 2, mt: 2, borderRadius: "12px" }}>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <InfoOutlined fontSize='small' />
                                                <Typography variant="body2">This script will create a cluster in the selected region with default values</Typography>
                                            </Grid>
                                        </Box>
                                        {(!age || userRegionName === "") && <Typography sx={{ mt: 2 }} color="error">Please provide region name and Azure region to enable this Button</Typography>}

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"

                                        >
                                            <Button sx={{ m: 2 }} disabled={!age || userRegionName === ""} variant="contained" startIcon={<FileCopy />} onClick={() => {
                                                navigator.clipboard.writeText(azureScript);
                                                enqueueSnackbar("Copied to clipboard!", {
                                                    variant: 'success',
                                                    anchorOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    },
                                                });
                                            }}>Copy to clipboard </Button>

                                            <Button disabled={!age || userRegionName === ""} onClick={() => setshowAwsScript(!showAwsScript)}
                                                startIcon={showAwsScript ? <VisibilityOff /> : <Visibility />}
                                                variant='outlined'>{showAwsScript ? "Hide script" : "show Script"}
                                            </Button>
                                        </Grid>
                                        {/* Script part ends here */}


                                        <Typography style={{ marginTop: "10px" }}>  <b>Step 4: </b> Once the CLI commands finish executing, your Kubeconfig file will be ready to download. Click on the <b>File Icon</b> from top left corner of the CLI window.</Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/Azure3.png" />

                                        <Typography style={{ marginTop: "10px" }}>  <b>Step 5: </b> Click on <b>Download</b></Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }} src="https://storage.googleapis.com/user-profileimage/Azure4.png" />

                                        <Typography style={{ marginTop: "10px" }}><b>Step 6: </b>Enter
                                            <span style={{ background: "#f5f5f5", borderRadius: "12px", color: "#160044", margin: "0px 10px", padding: "0px 5px" }}>~/nife_config_template</span>in the required field and click <b>Download</b>.</Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }}
                                            src="https://storage.googleapis.com/user-profileimage/Azure5.png" />

                                        <Typography style={{ marginTop: "10px" }}>  <b>Step 7: </b> Once the download is complete, upload the config file as shown below</Typography>
                                        <img style={{ width: "100%", height: "auto", borderRadius: "12px", margin: "10px 0px" }}
                                            src="https://storage.googleapis.com/user-profileimage/upload-ss.png" />

                                    </Box></motion.div></AnimatePresence>}

                        </Paper>
                    </Grid>}
                </Grid>}

        </Container>)
}


export default function BYOClocations({isCluster}) {
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    const [selectedCloud, setselectedCloud] = useState(isCluster ? "StandAlone" : "AWS");

    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [openDel, setOpenDel] = React.useState(false);

    const [searched, setsearched] = useState("");
    const [selectedDelete, setselectedDelete] = useState(null);
    const handleClickOpen = (value) => {
        setOpen(true);
        setselectedCloud(value)
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleClickOpenDel = (value, type) => {
        console.log("value==>", value)
        if (permissions[0].delete) {
            setOpenDel(true);
            setselectedDelete(value)
        }
        else {
            enqueueSnackbar("You don't have permission", {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    };

    const handleCloseDel = () => {
        setOpenDel(false);
        setselectedDelete(null)
    };



    const columns = [
        {
            field: "regionName",
            headerName: "Name",
            width: 210,
            align: "left",
            renderCell: (cellValues) => {
                return (<> <IconButton style={{ padding: "0px 0px 0px 0px", marginRight: "10px" }} ><Room /></IconButton>
                    {cellValues.value}</>)
            }
        },
        {
            field: "providerType",
            headerName: "Cloud provider",
            width: 160,
            align: "center",
            renderCell: (cellValues) => {
                if (cellValues.value === "aws") {
                    return <Tooltip title="Amazon Web Services "><IconButton><FaAws /></IconButton></Tooltip>
                }
                if (cellValues.value === "gcp") {
                    return <Tooltip title="Google Cloud Platform"><IconButton><SiGooglecloud /></IconButton></Tooltip>
                }
                if (cellValues.value === "azure") {
                    return <Tooltip title="Microsoft Azure Cloud"><IconButton><VscAzure /></IconButton></Tooltip>
                }
                if (cellValues.value === "other") {
                    return <Tooltip title="StandAlone Cluster"><IconButton><BsCloudHaze2Fill /></IconButton></Tooltip>
                }
                else return "-"

            }
        },
        {
            field: "id",
            headerName: "Datadog",
            width: 130,
            align: "center",
            renderCell: (cellValues) => {
                if (datadogByoc && datadogByoc.getDataDogByUserId) {
                    if (datadogByoc.getDataDogByUserId.find((e) => {
                        return e.clusterId === cellValues.value
                    })) {
                        return <><SiDatadog style={{ marginRight: "5px" }} /> Active</>
                    }
                    else return "-"
                }
                else return "-"
            }
        },
        {
            field: "action",
            headerName: "Actions",
            width: 100,
            align: "center",
            renderCell: (cellValues) => {
                return <DialogBYOCsettings cellValues={cellValues} />


            }
        },

    ]

    function DialogBYOCsettings({ cellValues }) {
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [apiKey, setapiKey] = useState("");
        const [appKey, setappKey] = useState("");
        const [openNew, setopenNew] = useState(false);
        const [isUpdate, setisUpdate] = useState(false);
        const [apiEndpoint, setapiEndpoint] = useState("")
        const [openRemove, setOpenRemove] = useState(false);
        const [selectedDatadog, setselectedDatadog] = useState(null);

        const open = Boolean(anchorEl);

        const handleCloseRemove = () => {
            setOpenRemove(false);
        };

        const handleClickOpenRemove = () => {
            setOpenRemove(true)
        }

        const handlesubmit = () => {
            if (isUpdate) {
                updateBYOCDatadog({ variables: { apiKey: apiKey, appKey: appKey, apiEndpoint: `https://api.${apiEndpoint}.datadoghq.com/api/v1/graph/embed`, id: selectedDatadog.id } });
            }
            else {
                addBYOCDatadog({ variables: { apiKey: apiKey, appKey: appKey, apiEndpoint: `https://api.${apiEndpoint}.datadoghq.com/api/v1/graph/embed`, clusterId: cellValues.row.id } });
            }

        }

        const handleChangeRegTypes = (e) => {
            setapiEndpoint(e.target.value)
        }

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);

        };

        const handleNewClick = () => {
            setopenNew(true);
            handleClose();
        };

        const handleCloseNew = () => {
            setopenNew(false);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleResetAll = () => {
            handleCloseNew();
            setapiKey("")
            setappKey("")
            setapiEndpoint("")

        }

        const [addBYOCDatadog, { loading }] = useMutation(ADD_DATA_DOG_TO_BYOC, {
            refetchQueries: [LIST_KUBE_REGIONS, FIND_DATADOG_FOR_BYOC],
            onError: (err) => {
                enqueueSnackbar(err.message, {
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })

            },
            onCompleted: () => {
                handleClose()
                handleResetAll();
                enqueueSnackbar("Successfully added!", {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })

            }
        });

        const [updateBYOCDatadog, { loading: loadingUpdate }] = useMutation(UPDATE_DATA_DOG_TO_BYOC, {
            refetchQueries: [LIST_KUBE_REGIONS],
            onError: (err) => {
                enqueueSnackbar(err.message, {
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })

            },
            onCompleted: () => {
                handleClose()
                handleResetAll();
                enqueueSnackbar("Successfully updated!", {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })

            }
        });

        useEffect(() => {
            if (datadogByoc && datadogByoc.getDataDogByUserId) {
                var item = datadogByoc.getDataDogByUserId.find((e) => {
                    return e.clusterId === cellValues.row.id
                })
                if (item) {
                    setselectedDatadog(item);
                    setapiKey(item.apiKey)
                    setappKey(item.appKey)
                    setapiEndpoint(item.apiEndpoint.split(".")[1])

                }
            }
        }, [])

        return (
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {selectedDatadog ? <Box>
                        <MenuItem onClick={() => { handleNewClick(); setisUpdate(true); }}>Update datadog</MenuItem>
                        <MenuItem onClick={() => { handleClickOpenRemove(cellValues); }}>Remove datadog</MenuItem>
                    </Box>
                        : <MenuItem onClick={() => handleNewClick()}>Add datadog</MenuItem>}

                    <MenuItem disabled={permissions && !permissions[0].delete} onClick={() => {

                        handleClickOpenDel(cellValues.row.id);
                        handleClose();
                    }}>Delete</MenuItem>

                </Menu>

                <Dialog
                    open={openNew}
                    maxWidth={"xs"}
                    onClose={handleCloseNew}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: "12px",
                            border: "solid 1px gray",
                            boxShadow: (!(apiKey !== "" && appKey !== "" && apiEndpoint !== "") ? true : false) ? "#160044  5px 5px, #160044 10px 10px" : "undefined"

                        }
                    }}
                >
                    <DialogTitle>
                        {isUpdate ? (loadingUpdate ? "Updating.." : "Update Datadog") : (loading ? "Adding.." : "Add Datadog")}
                    </DialogTitle>
                    {loading && <LinearProgress />}
                    <DialogContent>
                        <div>
                            <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >
                                <InputLabel id="demo-simple-select-filled-label">Choose Type</InputLabel>
                                <Select
                                    size={"small"}
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    label="Choose Type"
                                    onChange={(e) => handleChangeRegTypes(e)}
                                    value={apiEndpoint}
                                >
                                    <MenuItem> None </MenuItem>
                                    {[
                                        { name: 'US5 - Central', type: 'US5', id: 1 },
                                        { name: 'US1 - East', type: "US1", id: 2 },
                                        { name: 'US3 - West', type: "US3", id: 3 },
                                        { name: 'AP1 - Japan', type: "AP1", id: 4 },
                                        { name: 'EU1 - Europe', type: 'EU1', id: 5 }
                                    ].map((value) => (
                                        <MenuItem key={value.id} value={value.type}>{value.name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            <TextField size='small' fullWidth sx={{ my: 1 }} label="Datadog API key" value={apiKey} onChange={(e) => setapiKey(e.target.value)} />
                            <TextField size='small' fullWidth sx={{ my: 1 }} label="Datadog App key" value={appKey} onChange={(e) => setappKey(e.target.value)} />

                        </div>
                    </DialogContent>

                    <Collapse in={(apiKey !== "" && appKey !== "" && apiEndpoint !== "") ? true : false}>
                        <DialogActions>
                            <Button fullWidth disabled={loading} onClick={handleResetAll}>close</Button>
                            <Button variant='contained' onClick={handlesubmit} disabled={loading} disableElevation fullWidth>{isUpdate ? "update" : "add"} datadog</Button>
                        </DialogActions>
                    </Collapse>
                </Dialog>

                <Dialog
                    open={openRemove}
                    fullWidth
                    maxWidth="xs"
                    onClose={handleCloseRemove}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are you sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            remove the dataog.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color='error' onClick={() => removeDatadog({ variables: { dataDogId: selectedDatadog.id } })}>Delete</Button>
                        <Button onClick={handleCloseRemove} autoFocus>
                            cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }



    const { loading: loadkuberegions, error: errkuberegions, data: kuberegionsdata, refetch } = useQuery(LIST_KUBE_REGIONS);
    const { loading: loadingdogByoc, error: errdogByoc, data: datadogByoc } = useQuery(FIND_DATADOG_FOR_BYOC);



    const [DeleteKubeConfigRegion] = useMutation(DELETE_KUBE_CONFIG_REGION, {
        refetchQueries: [LIST_KUBE_REGIONS],
        onError: (err) => {
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        },
        onCompleted: () => {
            handleCloseDel()
            enqueueSnackbar("Successfully Deleted!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        }
    });


    const [removeDatadog] = useMutation(REMOVE_DATADOG_BYOC, {
        refetchQueries: [LIST_KUBE_REGIONS, FIND_DATADOG_FOR_BYOC],
        onError: (err) => {
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        },
        onCompleted: () => {
            handleCloseDel()
            enqueueSnackbar("Successfully removed!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        }
    });





    const hadleBYOHDelete = () => {

        DeleteKubeConfigRegion({
            variables: { id: selectedDelete }
        })



    }

    const isDarkTheme = useTheme().palette.mode === 'dark';


    useEffect(() => {
        refetch();
    }, []);
    return (
        <div>



            <Fade in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: 500 } : {})}>
                <Box style={{ width: "100%", margin: "0px 0px 0px 0px", padding: "0px" }}>
                    {kuberegionsdata && kuberegionsdata.getUserAddedRegions ?
                        <div>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"

                            >
                                <TextField
                                    value={searched}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {searched !== "" &&
                                                    <IconButton onClick={() => setsearched("")}>
                                                        <Close />
                                                    </IconButton>}
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        )
                                    }}

                                    size={"small"}

                                    placeholder='Search for BYOC'
                                    onChange={(e) => setsearched(e.target.value)}
                                />
                                <div>
                                    {kuberegionsdata && kuberegionsdata.getUserAddedRegions && kuberegionsdata.getUserAddedRegions.length > 0 ?
                                        <Button disabled={true} startIcon={<Add />} variant='outlined' sx={{ borderRadius: "24px", ml: 1 }}>Add BYOC (Reached maximum limit)</Button> : <div>

                                            {permissions[0].delete ? <>  <Button onClick={() => handleClickOpen(isCluster ? "StandAlone" : "AWS")} startIcon={<FaAws />} variant='outlined' sx={{ borderRadius: "24px", ml: 1 }}>Add BYOC</Button>


                                                <Button onClick={() => handleClickOpen("GCP")} startIcon={<SiGooglecloud />} variant='outlined' sx={{ borderRadius: "24px", ml: 1 }}>Add BYOC</Button>


                                                <Button onClick={() => handleClickOpen("Azure")} startIcon={<VscAzure />} variant='outlined' sx={{ borderRadius: "24px", ml: 1 }}>Add BYOC</Button>
                                            </>
                                                :
                                                <Button disabled={true} startIcon={<Add />} variant='outlined' sx={{ borderRadius: "24px", ml: 1 }}>Add BYOC (Don't have permission)</Button>}

                                        </div>}




                                </div>

                            </Grid>
                            <Paper variant="outlined" sx={{ borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px", padding: "10px", marginTop: "15px" }}>
                                <DataGrid
                                    dense
                                    autoHeight={true}
                                    density={"compact"}
                                    scrollbarSize={1}
                                    rowHeight={70}
                                    rows={kuberegionsdata && kuberegionsdata.getUserAddedRegions.filter((row) => {
                                        return row.regionName.toLowerCase().includes(searched);
                                    })}
                                    disableColumnFilter={true}
                                    columns={columns}
                                    pageSize={10}
                                    style={{ border: "2px" }
                                    }
                                />
                            </Paper>
                        </div>
                        : <Box>
                            <EmptyPageRenderCustom
                                mainTitle={isCluster ? "Add a Cluster" : "Add a BYOC"}
                                secondaryTitle={isCluster ? "Click to add Stand Alone Cluster" : "Click on Add BYOC to add new BYOC location."}
                                learnMoreLink="https://docs.nife.io/docs/UI%20Guide/Locations/Byoc"
                                primaryButtonText={isCluster? "Add Cluster" : "Add BYOC"}
                                primaryButtonAction={handleClickOpen}
                                iframeLink="https://konvey.s3.amazonaws.com/videos/video_438979620.mp4"
                                imageListTitle={isCluster ? "Supported cluster" : "Supported clouds"}
                                imageList={isCluster ? [{ "name": "StandAlone", "image": <BsCloudHaze2Fill style={{ width: 40, height: 20, margin: "10px 5px 10px 0px" }} />, "type": "svg" }] : [{ "name": "AWS", "image": isDarkTheme ? "/aws.png" : "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg" }, { "name": "GCP", "image": "https://brandeps.com/logo-download/G/Google-Cloud-logo-vector-01.svg" }, { "name": "Azure", "image": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg" }] 
                                 }
                            />
                        </Box>}
                </Box>


            </Fade>


            <Dialog
                open={open}
                fullScreen
                maxWidth="md"
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <AppBar sx={{ background: "#f5f5f5", color: "#000", boxShadow: "none" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <Close />

                        </IconButton>
                        <Typography sx={{ flex: 1, }}>
                            {isCluster ? "Add a Stand Alone Cluster" : "Add Bring Your Own Cloud (BYOC)"}                        </Typography>
                    </Toolbar>

                </AppBar>
                <BYOCInstructions isCluster={isCluster} userSelectedCloud={selectedCloud} handleCloseDialog={handleClose} />
            </Dialog>


            <Dialog
                open={openDel}
                fullWidth
                maxWidth="xs"
                onClose={handleCloseDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        delete the byoc.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={hadleBYOHDelete}>Delete</Button>
                    <Button onClick={handleCloseDel} autoFocus>
                        cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
