import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { IoIosPeople } from 'react-icons/io';
import { useRouteMatch } from 'react-router-dom';
import { EDIT_APP } from '../../Home/CreateNewApp/Main';
import { APPS } from '../../Home/DeploymentTable';
import { ORG_LIST } from '../../Settings/Organization';


export const FETCH_APP_ORG = gql`
query FetchAppOrgQuery($name: String!){
  app(name: $name){
    id
    organization{
        name
        id
    } 
  }
}`

export default function UpdateOrg(props) {
    const [open, setOpen] = React.useState(false);
    const [age, setAge] = React.useState(null);
    const [appId, setappId] = React.useState("");
    const [isWarn, setisWarn] = React.useState(false);
    const match = useRouteMatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleSelect = (event) => {
        setisWarn(true)
        setAge(event)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setisWarn(false)
    };

    const handleSubmit = () => {
        editApp({ variables: { appName: appId, organizationId: age.id } })
    }

    const { loading: loadorg, error: errorg, data: orgdata, refetch: refetch } = useQuery(ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
    });
    const { loading, error, data } = useQuery(FETCH_APP_ORG, { variables: { name: appId } });

    const [editApp, { data: editdata, loading: editloading, error: editerror }] = useMutation(EDIT_APP, {
        refetchQueries: [APPS,FETCH_APP_ORG,ORG_LIST],
        onError: (err) => {
            handleClose()
            enqueueSnackbar("Something went wrong!", {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              })
        },
        onCompleted: () => {
            handleClose()
            enqueueSnackbar("Successfully Updated!", {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              })
        }
      });

    useEffect(() => {
        if (props.appId) {
            setappId(props.appId)
        }
        else {
            setappId(match.params.id)
        }

    }, [data, orgdata]);


	if (loading || loadorg) {
		return (
			<div>
				<Skeleton width="30%" style={{ margin: "10px" }} />
				<Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
			</div>
		);
	}
    return (
        <div style={{ margin: "20px" }}><div>

         {!props.appId && <Grid container spacing={2}>

            <Grid item lg={4} xs={12}>
                <h2 style={{ color: "grey" }}>Update Orginaztion</h2>


                <Typography variant="body2" gutterBottom style={{ color: "grey" }}>
                    Move your App under different organization.
                </Typography>

            </Grid>

            <Grid item lg={8} xs={12}>

                <Typography variant="overline" display="block" gutterBottom>Current Organization of this App is</Typography>
                <Typography variant="body1" gutterBottom>
                    {data && data.app.organization.name} <Button onClick={handleClickOpen}>Chnage Organization</Button>
                </Typography>
             

            </Grid>
        </Grid>}
        </div>
            {!props.appId ? <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {isWarn ? "Are you sure?" : "Select New Organization"}
                </DialogTitle>
                <DialogContent>
                    {isWarn ?
                        <DialogContentText id="alert-dialog-description">
                            <Typography style={{ margin: "0px" }}>Do you want to Update {appId}'s Organization from {data && data.app.organization.name} to {age && age.name}</Typography>
                        </DialogContentText>
                        :
                        <DialogContentText id="alert-dialog-description">
                            <List
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                component="nav"
                                dense={true}
                                fullWidth
                                aria-labelledby="nested-list-subheader"
                            >
                                {orgdata && orgdata.organizations.nodes.map((e) => (<ListItemButton onClick={()=>handleSelect(e)}>
                                    <ListItemIcon>
                                        <IoIosPeople />
                                    </ListItemIcon>
                                    <ListItemText primary={e.name} />
                                </ListItemButton>))}
                            </List>
                        </DialogContentText>
                    }
                </DialogContent>
                {isWarn ?
                    <DialogActions>
                        <Button onClick={()=>setisWarn(false)}>No, Cancel</Button>
                        <Button color='error' onClick={handleSubmit} autoFocus>
                            Yes, Update Organization
                        </Button>
                    </DialogActions>
                    : <DialogActions>
                        <Button onClick={handleClose}> Close</Button>
                    </DialogActions>}
            </Dialog>
            :
            <div>
            <Typography variant='p' id="alert-dialog-title">
                    {isWarn ? "Are you sure?" : "Select New Organization"}
                </Typography>
             
                    {isWarn ?
                        <DialogContentText id="alert-dialog-description">
                            <Typography style={{ margin: "0px" }}>Do you want to Update {appId}'s Organization from {data && data.app.organization.name} to {age && age.name}</Typography>
                        </DialogContentText>
                        :
                        <DialogContentText id="alert-dialog-description">
                            <List
                                sx={{ width: '100%', bgcolor: 'background.paper',padding:"0px" }}
                                component="nav"
                                dense={true}
                                fullWidth
                                aria-labelledby="nested-list-subheader"
                            >
                                {orgdata && orgdata.organizations.nodes.map((e) => (<ListItemButton onClick={()=>handleSelect(e)}>
                                    <ListItemIcon>
                                        <IoIosPeople />
                                    </ListItemIcon>
                                    <ListItemText primary={e.name} />
                                </ListItemButton>))}
                            </List>
                        </DialogContentText>
                    }
             
                {isWarn &&
                    <DialogActions>
                        <Button onClick={()=>setisWarn(false)}>No, Cancel</Button>
                        <Button color='error' onClick={handleSubmit} autoFocus>
                            Yes, Update Organization
                        </Button>
                    </DialogActions>
                   }
           
            </div>}
        </div>
    )
}
