import { gql, useMutation, useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { APP_LIST } from "../../Home/Apps";
import { APPS } from "../../Home/DeploymentTable";
import { APP_LOCATION_LIST } from "../Deploy/Main";

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { useRouteMatch } from "react-router-dom";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import { useSnackbar } from 'notistack';
import LogTrigger from '../../../LogTrigger';
import MoveMap from './MoveMap';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const AvailabilityCluster = gql`
	query{
  getAvailabilityCluster(isLatency: "", first: 25){
    nodes{
    	id,
    	name,
    	regionCode,
    	isLatency,
		clustertype
		latitude
		longitude
    }
  }
}
`

const MOVE_APP = gql`
	mutation MoveAppMutation($appId: String!, $sourceRegCode: String!, $destRegCode: String!){
  moveApp(input: {appId:$appId, sourceRegCode: $sourceRegCode, destRegCode:$destRegCode }){
    app{
      id
    }
  }
}
`



export default function Main(props) {
	const match = useRouteMatch();
	const [appId, setappId] = React.useState("");
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [moveApp, { loading: loadmove, error: errmove, data: datamove }] = useMutation(MOVE_APP, {
		refetchQueries: [APP_LOCATION_LIST, APPS, APP_LIST],
		onCompleted: () => {
			enqueueSnackbar(`Successfully Moved!`, {
				preventDuplicate: true,
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			})
			setIsSuccess(true)
			setSourceCode(null)
			setDestCode(null)
			setOpen(false)
		},
		onError: () => {
			enqueueSnackbar(`Error: Something went wrong`, {
				preventDuplicate: true,
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			})
			setSourceCode(null)
			setDestCode(null)
			setOpen(false)
		}
	})
	const handleMove = (code) => {
		moveApp({ variables: { appId: appId, sourceRegCode: sourceCode.code, destRegCode: destCode.regionCode } })
		setOpen(true)

	}
	const role = localStorage.getItem("role")

	const [open, setOpen] = React.useState(false);
	const [isSuccess, setIsSuccess] = React.useState(false);
	const [sourceCode, setSourceCode] = React.useState(null)
	const [destCode, setDestCode] = React.useState(null)
	const [newList, setnewList] = React.useState([])





	const handleClose = () => {
		setOpen(false);
		setIsSuccess(false)
		setSourceCode(null)
		setDestCode(null)
	}

	const { loading, error, data } = useQuery(APP_LOCATION_LIST, { variables: { name: appId } });
	const { loading: load, error: err, data: regdata } = useQuery(AvailabilityCluster);


	useEffect(() => {
		if (regdata) {
			var temp = []
			regdata.getAvailabilityCluster.nodes.forEach(element => {
				if (element.clustertype) {
					temp.push(element)
				}


			});


			setnewList(temp)
		}



		if (props.appId) {
			setappId(props.appId)
		}
		else {
			setappId(match.params.id)
		}

	}, [regdata]);

	if (loading) {
		return (
			<div>
				<Skeleton width="30%" style={{ margin: "10px" }} />
				<Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
			</div>
		);
	}
	if (error) return `Error! ${error.message}`;
	if (load) return <Typography style={{ textAlign: "center", margin: "30px" }}><CircularProgress size="15px" /> Loading...</Typography>;
	if (err) return `Error! ${err.message}`;
	if (error) {
		LogTrigger(error.message, "error", window.location.href, "APP_LOCATION_LIST q")
	}
	if (err) {
		LogTrigger(err.message, "error", window.location.href, "AvailabilityCluster q")
	}
	if (errmove) {
		LogTrigger(errmove.message, "error", window.location.href, "MoveApp m")
	}

	if (regdata) {
		LogTrigger(`Availability Cluster length - ${regdata.getAvailabilityCluster.nodes && regdata.getAvailabilityCluster.nodes.length}`, "info", window.location.href, "MOVE_APP")
	}

	if (datamove) {
		LogTrigger(`App moved`, "info", window.location.href, "MOVE_APP")
	}



	return (
		<div style={{ margin: "20px" }}>

			<Dialog
				open={open}
				fullWidth={true}
				maxWidth={"sm"}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>

				<DialogTitle>Moving <b>{sourceCode && sourceCode.name}</b> to <b>{destCode && destCode.name}</b>..<CircularProgress size="15px" /></DialogTitle>
				<div style={{ textAlign: "center", margin: "0px" }}>
					<Typography variant="body2" gutterBottom style={{ color: "grey" }}>
						{errmove ? (<div>
							<h3 style={{ color: "black" }}> Error<IconButton size="large">
								<ErrorOutlineIcon style={{ color: "red" }} />
							</IconButton></h3>{errmove.message}, please try again
						</div>) :
							(<>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center">
									<MoveMap names={[sourceCode && sourceCode.name, destCode && destCode.name]} src={[sourceCode && sourceCode.longitude, sourceCode && sourceCode.latitude]} type={["Source", "Destination"]} dest={[destCode && destCode.longitude, destCode && destCode.latitude]} />


								</Grid>

							</>)
						}  </Typography>


				</div>
			</Dialog>


			{data && data.app.regions &&
				<div>
					<Grid container spacing={2}>

						{!props.appId && <Grid item lg={4} xs={12}>
							<h2 style={{ color: "grey" }}>Move App</h2>


							<Typography variant="body2" gutterBottom style={{ color: "grey" }}>
								Change App Region to different location.
							</Typography>
							<a target='_blank' href="https://docs.nife.io/docs/UI%20Guide/Apps-&-their-Management/App-management/move">Read More</a>

						</Grid>}


						<Grid item lg={props.appId ? 6 : 4} xs={12}>

							{!props.appId && <Typography variant="overline" display="block" gutterBottom>Select source and destination</Typography>}
							<Autocomplete
								disabled={role === "Viewer"}
								id="Source Region"
								options={data.app.regions}
								getOptionLabel={(option) => option.name}
								style={{ maxWidth: 300, paddingTop: props.appId ? "0px" : "10px" }}

								onChange={(e, i) => {
									if (i != null) {
										setSourceCode(i)
									}
									else {
										setSourceCode("")
									}
								}
								}
								renderInput={(params) => <TextField {...params} label="Source Region" variant="outlined" />}
							/>

							{props.size &&
								sourceCode && destCode && <Button style={{ marginTop: "10px", backgroundColor: "#160044", color: "white" }} onClick={e => { handleMove() }} color="primary" >
									Move {sourceCode.name} to {destCode.name}!
								</Button>
							}

						</Grid>

						<Grid item lg={props.appId ? 6 : 4} xs={12}>
							<Autocomplete
								disabled={role === "Viewer"}
								id="Destination Region"
								options={newList}
								getOptionLabel={(option) => option.name}
								getOptionDisabled={(option =>

									(data.app.regions.find(o => o.name === option.name) ? true : false)
								)}
								style={{ maxWidth: 300, paddingTop: props.appId ? "0px" : "10px", marginTop: props.appId ? "0px" : "35px" }}
								onChange={(e, i) => {
									if (i != null) {
										setDestCode(i)
									}
									else {
										setDestCode(null)
									}
								}
								}
								renderInput={(params) => <TextField {...params} label="Destination Region" variant="outlined" />}
							/>
							{!props.size &&
								sourceCode && destCode && <Button style={{ marginTop: "10px", backgroundColor: "#160044", color: "white" }} onClick={e => { handleMove() }} color="primary" >
									Move {sourceCode.name} to {destCode.name}!
								</Button>
							}
						</Grid>

					</Grid>
				</div>
			}

		</div>
	);
}


