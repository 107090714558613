import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router';
import { githubregister } from '../actions/index';
import { supabase } from "../client";
import CustomErrorMessageBox from '../CustomComponents/CustomErrorMessageBox';
import { CircularProgress } from '@mui/material';



function GithubRegButton(props) {
    const urlid = useQuery().get("token")

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const [errorMsg, seterrorMsg] = React.useState("");
    const user = supabase.auth.user()

    const done = (msg) => {
        //history.push("/plans");
        seterrorMsg(msg)
    }


    var CryptoJS = require("crypto-js");
    function encodeAes(plaintText) {
        var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
        var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
            iv: key,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        encryptedData = encryptedData.ciphertext.toString();
        return encryptedData

    }

    var temptoken = supabase.auth.currentSession && supabase.auth.currentSession.provider_token
    const handleSubmit = () => {
        if(user){
            var temp;
            if (values.companyName) {
                temp = values.companyName
            }
            else {
                temp = user.email.split('@')[0].replace(".", "-")
            }
            const data = {
    
                "sessionId": urlid,
                "email": user.email,
                "password": encodeAes(temptoken),
                "companyName": temp,
                "phoneNumber": values.phoneNumber,
                "industry": "Undefined",
                "location": values.location,
                "firstName": user.user_metadata.user_name,
                "lastName": "  ",
                "ssoType": "github",
            }
            props.githubregister(data, done)
        }
      

    }

    const [values, setValues] = useState({
        sessionId: '',
        firstName: '',
        lastName: '',
        emailId: '',
        password: '',
        companyName: '',
        phoneNumber: '',
        location: '',
        industry: '',
    })

    useEffect(() => {
        setValues({ ...values, sessionId: urlid });
        axios.get("https://api.ipify.org?format=json").then((res) => {
            axios.get(`https://ipapi.co/${res.data.ip}/json/`).then((res2) => {
                setValues({ ...values, phoneNumber: res2.data.country_calling_code, location: res2.data.city + ", " + res2.data.country_name })
            })

        })
        handleSubmit()
    }, []);

    if (temptoken===null) return "Token not found :("
    return (
        <Container maxWidth={false}>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: "99vh" }}
            >

                {errorMsg ? <CustomErrorMessageBox type="Registration error" message={errorMsg} /> : (
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant='h3' sx={{ my: 3, fontWeight: "lighter" }} color={"primary"}>Almost there..</Typography>
                        <div>
                        <CircularProgress size={10} sx={{mr:2}}/>
                        <Typography variant='p' sx={{ my: 2 }}>{"Creating account"}</Typography>
                        </div>
                    </Grid>)}
            </Grid>
        </Container>
    );
}
const mapStateToProps = ({ authReducer }) => {
    return { authReducer }
}
export default connect(mapStateToProps, { githubregister })(GithubRegButton);