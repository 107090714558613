import { Collapse, Grid, Paper } from '@mui/material'
import React from 'react'
import { BiWifiOff } from 'react-icons/bi'

export default function DetectIfOffline({isOffline}) {
    return (
        <div>
            <Collapse in={isOffline ? true : false}>
                <div>
                    <Paper sx={{ backgroundColor: "primary.main", color: (theme) => theme.palette.mode === 'dark' ? '#000000' : '#FFFFFF',  width: "100%", boxShadow: "none", borderRadius: "0px" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <BiWifiOff style={{ margin: "7px" }} /> You are offline.
                        </Grid>
                    </Paper>
                </div>

            </Collapse>
        </div>
    )
}
