import { gql, useQuery } from '@apollo/client';
import { Skeleton, Typography,Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import UpgradePlan from '../../Containers/UpgradePlan';
import { BiSolidBarChartSquare } from 'react-icons/bi';




export const WORKLOAD_BAR_CHART = gql`
    query workloadlistByUserQuery{
        getWorkloadMangementByUser{
                id
                environmentName
                environmentEndpoint
                apps{
                  nodes{
                    id
                  }   
        }
    }
}`




export default function WorkloadBarChart({ handleUpdateCount, setodv }) {
    const [barChartData, setbarChartData] = useState([]);
    const { loading, data, refetch } = useQuery(WORKLOAD_BAR_CHART, {
        onCompleted: (data) => {
            setbarChartData([])
            if (data) {
                if (setodv && data.getWorkloadMangementByUser) {
                    setodv(prevState => ({
                        ...prevState,
                        wlCount: data.getWorkloadMangementByUser.length,

                    }));

                    if (data.getWorkloadMangementByUser[0]) {
                        var apps = 0;
                        if (data.getWorkloadMangementByUser[0].apps.nodes) {
                            apps = data.getWorkloadMangementByUser[0].apps.nodes.length
                        }
                        setodv(prevState => ({
                            ...prevState,
                            wlItem: data.getWorkloadMangementByUser,

                        }));
                    }

                }

                data.getWorkloadMangementByUser.forEach(element => {

                    setbarChartData(oldArray => [...oldArray, { "name": element.environmentName, "apps": (element.apps.nodes ? element.apps.nodes.length : 0) }])
                    if (handleUpdateCount) {
                        handleUpdateCount(data.getWorkloadMangementByUser ? data.getWorkloadMangementByUser.length : 0);
                    }


                });
            }
        }
    });
    useEffect(() => {
        refetch()
    }, [data]);



    if (loading) return <div>
        <Skeleton variant="rectangular" height={300} />
    </div>;
    return (
        <div>
            <Typography style={{ fontWeight: "bold" }} gutterBottom>Workload Usage</Typography>

            {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).workloadManagement) ?
                <div>
                    {data && barChartData.length > 0 ?
                        <>
                            <ResponsiveContainer width={'99%'} height={300}>
                                <BarChart

                                    data={barChartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                >

                                    <XAxis dataKey="name" />
                                    <YAxis width={20}/>
                                    <Tooltip />
                                    <Legend />
                                    <Bar name="Total apps" dataKey="apps" fill={"#67b7e3"} />

                                </BarChart>
                            </ResponsiveContainer>

                        </>
                        : <>
                            <Typography sx={{mb:4}} variant="body2">Create workload and deploy an app to see workload usage bar chart.</Typography>
                            <Typography sx={{textAlign:"center",my:"100px"}}><BiSolidBarChartSquare style={{fontSize:"50px"}} /></Typography>

                        </>}

                </div>
                : <UpgradePlan />}

        </div>
    );
}

