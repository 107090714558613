import React,{useEffect} from "react";
import AppBar from '../../../Containers/AppBar'
import LogList from './LogList'


export default function Main() {
  useEffect(() => {
     document.title = "Logs"
  }, []);
  return (
    <div style={{margin:"25px"}}>
  
      <LogList/>
    </div>
  );
}
