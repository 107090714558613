import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import CreateNewApp from '../../Home/CreateNewApp/Main';
import Redeploy from '../Services/Redeploy';
import DeleteApp from "./DeleteApp";
import MoveApp from "./MoveApp";
import SuspendApp from "./SuspendApp";




export default function DailogSettngs(props) {
    const role = localStorage.getItem("role")
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showConfigureApp, setshowConfigureApp] = React.useState(false);
    const [value, setValue] = React.useState(0);


    const handleCloseConfigApp = () => {
        setshowConfigureApp(false)
        handleCloseMenu()
    }

    const handleCloseRedeployTab = () =>{
        setValue(0);
        handleCloseMenu();
    }

    const handleClickOpen = (value) => {
        handleCloseMenu()
        if(value !==3){
            setOpen(true);
        }
        setValue(value)
    };

    const handleClickOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    if (props.appDeployed) {
        return (
            <div>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={() => handleClickOpen(0)}>Move</MenuItem>
                    <MenuItem onClick={() => handleClickOpen(1)}>{props.appStatus === "Suspended" ? "Activate" : "Suspend"}</MenuItem>
                    <MenuItem onClick={() => handleClickOpen(3)}>Redeploy</MenuItem>
                    {permissions[0].delete &&
                        <MenuItem color="error" onClick={() => handleClickOpen(2)}>Delete</MenuItem>}
                </Menu>


               {value===3 ? 
               <Redeploy handleCloseRedeployTab={handleCloseRedeployTab} handleClickOpen={true} hideRedeployButton={true} name={props.appId} imageName={""} disabled={false} data={props.idOfApp} replicas={1} />
               :
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    disabled={role==="Viewer"}
                    onClick={handleClickOpenMenu}
                    size="large">
                    <MoreVertIcon />
                </IconButton>}

                <Dialog

                    PaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: "12px",
                            border: "solid 1px gray",
                            boxShadow: (true) ? "#160044 5px 5px, #160044 10px 10px" : "undefined"

                        }
                    }}
                    open={open}
                    fullWidth={true}
                    onClose={handleClose}
                    maxWidth={value === 3 ? "md" : "xs"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle sx={{ pb: 0 }}>
                        {value === 0 && "Move App"}
                        {value === 1 && `${props.appStatus === "Suspended" ? "Activate" : "Suspend"} App`}
                        {value === 2 && "Delete App"}
                        {value === 3 && "Redeploy App"}
                    </DialogTitle>

                    <div>
                        <DialogContent style={{ padding: "0px" }}>
                            {value === 0 && <MoveApp appId={props.appId} />}

                            {value === 1 &&
                                <SuspendApp appId={props.appId} />}

                            {value === 2 && <DeleteApp appId={props.appId} />}

                        </DialogContent>

                    </div>

                </Dialog>
            </div>
        );
    }
    else {
        return (<>


            {showConfigureApp ? <>
                <CircularProgress size={10} />
                <CreateNewApp hideCreateAppButton={true} pleaseOpen={showConfigureApp} steptwo={showConfigureApp} handleCloseConfigApp={handleCloseConfigApp} appName={props.appId} />
            </>
                :
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    disabled={role==="Viewer"}
                    onClick={handleClickOpenMenu}
                    size="large">
                    <MoreVertIcon />
                </IconButton>}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >

                <MenuItem onClick={() => setshowConfigureApp(true)}>Configure App</MenuItem>

                {permissions[0].delete &&
                    <MenuItem color="error" onClick={() => handleClickOpen(2)}>Delete</MenuItem>}
            </Menu>

            <Dialog
                open={open}
                fullWidth={true}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: "12px",
                        border: "solid 1px gray",
                        boxShadow: (true) ? "#160044 5px 5px, #160044 10px 10px" : "undefined"

                    }
                }}
                onClose={handleClose}
                maxWidth={"xs"}
            >

                <DeleteApp appId={props.appId} closeDialog={() => setOpen(false)} />
            </Dialog>


        </>)
    }
}






