import { useQuery } from '@apollo/client';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../actions/index';
import { USER_INFO } from '../Components/Home/Profile';
import { MainListItems } from './listItems';

function TemporaryDrawer(props) {
  const [open, setopen] = React.useState(false)
  const handleLogout = () => {
    props.logout()
  }




  const { loading: load, error: err, data: userdata } = useQuery(USER_INFO, {
    variables: { userId: localStorage.getItem("userId") },
  });

  if (load) {
    return (<div>
      <Skeleton variant="text" width="20%" height={50} style={{ marginLeft: "5%" }} />

    </div>)
  }
  if (err) return `Error! ${err.message}`;
  return (


    <React.Fragment >


      <MenuIcon sx={{mt:0.5}} onClick={() => setopen(!open)} />

      <Drawer
      color="primary"
      sx={{overflowY:"none",width:"300px"}}
        onClose={() => setopen(!open)}
        open={open}>
        
        <ListItem>
          <ListItemText primary="TeamBoard" />
        </ListItem>
        <Avatar style={{ borderRadius: "50%", display: "block", margin: "0 auto", width: "70px", height: "70px" }} alt={userdata && userdata.getUserById.firstName} src={userdata && userdata.getUserById.profileImageUrl} />
        <Typography variant="h5" style={{ textAlign: "center" }}>{userdata.getUserById.firstName} {userdata.getUserById.lastName}</Typography>
        <Link to="/settings/account" style={{ textDecoration: "none", textAlign: "center" }}>
          View Profile
        </Link>
        <List dense={true}><MainListItems />
          <Divider />
          <ListItem onClick={handleLogout} style={{ textDecoration: "none", color: "rgba(255, 0, 0, 0.54)", marginLeft: "10px", width: "90%" }}>
            <ListItemIcon>
              <ExitToAppIcon style={{ fill: "rgba(225, 0, 0, 0.54)" }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
          </List>
       
      </Drawer>
    </React.Fragment>


  );
}


const mapStateToProps = ({ authReducer }) => {
  return { authReducer }
}
export default connect(mapStateToProps, { logout })(TemporaryDrawer)