import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';
import { logout } from '../../actions/index';
import Profile, { USER_INFO } from './Profile';
import Scerets from "./Scerets";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import LogTrigger from '../../LogTrigger';



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


export const ORG_LIST = gql`
query{
  organizations{
    nodes{
      id
      name
      slug
      type
    }
  }
}

`


export const ADD_ORG = gql`
mutation AddOrgMutaion($name :  String!){
  createOrganization(input:{
    name:$name
  }){
    organization{
       id
      name
      slug
      type
 
    }
  }
}
`

export const DELETE_ORG = gql`
mutation DeleteOrgMutaion($organizationId :  String!){
  deleteOrganization(input:{
    organizationId:$organizationId
  }){
    deletedOrganizationId
  }
}
`




TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  orange: {
    width: "100px",
    height: "100px",
    margin: "10px",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));


const users = [
  { title: 'Jigar', year: 1994 },
  { title: 'Sundar', year: 1972 },
  { title: 'Avinash', year: 1972 },
  { title: 'Nida', year: 1972 },
  { title: 'Afzal', year: 1972 },
  { title: 'Rashiq', year: 1972 },

]
function ScrollableTabsButtonForce(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [newOrgName, setNewOrgName] = React.useState("")
  const [isProgress, setIsProgress] = React.useState(false)
  const [selectedOrg] = useState({})
  const [showaddmembers, setshowaddmembers] = useState(false)


  useEffect(() => {
    document.title = "Settings"

  }, []);

 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseAddMembers = e =>{
    setshowaddmembers(false)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = React.useState(false);





  const handleAddMemberChange = (e) => {
    setshowaddmembers(true)
  }
  const handleNewOrg = (e) => {
    setNewOrgName(e.target.value)
  }

  const handleDeleteOrg = (e) => {
    deleteOrg({ variables: { organizationId: selectedOrg} });
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    createOrg({ variables: { name: newOrgName } });
    setIsProgress(true)

  }
  const [createOrg] = useMutation(ADD_ORG, {
    refetchQueries: [ORG_LIST],
    onError: (err) => {

    },
    onCompleted: () => {
      setNewOrgName("")
      setIsProgress(false)
    }
  });

  const [deleteOrg] = useMutation(DELETE_ORG, {
    refetchQueries: [ORG_LIST],
    onError: (err) => {

    },
    onCompleted: () => {
      setOpen(false)
      // setNewOrgName("")
      // setIsProgress(false)
    }
  });


  const { loading: loadorg, error: errorg, data: orgdata } = useQuery(ORG_LIST, {
    variables: { userId: localStorage.getItem("userId") },
  });


  const { loading: load, error: err, data: userdata } = useQuery(USER_INFO, {
    variables: { userId: localStorage.getItem("userId") },
  });

  if (load) return 'Loading...';
  if (err) return `Error! ${err.message}`;
  if (loadorg) return 'Loading...';
  if (errorg) return `Error! ${errorg.message}`;
  if (errorg) {
    LogTrigger(errorg.message, "error", window.location.href, "Setting")
}
if (err) {
  LogTrigger(err.message, "error", window.location.href, "Setting")
}

  return (
    <div className={classes.root}>
      <AppBar position="static" color="white" elevation={0} style={{ boderRaduis: "15px" }}>
        <Grid container>
          <Grid item lg={2} >
            {/*<UserImage/>*/}
            <Gravatar email={userdata.getUserById.firstName + "fsdgsajguk"} size={100} rating="pg" className="CustomAvatar-image" style={{ borderRadius: "50%", margin: "10px" }} />

          </Grid>

          <Grid item lg={8}>
            <h1 className={classes.title} style={{ marginBottom: "5px" }}>{userdata.getUserById.firstName} {userdata.getUserById.lastName}</h1>
            <Typography variant="subtitle2" className={classes.title} style={{ textDecoration: "none" }} gutterBottom>
              {userdata.getUserById.email}
            </Typography>
          </Grid>

        </Grid>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          allowScrollButtonsMobile>
          <Tab label="Account"  {...a11yProps(0)} />
          <Tab label="Organizations"  {...a11yProps(1)} />
          <Tab label="Secrets"  {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>

        <Profile />
        {/*<Grid container spacing={2}>
            <Grid item xs={12}>
            <h2> Profile</h2>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                value={email}
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoFocus
                autoComplete="lname"
                onChange={e=> {setEmail(e.target.value);setProfileChanged(true)}}
              />
            </Grid>
                        <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                value={phone}
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                onChange={e=> {setPhone(e.target.value);setProfileChanged(true)}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                value={fname}
                fullWidth
                id="firstName"
                label="First Name"
                onChange={e=> {setFName(e.target.value);setProfileChanged(true)}}
                
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                value={lname}
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={e=> {setLName(e.target.value);setProfileChanged(true)}}
              />
            </Grid>
            {profileChanged && 
            <Grow in={profileChanged}>
            <Grid item xs={12}><Button style={{backgroundColor:"#5F87FC",color:"white"}}>Save</Button>
            <Button variant="outlined" style={{backgroundColor:"white",color:"#5F87FC",marginLeft:"10px"}}>Cancel</Button>
             </Grid>
            </Grow>
           }
            

            <Grid item xs={12}>
            <h2> Company</h2>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                style={{boderRaduis:"15px"}}
                name="firstName"
                variant="outlined"
                required
                value={company}
                fullWidth
                id="firstName"
                label="Company Name"

                onChange={e=> {setCompany(e.target.value);setCompanyChanged(true)}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                value={industry}
                fullWidth
                id="lastName"
                label="Industry Name"
                name="lastName"
                autoComplete="lname"
                onChange={e=> {setIndustry(e.target.value);setCompanyChanged(true)}}
              />
            </Grid>

            {companyChanged && 
            <Grow in={companyChanged}>
            <Grid item xs={12}><Button style={{backgroundColor:"#5F87FC",color:"white"}}>Save</Button>
            <Button variant="outlined" style={{backgroundColor:"white",color:"#5F87FC",marginLeft:"10px"}}>Cancel</Button>
            </Grid>
            </Grow>
            }



            <Grid item xs={12}>
            <h2> Password</h2>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                style={{boderRaduis:"15px"}}
                name="firstName"
                variant="outlined"
                required
                value={cpassword}
                fullWidth
                type="password"
                id="firstName"
                label="Current Password"
                onChange={e=> {setCPassword(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                value={newPassword}
                fullWidth
                id="lastName"
                error={passwordValidation}
                label="New Password"
                type="password"
                helperText={passwordValidation && passwordErrors}
                name="lastName"
                autoComplete="lname"
                onChange={e=> {setNewPassword(e.target.value);setPasswordChanged(true);valuevalidatePassword(e) }}
              />
            </Grid>
            
            {passwordChanged && 
            <Grow in={passwordChanged}>
              <Grid item xs={12}><Button disabled={passwordValidation} style={{backgroundColor:"#5F87FC",color:"white"}}>Update Password</Button>
            </Grid>
            </Grow>
            }





            <Grid item xs={12}>
            <h2> Adress</h2>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                style={{boderRaduis:"15px"}}
                name="firstName"
                variant="outlined"
                required
                value={loaction}
                fullWidth
                id="firstName"
                label="Location"
                onChange={e=> {setLocation(e.target.value);setAdressChanged(true)}}
                
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                value={billingAddress}
                fullWidth
                id="lastName"
                label="Billing Address"
                name="lastName"
                autoComplete="lname"
                onChange={e=> {setBillingAddress(e.target.value);setAdressChanged(true)}}
              />
            </Grid>
            {addressChanged && 
            <Grow in={addressChanged}>
            <Grid item xs={12}>
            <Button style={{backgroundColor:"#5F87FC",color:"white"}}>Save</Button>
            <Button variant="outlined" style={{backgroundColor:"white",color:"#5F87FC",marginLeft:"10px"}}>Cancel</Button>
            </Grid></Grow>
            }


             <Grid item xs={12}>
            <h2> Close Account</h2>
            </Grid>
            <Grid item xs={12}>
            
            <Button variant="outlined" style={{backgroundColor:"white",color:"red",borderSize:"2px",borderColor:"red",borderStyle:"solid"}}>Delete this account</Button>
            </Grid>




      </Grid>*/}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>

          {showaddmembers && <>
            <h2>Add member</h2>
            <Grid container>
              <Grid item lg={4}>
                <FormControl variant="outlined" style={{ width: "300px" }} className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Select Orginaztion</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Orginaztion"
                    value={"NETFLIX"}
                    onChange={handleAddMemberChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {orgdata.organizations.nodes.map((value) => (
                      <MenuItem key={value.name} value={value.name}>{value.name}</MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id="tags-standard"
                  options={users}
                  disableCloseOnSelect
                  style={{ width: "300px" }}
                  getOptionLabel={(option) => option.title}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select members"
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4}>
                <FormControl variant="outlined" style={{ width: "300px" }} className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Select Roles</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Roles"
                    onChange={handleAddMemberChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem key={1} value={1}>Admin</MenuItem>
                    <MenuItem key={2} value={2}>Read Only</MenuItem>
                    <MenuItem key={3} value={3}>User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>




            <Button type="submit" variant="outlined" color="primary" style={{ backgroundColor: "#6089FE", color: "white",margin:"10px 0px 20px 0px" }}>{isProgress ? <CircularProgress style={{ color: "white" }} /> : "Save"}</Button>
            <Button onClick={handleCloseAddMembers} type="submit" variant="outlined" style={{ backgroundColor: "#fff", color: "#",margin:"10px 0px 20px 10px" }}>{isProgress ? <CircularProgress style={{ color: "white" }} /> : "Cancel"}</Button>
          </>}
          <form className={classes.root} onSubmit={handleSubmit}>
            <h2> Create Organization</h2>
            <TextField style={{ width: "250px" }} required id="outlined-basic" label="Enter Organization name" variant="outlined" value={newOrgName} onChange={handleNewOrg} />
            <Button type="submit" variant="outlined" color="primary" style={{ backgroundColor: "#6089FE", color: "white", height: "55px", margin: "0px 10px 20px 10px" }}>{isProgress ? <CircularProgress style={{ color: "white" }} /> : "Create"}</Button>
          </form>

          <Divider />


          <Dialog
            open={open}
            style={{ "border": "solid 1px red" }}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`Do you want to delete ${selectedOrg.name} Orginaztion?`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Once you delete your Organization, there is no going back, Please be certain.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteOrg} color="primary">
                Yes, Delete it
              </Button>
              <Button onClick={handleClose} autoFocus>
                No, Cancel
              </Button>
            </DialogActions>
          </Dialog>

        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Scerets />
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
    </div>
  );
}


const mapStateToProps = ({ authReducer }) => {
  return { authReducer }
}
export default connect(mapStateToProps, { logout })(ScrollableTabsButtonForce)