import { gql, useQuery } from '@apollo/client';
import { Fade } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import ReactCountryFlag from "react-country-flag";
import { useHistory } from "react-router-dom";
import LogTrigger from '../../LogTrigger';
import Title from './Title';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(0, 'Singapore cluster', '', 'London, UK', 'VISA ⠀•••• 2574', "Show"),
  createData(1, 'india cluster', '', 'Boston, MA', 'MC ⠀•••• 1253', "Show"),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  cursor: {
    cursor: "pointer",
  },
}));


export const CLUSTERS = gql`
query{
  getAvailabilityCluster(isLatency: "", first: 25){
    nodes{
      name
      regionCode
      ipAddress
      clustertype
      clusterConfigPath
      isLatency
    }
  }
}
`
export default function Clusters(props) {
  const classes = useStyles();
  let history = useHistory();
  const { data, error, loading } = useQuery(CLUSTERS, {
    onCompleted: () => {
    }
  })
  //if (loading) return <Typography style={{textAlign:"center"}}><CircularProgress size="15px"/> Loading...</Typography>;
  if (loading) {
    return (
      <div>
        <Skeleton variant="text" width="40%" height={30} />
        <Skeleton variant="rectangular" height={40} />
      </div>
    );
  }
  if (error) return `Error! ${error.message}`;
  if (error) {
    LogTrigger(error.message, "error", window.location.href, "Location")
  }

  if (data) {
    LogTrigger(`Available Locations length - ${data.getAvailabilityCluster.nodes && data.getAvailabilityCluster.nodes.length}`, "info", window.location.href, "Locations")
  }
  return (
    <React.Fragment>


      <Grid>
        <Title >{data.getAvailabilityCluster.nodes.length} Available Locations</Title>

      </Grid>

      <Table size="small">



        <TableBody className={classes.cursor}>
          {data.getAvailabilityCluster.nodes.slice(0, 5).map((row, index) => (
            <Fade
              key={index}
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              {...(true ? { timeout: 500 * index } : {})}
            >
              <TableRow key={index}>
                <TableCell onClick={() => history.push(`/location/${row.regionCode}/${row.name}`)}>
                  <p><ReactCountryFlag
                    countryCode={row.regionCode.slice(0, 2)}

                    style={{
                      width: '1em',
                      height: '1em',
                      marginRight: "10px"
                    }}
                    title={row.regionCode}
                  />
                    {row.name}</p>

                </TableCell>
                {/* <TableCell align="right"><Link to={`/location/${row.regionCode}`}>See More</Link></TableCell> */}
              </TableRow>
            </Fade>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Button onClick={() => history.push("/location")} color="primary">See more</Button>
      </div>
    </React.Fragment>
  );
}