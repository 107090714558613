import React,{useEffect} from 'react';
import axios from 'axios';
import { useLocation,useHistory } from 'react-router';
import { connect } from "react-redux"
import { githubregister,githubobj,githublogin } from '../actions/index'
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function VerifyGithub(props) {
    const urlcode = useQuery().get("code")
    const [isError, setisError] = React.useState(false);

    const handleVerify = () => {
        const headers = {
            'Content-Type': 'application/json',
        }
        const data = {
            'client_id':"8ffec76f2cc2cc9e3c7d",
            'client_secret':'6e664a11c06681712cbd51119b150af4c754d824',
            'redirect_uri':'http://dev.ui.apps.nifetency.com/verify-github',
            'code': "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"
        }
        axios.post(`https://github.com/login/oauth/access_token`, data, { headers: headers }).then((res) => {
     
           props.githublogin(res.data)
        }, (err) => { 
            
            if (err.response.data.message==="Invalid User"){
                props.githubobj({"email":err.response.data.email,"code":urlcode})
                history.push("/github-register")
            }
            else{
                setisError(true)
            }
     
        })
    }
    let history = useHistory()
    useEffect(() => {
        handleVerify()
    }, []);
    return (
        <div style={{textAlign:"center"}}>
            {isError ? "Github Invalid Token, Please try again" : "verifying.." }
        </div>
    );
}
const mapStateToProps = ({ authReducer }) => {
    return { authReducer }
}
export default connect(mapStateToProps, { githubregister,githubobj,githublogin })(VerifyGithub);