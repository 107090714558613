import React from 'react';
import ReactDOM from 'react-dom';
import GoogleLogin from 'react-google-login';
import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { Redirect,useHistory  } from 'react-router-dom'
import {googleLogin, googleobj} from '../actions/index'
import { connect } from "react-redux"
import { AiOutlineGoogle } from "react-icons/ai";
import { IconButton, Tooltip } from '@mui/material';
import {FcGoogle} from "react-icons/fc"
import { CustomTooltip } from './Settings/Organization';

const StyledButton = withStyles({
  root: {
    background: '#ea4335',
    borderRadius: 25,
    border: 0,
    color: '#fff',
    height: 48,
    padding: '0 30px',
    margin: '1px',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: '#E12717',

    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `0 0 0 2px`,
      borderColor: '#f33',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

function GoogleLoginButton(props) {
  let history = useHistory();
  const handleLoad = (e) => {
 
  setIsload(e)
}
  const [isloggedIn, setIsloggedIn] = React.useState(true)
  const [isload, setIsload] = React.useState(true)

  const handleResponse = (value) => {
    setIsloggedIn(value)
    if (value=="Register"){
       
       history.push("/google-register");
    }
  }
  var CryptoJS = require("crypto-js");
  function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    encryptedData = encryptedData.ciphertext.toString();
    return encryptedData

  }

  const responseGoogle = (response) => {
    if (response.tokenObj ){
      props.googleLogin({"accessToken":encodeAes(response.tokenObj.access_token),"ssoType":"google"},handleResponse)
      props.googleobj(response)
    }
   

   /* return <Redirect to='/google-register'/>*/
}


return (
  <GoogleLogin
    clientId="570713914591-fhpqlkq1bobjhp438ispf4bgdvbvtqg7.apps.googleusercontent.com"//"643980656455-5pa1cdc17q6p743h6hva97e3t9512edv.apps.googleusercontent.com"//{process.env.REACT_APP_GOOGLE_CLIENT_ID} // "785266231457-7s6int6mpun4delti52kp0jcn8fq3prd.apps.googleusercontent.com" //
    buttonText="Login"
    onAutoLoadFinished={handleLoad}
    render={renderProps => (
      <CustomTooltip title={isload ? "Loading.." : "Connect With Google"}>
        <Button size='large' disabled={renderProps.disabled} fullWidth variant='outlined' disableElevation onClick={renderProps.onClick} sx={{textTransform:"capitalize",borderRadius:"24px",border:"1px solid rgba(0, 0, 0, 0.12)",py:1}}>{isload ? <AiOutlineGoogle style={{fontSize:"35px"}}/>: <FcGoogle style={{fontSize:"35px",color:"white"}}/>}</Button>
      {/* <IconButton  onClick={renderProps.onClick} disabled={renderProps.disabled}> {isload ? <AiOutlineGoogle style={{fontSize:"35px"}}/>: <FcGoogle style={{fontSize:"35px",color:"white"}}/>}</IconButton> */}
      </CustomTooltip>
    )}
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />
)}

const mapStateToProps =  ( {authReducer} ) => {

    return { authReducer }
}
export default connect(mapStateToProps, { googleLogin,googleobj })(GoogleLoginButton)