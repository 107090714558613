import { LOGIN, REGISTER, LOGIN_ERROR, REGISTER_ERROR, LOGOUT, REGENERATE, GOOGLEOBJ, ACTIVATE_ACCOUNT, LOGIN_AGAIN,GITHUBOBJ, RESET_MSG } from "../actions/types"

let msg = null
let regmsg = null
let isAuthenticated = localStorage.getItem("token") ? (localStorage.getItem("token") === "null" ? false : true) : false;
let userId = null
let isRefresh = false
let googleobj = null
let githubobj = null
let signedIn = false
let loginAgain = false
const init = {
    token: localStorage.getItem("token"),
    isAuthenticated,
    msg,
    regmsg,
    userId,
    isRefresh,
    googleobj,
    githubobj,
    signedIn,
    loginAgain,
}

export default function Red(state = init, action) {
    switch (action.type) {
        case LOGIN:
            localStorage.setItem("token", action.payload.access_token)
            localStorage.setItem("userId", action.payload.userId)
            return {
                ...state,
                token: localStorage.getItem("token"),
                isAuthenticated: true,
            }
        case REGISTER:
            //localStorage.setItem("token" ,action.payload.access_token)
            localStorage.setItem("userId", action.payload.user_Id)
            return {
                ...state,
                //token: localStorage.getItem("token"),
                signedIn: true,
            }
        case ACTIVATE_ACCOUNT:
            return {
                ...state,
                isAuthenticated: true,
            }

        case LOGIN_ERROR:
            return {
                ...state,
                msg: action.payload
            }
        case REGISTER_ERROR:
            return {
                ...state,
                regmsg: action.payload
            }
        case RESET_MSG:
            return {
                ...state,
                msg: null
            }
        case REGENERATE:
          
            localStorage.setItem("token", action.payload.access_token)
            localStorage.setItem("userId", action.payload.userId)
            return {
                ...state,
                token: localStorage.getItem("token"),
                isRefresh: true

            }
        case LOGOUT:
            var temp = localStorage.getItem("mode");
            localStorage.clear();
            localStorage.setItem("mode",temp)

            return {
                ...state,
                token: action.payload,
                isAuthenticated: false,
                msg: action.payload
            }
        case GOOGLEOBJ:
            return {
                ...state,
                googleobj: action.payload
            }
        case GITHUBOBJ:
            return {
                ...state,
                githubobj: action.payload
            }
        case LOGIN_AGAIN:
            return {
                ...state,
                loginAgain: action.payload,
            }
        default:
            return state
    }
}