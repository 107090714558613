import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { gql, useMutation, useQuery } from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSnackbar } from 'notistack';
import { IoMdPersonAdd } from "react-icons/io"
import { ORG_LIST } from './Organization';
import UpgradePlan from '../../Containers/UpgradePlan';
import { MEMBERS_LIST } from '../Org/MembersTable';
import { Chip, Collapse, Grid, Paper, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdPerson } from 'react-icons/md';
import { FaCheck, FaRegEye } from 'react-icons/fa';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const UPDATE_ROLE_INVITE_USER = gql`
mutation UpdateInviteUserMutaion($roleId : Int!, $email: String!){
    addInviteUserRole(
    roleId:$roleId,
    email: $email
  )
}`

export const INVITE_USER = gql`
mutation InviteUserMutaion($organization : [String], $userEmail: String!){
    inviteUser(input:{
    organization:$organization,
    userEmail: $userEmail
  }){
    message
    userId
  }
}`






export default function FormDialog(props) {
    const role = localStorage.getItem("role")
    const [open, setOpen] = React.useState(false);
    const [email, setemail] = React.useState("");
    const [inputValue, setInputValue] = React.useState([]);
    const [inviteRoleAs, setinviteRoleAs] = React.useState("User");
    const { enqueueSnackbar } = useSnackbar();

    const { data: orgdata, refetch } = useQuery(ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
    });


    const [addInviteUserRole, { error: roleerror }] = useMutation(UPDATE_ROLE_INVITE_USER, {
        refetchQueries: [MEMBERS_LIST],
        onCompleted: () => {
            setinviteRoleAs("User")
        }
    })

    const [inviteUser, { error: regerror }] = useMutation(INVITE_USER, {
        refetchQueries: [MEMBERS_LIST],
        onError: (err) => {
            if (err && err.message === "User Already Invited") {
                enqueueSnackbar("User Already Invited!", {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }

        },
        onCompleted: () => {
            if (inviteRoleAs === "Viewer") {
                addInviteUserRole({ variables: { roleId: 3, email: email } });
            }

            enqueueSnackbar("Invited Successfully!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            setOpen(false)
        }
    });


    const [emailValidation, setEmailValidation] = React.useState(false)
    const valuevalidateEmail = (e) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const checkvalue = re.test(e.target.value.toLowerCase())
        if (checkvalue.length > 0) {
            setEmailValidation(false)
        }
        setEmailValidation((checkvalue) ? false : true)
    }


    const handleClickOpen = () => {
        setemail("")
        setInputValue([])
        setinviteRoleAs("User")
        setOpen(true);
    };

    const handleChangeEmail = (e) => {
        setemail(e.target.value)
    }

    const handleClose = () => {
        setemail("")
        setOpen(false);
    };

    const handleInivteUser = () => {
        var temp = []
        inputValue.forEach(element => {
            temp.push(element.id)
        });
        inviteUser({
            variables: {
                organization: temp,
                userEmail: email,
            }
        })
    }

    React.useEffect(() => {
        refetch()

    }, [])


    return (
        <div>
            <Button disabled={role === "Viewer"} startIcon={<IoMdPersonAdd />} variant='contained' style={{ borderRadius: "24px", marginLeft: "0px", "boxShadow": "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }} disableElevation color="primary" onClick={handleClickOpen}>
                Invite member
            </Button>
            <Dialog
                PaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: "12px",
                        border: "solid 1px gray",
                        boxShadow: (email === "" || inputValue.length === 0 || emailValidation) ? "#160044 5px 5px, #160044 10px 10px" : "undefined"

                    }
                }}

                maxWidth={"xs"} fullWidth={true} open={open} onClose={handleClose}>
                <DialogTitle sx={{ pb: 0 }}>Invite User</DialogTitle>
                {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).inviteUserLimit === 0) ?
                    <UpgradePlan /> :
                    <div>
                        {regerror && regerror.message === "You've reached your maximum limit of current plan. Please upgrade your plan to invite users" ?
                            <DialogContent>
                                <DialogContentText>
                                    You've reached your maximum limit of current plan. Please upgrade your plan to invite users
                                </DialogContentText>
                                <UpgradePlan invite={true} />
                            </DialogContent>
                            :

                            <DialogContent sx={{ pt: 1 }}>
                                <div style={{marginBottom:"10px"}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Paper onClick={()=>setinviteRoleAs("User")} variant='outlined' sx={{p:2, cursor:"pointer", bgcolor:"#f5f2f8",color:"#9c47cc"}}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <MdPerson style={{fontSize:"30px"}} />
                                                    {inviteRoleAs === "User" && <FaCheck style={{fontSize:"20px"}} />}
                                                </Grid>
                                                <Typography sx={{fontWeight:"bold"}}>
                                                    Member role
                                                </Typography>
                                                <Typography variant='body2' sx={{fontSize:"10px"}}>
                                                    Will alow invited member to view, create and update
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Paper onClick={()=>setinviteRoleAs("Viewer")} variant='outlined' sx={{p:2, cursor:"pointer", bgcolor:"#fffbc0",color:"rgb(203, 162, 0)"}}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <FaRegEye style={{fontSize:"30px"}} />
                                                    {inviteRoleAs === "Viewer" && <FaCheck style={{fontSize:"20px"}} />}
                                                </Grid>
                                                <Typography sx={{fontWeight:"bold"}}>
                                                    Viewer role
                                                </Typography>
                                                <Typography variant='body2' sx={{fontSize:"10px"}}>
                                                    Will give invited member only view access.
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                           
                                </div>

                                <TextField
                                    autoFocus
                                    value={email}
                                    size="small"
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    helperText={emailValidation && "Invalid email"}
                                    error={emailValidation}
                                    fullWidth
                                    onChange={(e) => {
                                        handleChangeEmail(e);
                                        valuevalidateEmail(e);
                                    }}
                                    variant="outlined"
                                />


                                <div style={{ marginTop: "10px" }}>

                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={orgdata ? orgdata.organizations.nodes.filter((e)=>{
                                            return e.parentId === ""
                                        }) : []}
                                        disableCloseOnSelect
                                        size="small"
                                        value={inputValue}
                                        onChange={(event, value) => setInputValue(value)}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />

                                                {option.name}
                                            </li>
                                        )}

                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Organization(s)" placeholder="Search.." />
                                        )}
                                    />


                                </div>
                            </DialogContent>}
                        {regerror && regerror.message === "You've reached your maximum limit of current plan. Please upgrade your plan to invite users" ? <DialogActions>
                            <Button onClick={handleClose}>close</Button>
                        </DialogActions>
                            :
                            <>
                                <Collapse in={!(email === "" || inputValue.length === 0 || emailValidation)}>
                                    <DialogActions>
                                        <Button fullWidth onClick={handleClose}>close</Button>
                                        <Button color='primary' variant='contained' disableElevation fullWidth disabled={email === "" ? true : inputValue.length === 0 ? true : emailValidation ? true : false} onClick={handleInivteUser}>Submit</Button>
                                    </DialogActions>
                                </Collapse>
                            </>
                        }
                    </div>}
            </Dialog>
        </div>
    );
}
