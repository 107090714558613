import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import StepTwo from "../../Home/CreateNewApp/StepTwo"
import { useHistory } from 'react-router-dom';
import { AiOutlineRedo } from 'react-icons/ai';
import { TextField } from '@mui/material';
import { APP } from '../Overview/Main';
import { useQuery } from '@apollo/client';

function Redeploy(props) {
  const role = localStorage.getItem("role")
  const [open, setOpen] = React.useState(false);
  const [oldname, setoldname] = useState("");
  const [newname, setnewname] = useState("");
  let history = useHistory()

  const { data: appdata, error: apperr, loading: appload } = useQuery(APP, {
    variables: {
      name: props.name ? props.name : ""
    }, notifyOnNetworkStatusChange: true,
    onCompleted: () => {
    }
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props.handleClickOpen) {
      props.handleCloseRedeployTab();
    }
  };

  const handlePost = () => {
    if (newname == oldname) {
      setOpen(false)
    }
    else {
      history.push(`/deployments/service/${newname}`)
      setOpen(false)
    }

  }
  console.log(props)
  useEffect(() => {
    if (props) {
      setoldname(props.name)
      setnewname(props.name)
    }
    if (props.handleClickOpen) {
      handleClickOpen();
    }
  }, []);


  return (
    <div>
      {!props.hideRedeployButton && <Button disabled={role === "Viewer" || false} startIcon={<AiOutlineRedo />} variant="contained" onClick={handleClickOpen}>
        Redeploy!
      </Button>}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >



        <DialogTitle id="alert-dialog-title">
          {"Redeploy App "}
          <TextField size='small' variant='outlined' value={newname} onChange={(e) => setnewname(e.target.value)} />
        </DialogTitle>
        <DialogContent>
          <StepTwo imageName={props.imageName} app={appdata && appdata.app} organizationID={props.orgId && props.orgId.app.organization && props.orgId.app.organization.id} name={oldname} newname={newname} redeploy={true} replicas={props.replicas} appdata={props.data} handlePost={handlePost} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default Redeploy;