import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BiExpandVertical } from 'react-icons/bi';
import { useLazyQuery, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IoIosPeople } from 'react-icons/io';
import { Divider, Typography } from '@mui/material';

export const SWITCH_ORG_LIST = gql`
query{
  organizations{
    nodes{
      id
      name
      slug
      type
      parentId
    }
}
}`

export default function SwitchOrgs({orgId}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [fetchSwitchOrg, { loading, error, data }] = useLazyQuery(SWITCH_ORG_LIST, {
    variables: { userId: localStorage.getItem("userId") },
  });

  React.useEffect(() => {
    fetchSwitchOrg();
  }, [data]);

  return (
    <div>
     {data && data.organizations.nodes && <div>
        <IconButton
      sx={{ml:2}}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <BiExpandVertical />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 100 * 4.5,
            borderRadius: "12px",
          },
        }}
      >
       <MenuItem onClick={()=>history.push("/organizations")}>Organizations</MenuItem>
       <Divider/>
        {data && data.organizations.nodes && data.organizations.nodes.filter((e)=>{return e.parentId === ""}).map((option) => (
          <MenuItem key={option.id} selected={option.name === orgId} 
             onClick={() => {
              handleClose();
              history.push(`orgs?o=${option.name}`);
            }}>
            <IconButton ><IoIosPeople style={{fill: "#bdbdbd"}} /></IconButton>
            <Typography sx={{ textTransform: "capitalize", fontWeight: option.name === orgId ? "bold" : "normal", color: option.name === orgId ? "primary" : "undefined" }}>
            {option.name.toString().toLowerCase()}
            </Typography>
            {option.type==="true" && <b style={{ color: "rgb(33, 150, 243)", border: "1px solid rgb(33, 150, 243)", background: "rgb(227, 242, 253)", marginLeft: "5px", padding: "2px 10px 2px 10px", borderRadius: "12px", fontSize: "12px" }}>Default</b>}
          </MenuItem>
        ))}
      </Menu>
    </div>}
    
    </div>
  );
}
