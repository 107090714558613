import React,{useRef,useCallback} from 'react';
import Button from '@mui/material/Button';
import { Redirect,useHistory  } from 'react-router-dom'
import {gitlablogin, googleobj} from '../actions/index'
import { connect } from "react-redux"
import { AiFillGitlab } from "react-icons/ai";
import withStyles from '@mui/styles/withStyles';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { supabase } from '../client';
import { IconButton, Tooltip } from '@mui/material';
import { CustomTooltip } from './Settings/Organization';


const StyledButton = withStyles({
  root: {
    background: '#fca326',
    borderRadius: 25,
    border: 0,
    color: '#fff',
    height: 48,
    padding: '0 30px',
    margin: '1px',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: 'orange',

    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `0 0 0 2px`,
      borderColor: '#f33',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);


function GithubLoginButton(props) {
  const [user, setUser] = useState(null);
  const [userToken, setuserToken] = useState(null);




  useEffect(() => {

    checkUser();

    window.addEventListener('hashchange', function () {
      checkUser();
    });
  }, [])

  
  var CryptoJS = require("crypto-js");
  function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    encryptedData = encryptedData.ciphertext.toString();
    return encryptedData

  }

  async function signOut() {

    await supabase.auth.signOut();
    setUser(null);
  }


  let history = useHistory()

  const handleResponse = (e) =>{
    signOut()
    if(e=="Register"){
       history.push("/gitlab-register")
    }
  }

  const handleLogingithub = () => {
    props.gitlablogin({"accessToken": encodeAes(supabase.auth.currentSession.provider_token), "ssoType":"gitlab"},handleResponse)
  }

  async function checkUser() {
    const user = supabase.auth.user();
    if (supabase.auth.currentSession && supabase.auth.currentSession.user.app_metadata.provider==="gitlsasdab") {
      setuserToken(supabase.auth.currentSession.provider_token)
      handleLogingithub()
    }
    setUser(user);
  }
  async function signInWithGithub() {
   
    const { user, session, error } = await supabase.auth.signIn(
      {
        provider: 'gitlab',
      }
    );
  }



  return (
    <div className="App">
     <CustomTooltip title="Connect With Gitlab">
     <Button size='large' fullWidth variant='outlined' disableElevation onClick={signInWithGithub} sx={{textTransform:"capitalize",borderRadius:"24px",border:"1px solid rgba(0, 0, 0, 0.12)",py:1}} ><AiFillGitlab style={{fontSize:"35px",color:"orange"}}/></Button>
      {/* <IconButton style={{fontSize:"35px",color:"orange"}} onClick={signInWithGithub}><AiFillGitlab/></IconButton> */}
      </CustomTooltip></div>
  );
}

const mapStateToProps =  ( {authReducer} ) => {

    return { authReducer }
}
export default connect(mapStateToProps, { gitlablogin,googleobj })(GithubLoginButton)