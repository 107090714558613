import { gql, useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import { Paper, Skeleton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import InviteUser, { UPDATE_ROLE_INVITE_USER } from "../Settings/InviteUser";
import LogTrigger from '../../LogTrigger';
import { CustomTooltip } from '../Settings/Organization';
import { IoIosPeople } from 'react-icons/io';
import UserDetailsTooltip from '../Home/UserDetailsTooltip';
import { FaCheck, FaRegEye } from 'react-icons/fa';
import { MdPerson } from 'react-icons/md';
import { BiCool } from 'react-icons/bi';

export const DELETE_ORG_MEMBER = gql`
mutation DeleteOrgMemberMutaion($OrganizationId :  String!, $UserId: String!){
    removeUserOrg(OrganizationId:$OrganizationId, UserId: $UserId)
}`

export const UPDATE_ROLE = gql`
mutation UpdateRegionMutaion($userId : String!, $roleId: Int!){
    updateRole(
    userId:$userId,
    roleId: $roleId
  )
}`


export const UPDATE_ORG = gql`
mutation UpdateRegionMutaion($userId : Int!, $organizationId: [String]!){
    addUserToOrg(input: {userId:$userId,organizationId:$organizationId})
}`




export const MEMBERS_LIST = gql`
query {
    getUserByAdmin {
          id
          email
          companyName
          phoneNumber
          location
          industry
          firstName
          lastName
          ssoType
          freePlan
          profileImageUrl
          isActive
          isDelete
          companyId
          organization{
            nodes{
              name
              id
            }
          }
          userProfileCreated
          roleId
          
    }
}`

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const columns = [
  {
    field: "email",
    headerName: "Members",
    width: 300,
    align: "left",
    renderCell: (cellValues) => {
      return (
        <UserDetailsTooltip data={cellValues.row}>
        </UserDetailsTooltip>);
    }
  },
  {
    field: "isActive",
    headerName: "Status",
    align: "center",
    sortable: false,
    renderCell: (cellValues) => {
      if (cellValues.row.userProfileCreated === "1" || cellValues.row.id === localStorage.getItem("userId")) {
        return (<Typography variant='body2' style={{ color: "green", background: "rgb(229, 252, 235)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > Active</Typography>)
      }

      if (cellValues.row.userProfileCreated === "0") {
        return (<Typography variant='body2' style={{ color: "black", background: "rgb(239, 239, 239)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > Invited</Typography>)
      }

      else {
        return (<div style={{ color: "rgb(33, 150, 243)", background: "rgb(227, 242, 253)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > Not Active</div>)
      }
    }
  },
  {
    field: "organization",
    sortable: false,
    headerName: "Organizations",
    width: 300,
    align: "center",
    renderCell: (cellValues) => {

      return <span>
        <CustomTooltip title={cellValues.value[0].nodes && cellValues.value[0].nodes.map((i) => i.name).join(', ').replace(/, ([^,]*)$/, ' and $1')} arrow>
          <div style={{ color: "#3d5066", textTransform: "capitalize", cursor: "pointer" }}>

            {cellValues.value[0].nodes ?
              <span style={{ color: "#3d5066", textTransform: "capitalize" }}>
                <IconButton style={{ padding: "0px", margin: "0px 5px 0px 5px" }}>
                  <IoIosPeople style={{ fontSize: "18px" }} />
                </IconButton>
                {cellValues.value[0].nodes[0].name}</span> : " No Organization"} {cellValues.value[0].nodes && cellValues.value[0].nodes.length !== 1 && <b style={{ color: "grey" }}>+ {cellValues.value[0].nodes && cellValues.value[0].nodes.length - 1} mores</b>}</div>
        </CustomTooltip>

      </span>
    }
  },
  {
    field: "roleId",
    headerName: "Role",
    align: "center",
    renderCell: (cellValues) => {
      if (cellValues.value === 2) {
        return (<div style={{ background: "#f5ecff", color: "#851bc0", borderRadius: "24px", padding: "0px 10px 0px 10px", }} > Member</div>)
      }

      if (cellValues.value === 1) {
        return (<div style={{ color: "rgb(33, 150, 243)", background: "rgb(227, 242, 253)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > Admin</div>)
      }

      if (cellValues.value === 3) {
        return (<div style={{ color: "rgb(203, 162, 0)", background: "#fffbc0", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px rgb(164, 131, 0) " }} > Viewer</div>)
      }

    }
  },
  {
    field: "userProfileCreated",
    headerName: "Actions",
    sortable: false,
    align: "center",
    renderCell: (cellValues) => {
      return (
        <More data={cellValues.row} />
      )
    }
  },



];


function More(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDelete, setisDelete] = React.useState(false);
  const [selectedUser, setselectedUser] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);
  const [delorg, setdelorg] = React.useState("");


  const handleClick = (event) => {

    setselectedUser(props.data)
    handleClickOpen()
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClickClose = () => {
    setOpen2(false);
  };

  const [age, setAge] = React.useState(props.data.roleId);
  const handleChange = (event) => {
    setAge(event);
    if (event === 1 || event === 2) {
      updateRole({ variables: { userId: selectedUser.id, roleId: event } })
    }
    if (event === 3) {
      addInviteUserRole({ variables: { roleId: 3, email: selectedUser.email } });

    }

  };





  const handleRemoveUser = () => {
    deleteOrg({ variables: { OrganizationId: delorg.id, UserId: selectedUser.id } })
  };


  const permissions = JSON.parse(localStorage.getItem('permissions'))

  const [addInviteUserRole, { error: roleerror }] = useMutation(UPDATE_ROLE_INVITE_USER, {
    refetchQueries: [MEMBERS_LIST],
    onCompleted: () => {
      enqueueSnackbar("Successfully updated!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  })

  const [deleteOrg] = useMutation(DELETE_ORG_MEMBER, {
    refetchQueries: [MEMBERS_LIST],
    onError: (err) => {
      //   handleCloseDeleteOrg()
    },
    onCompleted: () => {
      enqueueSnackbar("Successfully removed!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      setOpen2(false)
      setisDelete(false)
      // setselectedUser(null)
      // setdelorg("")
    }
  });

  const [updateRole, { loading: regloading }] = useMutation(UPDATE_ROLE, {
    refetchQueries: [MEMBERS_LIST],
    onError: (err) => {
      //   setshowApply(false)
    },
    onCompleted: () => {
      enqueueSnackbar("Successfully updated!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  });




  return (
    <div>
      <Dialog
        open={open2}
        maxWidth={"sm"}
        fullWidth
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {regloading && <LinearProgress />}
        <DialogTitle>{isDelete ? "Are you sure?" : "Member settings"}</DialogTitle>
        {isDelete ? <DialogContent>
          <Typography variant='body2'>
            Do you want to remove <b>{selectedUser.email}</b> member from <b>{delorg.name}</b> organization.
          </Typography>

          <br />


        </DialogContent> :
          <DialogContent>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°$`}
                    sx={{ width: 70, height: 70, mb: 1, mr: 2 }}
                    src={selectedUser.profileImageUrl}
                  />
                </ListItemAvatar>
                <ListItemText id={selectedUser.id} primary={`${selectedUser.firstName} ${selectedUser.lastName} ${selectedUser.id === localStorage.getItem("userId") ? "(You)" : ""}`} secondary={selectedUser.email} />
              </ListItem>
            </List>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"

            >

              {selectedUser.id !== localStorage.getItem("userId") && permissions[0].delete && <div style={{ marginRight: "10px", marginBottom: "10px" }}>
                <DialogContentText >Member's Role</DialogContentText>
                <Typography style={{ fontSize: "12px", color: "grey", marginBottom: "7px" }}>Select the role to update account role.</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Paper onClick={() => handleChange(1)} variant='outlined' sx={{ p: 2, cursor: "pointer", color: "rgb(33, 150, 243)", bgcolor: "rgb(227, 242, 253)", }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <BiCool style={{ fontSize: "30px" }} />
                        {age === 1 && <FaCheck style={{ fontSize: "20px" }} />}
                      </Grid>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Admin role
                      </Typography>
                      <Typography variant='body2' sx={{ fontSize: "10px" }}>
                        Admin has the highest authority of all roles.
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper onClick={() => handleChange(2)} variant='outlined' sx={{ p: 2, cursor: "pointer", bgcolor: "#f5f2f8", color: "#9c47cc" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <MdPerson style={{ fontSize: "30px" }} />
                        {age === 2 && <FaCheck style={{ fontSize: "20px" }} />}
                      </Grid>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Member role
                      </Typography>
                      <Typography variant='body2' sx={{ fontSize: "10px" }}>
                        Will alow invited member to view, create and update
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper onClick={() => handleChange(3)} variant='outlined' sx={{ p: 2, cursor: "pointer", bgcolor: "#fffbc0", color: "rgb(203, 162, 0)" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <FaRegEye style={{ fontSize: "30px" }} />
                        {age === 3 && <FaCheck style={{ fontSize: "20px" }} />}
                      </Grid>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Viewer role
                      </Typography>
                      <Typography variant='body2' sx={{ fontSize: "10px" }}>
                        Will give invited member only view access.
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                {/* <FormControl>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    disabled={regloading}
                    size={"small"}
                    label="Role"
                    onChange={handleChange}
                  >
                    <MenuItem value={2}>Member</MenuItem>
                    <MenuItem value={1}>Admin</MenuItem>
                    <MenuItem value={3}>Viewer</MenuItem>

                  </Select>
                </FormControl> */}


              </div>}
              {selectedUser.organization && selectedUser.organization[0].nodes &&
                <div>
                  <DialogContentText >Member's Organizations</DialogContentText>


                  {selectedUser.id === localStorage.getItem("userId") ? <div>
                    {selectedUser.organization && selectedUser.organization[0].nodes && selectedUser.organization[0].nodes.map((i) => (<Chip variant='outlined' color='primary' style={{ marginTop: "10px", marginRight: "5px" }} href="#basic-chip" size="medium" label={i.name} />))}
                  </div>

                    :
                    <div>
                      <Typography style={{ fontSize: "12px", color: "grey" }}>Click on the organization to remove {selectedUser.email} from specify organization.</Typography>
                      {selectedUser.organization && selectedUser.organization[0].nodes && selectedUser.organization[0].nodes.map((i) => (<Chip variant='outlined' color='primary' style={{ marginTop: "10px", marginRight: "5px" }} href="#basic-chip"
                        onDelete={() => {
                          if (permissions[0].delete) {
                            setdelorg(i);
                            setisDelete(true)
                          }
                          else {
                            enqueueSnackbar("You don't have permision to remove", {
                              variant: 'info',
                              anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                              },
                            })
                          }
                        }} size="medium" label={i.name} />))}
                    </div>}
                </div>}

            </Grid>

          </DialogContent>}

        <DialogActions>
          {isDelete ? <>
            <Button fullWidth disableElevation variant='contained' color="error" onClick={handleRemoveUser}>Yes, Delete</Button>

            <Button fullWidth color="primary" onClick={() => setisDelete(false)}>No, Close</Button>
          </> :
            <Button onClick={handleClickClose}>Close</Button>}

        </DialogActions>
      </Dialog>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}

      >
        {[
          'Show details',
          'Remove from this org',
          'Add to another org',
          'Update role',


        ].map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClickOpen}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )

}

function MembersTable(props) {
  const { loading, error, data, refetch } = useQuery(MEMBERS_LIST)
  const [searched, setSearched] = React.useState("")
  const [rows, setRows] = React.useState([])
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal)
    const filteredRows = rows.filter((row) => {
      return row.email.includes(searchedVal) || row.firstName.includes(searchedVal) || row.lastName.includes(searchedVal)
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };


  useEffect(() => {
    refetch()
    if (!error && !loading) {
      if (props.orgname) {
        var temp = [];
        data.getUserByAdmin.forEach(item => {
          item.organization[0].nodes && item.organization[0].nodes.forEach(element => {
            if (props.orgname === element.name) {
              temp.push(item)
            }
          });
        });

        setRows(temp)
      }
      else {
        setRows(
          data.getUserByAdmin
        );
      }

    }
  }, [data, props.orgname]);

  if (loading) {
    return (
      <div>
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      </div>
    );
  }
  if (error) return `Error! ${error.message}`;
  if (error) {
    LogTrigger(error.message, "error", window.location.href, "Members")
  }


  return (
    <div>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        style={{ marginBottom: "16px" }}
      >


        <TextField
          value={searched}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searched !== "" &&
                  <IconButton onClick={() => setSearched("")}>
                    <CloseIcon />
                  </IconButton>}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          variant="outlined"
          size={"small"}

          placeholder='Search for Members'
          onChange={(e) => setSearched(e.target.value)}
        />
        <InviteUser />
      </Grid>

      <Paper variant='outlined' style={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 08px 80px 02px", borderRadius: "12px" }}>
        <div style={{ width: "100%", marginTop: "0px" }}>


          {data && data.getUserByAdmin && <DataGrid
            rowHeight={60}

            rows={rows.filter((row) => {
              return row.email.includes(searched) || row.firstName.includes(searched) || row.lastName.includes(searched)
            })}
            columns={columns}
            autoHeight={true}
            density={"compact"}

            pageSize={10}

            style={{ border: "2px" }}
          />
          }
        </div>
      </Paper>
    </div>
  );
}

export default MembersTable;