import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },}))


export default function AppBar(props) {
  const classes = useStyles();


  return (
    <div>              
        <Grid container spacing={1} alignItems="flex-end">

        <Grid item>
  
          </Grid>
          <Grid item>

          </Grid>
          <Grid item className={classes.formControl}>



          </Grid>
        </Grid>
        <Typography variant="h5" style={{fontWeight: "bold",
        
        margin: "0px",
        marginLeft: "0px"}}> {props.title}</Typography>
        
    </div>
  );
}
