import CssBaseline from '@mui/material/CssBaseline';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Joyride, { STATUS } from 'react-joyride';
import Clusters from './Clusters';
import DeploymentTable from './DeploymentTable';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { gql, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import StorageIcon from '@mui/icons-material/Storage';
import { Collapse, DialogTitle, Fade, Slide } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import { FiBarChart2, FiDollarSign } from "react-icons/fi";
import { IoIosPeople } from "react-icons/io";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Nav from "../../Components/Settings/Nav";
import UpgradePlan from '../../Containers/UpgradePlan';
import LogTrigger from '../../LogTrigger';
import { logout } from '../../actions/index';
import { CLUSTERS } from "./Clusters";
import CreateNewApp from "./CreateNewApp/Main";
import LocationGraph from './LocationGraph';
import OrgGraph, { ORG_GRAPH_DATA } from './OrgGraph';
import { ADD_ORG, ORG_LIST } from "./SettingsTab";
import Title from './Title';
import New from './CreateNewApp/New';
import WorkloadBarChart from './WorkloadBarChart';
import { OpenInNew } from '@mui/icons-material';
import { APP_LIST } from './Apps';

export const OVERVIEW_DATA = gql`
{
    AppsCount {
      totalApps
      active
      inActive
      new
    }
  }`




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 3,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    paddingLeft: "10%",
    margin: "0px",
    padding: "6%",


  },
  drawerPaper: {
    background: "linear-gradient(0deg,#4e73df 10%,#618aff 100%)",
    color: "#fff",
    position: 'relative',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '99.3vh',
    overflow: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: "auto",
  },
  header: {
    height: 150,

  },
  margin: {
    margin: "20px",
    textAlign: "end",
  },
  link: {
    display: 'flex',
    textDecoration: "none",
    color: '#160044'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },

  headline: {
    color: "main",
    fontSize: "18px",
    margin: "10px"
  },
  bread: {
    borderRadius: "12px",
    marginBottom: "20px",
    boxShadow: "rgba(17, 12, 46, 0.05) 0px 48px 100px 5px",
    paddingRight: "10px",
    paddingLeft: "10px",
  }
}));

function Dashboard(props) {

  let history = useHistory();
  const role = localStorage.getItem("role")
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [newOrgName, setnewOrgName] = React.useState("")
  const [isProgress, setisProgress] = React.useState(false)
  const [isOrgNameError, setisOrgNameError] = useState(false);
  const [costMonitoring, setcostMonitoring] = useState(null);

  const [run, setrun] = useState(false);
  const [steps, setsteps] = useState([
    {
      content: <div>
        <h2 style={{ marginBottom: "2px" }}>Let's begin our journey!</h2>
        <span style={{ marginBottom: "20px", fontSize: "13px", color: "grey", fontFamily: "Rubik" }}>Click on Next for a quick tour of Teamboard</span>
      </div>,
      locale: { skip: <strong aria-label="skip">SKIP</strong> },
      placement: 'center',
      target: 'body',
    },
    {
      content: (
        <p>View and Manage <b>Organization</b> under this account</p>
      ),
      placement: 'right',
      target: '.demo__orgs_tab',
      title: <div style={{ fontSize: "18px", color: "#160044" }}>Organization Tab</div>,
    },
    {
      content: (
        <p>Manage multiple (dev, prod and staging) environments using <b>Workload</b></p>
      ),
      placement: 'right',
      target: '.demo__workload_tab',
      title: <div style={{ fontSize: "18px", color: "#160044" }}>Workload Tab</div>,
    },
    {
      content: (
        <p>View and Manage All the <b>Apps</b> of your company</p>
      ),
      placement: 'right',
      target: '.demo__apps_tab',
      title: <div style={{ fontSize: "18px", color: "#160044" }}>Application Tab</div>,
    },
    {
      content: (
        <p>Here you can Manage your <b>Profile, Secrets and Config Templates</b></p>
      ),
      placement: 'right',
      target: '.demo__settings',
      title: <div style={{ fontSize: "18px", color: "#160044" }}>Settings</div>,
    },
    {
      content: (
        <div>
          <h4>Alright!, Let's Create and deploy our first application!</h4>

        </div>
      ),
      placement: 'top',
      target: '.demo__create_app',
    },
  ]);

  const handleClickOpen = () => {
    setOpen(true);
    setnewOrgName("")
  };

  const handleClose = () => {
    setOpen(false);
  };








  const handleCreateNewOrg = (e) => {
    e.preventDefault()
    setisProgress(true)
    createOrg({ variables: { name: newOrgName } });
  }




  const handleOrgName = (e) => {
    setnewOrgName(e.target.value)
    const re = /[a-zA-Z]/
    const checkvalue = re.test(e.target.value)
    if (checkvalue.length > 0) {
      setisOrgNameError(false)
    }
    setisOrgNameError((checkvalue) ? false : true)
  }

  const handleWelcome = () => {
    var counter = 0;

    var i = setInterval(function () {
      counter++;
      if (counter === 10) {
        clearInterval(i);
      }
    }, 800);
  }

  const handleClickStart = (event) => {
    event.preventDefault();
    setrun(true)
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setrun(false)
      localStorage.removeItem("showUserGuide")
    }


  };


  const { loading, error, data, refetch } = useQuery(OVERVIEW_DATA);

  const { loading: loadLocation, error: errLocation, data: locationCount } = useQuery(CLUSTERS);
  const { loading: loadOrg, error: errOrg, data: orgCount } = useQuery(ORG_GRAPH_DATA);

  const [createOrg, { data: dataorg, loading: loadingorg, error: errororg }] = useMutation(ADD_ORG, {
    refetchQueries: [ORG_LIST, ORG_GRAPH_DATA],
    onError: (err) => {
      setisProgress(false)
      setnewOrgName("")

      if (err.message.includes("Duplicate entry")) {
        enqueueSnackbar("Organization name not unique, try diffrent name", {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      }
      else {
        enqueueSnackbar(err.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      }

    },
    onCompleted: () => {
      setOpen(false)
      setisProgress(false)
      LogTrigger("Organization Created", "info", window.location.href, "Dashboard")
    }
  });


  useEffect(() => {
    refetch()
    document.title = "Nife | Home"



    if (localStorage.getItem("deployLink")) {
      history.push(`/deploy?name=${localStorage.getItem("deployLink")}`)
    }

    if (localStorage.getItem("showUserGuide") && !localStorage.getItem("deployLink")) {
      setrun(true)
    }


  }, [data]);

  if (error) {
    LogTrigger(error.message, "error", window.location.href, "Dashboard")
  }

  if (data) {
    LogTrigger("Overview Data loaded", "info", window.location.href, "Dashboard")
  }

  if (errLocation) {
    LogTrigger(errLocation.message, "error", window.location.href, "Dashboard")
  }
  if (errOrg) {
    LogTrigger(errOrg.message, "error", window.location.href, "Dashboard")
  }
  if (errororg) {
    LogTrigger(errororg.message, "error", window.location.href, "Dashboard")
  }
  return (
    <div className={classes.root}>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            fontFamily: "Rubik",
            primaryColor: "#160044",
            zIndex: 10000,
          },
        }}
      />
      <CssBaseline />


      <main className={classes.content}>

        <Nav />
        <Container className={classes.container} maxWidth={false}>
          <Fade in={(data && data.AppsCount && data.AppsCount.totalApps === 0)} mountOnEnter unmountOnExit style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 300 } : {})}>
            <div style={{ height: "90vh" }}>
              <New />
            </div>
          </Fade>

          <Fade in={(loading || (data && data.AppsCount && data.AppsCount.totalApps))} style={{ transformOrigin: '0 0 0' }} {...((loading || (data && data.AppsCount && data.AppsCount.totalApps)) ? { timeout: 0 } : {})} mountOnEnter unmountOnExit>
            <div>
              <Typography variant='body2' sx={{ fontWeight: "lighter", mb: 1 }} color={"primary"}>Here what's happening in your teamboard account today,  <a target='_blank' style={{ color: "grey" }} href={"https://nife-demo-videos.s3.ap-south-1.amazonaws.com/Nife+Platform+%EF%BD%9C+A+Comprehensive+Overview+%23NifePlatform+%23AppManagement+%23Deployment+%23developerlife.mp4"}>
                View more about Overview
              </a></Typography>

              {/* <Grid container spacing={2}>
                <Grid item lg={12} xs={12} style={{ paddingRight: "0px" }}>
                  <Paper variant='outlined' className={classes.paper} elevation={0} style={{ boxShadow: "rgba(17, 6, 46, 0.05) 0px 48px 100px 0px", borderRadius: "10px", minHeight: "50px" }}>
                    <Grid container spacing={1}>
                      <Grid item lg={4} xs={12} style={{ paddingRight: "0px" }}>
                        <AppDonutChart />
                      </Grid>
                      <Grid item lg={8} xs={12} style={{ paddingRight: "0px" }}>
                        More info
                        <br/>
                        <AppBubbleChart/>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item lg={6} xs={12} style={{ paddingRight: "0px" }}>
                  <Paper variant='outlined' className={classes.paper} elevation={0} style={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 48px 100px 0px", borderRadius: "10px", minHeight: "50px" }}>
                  More info
                  </Paper>
                </Grid>
              </Grid> */}

              <Grid container spacing={2}>
                <Grid item xs={12} sm container >
                  <Grid item xs container direction="row" alignItems="flex-start" justifyContent="center" spacing={2} >
                    <Grid item xs={12} lg={6}>


                      <Paper variant='outlined' className={classes.paper} style={{ boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", borderRadius: "10px", minHeight: "190px" }}>

                        <div className='star-burst'>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"

                          >  {loading ? <Skeleton style={{ borderRadius: "12px" }} variant="rounded" height={50} width={50} /> : <IconButton
                            onClick={() => history.push('/apps')}
                            style={{ borderRadius: "12px", background: "rgba(236, 236, 236, 0.59) none repeat scroll 0% 0%" }}
                            size="large"><StorageIcon /></IconButton>}
                            <div className="demo__create_app">      <CreateNewApp totalapps={data && data.AppsCount.active} /></div>

                          </Grid>
                          {loading ? <Skeleton variant="text" height={40} /> :
                            <Fade
                              in={true}
                              style={{ transformOrigin: '0 0 0' }}
                              {...(true ? { timeout: 500 } : {})}
                            >

                              <h2 style={{ margin: "16px 0px" }}>{data && data.AppsCount.active} Active Apps <IconButton style={{ padding: "0px" }} size="large">
                                <CheckCircleIcon style={{ color: "green" }} size={4} />
                              </IconButton></h2>
                            </Fade>}
                          <Fade
                            in={true}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(true ? { timeout: 1000 } : {})}
                          ><div>
                              {loading ? <Skeleton variant="text" /> :
                                <h4 style={{ color: "gray", margin: "16px 0px" }}>{data && data.AppsCount.inActive} Inactive  {data && data.AppsCount.new > 0 && `, ${data.AppsCount.new} New`}  Apps</h4>
                              }
                            </div>
                          </Fade>

                        </div>

                      </Paper>

                    </Grid>

                    <Grid item xs={12} lg={6} style={{ paddingRight: "0px" }}>
                      <div className='demo__projects'>
                        <Paper variant='outlined' className={classes.paper} style={{ boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", borderRadius: "10px", minHeight: "190px" }}>


                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"

                          >
                            {loading ? <Skeleton style={{ borderRadius: "12px" }} variant="rounded" height={50} width={50} /> : <IconButton
                              onClick={() => history.push('/organizations')}
                              style={{ borderRadius: "12px", background: "rgba(236, 236, 236, 0.59) none repeat scroll 0% 0%" }}
                              size="large"><IoIosPeople size={25} /></IconButton>}


                            {loading ? <Skeleton style={{ borderRadius: "24px" }} variant="rounded" width={95} height={30} /> : <Button disabled={role === "Viewer"} startIcon={<AddIcon />} variant='outlined' onClick={handleClickOpen} style={{ borderRadius: "24px" }}>New</Button>}

                          </Grid>

                          <Fade
                            in={true}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(true ? { timeout: 500 } : {})}
                          >
                            <div>
                              {loadLocation ? <Skeleton variant="text" height={40} /> :
                                <h2 style={{ margin: "16px 0px" }}>{orgCount ? orgCount.GetAppsAndOrgsCountDetails.totalOrgCount : 0} Organizations</h2>}
                            </div></Fade>
                          <Fade
                            in={true}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(true ? { timeout: 1000 } : {})}
                          >
                            <div>
                              {loadLocation ? <Skeleton variant="text" /> :
                                <h4 style={{ color: "gray", margin: "0px 0px 16px 0px" }}>{locationCount && locationCount.getAvailabilityCluster.nodes && locationCount.getAvailabilityCluster.nodes.length} Available Locations</h4>}
                            </div>
                          </Fade>


                        </Paper>
                      </div>
                    </Grid>

                    <Grid item lg={12} xs={12} style={{ paddingRight: "0px" }}>
                      <Paper variant='outlined' className={classes.paper} elevation={0} style={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 48px 100px 0px", borderRadius: "10px", minHeight: "300px" }}>

                        <DeploymentTable setcostMonitoring={setcostMonitoring} />

                      </Paper>


                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12} lg={4} style={{ paddingLeft: "0px" }}>

                  <Paper variant='outlined' className={classes.paper} elevation={0} sx={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 48px 100px 0px", borderRadius: "10px", mb: 2 }}>
                    <ListItem sx={{ p: 0 }} secondaryAction={

                      <Typography variant="body2" color={((parseInt(data && data.AppsCount.totalApps) * 100) / parseInt(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).apps)) > 90 ? "error" : "default"}>
                        {!loading && localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).apps && <span>{parseInt((parseInt(data && data.AppsCount.totalApps) * 100) / parseInt(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).apps)).toFixed(2)}% Used</span>}
                      </Typography>
                    }>
                      <ListItemAvatar >{loading ? <Skeleton style={{ borderRadius: "12px" }} variant="rounded" height={30} width={30} /> : <FiBarChart2 size={25} />}</ListItemAvatar>
                      <Fade
                        in={true}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(true ? { timeout: 500 } : {})}
                      >
                        {loading ? <div><Skeleton variant="text" height={15} width={100} /><Skeleton variant="text" height={15} width={100} /></div> :
                          <ListItemText primary={data && data.AppsCount.totalApps} secondary="Total Apps" />}
                      </Fade>
                    </ListItem>
                  </Paper>

                  {costMonitoring && <Paper variant='outlined' className={classes.paper} elevation={0} sx={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 48px 100px 0px", borderRadius: "10px", mb: 2 }}>
                    <ListItem sx={{ p: 0 }} secondaryAction={

                      <IconButton
                        onClick={() => {
                          window.open(`${costMonitoring.hostname}?token=${localStorage.getItem("token") && localStorage.getItem("token")}`, '_blank')
                        }}
                      ><OpenInNew /></IconButton>
                    }>
                      <ListItemAvatar >{loading ? <Skeleton style={{ borderRadius: "12px" }} variant="rounded" height={30} width={30} /> : <FiDollarSign size={25} />}</ListItemAvatar>
                      <Fade
                        in={true}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(true ? { timeout: 500 } : {})}
                      >
                        {loading ? <div><Skeleton variant="text" height={15} width={100} /><Skeleton variant="text" height={15} width={100} /></div> :
                          <ListItemText primary={"Cost Monitoring"} secondary="Active" />}
                      </Fade>
                    </ListItem>
                  </Paper>}


                  <Paper variant='outlined' className={classes.paper} elevation={0} style={{ borderRadius: "10px", minHeight: "300px" }}>
                    {loading ? <ListItem>
                      <ListItemAvatar > <Skeleton style={{ borderRadius: "12px" }} variant="rounded" height={30} width={30} /></ListItemAvatar>
                      <div><Skeleton variant="text" height={15} width={200} /><Skeleton variant="text" height={15} width={200} /></div>

                    </ListItem> :
                      <div>
                        {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).applicationHealthDashboard) ? <LocationGraph />
                          : <div> <Title>Location Pie Chart</Title><UpgradePlan center={true} /></div>}
                      </div>}
                  </Paper>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper variant='outlined' className={classes.paper} style={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 5px", borderRadius: "10px", minHeight: "400px" }}>
                    {loading ? <ListItem>
                      <ListItemAvatar > <Skeleton style={{ borderRadius: "12px" }} variant="rounded" height={30} width={30} /></ListItemAvatar>
                      <div><Skeleton variant="text" height={25} width={200} /><Skeleton variant="text" height={25} width={200} /></div>

                    </ListItem> :
                      <Clusters />
                    }
                  </Paper>
                </Grid>
                <Grid item xs={12} md={8} lg={8} >
                  <Paper variant='outlined' className={classes.paper}
                    style={{ boxShadow: "rgba(17, 12, 46, 0.05no) 0px 30px 50px 5px", borderRadius: "10px", height: "400px" }}>
                    {loading ? <ListItem>
                      <ListItemAvatar > <Skeleton style={{ borderRadius: "12px" }} variant="rounded" height={30} width={30} /></ListItemAvatar>
                      <div><Skeleton variant="text" height={25} width={200} /><Skeleton variant="text" height={25} width={200} /></div>

                    </ListItem> :
                      <div style={{ height: "400px" }}>
                        {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).applicationHealthDashboard) ?
                          <OrgGraph />
                          : <div > <Title>Organizations Bar Chart</Title><UpgradePlan center={true} /></div>}
                      </div>}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper variant='outlined' className={classes.paper} style={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 5px", borderRadius: "10px", minHeight: "400px" }}>
                    {loading ? <ListItem>
                      <ListItemAvatar > <Skeleton style={{ borderRadius: "12px" }} variant="rounded" height={30} width={30} /></ListItemAvatar>
                      <div><Skeleton variant="text" height={25} width={200} /><Skeleton variant="text" height={25} width={200} /></div>

                    </ListItem> :
                      <WorkloadBarChart />
                    }
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </Fade>



          <Dialog
            PaperProps={{
              elevation: 0,
              sx: {
                borderRadius: "12px",
                border: "solid 1px gray",
                boxShadow: (newOrgName === "" ? true : false) ? "#160044  5px 5px, #160044 10px 10px" : "undefined"

              }
            }}
            open={open} maxWidth={"xs"} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">


            {orgCount && localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).organizationCount <= orgCount.GetAppsAndOrgsCountDetails.totalOrgCount ?
              <DialogContent>
                <div style={{ marginTop: "10px" }}>
                  <Typography style={{ margin: "10px 0px" }} variant="h6" component="h6">
                    You have reached Organization limit</Typography>
                  <p style={{ color: "grey" }}> Please upgrade your plan to create Organization</p>

                  <UpgradePlan invite={true} /></div>
              </DialogContent> :
              <div>
                <DialogTitle style={{ paddingBottom: "0px" }}>Create <b>New Organization</b></DialogTitle>
                <div>


                  <DialogContent>

                    <TextField
                      size='small'
                      variant='outlined'
                      label="Enter Organization name"
                      value={newOrgName}
                      fullWidth={true}
                      error={isOrgNameError}
                      helperText={isOrgNameError && "Invalid Name"}
                      onChange={handleOrgName}
                      autoFocus />

                  </DialogContent>
                </div>

                <Collapse in={newOrgName === "" ? false : true}>
                  <DialogActions>
                    <Button fullWidth onClick={handleClose}> Cancel </Button>
                    <Button
                      fullWidth

                      variant="contained"
                      color="primary"
                      disabled={isOrgNameError}
                      onClick={handleCreateNewOrg}
                      disableElevation>{isProgress ? <CircularProgress size={20} style={{ color: "white" }} /> : "Create"}</Button>
                  </DialogActions>
                </Collapse>

              </div>}




          </Dialog>

        </Container>
      </main>
    </div>
  );
}

const mapStateToProps = ({ authReducer }) => {
  return { authReducer }
}
export default connect(mapStateToProps, { logout })(Dashboard)