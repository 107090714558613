import React, { useState } from 'react';
import AWS from 'aws-sdk';

const S3_BUCKET = 'oikos-uptime-data';
const REGION = 'ap-south-1';

AWS.config.update({
  accessKeyId: 'AKIA33QERXVKXCNCBBPT',
  secretAccessKey: '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'
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const UserMetrics = () => {
  const [userMetrics, setUserMetrics] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const fetchUserMetrics = async () => {
    const params = {
      Bucket: S3_BUCKET,
      Key: 'userMetrics.json'
    };

    try {
      const data = await myBucket.getObject(params).promise();
      setUserMetrics(JSON.parse(data.Body.toString('utf-8')));
    } catch (err) {
      if (err.code === 'NoSuchKey') {
        setUserMetrics([]);
      } else {
        console.error('Error fetching data from S3:', err);
      }
    }
  };

  const saveUserMetricsToS3 = async (updatedMetrics) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: 'userMetrics.json',
      Body: JSON.stringify(updatedMetrics),
      ContentType: 'application/json'
    };

    try {
      await myBucket.putObject(params).promise();
      console.log('Successfully saved data to S3');
    } catch (err) {
      console.error('Error saving data to S3:', err);
    }
  };

  const createFunction = (userId) => {
    // Implement your logic to determine the type
    const type = Math.random() > 0.5 ? 1 : 0;
    return type;
  };

  const checkAndAddUser = async (userId) => {
    await fetchUserMetrics();

    const existingUser = userMetrics.find(user => user.userId === userId);

    if (existingUser) {
      setCurrentUser(existingUser);
      return existingUser;
    } else {
      const type = createFunction(userId);
      const newUser = { userId, type };
      const updatedMetrics = [...userMetrics, newUser];
      setUserMetrics(updatedMetrics);
      await saveUserMetricsToS3(updatedMetrics);
      setCurrentUser(newUser);
      return newUser;
    }
  };

  return (
    <div>
      <h1>User Metrics</h1>
      <ul>
        {userMetrics.map(user => (
          <li key={user.userId}>{user.userId}: {user.type}</li>
        ))}
      </ul>
      <div>
        <input type="text" id="userIdInput" placeholder="Enter UserID" />
        <button onClick={() => {
          const userId = document.getElementById('userIdInput').value;
          checkAndAddUser(userId);
        }}>
          Check/Add User
        </button>
      </div>
      {currentUser && (
        <div>
          <h2>Current User</h2>
          <p>UserID: {currentUser.userId}</p>
          <p>Type: {currentUser.type}</p>
        </div>
      )}
    </div>
  );
}

export default UserMetrics;
