import { gql, useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Moment from "moment";
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from "react-router-dom";
import { ReactComponent as NoDataSvgFile } from '../../Images/NoData.svg';
import { logout } from '../../actions/index';


import { Box, Fade } from '@mui/material';
import { FaCheckCircle } from 'react-icons/fa';
import LogTrigger from '../../LogTrigger';
import DailogSettngs from '../Deployments/AppSettings/DailogSettngs';
import { CustomTooltip } from '../Settings/Organization';

export const APPS = gql`
query{
  apps(type: "", first:5, region:"", orgSlug:""){
    nodes{
      id,
      name,
      deployed,
      builtinType,
      imageName,
      status,
      createdAt,
      config{
        definition
      },
      hostname
    }
  }
}`


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  cursor: {
    cursor: "pointer",
  },
  margin: {
    margin: "10px",
  }
}));


function DeploymentTable({ setcostMonitoring, setodv }) {

  const classes = useStyles();
  const [redirectTo, setRedirectTo] = React.useState(null)

  const [searched, setSearched] = React.useState("")
  const [searchValue, setSearchValue] = React.useState("")



  const requestSearch = (searchedVal) => {
    setSearched(searchedVal)
    setSearchValue(searchedVal)

    const filteredRows = data.apps.nodes.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });

    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };


  const [rows, setRows] = React.useState([])
  let history = useHistory();
  const { loading, error, data, refetch } = useQuery(APPS)

  useEffect(() => {
    refetch()
    if (!error && !loading && data.apps.nodes) {

      setRows(
        data.apps.nodes.filter((e) => {
          return e.builtinType !== "DB" && e.builtinType !== "FinOps"
        })
      );

      if(setodv){
          setodv(prevState => ({
        ...prevState,
        dbCount: data.apps.nodes.filter((e) => {
          return e.builtinType === "DB"
        }).length,
        dbMysql: data.apps.nodes.filter((e) => {
          return e.builtinType === "DB" && e.imageName.startsWith("mysql")
        }).length,
        dbPostgres: data.apps.nodes.filter((e) => {
          return e.builtinType === "DB" && e.imageName.startsWith("postgres")
        }).length,
        dbRedis: data.apps.nodes.filter((e) => {
          return e.builtinType === "DB" && e.imageName.startsWith("redis")
        }).length,
        dbMongo: data.apps.nodes.filter((e) => {
          return e.builtinType === "DB" && e.imageName.startsWith("mongo")
        }).length,
        dbMaria: data.apps.nodes.filter((e) => {
          return e.builtinType === "DB" && e.imageName.startsWith("maria")
        }).length,

        appsCount: data.apps.nodes.filter((e) => {
          return e.builtinType !== "DB" && e.builtinType !== "FinOps"
        }).length,

      }));
      }
    

      // if (data && data.apps.nodes && data.apps.nodes.length > 0) {
      //   var item = data.apps.nodes.find(o => o.builtinType === "FinOps")
      //   if (item) {
      //     setcostMonitoring(item)
      //   }
      // }
      // else {
      //   setcostMonitoring(null)
      // }
    }

  }, [data]);
  if (loading) {
    return (
      <div style={{ minHeight: "250px" }}>

        <Skeleton variant="rectangular" height={30} width="30%" style={{ borderRadius: "5px" }} />
        <Skeleton height={30} />
        <Skeleton sx={{ mb: 1 }} variant="rectangular" style={{ borderRadius: "5px" }} height={40} />
        <Skeleton sx={{ mb: 1 }} variant="rectangular" style={{ borderRadius: "5px" }} height={40} />
        <Skeleton sx={{ mb: 1 }} variant="rectangular" style={{ borderRadius: "5px" }} height={40} />


      </div>
    );
  }
  if (error) {

    LogTrigger(error.message, "error", window.location.href, "Deployment Table")
    return `Error! ${error.message}`;
  }

  if (data) {
    LogTrigger(`App lenght - ${data.apps.nodes && data.apps.nodes.length}`, "info", window.location.href, "Deployment Table")
  }
  if (redirectTo) {

    return <Redirect to={`/deployments/overview/${redirectTo}`} />
  }
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"

      >


        <Typography sx={{ fontWeight: "bold", mb: 2 }}>Recent Apps</Typography>

      </Grid>

      <Fade
        in={true}
        style={{ transformOrigin: '0 0 0' }}
        {...(true ? { timeout: 500 } : {})}
      >
        <Table size="small">

          {rows && rows.length > 0 &&
            <TableBody className={classes.cursor}>
              {rows && rows.slice(0, 2).map((item, index) => (
                <Fade
                  key={index}
                  in={true}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(true ? { timeout: 500 * index } : {})}
                >
                  <TableRow key={item.id}>

                    <TableCell align="left" onClick={() => { setRedirectTo(item.name) }}>

                      <div>
                        <CustomTooltip arrow placement="top-start" title={<Box sx={{ p: 1 }}>
                          App name: <b style={{ textTransform: "capitalize" }}>{item.name}</b>
                          <br />
                          Status: <b>{item.status} {item.status === "Active" && "✅"}{item.status === "New" && "☑️"}{item.status === "Suspended" && "❌"}</b>
                          <br />
                          Deployed:<b> {item.deployed ? "Yes 👍" : "No 👎"}</b>
                        </Box>}>
                          <Grid
                            container
                          >
                            <Grid item>
                              <FaCheckCircle style={{ marginTop: "10px", marginRight: "10px", fontSize: "18px", color: item.status === "Active" ? "green" : (item.status === "Suspended" ? "grey" : "rgb(33, 150, 243)") }} />
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                              >
                                <div>

                                  <Typography component={"div"} sx={{ textTransform: "capitalize", fontSize: "0.9rem", cursor: "pointer", '&:hover': { color: item.status === "Active" ? "green" : (item.status === "Suspended" ? "grey" : "rgb(33, 150, 243)") } }} noWrap>
                                    {item.WorkloadManagementName ? <span>
                                      {item.name.substr(item.name.indexOf("-") + 1)}<i style={{ color: "#5d86f9", marginLeft: "3px" }}>({item.name.split("-")[0]})</i>
                                    </span> : item.name}
                                  </Typography>

                                  <Typography sx={{ fontSize: "11px", fontWeight: "normal" }}>
                                    {item.status} {"and "}
                                    {item.deployed ? "Deployed" : "Not deployed"}</Typography>
                                </div>
                              </Grid>
                            </Grid>

                          </Grid>
                        </CustomTooltip>
                      </div>
                    </TableCell>







                    <TableCell align="center" style={{ frontSize: "larger" }}>
                      {item.deployed == false ? "Not Deployed"
                        : <Button endIcon={<OpenInNewIcon />} onClick={() => {
                          window.open(item.hostname.toString()
                            , '_blank')
                        }} className="btn btn-primary">{true && "Open"}</Button>}
                    </TableCell>

                    <TableCell>
                      <DailogSettngs appStatus={item.status} appId={item.name} idOfApp={item.id} appDeployed={item.deployed} />
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Moment(new Date(item.createdAt), "YYYY MM DD hh:mm:ss").fromNow()}
                    </TableCell>

                  </TableRow>
                </Fade>
              ))}

            </TableBody>}

        </Table>
      </Fade>

      {(data.apps.nodes === null || rows.length === 0) && <div style={{ textAlign: "center", marginTop: "20px" }}>
        <NoDataSvgFile style={{ width: "100%", height: "150px", textAlign: "center" }} />
        <Typography component={"span"} style={{ fontWeight: 600, fontSize: "20px" }} gutterBottom>No data available</Typography>
        <Typography component={"span"} style={{ color: "#111" }} variant="button" display="block" gutterBottom>Create your first App</Typography>

      </div>}

      {data.apps.nodes != null && (rows && rows.length !== 0 && <div className={classes.seeMore}>
        <Button color="primary" onClick={() => history.push("/apps")}> See More</Button>
      </div>)}

    </React.Fragment>
  );
}


const mapStateToProps = ({ authReducer }) => {
  return { authReducer }
}
export default connect(mapStateToProps, { logout })(DeploymentTable)