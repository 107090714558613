import CssBaseline from '@mui/material/CssBaseline';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import UpgradePlan from '../../Containers/UpgradePlan';
import BU from "../Settings/BU";
import Nav from "../Settings/Nav";
import { ALL_ORG_LIST } from '../Settings/Organization';
import MembersTable from './MembersTable';
import OrgAppsTable from "./OrgAppsTable";
import Workload from "./Workload";
import AppsTable from '../Home/Apps';
import GrafanaOrgDisplay from './GrafanaOrg';
import SitesTable from '../Home/Sites/SitesTable';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{ padding: "20px 0px 0px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: 3,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    paddingLeft: "10%",
    padding: 5,
    paddingTop: "3%",
  },
  subtitle: {
    flexGrow: 1,
    paddingLeft: "10%",
    padding: 5,
  },
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    height: "auto",
  },
  card: {
    margin: "0px",
  },
  header: {
    height: 150,
    borderRadius: "12px",
    margin: "10px 20px 0px 20px",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  headline: {
    textTransform: "capitalize",
    fontSize: "18px",
    margin: "10px"
  },
  link: {
    display: 'flex',
    textDecoration: "none",
    color: '#5d86f9'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },


}));


function useQueryFetch() {
  return new URLSearchParams(useLocation().search);
}


export default function Dashboard() {

  const classes = useStyles();
  const isOrg = useQueryFetch().get("o")
  const isSubOrg = useQueryFetch().get("s")
  const isBU = useQueryFetch().get("b")

  const [org, setorg] = React.useState(null);
  const [suborg, setsuborg] = React.useState(null);
  const [bu, setbu] = React.useState(null);
  const [whichOrg, setwhichOrg] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [show] = React.useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReset = () => {
    setValue(0)
  }



  const handleCheck = (e) => {

    var orgcheck = orgdata && orgdata.organizationsandBusinessUnit.nodes && orgdata.organizationsandBusinessUnit.nodes.find(o => o.name === e)
    var bucheck = orgdata && orgdata.organizationsandBusinessUnit.businessUnit && orgdata.organizationsandBusinessUnit.businessUnit.find(o => o.name === e)

    if (orgcheck && orgcheck.parentId) {
      setsuborg(orgcheck)
    }
    else {
      setorg(orgcheck)
    }
    if (bucheck) {
      setbu(bucheck)
    }

  }

  const { data: orgdata, refetch } = useQuery(ALL_ORG_LIST, {
    variables: { userId: localStorage.getItem("userId") },
  }
  );


  useEffect(() => {
    refetch()
    document.title = "Organization"
    var temp = [];

    if (isOrg) {
      handleCheck(isOrg)
      temp.push(isOrg)
    }
    // if (isOrg && isSubOrg) {
    //   handleCheck(isSubOrg)
    //   temp.push(isSubOrg)
    // }
    // if (isOrg && isSubOrg && isBU) {
    //   handleCheck(isBU)
    //   temp.push(isBU)
    // }

    setwhichOrg(isOrg);
  }, [show, orgdata, isOrg]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Nav />
        <Container maxWidth={false} className={classes.container}>
          {/* Org */}
          {isOrg && !isBU && !isSubOrg && <>
            {<Box sx={{ width: '100%' }}>

              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Apps" {...a11yProps(0)} />
                  <Tab label="Sites" {...a11yProps(1)} />
                  <Tab label="Members" {...a11yProps(2)} />
                  <Tab label="Metrics" {...a11yProps(3)} />
                  <Tab label="Workload" {...a11yProps(4)} />

                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <AppsTable filterOrg={true} orgId={isOrg} />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <SitesTable filterOrg={true} orgId={isOrg} />
              </TabPanel>


              <TabPanel value={value} index={2}>
                <MembersTable orgname={isOrg} />
              </TabPanel>

              <TabPanel value={value} index={3}>
                <GrafanaOrgDisplay org={org} />
              </TabPanel>

              <TabPanel value={value} index={4}>
                <Workload org={org} suborg={suborg} bu={bu} />
              </TabPanel>


            </Box>}
          </>}

          {/* Bu  */}
          {/* {isOrg && isBU && !isSubOrg && <>
            {<Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Apps" {...a11yProps(0)} />
                  <Tab label="Members" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <OrgAppsTable buname={isBU} org={isOrg} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <MembersTable orgname={isOrg} />
              </TabPanel>
            </Box>}
          </>} */}

          {/* {isOrg && isBU && isSubOrg && <>
          
            {<Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Apps" {...a11yProps(0)} />
                  <Tab label="Members" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <OrgAppsTable buname={isBU} org={isOrg}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <MembersTable orgname={isOrg} />
              </TabPanel>
            </Box>}
          </>} */}

          {/* sub org */}
          {/* {isOrg && isSubOrg && !isBU && <>
            
              <Box sx={{ width: '100%' }}>


                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Apps" {...a11yProps(0)} />
                    <Tab label="Business Unit" {...a11yProps(1)} />
                    <Tab label="Members" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <OrgAppsTable soname={isSubOrg} org={isOrg} />
                </TabPanel>

                <TabPanel value={value} index={1}>
                  {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).subOrganizationCount === 0) ?
                    <UpgradePlan /> :

                    <BU orgname={whichOrg} orgName={isOrg} subOrg={isSubOrg} reset={handleReset} isBU={true} />}
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <MembersTable orgname={isOrg} />
                </TabPanel>
              </Box>
          </>} */}
        </Container>
      </main>
    </div>
  );
}