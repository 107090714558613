import React , { Component } from 'react'
import { connect } from 'react-redux'
import { Route , Redirect } from "react-router-dom"


class PrivateRoute extends Component {
    render(){
        const { component : Component , ...rest } = this.props
        const { isAuthenticated} = this.props.authReducer
        
        return (
            <Route {...rest } render = {(props) => {
                if (isAuthenticated ){
                    return <Component {...props}/>
                }
                else {
                    return <Redirect to="/"/>
                }
            }}/>
        )
    }
}





const mapStateToProps = ({ authReducer }) => {
    return { 
        authReducer
    }
}   

export default connect(mapStateToProps)(PrivateRoute)