import { MoreHoriz } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};




export default function OrgDailogSettings(props) {
    const role = localStorage.getItem("role")
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    const plan = JSON.parse(localStorage.getItem("access")).planName
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

  





    const handleClickOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    if (!permissions) return "You don't have permission to access."

    return (
        <div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {!props.isBU && <MenuItem onClick={() => {
                    handleCloseMenu();
                    props.editOrg(props.value);

                }}>

                    Edit Organization</MenuItem>}

             

                {!props.isSubOrg && !props.isBU && <MenuItem disabled={!permissions[0].delete ? true : props.datalen<=1 && true} onClick={() => {
                    props.migrateOrganization(props.value);
                    handleCloseMenu();
                }}> {props.datalen>1 || plan==="free plan" || plan==="Starter" ? "Migrate Organization" : " Cannot Migrate With Single Organization"} {plan==="free plan" || plan==="Starter" ? "(Upgrade to Premium plan or more)" : !permissions[0].delete ? "(You don't have permission)" : true}</MenuItem>}

                {permissions[0].delete &&
                    <MenuItem disabled={props.datalen && props.datalen === 1} style={{ color: "#e95a5a" }} onClick={() => props.deleteOrg(props.value)}>
                        {props.isBU ? "Delete Business Unit" : "Delete Organization"}
                    </MenuItem>}
            </Menu>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                disabled={role==="Viewer"}
                onClick={handleClickOpenMenu}
                size="large">
                <MoreHoriz/>
            </IconButton>
            

            <Dialog
                open={open}
                fullWidth={true}
                onClose={handleClose}
                maxWidth={"md"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                   


                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}






