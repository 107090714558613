import React, { useEffect } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';

const NifeLineChart = ({data,type}) => {
  useEffect(() => {
  }, [data]);

  if(!data){
    return "No data here..";
  }
  return (
    <ResponsiveContainer width="100%" height={100}>
      <AreaChart data={data}>
        <XAxis dataKey="time" />
        <YAxis width={20}/>
        <Tooltip cursor={{fill: 'transparent'}} />
        
        {type === "app" && <Area   name='App Created' type="monotone" dataKey="appCreated" stroke="#a976f1" fill="#a976f1b5" />}
        {type === "app" && <Area   name='App Deployed' type="monotone" dataKey="appDeployed" stroke="#4dfbe0" fill="#4dfbe0b5" />}

        {type === "site" && <Area   name='Site Deployed' type="monotone" dataKey="siteDeployed" stroke="#4dfbe0" fill="#4dfbe0b5" />}
     
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default NifeLineChart;
