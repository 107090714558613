import React, { useEffect } from 'react'
import CreateNewSite from './CreateNewSite'
import { Box, Button, Fade, Grid, IconButton, InputAdornment, Paper, Skeleton, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { Close, OpenInNew, Search } from '@mui/icons-material';
import EmptyPageRenderCustom from '../../NewPages/EmptyPageRenderCustom';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { FaCode } from 'react-icons/fa';
import { CustomTooltip } from '../../Settings/Organization';
import moment from 'moment';
import FrameworksList from "./frameworksnew.json"
import SiteActions from './SiteActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IoIosPeople, IoMdPeople } from 'react-icons/io';

export const DELETE_SITE = gql`
mutation DeleteSiteMutation($s3AppName: String){
    deleteS3Deployment(s3AppName: $s3AppName)
  }
`

export const SITES_LIST = gql`
query sitesList{
    getAllS3deployments {
        id
        s3AppName
        status
        appUrl
        buildCommandsS3 {
          s3Cmd
        }
        envVariablesS3 {
          name
          value
        }
        organizationId
        orgDetails {
            id
            name
            type 
          }
          userDetails {
            id
            email
            firstName
            lastName
            profileImageUrl 
            roleId
            userProfileCreated
           }
        deploymentTime
        buildTime
        createdBy
        createdAt
      }
}`
const SupportedAppsList = [

    {
        "name": "React",
        "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/768px-React-icon.svg.png"
    },
    {
        "name": "Angular",
        "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/2048px-Angular_full_color_logo.svg.png"
    },

    {
        "name": "vite",
        "image": "https://vitejs.dev/logo.svg"
    },
    {
        "name": "Vue.js",
        "image": "https://api-frameworks.vercel.sh/framework-logos/vue.svg"
    },

    {
        "name": "Remix",
        "image": "https://api-frameworks.vercel.sh/framework-logos/remix-no-shadow.svg"
    },

    {
        "name": "Ember.js",
        "image": "https://api-frameworks.vercel.sh/framework-logos/ember.svg"
    },
]

const columnsSite = [
    {
        field: "s3AppName",
        headerName: "Name",
        width: 200,
        align: "left",
        renderCell: (cellValues) => {
            return (<span style={{ textTransform: "capitalize" }}> <IconButton style={{ padding: "0px 0px 0px 0px", marginRight: "10px" }} ><FaCode color='primary' style={{ fontSize: "18px" }} /></IconButton> {cellValues.value}
                {/* <i style={{ color: "#5d86f9", marginLeft: "2px" }}>({cellValues.row.environmentEndpoint})</i> */}
            </span>)
        }
    }
    ,
    {
        field: "status",
        headerName: "Status",

        align: "center",
        renderCell: (cellValues) => {
            if (cellValues.value == "Active") {
                return (<div style={{ color: "green", background: "rgb(229, 252, 235)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
            }

            if (cellValues.value == "New") {
                return (<div style={{ color: "rgb(33, 150, 243)", background: "rgb(227, 242, 253)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
            }
        }
    }
    ,
    {
        field: "orgDetails", headerName: "Organization", width: 200, align: "center", sortable: false,
        renderCell: (cellValues) => {
            return (<div style={{ color: "#3d5066", textTransform: "capitalize" }}>
                <IconButton style={{ padding: "0px", margin: "0px 5px 0px 5px" }}>
                    <IoIosPeople style={{ fontSize: "18px" }} />
                </IconButton>{cellValues.value.name}</div>)
        }
    },
    {
        field: "appUrl",
        headerName: "Link",

        align: "center",
        renderCell: (cellValues) => {

            return (
                <div
                    style={{
                        textAlign: "left"
                    }}
                >
                    {cellValues.value == "nife.io" ? ("Not Deployed") : (
                        <div>
                            {cellValues.value ? <Button endIcon={<OpenInNew />}

                                size="small"
                                onClick={() => {
                                    window.open(`http://${cellValues.row.s3AppName}.static.nifetency.com`, '_blank')
                                }}
                            >open</Button> : "No link"}</div>)}
                </div>
            );

        }
    },
    {
        field: "action",
        headerName: "Action",
        align: "center",
        renderCell: (cellValues) => {
            var name = cellValues.row.s3AppName;
            return <SiteActions appName={name} />
        }
    }
    ,
    {
        field: "createdAt",
        headerName: "Created At",
        width: 200,
        align: "left", renderCell: (cellValues) => {
            var date = moment(new Date(cellValues.row.createdAt), "YYYY MM DD hh:mm:ss").fromNow();
            return <CustomTooltip arrow placement="top-start" title={date}>
                <Typography sx={{ color: "grey", fontSize: "12px" }}> {date}</Typography>
            </CustomTooltip>
        }
    }
]

const getFrameworkLogos = () => {
    const filteredFrameworks = FrameworksList.map(framework => ({
        name: framework.name,
        image: framework.logo
    }));

    return filteredFrameworks;
};

export default function SitesTable(props) {
    const [searched, setsearched] = React.useState("");
    const [openCreateNewSite, setopenCreateNewSite] = React.useState(false);

    let history = useHistory()

    const handleCellClick = (param, event) => {
        if ((param.colDef.field !== "action") && (param.colDef.field !== "__check__") && (param.colDef.field !== "appUrl")) {
            history.push(`/site/${param.row.s3AppName}`)
        }

        if (param.colIndex === 2) {
            event.stopPropagation();
        }
    };



    const [fetchSites, { loading, error, data }] = useLazyQuery(SITES_LIST, {
        onCompleted: (data) => {
        }
    });

    const logos = getFrameworkLogos();

    useEffect(() => {
        fetchSites()
        return () => {

        };
    }, [openCreateNewSite]);

    if (loading) {
        return <>
            <Skeleton style={{ margin: "10px" }} />
            <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
        </>
    }
    return (
        <div>

            {data && data.getAllS3deployments ? <Box>
                <Box>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                        sx={{ mb: 2 }}
                    >
                        <TextField
                            value={searched}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searched !== "" &&
                                            <IconButton onClick={() => setsearched("")}>
                                                <Close />
                                            </IconButton>}
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}

                            size={"small"}

                            placeholder='Search for Sites'
                            onChange={(e) => setsearched(e.target.value)}
                        />
                        <CreateNewSite />
                    </Grid>
                </Box>
                <Paper variant='outlined' style={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px", borderRadius: "12px" }}>
                    <Box style={{ width: "100%", margin: "0px 0px 0px 0px", padding: "10px" }}>
                        {data.getAllS3deployments && <DataGrid
                            dense
                            rowHeight={70}
                            autoHeight={true}
                            density={"compact"}
                            scrollbarSize={1}
                            rows={
                                data.getAllS3deployments ? data.getAllS3deployments
                                    .filter((e) => {
                                        // Check if the deployment matches the search term
                                        const matchesSearch = e.s3AppName.includes(searched);

                                        // If props.filterOrg is true, further filter by orgId
                                        const matchesOrg = !props.filterOrg || e.orgDetails.name === props.orgId;

                                        // Return true only if both conditions are met
                                        return matchesSearch && matchesOrg;
                                    })
                                    : []
                            }

                            disableColumnFilter={true}
                            columns={columnsSite}
                            pageSize={10}
                            onCellClick={handleCellClick}
                            sx={{
                                display: "flex",
                                border: "2px",
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                    width: '0.4em',
                                    height: '0.4em',
                                    borderRadius: "24px"
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#cdcdcd',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                                    background: '#160044',
                                },
                            }}
                        />
                        }
                    </Box>
                </Paper>
            </Box> : <Fade in={true} mountOnEnter unmountOnExit style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: 1000 } : {})}>
                <div style={{ margin: "10px" }}>
                    <CreateNewSite hideButton={true} openCreateNewSite={openCreateNewSite} setopenCreateNewSite={setopenCreateNewSite} />
                    <EmptyPageRenderCustom
                        mainTitle={<>Create a site {props.filterLocation && ``} {props.filterOrg && `inside ${props.orgId.toString().toLowerCase()}`}</>}
                        primaryButtonText={"New Site"}
                        primaryButtonAction={() => setopenCreateNewSite(true)}
                        secondaryTitle="Create, configure, deploy, and customize web sites with ease."
                        learnMoreLink="https://docs.nife.io/docs/UI%20Guide/Site%20deployment/Build-File-Deployment"
                        iframeLink="https://www.youtube.com/embed/A9KMteB0N-E?si=aQodNR1cMJqHhmZb"
                        imageList={SupportedAppsList}
                        imageListTitle="Supported Framework"

                    />
                </div>
            </Fade>}

        </div>
    )
}
