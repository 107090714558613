import { useMutation, useQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { ADD_ORG } from "../SettingsTab";

import { Box, Fade, Skeleton, Tooltip, Typography } from '@mui/material';
import { SiWheniwork } from 'react-icons/si';
import UpgradePlan from '../../../Containers/UpgradePlan';
import LogTrigger from '../../../LogTrigger';
import { WORKLOAD_LIST } from '../../Org/Workload';
import { ALL_ORG_LIST, ORG_LIST } from '../../Settings/Organization';
import { useSnackbar } from 'notistack';
import { Check, CheckOutlined } from '@mui/icons-material';
import { FaCheckCircle } from 'react-icons/fa';

export default function S1(props) {
    const [newOrgName, setnewOrgName] = useState("")
    const [isProgress, setisProgress] = useState(false)
    const [opensuborg, setopensuborg] = useState(false)
    const [row, setrow] = useState([]);
    const [stop, setstop] = useState(true);
    const [value, setValue] = React.useState(row[0]);
    const [inputValue, setInputValue] = React.useState('');

    var worklaodOrgId = ""
    if (value) {
        if (value.parentId) {
            worklaodOrgId = value.parentId
        }
        else {
            if (value.region) {
                worklaodOrgId = value.id
            }
            else {
                worklaodOrgId = value.orgId
            }
        }

    }

    const { loading: loadwl, error: errwl, data: wldata, refetch: refetchwl } = useQuery(WORKLOAD_LIST, {
        variables: { orgId: worklaodOrgId, subOrgId: "", businessUnitId: "" },
    });


    const { loading: loadorg, error: errorg, data: orgdata, refetch } = useQuery(ALL_ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
        onCompleted: () => {

        }
    }
    );

    const [createOrg, { data: data, loading: loading, error: error }] = useMutation(ADD_ORG, {
        refetchQueries: [ORG_LIST, ALL_ORG_LIST],
        onError: (err) => {

        },
        onCompleted: () => {
            enqueueSnackbar("Organization created!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            sethideCreateOrg(false)
            setnewOrgName("")
            setisProgress(false)
            LogTrigger("Organization created", "info", window.location.href, "StepOne")

        }
    });




    const handleComplete = (e) => {
        if (value.parentId) {
            //sub org

            props.isError({ 'orgid': value.parentId, 'suborg': value.id, 'buiid': "" }, e, [value.parentOrgName, value.name])
        }
        else {
            if (value.region) {
                //org

                props.isError({ 'orgid': value.id, 'suborg': "", 'buiid': "" }, e, [value.name])
            }
            else {
                // bu

                props.isError({ 'orgid': value.orgId, 'suborg': value.subOrgId, 'buiid': value.id }, e, [value.orgName, value.subOrgName, value.name])
            }
        }



    }




    if (value && !loadwl && stop) {
        setstop(false)
        if (wldata && wldata.getWorkloadMangementByorgnizationId === null) {
            handleComplete("");
        }

    }




    const handleCreateNewOrg = (e) => {
        e.preventDefault()
        setisProgress(true)
        createOrg({ variables: { name: newOrgName } });
    }

    const handleOrgName = (e) => {
        setnewOrgName(e.target.value)
    }
    const [hideCreateOrg, sethideCreateOrg] = React.useState(false)

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        refetch()
        setrow([])
        if (orgdata) {

            if (orgdata.organizationsandBusinessUnit.nodes) {
                orgdata.organizationsandBusinessUnit.nodes.forEach(element => {
                    if (element.parentId === "") {
                        setrow(row => [...row, element])
                        if ((element.type === "true" || element.type === "1")  && props.deployApp) {
                            setValue(element);
                            setInputValue(element);

                        }
                    }

                })
            }

        }
    }, [orgdata]);

    if (errorg) {
        LogTrigger(errorg.message, "error", window.location.href, "Create Org")
    }
    if (loadorg) return <Fade in={true}>
        <Typography style={{ textAlign: "left", margin: "10px" }}><CircularProgress size="15px" /> Fetching organization...</Typography>
    </Fade>;
    if (errorg) return errorg.message
    return (<div>



        <Grid container alignItems="center" >
            <Grid item sm={8}>
                <div className='demo__select_org'>
                    {orgdata && (<>
                        {orgdata.organizationsandBusinessUnit.nodes && (<>



                            {orgdata.organizationsandBusinessUnit.nodes && !(props.deployApp && props.deployApp) && 

                                <Autocomplete
                                    id="combo-box-demo"
                                    value={value}
                                    inputValue={inputValue}
                                    loading={loadorg}
                                    disabled={props.isAppDisable}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    onChange={(e, newValue) => {
                                        setValue(newValue);
                                    }}
                                    options={row}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Tooltip title={option.type === "true" ? "Default organization" : "Organization"}>
                                            <Box key={option.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.name}
                                                {option.type === "true" ? <b style={{ color: "rgb(33, 150, 243)", border: "1px solid rgb(33, 150, 243)", background: "rgb(227, 242, 253)", marginLeft: "5px", padding: "2px 10px 2px 10px", borderRadius: "12px", fontSize: "12px" }}>Default</b> : <Chip sx={{ ml: 1 }} size='small' label={"Organization"} />}
                                            </Box>
                                        </Tooltip>


                                    )}


                                    style={{ width: 300, marginBottom: "10px" }}
                                    renderInput={(params) => <TextField {...params} placeholder="Search Organization" variant="outlined" />}
                                />

                            }
                            {props.deployApp &&
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <FaCheckCircle style={{ fill: "green", fontSize: "13px", marginRight: "5px" }} />
                                    <span>Selected default Organization <b>{inputValue && inputValue.name}</b></span>
                                </Grid>
                            }
                        </>)}
                    </>)}
                </div>






            </Grid>
            {!opensuborg && <Divider orientation="vertical" flexItem style={{ marginRight: "20px" }} />}
            {!opensuborg && <div>

                {hideCreateOrg && <> <Grid item sm={12}>
                    <Grow in={true}>
                        <div>

                            <form >
                                {orgdata && localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).organizationCount <= orgdata.organizationsandBusinessUnit.nodes.length ? <span>You've reached your maximum limit of current plan. Please upgrade your plan to create Organization<UpgradePlan invite={true} /></span> : <div>
                                    <h3> Create New Organization</h3>
                                    <TextField required id="outlined-basic" style={{ width: "300px" }} label="Enter Organization name" variant="outlined" value={newOrgName} onChange={handleOrgName} />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ color: "white", height: "55px", margin: "0px 10px 20px 10px" }}
                                        onClick={handleCreateNewOrg}
                                        disableElevation>{isProgress ? <CircularProgress style={{ color: "white" }} /> : "Create"}</Button>
                                </div>}
                            </form>
                        </div>
                    </Grow>
                </Grid>
                </>}
                <Button color="primary" disabled={props.isAppDisable} onClick={() => sethideCreateOrg(!hideCreateOrg)} disableElevation>{!hideCreateOrg ? "Create New Organization" : "Hide"}</Button>
            </div>}



            {inputValue && loadwl &&
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}

                >
                    <Grid item>
                        <Skeleton
                            variant="rounded"
                            width={150}
                            height={30} />
                    </Grid>
                    <Grid item>
                        <Skeleton
                            variant="rounded"
                            width={150}
                            height={30} />
                    </Grid>
                    <Grid item>
                        <Skeleton
                            variant="rounded"
                            width={150}
                            height={30} />
                    </Grid>


                </Grid>}

            {wldata && wldata.getWorkloadMangementByorgnizationId && <Grid container>
                <Grid item sm={12}>
                    <Typography variant='body2' style={{ color: "grey", marginBottom: "10px", marginTop: "15px" }}> Select Workload</Typography>
                    <Button disabled={!props.name || props.isAppDisable} style={{ margin: "0px 3px 3px 0px", textTransform: "capitalize" }} variant="contained" disableElevation size='small'

                        onClick={() => {
                            handleComplete("");

                        }} >Continue Without Workload</Button>
                    {wldata && wldata.getWorkloadMangementByorgnizationId && wldata.getWorkloadMangementByorgnizationId.map((e, i) => (
                        <Button disabled={!props.name || props.isAppDisable} style={{ margin: "0px 3px 3px 0px", textTransform: "capitalize" }} variant="outlined" size='small'
                            startIcon={<SiWheniwork />}
                            onClick={() => {
                                handleComplete(e);

                            }} >{e.environmentName}{" "}({e.environmentEndpoint}-{props.name})</Button>
                    ))
                    }
                </Grid>

            </Grid>}



        </Grid>


    </div >)
}