import { gql, useMutation, useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { APP_LIST } from "../../Home/Apps";
import { APPS } from "../../Home/DeploymentTable";
import { APP_LOCATION_LIST } from "../Deploy/Main";

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Countdown from "react-countdown";
import { Redirect, useRouteMatch } from "react-router-dom";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import ReactCountryFlag from "react-country-flag";
import LogTrigger from '../../../LogTrigger';
const PAUSE_APP = gql`
mutation PauseRegions($appId: String!, $regionCode: String!){
  pauseApp(input: {appId: $appId, regionCode: $regionCode}){
  app{
  	id
  }
  }
}`

const RESUME_APP = gql`
mutation ResumeRegions($appId: String!, $regionCode: String!){
  resumeApp(input: {appId: $appId, regionCode: $regionCode}){
  app{
  	id
  }
  }
}`

const REGION_STATUS = gql`
	query RegionStatus($appId: String!){
  getRegionStatus(appId: $appId){
    nodes{
      id
      regionCode
      status
    }
  }
}
`

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function SuspendApp(props) {
	const history = useHistory()


	const role = localStorage.getItem("role")

	const [open, setOpen] = React.useState(false);
	const [isChange, setIsChange] = React.useState(false);
	const [selectedApp, setSelectedApp] = React.useState();
	const [isActive, setIsActive] = React.useState(false)
	const [appId, setappId] = React.useState("");

	const classes = useStyles();
	const [checked, setChecked] = React.useState(['wifi']);

	function dynamicSort(property) {
		var sortOrder = 1;

		if (property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
		}

		return function (a, b) {
			if (sortOrder == -1) {
				return b[property].localeCompare(a[property]);
			} else {
				return a[property].localeCompare(b[property]);
			}
		}
	}


	const handleToggle = (value, status) => () => {
		setIsChange(true)

		setSelectedApp(value)
		if (status == "running") {
			setIsActive(true)
		}
		else {
			setIsActive(false)
		}
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};


	const [pauseApp] = useMutation(PAUSE_APP, {
		refetchQueries: [APP_LOCATION_LIST, APPS, APP_LIST, REGION_STATUS],
		onCompleted: () => {
			setIsChange(false)
			LogTrigger(`App Paused`, "info", window.location.href, "SUSPEND_APP")
		}
	})

	const [resumeApp] = useMutation(RESUME_APP, {
		refetchQueries: [APP_LOCATION_LIST, APPS, APP_LIST, REGION_STATUS],
		onCompleted: () => {
			setIsChange(false)
			LogTrigger(`App Resumed`, "info", window.location.href, "SUSPEND_APP")
		}
	})
	const match = useRouteMatch();
	const handleDelete = () => {
		if (isActive) {
			pauseApp({ variables: { appId: appId, regionCode: selectedApp } })
		}
		else {
			resumeApp({ variables: { appId: appId, regionCode: selectedApp } })
		}
	}

	const findCodeToName = (code) => {
		var name = ""
		data.app.regions.forEach((item, key) => {
			if (code == item.code) {
				name = item.name
			}
		})

		return name
	}

	const handleClickOpen = (item) => {
		setOpen(true);
	};
	const handleCancel = () => {
		setIsChange(false)
	}
	const handleClose = () => {

		setOpen(false);
	}
	const { loading: load, error: err, data: status } = useQuery(REGION_STATUS, { variables: { appId: appId } });

	const { loading, error, data } = useQuery(APP_LOCATION_LIST, { variables: { name: appId } });

	useEffect(() => {

		if (props.appId) {
			setappId(props.appId)
		}
		else {
			setappId(match.params.id)
		}

	}, []);

	
	if (loading) {
		return (
			<div>
				<Skeleton width="30%" style={{ margin: "10px" }} />
				<Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
			</div>
		);
	}
	if (error) return `Error! ${error.message}`;
	if (err) {
		LogTrigger(err.message, "error", window.location.href, "REGION_STATUS q")
	}

	if (data.app.status == "Terminated") {
		const renderer = ({ hours, minutes, seconds, completed }) => {
			if (completed) {
				return <Redirect to='/teamboard' />
			} else {
				return (
					<Typography variant="body1" gutterBottom style={{ margin: "20px" }}></Typography>

				);
			}
		};
		return (
			<Countdown date={Date.now() + 5000} renderer={renderer} />);

	}
	if (load) return 'Loading...';
	if (err) return `Error! ${err.message}`;
	if (data && data.app.regions && data.app.regions.length > 0) return (

		<div style={{ margin: "20px" }}>

			<Dialog
				open={open}
				fullWidth
				PaperProps={{
					elevation: 0,
					sx: {
						borderRadius: "12px",
						border: "solid 1px gray",
						boxShadow: "#000 5px 5px, #000 10px 10px"

					}
				}}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>

				<DialogTitle id="alert-dialog-slide-title">{isChange ? "Are you sure?" : `Regions`}</DialogTitle>
				<DialogContent>

					{isChange ?
						<Typography style={{ margin: "0px" }}>Do you want to {isActive ? "Suspend" : "Activate"} <b style={{ color: "#6C63FF" }}>{appId}</b> from <b style={{ color: "#6C63FF" }}>{selectedApp}</b>?</Typography> :
						(<List className={classes.root} dense={true} fullWidth>

							{data.app.regions == null ? ("No regions") :
								<div>
									{status.getRegionStatus.nodes.map(function (item, i) {
										var name = findCodeToName(item.regionCode);
										return <ListItem>
											<ListItemIcon>
												<ReactCountryFlag
													countryCode={item.regionCode.slice(0, 2)}
													svg
													style={{
														width: '2em',
														height: '2em',
													}}
													title={item.regionCode}
												/>

											</ListItemIcon>
											<ListItemText id="switch-list-label-wifi"
												primary={name}
												secondary={item.status == "running" ? <div style={{ display: "flex" }}>running <FiberManualRecordIcon style={{ color: "green", height: "15px", width: "15px" }} size={2} /></div> : <div>suspended <FiberManualRecordIcon style={{ color: "grey", height: "15px", width: "15px" }} size={2} /></div>} />
											<ListItemSecondaryAction>
												<Switch
													edge="end"
													color="primary"
													disabled={role==="Viewer"}
													onChange={handleToggle(item.regionCode, item.status)}
													checked={item.status == "running" ? true : false}
													inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
												/>
											</ListItemSecondaryAction>
										</ListItem>
									})}
								</div>
							}
						</List>)}

				</DialogContent>

				<DialogActions>
					<div style={{ textAlign: "center", marginBottom: "10px" }}>
						{isChange ? (<div><Button style={{ textAlign: "center", marginRight: "10px", borderRadius: "25px" }} onClick={handleCancel}>
							No, Cancel
						</Button>
							<Button style={{ textAlign: "center", marginRight: "10px", borderRadius: "25px", color: "#E95A5A" }} onClick={e => { handleDelete() }} color="primary">
								{isActive ? "Yes, turn it off" : "Yes, turn it On"}
							</Button></div>) :
							(<Button style={{ textAlign: "center", marginRight: "10px", borderRadius: "25px" }} onClick={handleClose} color="primary">
								Close
							</Button>)}
					</div>

				</DialogActions>
			</Dialog>

			{!props.appId ?
				<Grid container>

					<Grid item lg={4}>
						<h2 style={{ color: "grey" }}>{props.status === "Suspended" ? "Activate" : "Suspend"} App</h2>
						<Typography variant="body2" gutterBottom style={{ color: "grey" }}>
							Your apps wil be {props.status === "Suspended" ? "Active" : "Suspended"}.
						</Typography>
						<a target='_blank' href={"https://docs.nife.io/docs/UI%20Guide/Apps-&-their-Management/App-management/suspend"}>
							Read More
						</a>

					</Grid>
					<Grid item lg={8}>
						<Typography variant="overline" display="block" gutterBottom>
							You have {data.app.regions ? data.app.regions.length : 0} Regions
						</Typography>
						<Button variant="outlined" onClick={(e) => { handleClickOpen() }}> Show Regions</Button>

					</Grid>
				</Grid>


				: <div>
					<div>


						{isChange ?
							<Typography>Do you want to {isActive ? "Suspend" : "Activate"} <b style={{ color: "#160044" }}>{appId}</b> from <b style={{ color: "#160044" }}>{selectedApp}</b>?</Typography> :
							(<List className={classes.root} fullWidth>

								{data.app.regions == null ? ("No regions") : data.app.regions.map((item, key) => (
									<div>
										{status.getRegionStatus.nodes.map(function (item, i) {
											var name = findCodeToName(item.regionCode);
											return <ListItem>
												<ListItemIcon>
													<ReactCountryFlag
														countryCode={item.regionCode.slice(0, 2)}
														svg
														style={{
															width: '2em',
															height: '2em',
														}}
														title={item.regionCode}
													/>

												</ListItemIcon>
												<ListItemText id="switch-list-label-wifi"
													primary={name}
													secondary={item.status == "running" ?

														<Grid
															container
															direction="row"
															justifyContent="flex-start"
															alignItems="center"
														>
															Running <FiberManualRecordIcon color="success" style={{ fontSize: "12px" }} />
														</Grid>
														: <div>suspended <FiberManualRecordIcon style={{ color: "grey", height: "15px", width: "15px" }} size={2} /></div>} />
												<ListItemSecondaryAction>
													<Switch
														edge="end"
														color="primary"

														onChange={handleToggle(item.regionCode, item.status)}
														checked={item.status == "running" ? true : false}
														inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
													/>
												</ListItemSecondaryAction>
											</ListItem>
										})}
									</div>
								))}
							</List>)}

					</div>



					{isChange && (<div style={{ marginTop: "20px" }}>
						<DialogActions>
							<Button fullWidth color="primary" onClick={handleCancel}>
								No, Cancel
							</Button>
							<Button variant='contained' disableElevation fullWidth color="error" onClick={e => { handleDelete() }}>
								{isActive ? "Yes, turn it off" : "Yes, turn it On"}
							</Button>
						</DialogActions>
					</div>)}


				</div>}

		</div>

	)
	else {
		return null;
	}
}	