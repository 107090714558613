import React, { useState } from 'react';

function ThemeCustomizer() {
  // Initial theme state
  const [theme, setTheme] = useState({
    primaryColor: '#2196f3',
    fontFamily: 'Arial, sans-serif',
    borderRadius: 5,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
  });

  // Event handlers for updating theme
  const handleColorChange = (color) => {
    setTheme({ ...theme, primaryColor: color });
  };

  const handleFontChange = (font) => {
    setTheme({ ...theme, fontFamily: font });
  };

  const handleRadiusChange = (radius) => {
    setTheme({ ...theme, borderRadius: radius });
  };

  const handleBoxShadowChange = (shadow) => {
    setTheme({ ...theme, boxShadow: shadow });
  };

  return (
    <div>
      {/* Color picker */}
      <input
        type="color"
        value={theme.primaryColor}
        onChange={(e) => handleColorChange(e.target.value)}
      />

      {/* Font selector */}
      <select
        value={theme.fontFamily}
        onChange={(e) => handleFontChange(e.target.value)}
      >
        <option value="Arial, sans-serif">Arial</option>
        <option value="Helvetica, sans-serif">Helvetica</option>
        {/* Add more font options */}
      </select>

      {/* Slider for border radius */}
      <input
        type="range"
        min="0"
        max="20"
        value={theme.borderRadius}
        onChange={(e) => handleRadiusChange(parseInt(e.target.value))}
      />

      {/* Input for box shadow */}
      <input
        type="text"
        value={theme.boxShadow}
        onChange={(e) => handleBoxShadowChange(e.target.value)}
      />

      {/* Live preview */}
      <div
        style={{
          backgroundColor: theme.primaryColor,
          fontFamily: theme.fontFamily,
          borderRadius: `${theme.borderRadius}px`,
          boxShadow: theme.boxShadow,
          width: '200px',
          height: '200px'
        }}
      >
        Live Preview
      </div>
    </div>
  );
}

export default ThemeCustomizer;
