import { CheckCircle } from '@mui/icons-material';
import { Grid, LinearProgress, Paper, Divider, List, ListItem, ListItemText,IconButton,Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AiFillCloseSquare } from 'react-icons/ai';
import { FaMinus } from 'react-icons/fa';
import { GoAlertFill } from 'react-icons/go';
import { MdOpenInNew } from 'react-icons/md';

export function MetricsCard({ data }) {
    return (
        <Paper variant='outlined' sx={{ borderRadius: "12px", p: 2, pb: 0.5 }}>
            <Typography variant='body2' sx={{ fontWeight: "bold" }}>{data.title}</Typography>
            <Typography variant='body2' sx={{fontSize:"12px", color:"grey"}}>{data.subtitle}</Typography>
            <Typography variant='h4' sx={{ my: 1, fontWeight: "bold" }}>{data.value}</Typography>
            {data.noLinearProgress && <Box>

           
            <Typography variant='body2' sx={{ mb: 0, fontSize: "13px", color: data.status > 0.9 ? "#080" : data.status < 0.5 ? "#c00" : "#FF581D" }}>{data.status > 0.9 ? "Good" : data.status < 0.5 ? "Poor" : "Avarage"} ({data.status * 100}%)</Typography>
            {data.status > 0.9 ?
                <>
                    <FaMinus style={{ color: "#0c6", fontSize: "30px" }} />
                    <FaMinus style={{ color: "#0c6", fontSize: "30px" }} />
                    <FaMinus style={{ color: "#0c6", fontSize: "30px" }} />

                </>
                : data.status < 0.5 ?
                    <>
                        <FaMinus style={{ color: "#f33", fontSize: "30px" }} />

                    </>
                    :
                    <>
                        <FaMinus style={{ color: "#fa3", fontSize: "30px" }} />
                        <FaMinus style={{ color: "#fa3", fontSize: "30px" }} />


                    </>}
                    </Box>}

        </Paper>
    )
}
const SpeedInsightsLayout = ({ urltocheck }) => {
    const [pageSpeedData, setPageSpeedData] = useState(null);

    const [mdv, setmdv] = useState({
        lpc: 0,
        lcpScore: 0,
        fcp: 0,
        fcpScore: 0,
        si: 0,
        siScore: 0,
        cls: 0,
        clsScore: 0,
        tbt: 0,
        tbtScore: 0,
        unusedcss: null,
        unusedjs: null,
        final: 0,
        fetchTime: "",
    })

    const calculateScore = ({fcpScore,siScore,lcpScore,tbtScore,clsScore}) => {
        // Convert input values to numbers
        const fcpValue = parseFloat(fcpScore);
        const siValue = parseFloat(siScore);
        const lcpValue = parseFloat(lcpScore);
        const tbtValue = parseFloat(tbtScore);
        const clsValue = parseFloat(clsScore);

        // Calculate weighted scores
        const weightedFcp = fcpValue * 0.1;
        const weightedSi = siValue * 0.1;
        const weightedLcp = lcpValue * 0.25;
        const weightedTbt = tbtValue * 0.3;
        const weightedCls = clsValue * 0.25;

        // Calculate total score
        const totalScore = weightedFcp + weightedSi + weightedLcp + weightedTbt + weightedCls;

        setmdv(prevState => ({
            ...prevState,
            final: parseInt(totalScore * 100),
        }));

    }
    console.log(pageSpeedData, mdv)
    useEffect(() => {
        // Function to fetch data from PageSpeed Insights API
        const fetchData = async () => {
            try {
                const response = await fetch(`https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${urltocheck}/`);
                const data = await response.json();
                setPageSpeedData(data);
                if (data.lighthouseResult.audits["largest-contentful-paint"]) {
                    setmdv(prevState => ({
                        ...prevState,
                        lcp: data.lighthouseResult.audits["largest-contentful-paint"].displayValue,
                        lcpScore: data.lighthouseResult.audits["largest-contentful-paint"].score,
                    }));
                }

                if (data.lighthouseResult.audits["first-contentful-paint"]) {
                    setmdv(prevState => ({
                        ...prevState,
                        fcp: data.lighthouseResult.audits["first-contentful-paint"].displayValue,
                        fcpScore: data.lighthouseResult.audits["first-contentful-paint"].score,
                    }));
                }

                if (data.lighthouseResult.audits["cumulative-layout-shift"]) {
                    setmdv(prevState => ({
                        ...prevState,
                        cls: data.lighthouseResult.audits["cumulative-layout-shift"].displayValue,
                        clsScore: data.lighthouseResult.audits["cumulative-layout-shift"].score,
                    }));
                }

                if (data.lighthouseResult.audits["speed-index"]) {
                    setmdv(prevState => ({
                        ...prevState,
                        si: data.lighthouseResult.audits["speed-index"].displayValue,
                        siScore: data.lighthouseResult.audits["speed-index"].score,
                    }));
                }

                if (data.lighthouseResult.audits["total-blocking-time"]) {
                    setmdv(prevState => ({
                        ...prevState,
                        tbt: data.lighthouseResult.audits["total-blocking-time"].displayValue,
                        tbtScore: data.lighthouseResult.audits["total-blocking-time"].score,
                    }));
                }

                if (data.lighthouseResult.audits["unused-css-rules"]) {
                    setmdv(prevState => ({
                        ...prevState,
                        unusedcss: data.lighthouseResult.audits["unused-css-rules"],
                    }));
                }

                if (data.lighthouseResult.audits["unused-javascript"]) {
                    setmdv(prevState => ({
                        ...prevState,
                        unusedjs: data.lighthouseResult.audits["unused-javascript"],
                    }));
                }

                if (data.lighthouseResult.fetchTime) {
                    setmdv(prevState => ({
                        ...prevState,
                        fetchTime: data.lighthouseResult.fetchTime,
                    }));
                }
                calculateScore({fcpScore:data.lighthouseResult.audits["first-contentful-paint"].score,
                siScore:data.lighthouseResult.audits["speed-index"].score,
                lcpScore:data.lighthouseResult.audits["largest-contentful-paint"].score,
                tbtScore:data.lighthouseResult.audits["total-blocking-time"].score,
                clsScore:data.lighthouseResult.audits["cumulative-layout-shift"].score})
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetch data function when component mounts
    }, []);

    if (pageSpeedData === null) {
        return <Box sx={{pt:4}}>
            <LinearProgress /> 
            <Typography sx={{mt:2}} textAlign={"center"}>Running analysis..please wait</Typography> 
        </Box>
    }

    return (
        <>

            {true && <div className="max-w-4xl mx-auto">
                <div>


                </div>
                <Grid container spacing={4} mt={0}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" component="h1" >Speed Insights</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant="body2" >Showing results for <b>{urltocheck}</b><IconButton onClick={()=>    window.open(urltocheck, '_blank')}> <MdOpenInNew /></IconButton></Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="body2" >Last updated: {moment(mdv.fetchTime).fromNow()}</Typography>
                    </Grid>
                    
                    <Grid item xs={12} md={2}>
                        <Typography variant='h6' sx={{ fontWeight: "bold" }}>Performance</Typography>
                        <div style={{ width: '100%', height: 'auto', padding: "20px" }}>
                            {/* value={mdv.fcpScore + mdv.lcpScore + mdv.siScore + mdv.tbtScore + mdv.clsScore} */}
                            <CircularProgressbar value={mdv.final} text={`${mdv.final} %`}
                                background
                                backgroundPadding={6}
                                styles={buildStyles({
                                    backgroundColor: mdv.final > 90 ? "#e4f9ee" : (mdv.final < 50 ? "#ffe9e9" : "#fff6e9"),
                                    textColor: mdv.final > 90 ? "#008800" : (mdv.final < 50 ? "#c00" : "#FF581D"),
                                    pathColor: mdv.final > 90 ? "#00cc66" : (mdv.final < 50 ? "#f33" : "#fa3"),
                                    trailColor: "transparent"
                                })}

                            />
                        </div>
                        <div>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {mdv.final > 90 ? <CheckCircle style={{ color: "green", marginRight: "5px" }} /> : mdv.final < 50 ? <GoAlertFill style={{ color: "#f33", marginRight: "5px" }} /> : <AiFillCloseSquare style={{ color: "#fa3", marginRight: "5px" }} />}

                                <Typography >{mdv.final > 90 ? "Good" : mdv.final < 50 ? "Poor" : "Need improvement"}</Typography>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <GoAlertFill style={{ color: "#f33", marginRight: "5px" }} />
                                <Typography variant='body2' sx={{ color: "grey" }}>0-49</Typography>
                            </Grid>



                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                sx={{ mt: 1 }}
                            >
                                <AiFillCloseSquare style={{ color: "#fa3", marginRight: "5px" }} />
                                <Typography variant='body2' sx={{ color: "grey" }}>50-89</Typography>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                sx={{ mt: 1 }}
                            >
                                <CheckCircle style={{ color: "green", marginRight: "5px", fontSize: "18px " }} />
                                <Typography variant='body2' sx={{ color: "grey" }}>90-100</Typography>
                            </Grid>
                        </div>


                    </Grid>


                    <Grid item xs={12} md={10}>
                        <div>

                            <Grid container spacing={2} mt={2}>
                                <Grid item xs={12} md={2.4}>
                                    <MetricsCard data={{ value: mdv.lcp, status: mdv.lcpScore, title: "LCP", subtitle: "Largest Contentful Paint" }} />
                                </Grid>
                                <Grid item xs={12} md={2.4}>
                                    <MetricsCard data={{ value: mdv.fcp, status: mdv.fcpScore, title: "FCP", subtitle: "First Contentful Paint" }} />
                                </Grid>
                                <Grid item xs={12} md={2.4}>
                                    <MetricsCard data={{ value: mdv.si, status: mdv.siScore, title: "SI", subtitle: "Speed Index" }} />
                                </Grid>
                                <Grid item xs={12} md={2.4}>
                                    <MetricsCard data={{ value: mdv.cls, status: mdv.clsScore, title: "CLS", subtitle: "Cumulative Layout Shift" }} />
                                </Grid>
                                <Grid item xs={12} md={2.4}>
                                    <MetricsCard data={{ value: mdv.tbt, status: mdv.tbtScore, title: "TBT", subtitle: "Total Blocking Time" }} />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>Diagnostics</Typography>
                                    <List dense={true}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText primary={<>    <Typography>Reduce unused CSS — <b>{mdv.unusedcss && mdv.unusedcss.displayValue}</b></Typography></>}
                                                secondary={"Reduce unused rules from stylesheets and defer CSS not used for above-the-fold content to decrease bytes consumed by network activity"}
                                            />
                                        </ListItem>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText primary={<>    <Typography>Reduce unused JavaScript — <b>{mdv.unusedjs && mdv.unusedjs.displayValue}</b></Typography></>}
                                                secondary={"Reduce unused JavaScript and defer loading scripts until they are required to decrease bytes consumed by network activity."}
                                            />
                                        </ListItem>
                                    </List>

                                </Grid>


                            </Grid>
                        </div>
                        <br />

                        {/* <div className="mt-4">
                            <Typography variant="h6" className="font-semibold">Accessibility</Typography>
                            <div className="flex items-center justify-center p-4 border rounded-md">
                                <div className="text-6xl font-bold">78</div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <Typography variant="h6" className="font-semibold">Best Practices</Typography>
                            <div className="flex items-center justify-center p-4 border rounded-md">
                                <div className="text-6xl font-bold">96</div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <Typography variant="h6" className="font-semibold">SEO</Typography>
                            <div className="flex items-center justify-center p-4 border rounded-md">
                                <div className="text-6xl font-bold">83</div>
                            </div>
                        </div> */}
                    </Grid>
                </Grid>

            </div>}
            {/* {true && <div
                sx={{
                    width: '80%',
                    margin: '0 auto',
                    padding: 4,
                    border: '1px solid #ccc',
                    borderRadius: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography variant="h5">PageSpeed Insights Dashboard</Typography>
                <div>
                    <Typography variant="h6">Overall Score</Typography>
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: '36px',
                            fontWeight: 'bold',
                            color: '#4CAF50', // Green color for good score, adjust as needed
                        }}
                    >
                        {pageSpeedData.score}
                    </Typography>
                </div>
                <div>
                    <Typography variant="h6">Performance Metrics</Typography>
                    <div>
                        <Typography sx={{ fontWeight: 'bold' }}>First Contentful Paint:</Typography>
                        <Typography>{pageSpeedData.metrics.firstContentfulPaint}</Typography>
                    </div>
                    <div>
                        <Typography sx={{ fontWeight: 'bold' }}>First Input Delay:</Typography>
                        <Typography>{pageSpeedData.metrics.firstInputDelay}</Typography>
                    </div>
             
                </div>
                <div>
                    <Typography variant="h6">Suggestions for Improvement</Typography>
                    <List>
                        {pageSpeedData.suggestions.map((suggestion, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={suggestion} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>} */}
        </>

    );
};

export default SpeedInsightsLayout;
