import React, { useState, useEffect } from 'react';
import { Octokit } from '@octokit/rest';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, Chip, Avatar, Paper, Fade, IconButton, CircularProgress } from '@mui/material';
import { MdCheckCircle, MdClose } from 'react-icons/md';
import { RiGitBranchFill } from 'react-icons/ri';
import { BsFileTextFill } from 'react-icons/bs';
import { CustomTooltip } from '../Components/Settings/Organization';
import { useSnackbar } from 'notistack';

const GitHubActions = ({ githubUrl, token }) => {
    const [runs, setRuns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedLog, setSelectedLog] = useState(null);


    const [owner, setowner] = useState("");
    const [repo, setrepo] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    const extractOwnerRepo = (githubUrl) => {
        try {
            const url = new URL(githubUrl);
            const [owner, repo] = url.pathname.split('/').slice(1, 3);
            setowner(owner)
            setrepo(repo)
            return { owner, repo };
        } catch (error) {
            console.error("Invalid GitHub URL:", error);
            return { owner: null, repo: null };
        }
    };


    useEffect(() => {
        const fetchWorkflowRuns = async () => {
            try {
                const octokit = new Octokit({
                    auth: token, // Replace with your GitHub token
                });

                const [owner, repo] = githubUrl.replace('https://github.com/', '').split('/');

                const response = await octokit.actions.listWorkflowRunsForRepo({
                    owner,
                    repo,
                });

                const runsData = await Promise.all(
                    response.data.workflow_runs.map(async (run) => {
                        const jobsResponse = await octokit.actions.listJobsForWorkflowRun({
                            owner,
                            repo,
                            run_id: run.id,
                        });

                        const jobsWithLogs = await Promise.all(
                            jobsResponse.data.jobs.map(async (job) => {
                                let logs = '';
                                try {
                                    const logResponse = await octokit.actions.downloadJobLogsForWorkflowRun({
                                        owner,
                                        repo,
                                        job_id: job.id,
                                    });
                                    logs = logResponse.data;
                                } catch (logError) {
                                    logs = 'Failed to fetch logs';
                                }
                                return {
                                    ...job,
                                    logs,
                                };
                            })
                        );

                        return {
                            ...run,
                            jobs: jobsWithLogs,
                        };
                    })
                );

                setRuns(runsData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        if (githubUrl && token) {
            fetchWorkflowRuns();
        }

    }, [githubUrl, token]);

    const formatDate = (dateString) => {
        return moment(dateString).format('YYYY-MM-DD HH:mm');
    };


    const formatLog = (log) => {
        const lines = log.split('\n');
        const formattedLines = [];
        let groupLines = [];
        let groupTitle = '';

        lines.forEach((line, index) => {
            const timestampMatch = line.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{7}Z/);
            let timestamp = '';
            let content = line;

            if (timestampMatch) {
                timestamp = formatDate(timestampMatch[0]);
                content = line.replace(timestampMatch[0], '').trim();
            }

            if (content.startsWith('##[group]')) {
                if (groupLines.length > 0) {
                    formattedLines.push(
                        <div
                            key={`group-${formattedLines.length}`}
                            style={{
                                backgroundColor: '#000',
                                marginLeft: "5px",
                                color: 'white',
                                padding: '10px',
                                borderRadius: '5px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                                marginBottom: '10px',
                            }}
                        >
                            <div style={{ color: 'lightblue', fontWeight: 'bold', margin: "5px", marginBottom: "10px" }}>
                                {groupTitle}
                            </div>
                            {groupLines.map((groupLine, idx) => (
                                <div key={`${groupTitle}-${idx}`} style={{ paddingLeft: '20px' }}>
                                    {groupLine}
                                </div>
                            ))}
                        </div>
                    );
                    groupLines = [];
                }
                groupTitle = content.replace('##[group]', '').trim();
            } else if (content.startsWith('##[endgroup]')) {
                if (groupLines.length > 0) {
                    formattedLines.push(
                        <div
                            key={`group-${formattedLines.length}`}
                            style={{
                                backgroundColor: '#000',
                                marginLeft: "5px",
                                color: 'white',
                                padding: '10px',
                                borderRadius: '5px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                                marginBottom: '10px',
                            }}
                        >
                            <div style={{ color: 'lightblue', fontWeight: 'bold', margin: "5px", marginBottom: "10px" }}>
                                {groupTitle}
                            </div>
                            {groupLines.map((groupLine, idx) => (
                                <div key={`${groupTitle}-${idx}`} style={{ paddingLeft: '20px' }}>
                                    {groupLine}
                                </div>
                            ))}
                        </div>
                    );
                    groupLines = [];
                }
                groupTitle = '';
            } else {
                if (groupTitle) {
                    groupLines.push(
                        <div key={`${groupTitle}-${index}`}>
                            <span style={{ opacity: "0.5" }}>{timestamp}<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M192 128l128 128-128 128z"></path></svg></span> {content}
                        </div>
                    );
                } else {
                    formattedLines.push(
                        <div key={`${index}`}>
                            <span style={{ opacity: "0.5" }}>{timestamp}<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M192 128l128 128-128 128z"></path></svg></span> {content}
                        </div>
                    );
                }
            }
        });

        // Add any remaining group lines
        if (groupLines.length > 0) {
            formattedLines.push(
                <div
                    key={`group-${formattedLines.length}`}
                    style={{
                        backgroundColor: '#000',
                        marginLeft: "5px",
                        color: 'white',
                        padding: '10px',
                        borderRadius: '5px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                        marginBottom: '10px',
                    }}
                >
                    <div style={{ color: 'lightblue', fontWeight: 'bold', margin: "5px", marginBottom: "10px" }}>
                        {groupTitle}
                    </div>
                    {groupLines.map((groupLine, idx) => (
                        <div key={`${groupTitle}-${idx}`} style={{ paddingLeft: '20px' }}>
                            {groupLine}
                        </div>
                    ))}
                </div>
            );
        }

        return formattedLines;
    };

    const columns = [
        {
            field: 'name', headerName: 'Name', width: 200,
            renderCell: (cellValues) => {
                return (

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {cellValues.row.conclusion === "success" ? <MdCheckCircle style={{ color: "green", fontSize: "23px" }} /> : <MdClose style={{ color: "red", fontSize: "23px" }} />}
                        <Typography variant='body2' sx={{ ml: 1 }}>{cellValues.value}
                            <Typography variant='subtitle1' sx={{ fontSize: "12px", color: "grey" }}>{cellValues.row.status}</Typography>
                        </Typography>
                    </Grid>

                )
            }

        },
        {
            field: 'user', headerName: 'Completed by', width: 200,
            renderCell: (cellValues) => {
                console.log(cellValues)
                return (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Avatar sx={{ width: "20px", height: "20px", mr: 1 }} src={cellValues.value.avatar_url} /> {cellValues.value.login}
                    </Grid>
                )
            }
        },
        { field: 'event', headerName: 'Event', width: 120 },
        {
            field: 'head_branch', headerName: 'Branch', width: 120,
            renderCell: (cellValues) => {
                return (
                    <CustomTooltip title={<Typography>Click to copy HEAD SHA <i>{cellValues.row.head_sha}</i></Typography>}>
                        <Chip icon={<RiGitBranchFill />} onClick={() => {
                            navigator.clipboard.writeText(cellValues.row.head_sha);
                            enqueueSnackbar(`Copied to clipborad!`, {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                            })

                        }} label={cellValues.value} size='small' />
                    </CustomTooltip>
                )
            }
        },
        {
            field: 'logs',
            headerName: 'Logs',
            width: 150,
            renderCell: (params) => (
                <Button startIcon={<BsFileTextFill />} size='small' sx={{ textTransform: "capitalize" }} variant="text" color="primary" onClick={() => setSelectedLog(params.value)}>
                    Show Logs
                </Button>
            ),
        },
        {
            field: 'created_at', headerName: 'Created At', width: 200,
            renderCell: (cellValues) => {
                return (
                    <Typography sx={{ color: "grey", fontSize: "12px" }}>{moment(cellValues.value).fromNow()}</Typography>
                )
            }
        },
    ];

    const rows = runs.map((run) => ({
        id: run.id,
        name: run.name,
        status: run.status,
        conclusion: run.conclusion,
        created_at: formatDate(run.created_at),
        event: run.event,
        head_branch: run.head_branch,
        head_sha: run.head_sha,
        user: run.actor,
        logs: run.jobs.map((job) => ({
            jobName: job.name,
            logs: formatLog(job.logs),
        })),
    }));



    if (error) {
        if (error === "Not Found")
            return (
                <>
                    <Typography variant='h6' sx={{ fontWeight: "bold", py: 2 }}>Workflow Runs</Typography>
                    <Typography variant='body2'>No Workflow Runs Founds for <b>{repo && repo}</b> repo</Typography>
                </>)
        return <p>Error: {error}</p>;
    }

    return (
        <div style={{ width: '100%' }}>

            <Typography variant='h6' sx={{ fontWeight: "bold", py: 2 }}>Workflow Runs</Typography>
            <Typography variant='body2' sx={{ color: "grey",mb:2 }}>  This page provides a comprehensive view of the status, history, and details of each workflow run with logs, helping you ensure the quality and reliability of your codebase.
            </Typography>


            {loading ? <>
                <Typography sx={{textAlign:"center"}}><CircularProgress size={15} sx={{mt:2}}/>Fetching details</Typography>
            </> : <Paper variant='outlined' sx={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px", borderRadius: "12px", p: 1 }}>


                <Fade in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(true ? { timeout: 500 } : {})}>
                    <div>
                        <DataGrid columns={columns}
                            rowHeight={70}
                            dense
                            autoHeight={true}
                            density={"compact"}
                            scrollbarSize={1}
                            rows={rows ? rows : []}
                            disableColumnFilter={true}
                            loading={loading}
                            pageSize={10}
                            // onCellClick={handleCellClick}
                            sx={{
                                border: "2px",
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                    width: '0.4em',
                                    height: '0.4em',
                                    borderRadius: "24px"
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#cdcdcd',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                                    background: '#160044',
                                },
                            }}

                        />
                    </div>
                </Fade>
            </Paper>}

            <Dialog open={Boolean(selectedLog)} onClose={() => setSelectedLog(null)} fullScreen fullWidth>

                <DialogContent>
                    {selectedLog &&
                        selectedLog.map((log, index) => (
                            <div key={index} style={{ marginBottom: '20px' }}>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >

                                    <h3>Logs of {log.jobName}</h3>
                                    <IconButton onClick={() => setSelectedLog(null)} color="primary">
                                        <MdClose />
                                    </IconButton>
                                </Grid>
                                <pre
                                    style={{
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        overflowX: 'auto',
                                    }}
                                >
                                    {log.logs}
                                </pre>
                            </div>
                        ))}
                </DialogContent>

            </Dialog>
        </div>
    );
};

export default GitHubActions;
