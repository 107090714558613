import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import StepTwo from "./StepTwo"
import StepOne from "./StepOne"
import DeployCopy from "./DeployCopy"
import { gql, useMutation, useQuery } from '@apollo/client';
import { APPS } from '../DeploymentTable';
import { APP_LIST } from '../Apps';
import { RiAddLine } from "react-icons/ri";
import { AiFillSetting } from "react-icons/ai"
import Skeleton from '@mui/material/Skeleton';
import { ReactComponent as MySvgFile } from "../../../Images/upgradePlan.svg"
import { useHistory } from 'react-router-dom';
import { OVERVIEW_DATA } from '../Dashboard';
import UpgradePlan from '../../../Containers/UpgradePlan';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import { APP_NAME_LIST } from '../../Deployments/Overview/Main';

export const ADD_NEW_APP = gql`
  mutation CreateAppMutation( $name: String!, $organizationId:String!, $subOrganizationId:String!, $businessUnitId:String!,$workloadManagementId : String! ){
    createApp(input: { name: $name, runtime: "linux", organizationId: $organizationId, subOrganizationId: $subOrganizationId,businessUnitId: $businessUnitId, workloadManagementId : $workloadManagementId }) {
      app {
        id
      }
    }
  }
`

export const EDIT_APP = gql`
mutation EditAppMutation( $appName: String!, $organizationId:String!, $subOrganizationId:String!, $businessUnitId:String!,$workloadManagementId : String! ){
  editApp(input: { appName: $appName, organizationId: $organizationId, subOrganizationId: $subOrganizationId,businessUnitId: $businessUnitId, workloadManagementId : $workloadManagementId })
}
`

const APP_QUOATA_EXIST = gql`
  query{
    appQuotaExist
  }
`
export const IS_FREE_USER = gql`
  query isFreeUser{
  getUserById{
    id
    freePlan
  }
}
`


export const AppUseStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    boxShadow: "none",
    background: theme.palette.action.hover,
    color: theme.palette.text.secondary,
    height: "80px",
    paddingTop: "10px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getSteps() {
  return ['Select Organization', 'Configuration', 'Scale'];
}

function getStepContent(step, setActiveStep, activeStep, appName, organizationID, handleAddApp, handleLink, applink, appTime, deployApp, appDeployed, appdata, isAppDisable, sethideChangeOrg, setdeployApp,dataCICDGithub, setdataCICDGithub) {

  switch (step) {
    case 0:
      return <StepOne data={activeStep} fun={setActiveStep} name={appName} organizationID={organizationID} isError={handleAddApp} deployApp={deployApp} appDeployed={appDeployed} isAppDisable={isAppDisable} />
    case 1:
      return <StepTwo data={activeStep} fun={setActiveStep} name={appName} handleLink={handleLink} deployApp={deployApp} appDeployed={appDeployed} appId={appdata} organizationID={organizationID} sethideChangeOrg={sethideChangeOrg} setdeployApp={setdeployApp} setdataCICDGithub={setdataCICDGithub}/>
    case 2:
      return <DeployCopy data={activeStep} fun={setActiveStep} name={appName} applink={applink} appTime={appTime} appDeployed={appDeployed} dataCICDGithub={dataCICDGithub} />;
    default:
      return 'Unknown step';
  }
}

export default function CreateNewApp(props) {
  const classes = AppUseStyles();
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [appName, setAppName] = React.useState("");
  const [organizationID, setorganizationID] = React.useState(null)
  const [isError, setisError] = React.useState(false)
  const [hideChangeOrg, sethideChangeOrg] = React.useState(false);
  const [nameValidation, setNameValidation] = React.useState(false)
  const [iserror, setIsError] = React.useState(false)
  const [applink, setapplink] = React.useState("");
  const [appTime, setappTime] = React.useState("");
  const [isExpired, setisExpired] = React.useState(false);
  const [deployApp, setdeployApp] = React.useState(null);
  const [appDeployed, setappDeployed] = React.useState(false);
  const [selectedOrgName, setselectedOrgName] = React.useState("");
  const [isAppDisable, setisAppDisable] = React.useState(false);
  const [dataCICDGithub, setdataCICDGithub] = React.useState(null);
  const steps = getSteps();
  const role = localStorage.getItem("role")

  let history = useHistory()
  const { loading: load, error: err, data: userdata } = useQuery(IS_FREE_USER, {
    variables: { userId: localStorage.getItem("userId") },
  });

  const { loading: loadvalid, error: errvalid, data: datavalid, refetch: refetch } = useQuery(APP_QUOATA_EXIST);

  const { loading: loadoverview, error: erroverview, data: dataoverview, refetch: refetchoverview } = useQuery(OVERVIEW_DATA);

  const [addApp, { data: appdata, loading: apploading, error: apperror }] = useMutation(ADD_NEW_APP, {
    refetchQueries: [APPS, APP_LIST,APP_NAME_LIST],
    onError: (err) => {
      setisAppDisable(false)
      setIsError(true);
      setNameValidation(true)
      console.log("king", props)
    },
    onCompleted: () => {
      setisAppDisable(false)
      setActiveStep(1)
      setappDeployed(true)
    }
  });


  const [editApp, { data: editdata, loading: editloading, error: editerror }] = useMutation(EDIT_APP, {
    refetchQueries: [APPS, APP_LIST],
    onError: (err) => {
      setisAppDisable(false)
    },
    onCompleted: () => {
      setisAppDisable(false)
      setActiveStep(1)
    }
  });

  const handleStepTwo = () => {
    setActiveStep(1);
    setAppName(props.appName)
  };



  const handleLink = (e, i) => {
    setapplink(e);
    setappTime(i)
  };


  const handleChangeOrg = () => {
    setappDeployed(true)
    setdeployApp(null)
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  // const handleFocus = (event) => {
  //   event.target.select();

  //   setisError(false)
  // }

  const handleClickOpen = () => {
    refetch()
    refetchoverview()
    setOpen(true);
    setisAppDisable(true);
  };

  const handleClose = () => {
    localStorage.removeItem('githubCICDtoken')
    setOpen(false);
    refetchoverview()
    setappDeployed(false)
    setActiveStep(0)
    if (setdeployApp) {
      setdeployApp(false)
    }
    if (props.pleaseOpen) {
      props.handleCloseConfigApp()
    }
    if (props.workload) {
      props.handleStop()
    }
    if (props.returnHome) {
      history.push("/teamboard")
    }
  };


  const handleAppName = (e) => {
    setAppName(e.target.value)
    setisError(e.target.value)
  }
  const handleAddApp = (value, wl, selectedOrg) => {
    setisAppDisable(true)
    setselectedOrgName(selectedOrg.join(', ').replace(/, ([^,]*)$/, ' and $1'))

    if (!nameValidation) {

      if (!appDeployed) {

        if (wl) {

          setAppName(`${wl.environmentEndpoint}-${appName}`)

          addApp({ variables: { name: `${wl.environmentEndpoint}-${appName}`, organizationId: value.orgid, businessUnitId: "", subOrganizationId: value.suborg ? value.suborg : "", workloadManagementId: wl.id } });
          setorganizationID(value.orgid)
          sethideChangeOrg(true)
        }
        else {
          addApp({ variables: { name: appName, organizationId: value.orgid, businessUnitId: value.buiid, subOrganizationId: value.suborg ? value.suborg : "", workloadManagementId: "" } });
          setorganizationID(value.orgid)
        }

      }
      else {
        if (wl) {

          setAppName(`${wl.environmentEndpoint}-${appName}`)

          editApp({ variables: { appName: `${wl.environmentEndpoint}-${appName}`, organizationId: value.orgid, businessUnitId: "", subOrganizationId: value.suborg ? value.suborg : "", workloadManagementId: wl.id } });
          setorganizationID(value.orgid)
        }
        else {
          editApp({ variables: { appName: appName, organizationId: value.orgid, businessUnitId: value.buiid, subOrganizationId: value.suborg ? value.suborg : "", workloadManagementId: "" } });
          setorganizationID(value.orgid)
        }
      }

    }

  }
  const valuevalidateName = (e) => {
    var regexp = /^([a-z0-9_](?:(?:[a-z0-9_-]|(?:(?!))){0,28}(?:[a-z0-9]))?)$/
    const checkvalue = regexp.test(e.target.value)
    if (checkvalue.length > 0) {
      setNameValidation(false)
    }
    setNameValidation((checkvalue) ? false : true);
    setisAppDisable(checkvalue ? false : true);
  }

  // console.log("1", selectedOrgName)

  const isStepOptional = (step, activeStep) => {
    if (activeStep === 1) {
      return step === 0;
    }
  };


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      maxWidth: 500,
    },
  }));

  useEffect(() => {
    const superb = require('superb')
    if (props.organizationID) {
      setorganizationID(props.organizationID)
    }

    if (props.deploy) {
      setdeployApp(props.deploy)
    }
    if (dataoverview && dataoverview.AppsCount.active <= 0) {
      setisExpired(true)
    }
    else {
      setisExpired(false)
    }

    if (props.isHook) {
      setOpen(true)
      localStorage.removeItem("deployLink")
      props.handlePostUrlDeploy()
    }
    if (props.pleaseOpen) {
      setOpen(true)
      handleStepTwo()
    }
    if (props.workload) {
      setOpen(true)
      handleStepTwo()

    }

    if (props.openCreateNewApp) {
      handleClickOpen();
      props.handleDisableopenCreateNewApp()
    }

    if (props.steptwo) {
      handleStepTwo()
    }
    else {
      setAppName(superb.random() + "-app-" + Math.floor(1000 + Math.random() * 9000))
    }

  }, [open, props.openCreateNewApp]);



  if (load && !props.hideCreateAppButton) return <Skeleton style={{ borderRadius: "24px" }} variant="rounded" width={95} height={30} />;
  if (err) return "err";
  if (loadvalid && !props.hideCreateAppButton) return <Skeleton style={{ borderRadius: "24px" }} variant="rounded" width={95} height={30} />;
  if (loadoverview && !props.hideCreateAppButton) return <Skeleton style={{ borderRadius: "24px" }} variant="rounded" width={95} height={30} />;
  if (erroverview) return erroverview.message

  return (
    <div>
      {props.hideCreateAppButton ? <Box>

      </Box> :
        <Box>
          {props.steptwo ?
            <Button disabled={loadvalid || role==="Viewer"} endIcon={<AiFillSetting />} variant="contained" disableElevation style={{ boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px", borderRadius: "24px", marginRight: props.margin ? "10px" : "0px", background: "#fff", color: "#000" }}
              onClick={handleClickOpen}>
              {loadvalid ? "Loading.." : (props.name ? props.name : (!props.pleaseOpen && "Configure your app"))}
            </Button>


            :
            <Button disabled={loadvalid || role==="Viewer"} startIcon={<RiAddLine />} variant="contained" color="primary" disableElevation style={{ boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px", borderRadius: "24px", marginRight: props.margin ? "10px" : "0px" }} onClick={handleClickOpen}>
              {loadvalid ? "Loading.." : (props.name ? props.name : "New App")}

            </Button>}
        </Box>}

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large">
              <CloseIcon />
            </IconButton>

            {props.steptwo ?
              <><Typography variant="h6" className={classes.title}>
                {activeStep === 0 ?
                  <div>
                    {props.workload ? props.name : <TextField
                      autoFocus
                      id="name"
                      label="App name"
                      type="text"
                      disabled={apploading}
                      required={true}
                      error={nameValidation && true}
                      helperText={iserror ? `Please enter Unique name, ${appName} Already Exits` : (nameValidation && "Please use lowercase letters and - (hyphen sign) only")}
                      style={{ marginBottom: "10px" }}
                      value={appName}
                      onChange={(e) => { handleAppName(e); valuevalidateName(e); setIsError(false); }}
                    />}
                    {apploading && <>
                      <CircularProgress size={10} />
                      Creating App..
                    </>}
                  </div>
                  : <>{appName}</>}
              </Typography>
                {activeStep === 2 && <Button autoFocus color="inherit" onClick={handleClose}>
                  save
                </Button>}</>
              :
              (datavalid && datavalid.appQuotaExist ? (
                <><Typography variant="h6" className={classes.title}>
                  {!appDeployed ?
                    <div>
                      {apploading ? <>
                        Creating App..
                        <CircularProgress size={20} sx={{ mx: 2 }} />

                      </> :
                        <>
                          {props.workload ? props.name : <><TextField
                            autoFocus
                            id="name"
                            label="App name"
                            type="text"
                            variant='standard'
                            required={true}
                            disabled={apploading}
                            error={nameValidation && true}
                            helperText={iserror ? `Please enter Unique name, ${appName} Already Exits` : nameValidation ? "can't use upper case letters or space, use - (hyphen sign)" : null}
                            style={{ marginBottom: "10px" }}
                            value={appName}
                            onChange={(e) => { handleAppName(e); valuevalidateName(e); setIsError(false); }}
                          />
                            <HtmlTooltip title={<><h3 style={{ marginTop: "10px" }}>What is App Name?</h3><Divider />The app name will be use in domain name as the URL or web address where visitors find your site.<br />By default, any site on Nife is accessible from its Nife subdomain, which has the form <pre>https://[name-of-your-site].nifetency.com</pre></>}><IconButton><InfoOutlined fontSize='small' /></IconButton></HtmlTooltip></>}
                        </>
                      }
                    </div>
                    : <p>{appName}<IconButton color="primary" component="span" size="large"><EditIcon /></IconButton></p>}

                </Typography>
                  {activeStep === 4 && <Button autoFocus color="inherit" onClick={handleClose}>
                    save
                  </Button>}</>
              ) : <>Please Upgrade Your Plan </>)
            }



          </Toolbar>
        </AppBar>
        <Container >
          {props.steptwo ?
            <div className={classes.root}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => {

                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index, activeStep)) {
                    labelProps.optional = (
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >

                        <span style={{ fontSize: "12px" }}>{selectedOrgName}</span>

                      </Grid>
                    );
                  }
                  return (
                    <Step key={label}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                      <StepContent>
                        <Typography>{getStepContent(index, setActiveStep, activeStep, appName, organizationID, handleAddApp, handleLink, applink, appTime, deployApp, appDeployed, isAppDisable, sethideChangeOrg, setdeployApp, dataCICDGithub, setdataCICDGithub)}</Typography>
                      </StepContent>
                    </Step>
                  )
                })}
              </Stepper>
            </div>

            : (datavalid && datavalid.appQuotaExist ?
              <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((label, index) => {

                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index, activeStep)) {
                      labelProps.optional = (


                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >

                          <span style={{ fontSize: "12px" }}>{selectedOrgName}</span>
                          {!hideChangeOrg && <span color='primary' style={{ color: "#5d86f9", cursor: "pointer", marginLeft: "5px" }} onClick={handleChangeOrg}>Change Organization</span>}
                        </Grid>

                      );
                    }
                    return (
                      <Step key={label}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                        <StepContent>
                          <Typography>{getStepContent(index, setActiveStep, activeStep, appName, organizationID, handleAddApp, handleLink, applink, appTime, deployApp, appDeployed, appdata,dataCICDGithub, setdataCICDGithub, isAppDisable, sethideChangeOrg, setdeployApp)}</Typography>
                        </StepContent>
                      </Step>
                    )
                  })}
                </Stepper>
              </div>
              : <LimitReachedComponent isExpired={isExpired} />
            )
          }
        </Container>
      </Dialog>
    </div>
  );
}



function LimitReachedComponent(props) {
  return (
    <Grid container>

      <Grid item xs={12} sm={7} lg={7}>
        <div style={{ textAlign: "left" }}>
          <h2>You've reached your maxium limit of current plan.</h2>
          Thanks for trying nife.To Continue enjoying more benefits please upgrade your current plan and become a pro member.
          <p>You can get a lot more out of it, click the button below to upgrade your account .</p>


          {props.isExpired ? <UpgradePlan app={true} /> :
            <UpgradePlan app={true} />}
          <div style={{ width: "100%", height: "15px", borderBottom: "1px solid grey", textAlign: "center", marginBottom: "20px" }}>
            <span style={{ fontSize: "22px ", color: "rgb(43, 43, 43)", backgroundColor: "#fff", padding: "5px 10px" }}>
              OR
            </span>
          </div>

          <p>We also suggest, you can clean inactive/unwanted apps from your account to free up some space. Click the button below to manage your apps.</p>
          <Button href={"/apps"} style={{ margin: "10px", marginLeft: "0px" }} variant='outlined' color="primary" disableElevation>
            Manage Your Apps
          </Button>
        </div>

      </Grid>
      <Grid item xs={12} sm={5} lg={5}>
        <img src="https://nife.io/wp-content/uploads/2022/08/cloud.gif" style={{ padding: "20px 20px", width: "100%", height: "100%" }} />
      </Grid>

    </Grid>
  );
}

