
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { Box, Button, CssBaseline, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Paper, Skeleton,Tabs, Tab } from '@mui/material';
import Nav from '../../../Settings/Nav';
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { CloudOff, OpenInNew } from '@mui/icons-material';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { gql, useQuery } from '@apollo/client';
import { formatTime } from '../../../Deployments/Overview/Main';
import { IoIosPeople } from 'react-icons/io';
import { BiSolidTimeFive } from 'react-icons/bi';
import { MdOutlineTimelapse } from 'react-icons/md';
import Configuration from './Configuration';
import CustomDomain from './CustomDomain';
import SpeedInsightsLayout from '../../../../Tools/SpeedInsightsLayout';

export const SITE_OVERVIEW = gql`
query siteOverviewQuery($s3AppName: String){
  getS3deployments(s3AppName: $s3AppName) {
        id
        s3AppName
        status
        appUrl
        buildCommandsS3 {
          s3Cmd
        }
        envVariablesS3 {
          name
          value
        }
        organizationId
        orgDetails {
          id
          name
          type 
        }
        userDetails {
          id
          email
          firstName
          lastName
          profileImageUrl 
          roleId
          userProfileCreated
         }
        deploymentTime
        buildTime
        createdBy
        createdAt
      }
}`

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: 3,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    paddingLeft: "10%",
    padding: 5,
    paddingTop: "3%",
  },
  subtitle: {
    flexGrow: 1,
    paddingLeft: "10%",
    padding: 5,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    height: "auto",
  },
  header: {
    borderRadius: "12px",
    margin: "10px 24px 24px 24px",
    boxShadow: "rgba(17, 12, 46, 0.05) 0px 48px 100px 5px",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  headline: {

    fontSize: "18px",
    margin: "10px"
  },
  link: {
    display: 'flex',
    textDecoration: "none",
    color: '#5d86f9'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },

  card: {
    margin: "10px",
  }

}));


export default function Overview() {
  const classes = useStyles();
  const match = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const { loading, error, data, refetch } = useQuery(SITE_OVERVIEW, {
    variables: {
      s3AppName: match.params.id ? match.params.id : ""
    },
    onCompleted: (data) => {
    }
  });
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
  }, [data]);


  if (error) {
    return error.message
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>



        <Nav />
        <Container maxWidth={false} className={classes.container}>

          {loading ? <Box>
            <Skeleton style={{ margin: "10px" }} />
            <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
          </Box> : <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} aria-label="basic tabs example">
                <Tab label="Overview" {...a11yProps(0)} onClick={() => handleChange(0)} />
                <Tab label="Configuration" {...a11yProps(1)} onClick={() => handleChange(1)} />
                <Tab label="Metrics" {...a11yProps(2)} onClick={() => handleChange(2)} />
         

              </Tabs>
            </Box>
            <TabPanel value={value} index={0}><Box sx={{mt:2}}>
              {data && data.getS3deployments && <Paper variant='outlined' sx={{ borderRadius: "12px" }}>

                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='h5' sx={{ mt: 5 }} textAlign={"center"}>
                    {data && data.getS3deployments.status === "Active" && <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                      <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                      <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>}
                    {data && data.getS3deployments.status === "Suspended" && <IconButton><CloudOff /></IconButton>}
                    {data && data.getS3deployments.status === "Suspended" && <br />}
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        Your Site is Deployed and {data && data.getS3deployments.status}
                      </motion.div>
                    </AnimatePresence>
                  </Typography>

                  {data && data.getS3deployments && data.getS3deployments.appUrl && <Button sx={{ my: 2 }} endIcon={<OpenInNew />} onClick={() => {

                    window.open(`http://${match.params.id}.static.nifetency.com`, '_blank')
                  }} className="btn btn-primary">Open Site</Button>}
                </Grid>




                {data && data.getS3deployments && <Grid container sx={{ p: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                  <Grid item xs={12} md={(data && data.getS3deployments.buildTime) ? 4 : 6} lg={(data && data.getS3deployments.buildTime) ? 4 : 6} sx={{ cursor: "pointer" }}>
                    <ListItem sx={{ borderRadius: "12px", p: 1 }} >
                      <ListItemIcon>
                        <IconButton style={{ maxWidth: "40px", background: "#f5ecff", color: "rgb(133, 27, 192)", borderRadius: "12px", margin: "10px" }}><IoIosPeople /></IconButton>
                      </ListItemIcon>
                      <ListItemText sx={{ textDecoration: "capitalize" }} primary="Organization" secondary={<span>{data && data.getS3deployments.orgDetails.name
                      }</span>} />
                    </ListItem>
                  </Grid>

                  {data && data.getS3deployments && <Grid item xs={12} md={(data && data.getS3deployments.buildTime) ? 4 : 6} lg={(data && data.getS3deployments.buildTime) ? 4 : 6} sx={{ cursor: "pointer" }}>
                    {data && data.getS3deployments.deploymentTime && <ListItem sx={{ borderRadius: "12px", p: 1 }} >
                      <ListItemIcon>
                        <IconButton style={{ maxWidth: "40px", background: "#e3f2fd", color: "#5d86f9", borderRadius: "12px", margin: "10px" }}><BiSolidTimeFive /></IconButton>
                      </ListItemIcon>
                      <ListItemText primary="Deployed in" secondary={<span>{data && data.getS3deployments.deploymentTime && data && <>{formatTime(data.getS3deployments.deploymentTime)}</>}</span>} />
                    </ListItem>}
                  </Grid>}

                  {data && data.getS3deployments.buildTime && <Grid item xs={12} md={4} lg={4} sx={{ cursor: "pointer" }}>
                    <ListItem sx={{ borderRadius: "12px", p: 1 }} >
                      <ListItemIcon>
                        <IconButton style={{ maxWidth: "40px", background: "#f6f7fb", borderRadius: "12px", margin: "10px" }}><MdOutlineTimelapse /></IconButton>
                      </ListItemIcon>
                      <ListItemText primary="Build in" secondary={<span>{data && data.getS3deployments.buildTime && data && <>{formatTime(data.getS3deployments.buildTime)}</>}</span>} />
                    </ListItem>
                  </Grid>}
                </Grid>}
              </Paper>}
            </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
            <SpeedInsightsLayout urltocheck={`https://${match.params.id}.static.nifetency.com`} />
            </TabPanel>
            <TabPanel value={value} index={1}>
            <CustomDomain data={`${match.params.id}.static.nifetency.com`} />
            <Configuration data={data} />
            </TabPanel>
          </Box>}

        </Container>

      </main>
    </div>
  );
}