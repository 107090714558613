import { gql, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import { UPDATE_PASSWORD, UPDATE_USER_INFO, USER_INFO } from "./Home/Profile";
import { StyledButton } from './Login';
import "./Home/CompleteProfilePhoneNumber.css";
import { Container, CssBaseline } from '@mui/material';
import { theme } from '../theme';



const UPDATE_PROFILE = gql`
mutation updateProfile($userId: String!, $userProfileCreated: Boolean!) {
  userProfileUpdated(userId: $userId, userProfileCreated: $userProfileCreated)
}
`








export default function SignInSide() {
  const [fname, setFName] = React.useState("");
  const [lname, setLName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [cpassword, setCPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [industry, setIndustry] = React.useState("");
  const [loaction, setLocation] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [passwordValidation, setPasswordValidation] = React.useState(false)
  const [passwordErrors, setPasswordErrors] = React.useState('')
  const [validPhoneNumber, setvalidPhoneNumber] = React.useState(true);

  const valuevalidatePassword = (e) => {
    const p = e.target.value
    const errors = [];
    if (p.length < 8) {
      errors.push("Your password must be at least 8 characters in length");
    }
    if (p.length > 60) {
      errors.push("Your password cannot be more than 60 characters in length");
    }
    if (p.search(/[a-z]/) < 0) {
      errors.push(" contains at least one lower case letter");
    }
    if (p.search(/[A-Z]/) < 0) {
      errors.push(" contains at least one upper case letter");
    }
    if (p.search(/[0-9]/) < 0) {
      errors.push(" at least one digit");
    }
    if (errors.length > 0) {
      setPasswordErrors(errors.join(","))
    }
    setPasswordValidation((errors.length > 0) ? true : false)
  }

  let history = useHistory()
  var CryptoJS = require("crypto-js");
  function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    encryptedData = encryptedData.ciphertext.toString();

    return encryptedData

  }

  const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER_INFO, {
    refetchQueries: [USER_INFO],
    onCompleted: () => {
      enqueueSnackbar("Updated Profile!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    },
    onError: () => { }
  });


  const [updateProfileStatus] = useMutation(UPDATE_PROFILE, {
    onCompleted: () => {

      history.push("/teamboard")
    },
    onError: () => {

    }
  })


  const handleProfileCompleted = () => {
    updateProfileStatus({ variables: { userId: localStorage.getItem("userId"), userProfileCreated: true } })
  }

  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {
      handleProfileCompleted()
      enqueueSnackbar("Updated Password!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    },
    onError: () => {
      enqueueSnackbar("Temporary Password doesn't match!", {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  });


  const handleSave = (e) => {
    e.preventDefault();

    updateUser({ variables: { id: localStorage.getItem("userId"), companyName: "company", phoneNumber: encodeAes(phone), location: loaction, industry: industry, FirstName: fname, LastName: lname } });
    updatePassword({ variables: { id: localStorage.getItem("userId"), oldpassword: encodeAes(cpassword), newPassword: encodeAes(newPassword) } });

  }


  useEffect(() => {
    axios.get("https://api.ipify.org?format=json").then((res) => {
      axios.get(`https://ipapi.co/${res.data.ip}/json/`).then((res2) => {
        setPhone(res2.data.country_calling_code)
        setLocation(res2.data.city + ", " + res2.data.country_name)
      })

    })
  }, []);


  return (
    <ThemeProvider theme={theme}>
    <CssBaseline/>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography sx={{ mr: 1, mt: 2, color: (theme)=> theme.palette.text.primary }}  variant="h4">
                  Welcome to
                </Typography>
                <img src='/Oikos.svg' style={{ marginTop: "12px" }} />
              </Grid>
            </div>
            <Typography sx={{ textAlign:"center",color: (theme)=> theme.palette.text.primary}}  variant="body2">Please fill the additional details and set new password below.</Typography>

          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            md={4}
          >
            <div>
              <img src='https://react-nife-2024.s3.ap-south-1.amazonaws.com/register.png' style={{ height: "100%", width: "100%" }} />
            </div>
            {/* <img alt='no image' src={"https://react-nife-2024.s3.ap-south-1.amazonaws.com/register.png"} /> */}

          </Grid>
          <Grid item xs={12} sm={4} md={4} square>

           <div>
              <Grid container spacing={2}>

              {!data && <Grid item xs={12}>


                  <TextField
                    size='small'
                    variant="outlined"
                    label="First Name"
                    fullWidth
                    value={fname}
                    onChange={e => { setFName(e.target.value) }}

                    id="text-input"
                    required
                    autoFocus
                  />
                </Grid>}
                {!data &&<Grid item xs={12}>
                  <TextField
                    size='small'
                    variant="outlined"
                    label="Last Name"
                    fullWidth
                    value={lname}
                    onChange={e => { setLName(e.target.value) }}
                    id="text-input"
                    required
                    autoFocus
                  />
                </Grid>}
                {!data && <Grid item xs={12}>


                  <PhoneInput
                    inputProps={{
                      name: 'phone',
                      required: true,
                    }}
                    country={'us'}
                    value={phone}
                    enableSearch={true}
                    onChange={phone => setPhone(phone)}
                    className="forCompleteProfile"
                    isValid={(value, country) => {
                      if (value.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
                        setvalidPhoneNumber(false)
                        return 'Invalid value: ' + value + ', ' + country.name;
                      }
                      else {
                        setvalidPhoneNumber(true)
                        return true;
                      }
                    }}
                  />
                </Grid>}
                {!data &&<Grid item xs={12}>
                  <TextField
                    size='small'
                    variant="outlined"
                    label="Industry"
                    fullWidth
                    value={industry}
                    onChange={e => { setIndustry(e.target.value) }}

                    id="text-input"
                    required
                    autoFocus
                  />
                </Grid>}
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    variant="outlined"
                    label="Temporary Password"
                    fullWidth
                    type="password"
                    value={cpassword}
                    onChange={e => { setCPassword(e.target.value) }}
                    id="text-input"
                    required
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    variant="outlined"
                    label="New Password"
                    fullWidth
                    type="password"
                    value={newPassword}
                    helperText={passwordValidation && passwordErrors}
                    error={passwordValidation}
                    onChange={e => { setNewPassword(e.target.value); valuevalidatePassword(e) }}
                    id="text-input"
                    required
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={!validPhoneNumber || passwordValidation || fname === "" || lname === "" || industry === "" || cpassword === ""}
                  >
                    Next
                  </StyledButton>
                </Grid>
              </Grid>

            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4} square>









          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}