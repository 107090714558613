import React from 'react'
import { Link } from 'react-router-dom';
import ComingSoonImage from './comingsoon.svg'
function ComingSoon() {
        return (
            <div style={{backgroundColor:"cyran", textAlign:"center"}}> 
                <img alt="ComingSoon" src={ComingSoonImage} height="300px" width="300px" />
            	<h1 style={{fontSize: "55px",textAlign: "center", fontWeight:"bold", color:"#111"}} className="ComingSoon">Coming Soon!</h1>
                <h1 style={{fontSize: "20px",textAlign: "center", fontWeight:700, color:"rgb(85 85 97)"}}>This page is under construction<br/><Link to="/" >Go back to Home page</Link></h1>
            </div>
        )
    }


export default ComingSoon