import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import DeployCopy from "../../Home/CreateNewApp/DeployCopy";
import { Fade } from '@mui/material';

export const APP_LOCATION_LIST = gql`
query AppsLocationList($name: String!){
  app(name: $name){
  status
  regions {
    code
    name
    latitude
    longitude
  }
  backupRegions{
    code
  }
  }
}`



const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    height: theme.spacing(16),
  },
  paper: {
    backgroundColor: "whitesmoke",
    padding: theme.spacing(2),
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));




export default function Main(props) {
  const match = useRouteMatch();
  useEffect(() => {

  }, []);

  const classes = useStyles();


  return (
    <div className={classes.root} style={{ margin: "25px" }}>
      <Grid container >

        <Grid item >
          <Fade in={true}>


            <Typography component="h2" variant="h1" style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }} color="primary" gutterBottom>
              Scale to Different Regions
            </Typography>
          </Fade>


          <Typography component="h2" variant="h2" style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px" }} gutterBottom>
            Deployment Criteria
          </Typography>
          <Typography style={{ color: "#585779d1" }}>
            To deploy your app you need to specify the location or region you want to deploy to or
            add an area and enter the filter criteria to filter out the nodes that meet your requirements.      </Typography>
          <a target='_blank' href={"https://docs.nife.io/docs/UI%20Guide/Apps-&-their-Management/App-management/Scale"}>Read more.</a>
          <br />
        </Grid>


      </Grid>

      <Grid item spacing={0}>







        <DeployCopy name={match.params.id} isScale={true} />







      </Grid>













    </div>
  );
}





