import { useQuery, gql, useMutation } from '@apollo/client';
import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { SiWheniwork } from 'react-icons/si';
import { useRouteMatch } from 'react-router-dom'
import CreateNewApp, { ADD_NEW_APP } from "../../Home/CreateNewApp/Main"
import { FETCH_APP_ORG } from './UpdateOrg';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UpgradePlan from '../../../Containers/UpgradePlan';
import { APPS } from '../../Home/DeploymentTable';
import { APP_LIST } from '../../Home/Apps';
import { useSnackbar } from 'notistack';
import { APPS_WORKLOAD } from '../Overview/Main';

export const FETCH_WORKLOAD_APPS = gql`
query WorkloadByOrgQuery($orgId: String!)
    {
        getWorkloadMangementByorgnizationId(
          orgId: $orgId
          subOrgId: ""
          businessUnitId: ""
        ) {
          id
          environmentName
          environmentEndpoint
          organizationId
          subOrganizationId
          businessUnitId
          userId
          createdAt
          apps {
            nodes{
                id
                name
            }
          }
        }

}`

export default function AddWorkload() {
    const role = localStorage.getItem("role")
    const match = useRouteMatch()
    const { loading, error, data } = useQuery(FETCH_APP_ORG, { variables: { name: match.params.id } });
    const { loading: load, error: err, data: datawl, refetch } = useQuery(FETCH_WORKLOAD_APPS, { variables: { orgId: (data && data.app.organization) ? data.app.organization.id : "" } });

    const [age, setAge] = React.useState('');
    const [stop, setstop] = React.useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleStop = () => {
        setstop(false)
    }

    const [addApp, { data: appdata, loading: apploading, error: apperror }] = useMutation(ADD_NEW_APP, {
        refetchQueries: [APPS, APP_LIST,APPS_WORKLOAD],
        onError: (err) => {
        },
        onCompleted: () => {
            enqueueSnackbar("Successfully Created!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            setstop(true)
        }
       
    });

    const handleSubmit = (e) => {
        addApp({
            variables: {
                name: `${e.environmentEndpoint}-${match.params.id.substr(match.params.id.indexOf("-") + 1)}`,
                organizationId: data ? data.app.organization.id : "",
                businessUnitId: "",
                subOrganizationId: "",
                workloadManagementId: e.id
            }
        })
    }




    useEffect(() => {
        refetch()
    }, [datawl, data]);


    return (
        <div style={{ margin: "20px" }}>
            {stop && <CreateNewApp workload={true} steptwo={true} appName={`${age.environmentEndpoint}-${match.params.id.substr(match.params.id.indexOf("-") + 1)}`} handleStop={handleStop} />}
            <Grid container>

                <Grid item lg={4}>
                    <h2 style={{ color: "grey" }}>Add Workload</h2>
                    <Typography variant="body2" gutterBottom style={{ color: "grey" }}>
                        Use Workload to create multiple environments of your App.
                    </Typography>
                    <a target='_blank' href={"https://docs.nife.io/docs/UI%20Guide/Apps-&-their-Management/App-management/workload"}>
							Read More
						</a>
                </Grid>
                {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).workloadManagement) ?
                    <Grid item lg={8}>
                        <Typography variant="overline" display="block" gutterBottom>Select Workload</Typography>
                        <FormControl sx={{ maxWidth: 300 }} fullWidth size="small">
                            <InputLabel id="demo-select-small">Workloads</InputLabel>
                            <Select
                            disabled={role==="Viewer"}
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={age}
                                label="Workloads"
                                onChange={handleChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {datawl && datawl.getWorkloadMangementByorgnizationId && datawl.getWorkloadMangementByorgnizationId.map((e) => {
                                 
                                  
                                    if (!e.apps.nodes || (e.apps.nodes && !e.apps.nodes.find((o) => o.name === e.environmentEndpoint + "-" + match.params.id.substr(match.params.id.indexOf("-") + 1)))) {
                                        return (<MenuItem onClick={()=>handleSubmit(e)} value={e}>{e.environmentName}</MenuItem>)
                                    }
                                })}

                            </Select>
                        </FormControl>
                    </Grid> : <UpgradePlan center={true} />}
            </Grid>
        </div>
    )
}

