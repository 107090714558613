import { CheckCircle, History, Security, VerifiedUser } from '@mui/icons-material';
import { Badge, Box, Button, Card, CardContent, CircularProgress, CssBaseline, Fade, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ImSearch } from 'react-icons/im';


export default function CodeScan({ githubUrl }) {
    const [isLoading, setisLoading] = useState(false);
    const [isError, setisError] = useState("");
    const [data, setdata] = useState("")
    const postData = async () => {
        const url = 'https://trufflehog.clb2.nifetency.com/run_docker'; // Replace with your API endpoint
        var temp = `docker run --rm trufflesecurity/trufflehog:latest github --repo ${githubUrl.slice(0, -1)}`

        const data = {
            "docker_command": temp
        };

        try {
            const response = await axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setisLoading(false)
            setdata(response.data)
            console.log('Response:', response.data);
            // Handle response data as needed
        } catch (error) {
            setisLoading(false)
            setisError(error.message)
            console.error('Error:', error.message);
            // Handle error state or show error message to user
        }
    };



    const LogDisplay = () => {
        const { logs } = data;

        // Function to replace TruffleHog header with CodeSafe Logs
        const replaceTruffleHogHeader = (logEntries) => {
            const header = "🐷🔑🐷  TruffleHog. Unearth your secrets. 🐷🔑🐷";
            return logEntries.map(entry => entry === header ? "CodeSafe Logs" : entry);
        };

        let logEntries = logs.split('\n').filter(line => line.trim() !== '');
        logEntries = replaceTruffleHogHeader(logEntries);

        return (
            <Card>
                <CardContent>
                    <List>
                        {logEntries.map((entry, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={entry} />
                            </ListItem>
                        ))}
                    </List>
                 
                </CardContent>
            </Card>
        );
    };


    useEffect(() => {

        return () => {
            // Cleanup code here if necessary
        };
    }, []);
    return (
        <div>
            <CssBaseline />



            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>

                        <Typography variant='h6' sx={{ fontWeight: "bold", py: 2 }}>Code Safe</Typography>

                        <Typography variant='body2' sx={{ color: "grey" }}>A powerful and versatile tool designed to enhance the security of your codebase by meticulously hunting down sensitive information. It operates with the precision of a dog, sniffing out secrets buried within your Git repositories.</Typography>
                        {isError ? <Box>
                            <Typography color={"error"}>{isError}</Typography>
                            <Button disabled={githubUrl === null} onClick={() => {
                                setisError("")
                                setisLoading(true);
                                postData();
                            }} sx={{ my: 2 }} variant='contained'>Try again</Button>
                        </Box> :
                            <Box>
                                {isLoading ?
                                    <Typography sx={{ fontWeight: "bold", my: 2, fontSize: "20px" }} color={"primary"} onClick={() => setisLoading(false)}><CircularProgress size={15} sx={{ mr: 1 }} />Scanning, please wait..</Typography> :
                                    <>
                                        {data ? <Typography variant='h6' color={"primary"} sx={{fontWeight:"bold",my:2}}>Scanning result: {data.result}</Typography> : <Button disabled={githubUrl === null} onClick={() => {
                                            setisLoading(true);
                                            postData();
                                        }} endIcon={<ImSearch />} sx={{ my: 2 }} variant='contained'>Check vulnerabilities</Button>}
                                    </>
                                }
                            </Box>}
                    </Grid>
                    {!isError && <Grid item xs={12} md={6}>

                        {isLoading ? <Fade in={true}><img src='https://react-nife-2024.s3.ap-south-1.amazonaws.com/searchLeaks.gif' width={"100%"} height={"auto"} /></Fade>
                            :
                            <>
                                {data ? <LogDisplay /> : <Fade in={true}>
                                    <Box sx={{ mt: 4 }}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Badge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={
                                                        <CheckCircle style={{ color: "#7ac142", background: "white", borderRadius: "50%", fontSize: "15px" }} />
                                                    }
                                                >
                                                    <Security style={{ fontSize: "30px" }} />
                                                </Badge>

                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Comprehensive Secret Detection"
                                                secondary="Identifing sensitive information, including high-entropy strings AWS, Google API keys and 17 other."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Badge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={
                                                        <CheckCircle style={{ color: "#7ac142", background: "white", borderRadius: "50%", fontSize: "15px" }} />
                                                    }
                                                >
                                                    <History style={{ fontSize: "30px" }} />
                                                </Badge>

                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Historical Scanning"
                                                secondary="Examining past commits to uncover secrets that might have been accidentally committed at any point."
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Badge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={
                                                        <CheckCircle style={{ color: "#7ac142", background: "white", borderRadius: "50%", fontSize: "15px" }} />
                                                    }
                                                >
                                                    <VerifiedUser style={{ fontSize: "30px" }} />
                                                </Badge>

                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Advanced Entropy and Pattern Matching"
                                                secondary="Reducing false positives and ensuring actual threats are flagged."
                                            />
                                        </ListItem>


                                    </Box>
                                </Fade>}
                            </>
                        }
                    </Grid>}
                </Grid>
            </Box>


        </div>
    )
}
