import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdCloudUpload, MdUpload } from 'react-icons/md';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

const UploadBuild = ({ maxSizeInBytes, setbuildFileUrl, appName,directBuildFile, handleDirectBuildFileDeploy }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [uploading, setuploading] = useState(false);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        setIsDragging(false);

        if (rejectedFiles.length > 0) {
               enqueueSnackbar("Exceeds size limit. (maximun allowed is 100Mb)", {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                    })
                
            return;
        }

        const validFiles = acceptedFiles.filter(
            (file) => file.size <= maxSizeInBytes
        );

        if (validFiles.length === 0) {
               enqueueSnackbar("Files exceed size limit.", {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                    })
                   
            return;
        }

        // Read and display some information about the first file
        const file = validFiles[0];
        const fileInfo = await readFileInfo(file);
        uploadFunction(validFiles)
        setUploadedFiles([{ ...fileInfo, file }]);
    }, [maxSizeInBytes]);


    const readFileInfo = async (file) => {
        return {
            name: file.name,
            size: file.size,
        };
    };

    const uploadFunction = async (e) => {
        setuploading(true)
        if (e[0]) {
            await axios
                .post(`${process.env.REACT_APP_API_LIVE}/api/v1/fileUpload/${appName}?fileExtension=${e[0].name.split('.').pop()}`, e[0], { headers: { "Content-Type": e.type } })
                .then((response) => {
                    if (directBuildFile) {
                        handleDirectBuildFileDeploy(response.data.s3Link)
                    }
                    else {
                        setbuildFileUrl(response.data.s3Link)
                    }

                    setuploading(false)
                })
                .catch((error) => {
                    enqueueSnackbar(error.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                    })
                    setuploading(false)
                });
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ['.zip', '.tar'],
        maxSize: maxSizeInBytes,
        onDragEnter: () => setIsDragging(true),
        onDragLeave: () => setIsDragging(false),
    });

    return (
        <div>
            {uploading ?
                <Box sx={{ textAlign: "center" }}>
                    <MdCloudUpload style={{ fontSize: '6em', color: theme.palette.primary.main }} />
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress size={20} />
                        <Typography sx={{ fontWeight: "bold", fontSize: "15px", ml: 1 }}>  Uploading...</Typography>
                    </Grid>
                </Box>
                :
                <div>
                    {directBuildFile ?
                        <div  {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button color={isDragging ? "info" : "primary"} disableElevation fullWidth variant='contained' startIcon={<MdUpload />}
                                sx={{ borderRadius: "12px", my: 2, height: 40 }}>
                                {isDragging ? "Drop here" : "Upload build file"}

                            </Button>
                            <Typography sx={{ textAlign: "center", color: "grey", fontSize: "13px", mb: 2 }}>or drag and drop build file here</Typography>
                        </div>
                        : <div {...getRootProps()} style={{ ...dropzoneStyles, borderColor: isDragging ? 'green' : '#cccccc' }}>
                            <input {...getInputProps()} />
                            <div>
                                <span role="img" aria-label="Upload Icon" style={{ fontSize: '3em' }}>
                                    <MdCloudUpload style={{ fontSize: '2em', color: theme.palette.primary.main }} />
                                </span>
                            </div>
                            <p>
                                {isDragging ? 'Release your mouse to upload the file' : 'Drag & drop files here, or click to select your source code'}
                            </p>
                        </div>}
                </div>}







        </div>
    );
};

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'border 0.3s ease-in-out',
};

export default UploadBuild;
