import { gql, useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
//import ImgDialog from './ImgDialog'
import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Snackbar from '@mui/material/Snackbar'
import axios from 'axios'
import { USER_INFO } from '../Home/Profile'
import { styles } from './styles'


export const UPDATE_LOGO = gql`
  mutation UpadateLogo($logoUrl: String!){
    uploadCompanyLogo(input: {logoUrl:$logoUrl})
}
`



function Demo({ classes }) {
  const [imageSrc] = React.useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  // const [croppedImage, setCroppedImage] = useState(null)
  const [open, setOpen] = React.useState(true);
  // const [file, setfile] = React.useState(null);
  const [isError, setisError] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);


  const handleClose = () => {
    setOpen(false);

  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  }, [])



  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      handleSubmit(file)
      // setfile(file)
      // let imageDataUrl = await readFile(file)
      // const orientation = await getOrientation(file)
      // const rotation = ORIENTATION_TO_ANGLE[orientation]
      // if (rotation) {
      //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      // }

      // setImageSrc(imageDataUrl)
    }
  }

  const handleCloseMsg = () => {
    setisSuccess(false)
  }

  const [updateLogo] = useMutation(UPDATE_LOGO,
    {
      refetchQueries: [USER_INFO],
      onCompleted: () => {
        // window.location.reload()
      }
    })

  const handleSubmit = (file) => {
    var formdata = new FormData();
    formdata.append("photo", file);

    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_LIVE}/api/v1/profileImage?userId=${localStorage.getItem("userId")}&type=companylogo`,
      headers: {
        'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL',
        'Authorization': `Bearer ${localStorage.getItem("token")}`,

      },
      data: formdata
    };




    axios(config).then((res) => {

      setOpen(false);
      setisSuccess(true)
      updateLogo({ variables: { logoUrl: res.data.s3Link } })

    })
      .catch((err) => {
        setisError(true)
      })
  }






  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',

        }}
        open={isSuccess} autoHideDuration={6000} onClose={handleCloseMsg}>
        <Alert onClose={handleCloseMsg} severity={isError ? "error" : "success"}>
          {isError ? "Somethign went worng, try again" : "Successfully updated profile image!"}
        </Alert>
      </Snackbar>

      {imageSrc && (
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div className={classes.cropContainer}>
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className={classes.controls}>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: classes.slider }}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              {/* <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div> */}
              <Button
                onClick={handleClose}
                variant="contained"
                disableElevation
                classes={{ root: classes.cropButton }}
              >
                Close
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disableElevation
                classes={{ root: classes.cropButton }}
              >
                Save
              </Button>
            </div>

          </DialogContent>
        </Dialog>
      )}
      <input type="file" hidden={true} onChange={onFileChange} accept="image/*" />

    </div>
  )
}

// function readFile(file) {
//   return new Promise((resolve) => {
//     const reader = new FileReader()
//     reader.addEventListener('load', () => resolve(reader.result), false)
//     reader.readAsDataURL(file)
//   })
// }

export const UploadCompanyLogo = withStyles(styles)(Demo)

