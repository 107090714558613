import React from 'react'
import { BYOCInstructions } from '../BYOClocations'
import { Typography } from '@mui/material'
import { useLocation } from 'react-router';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export default function BYOCinst() {
    const type = useQuery().get("type");
    const gcpProjectName = useQuery().get("gcpProjectName");
    const awsAccountId = useQuery().get("awsAccountId");
    const regionName = useQuery().get("regionName");
    const regionCode = useQuery().get("regionCode");
    return (
        <div>
          

            <BYOCInstructions data={{ type: type, awsAccountId: awsAccountId, regionName: regionName,regionCode:regionCode,gcpProjectName:gcpProjectName }} />

        </div>
    )
}
