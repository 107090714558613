import React, { useState } from 'react'
import { APP } from '../Overview/Main'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, LinearProgress } from '@mui/material';
import { MdFileCopy, MdFileDownload } from 'react-icons/md';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

//   
const DOWNLOAD_CONFIG = gql`
mutation DownloadConfigMutation($sourceUrl: String, $appName: String!, $externalPort: String, $image: String, $internalPort: String, $organizationId: String, $routingPolicy: String,
$cpuLimit: String,
$memoryLimit: String,
$builtin:String,
$cpuRequests: String,
$memoryRequests: String, 
     ){
  createNifeTomlFile(input:{
    appName:$appName,
    externalPort: $externalPort,
    image: $image,
    builtin: $builtin,
    internalPort: $internalPort,
    organizationId: $organizationId,
    routingPolicy: $routingPolicy,
    sourceUrl: $sourceUrl,
    cpuLimit: $cpuLimit,
    memoryLimit: $memoryLimit,
    cpuRequests: $cpuRequests,
    memoryRequests: $memoryRequests, 
  })
}`

// 

export default function ConfigDownload({ cicd, appName }) {
    const match = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();

    const { data: appdata, error: apperr, loading: appload } = useQuery(APP, {
        variables: {
            name: cicd ? appName : match.params.id
        },
        onCompleted: (data) => {
        }
    })
    const [downloadConfig, { loading: downloadLoading }] = useMutation(DOWNLOAD_CONFIG, {
        onError: (err) => {
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        },
        onCompleted: (data) => {
            if (data) {
                window.open(data.createNifeTomlFile)
                enqueueSnackbar("Starting download..", {
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'center',
                        horizontal: 'right',
                    },
                })


            }

        }
    });

    const handleSubmit = () => {
        if (appdata && appdata.app.config) {
            downloadConfig({
                variables: {
                    cpuLimit: appdata.app.config.definition.services[0].limits.cpu ? appdata.app.config.definition.services[0].limits.cpu : "1",
                    memoryLimit: appdata.app.config.definition.services[0].limits.memory ? appdata.app.config.definition.services[0].limits.memory : "256",
                    cpuRequests: appdata.app.config.definition.services[0].requests.cpu ? appdata.app.config.definition.services[0].requests.cpu : "0.5",
                    memoryRequests: appdata.app.config.definition.services[0].requests.memory? appdata.app.config.definition.services[0].requests.memory : "128",
                    sourceUrl: (appdata.app.sourceUrl && appdata.app.sourceUrl.startsWith("https://github.com/")) ? `${appdata.app.sourceUrl}`  : "",
                    appName: cicd ? appName : match.params.id,
                    builtin:appdata && appdata.app.config && appdata.app.config.build ? appdata.app.config.build.builder : "Github",
                    externalPort: appdata.app.config.definition.services[0].external_port.toString(),
                    image: appdata.app.config.build.image,
                    internalPort: appdata.app.config.definition.services[0].internal_port.toString(),
                    organizationId: appdata && appdata.app.id,
                    routingPolicy: appdata.app.config.definition.services[0].routing_policy
                }
            })
        }
    }
    useEffect(() => {
        return () => {
        }
    }, [])

    if (appload && !appdata) {
        return "Loading.."
    }
    return (
        <div>

            {!cicd && downloadLoading && <LinearProgress />}
            {(appdata.app.sourceUrl && appdata.app.sourceUrl.startsWith("https://github.com/")) &&
                <Button disableElevation sx={{ textTransform: "capitalize" }} size={cicd ? "small" : "medium"} variant={cicd ? "contained" : "text"} startIcon={<MdFileDownload />} onClick={handleSubmit}>{downloadLoading ? "generating Download link.." : `Download ${cicd ? "nife" : "config"}.toml`}</Button>
            }
        </div>
    )
}
