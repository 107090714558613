import React from 'react'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import moment from 'moment';
import { CustomTooltip } from '../Settings/Organization';


export default function UserDetailsTooltip({ children, data }) {
    // console.log(data)
    // var message;
    // if(data.userProfileCreated === "1" || data.id === localStorage.getItem("userId")){
    //     message = data.roleId === 2 ? "Active member" : (data.roleId === 1 ? "Active admin" : "Active viewer") 
    // }
    // if(data.userProfileCreated === "0"){
    //     message = "Invited" 
    // }
if(data===null){
    return "No user info"
}
    return (
        <div>
            <CustomTooltip
                title={
                    <React.Fragment>

                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            sx={{p:2}}
                        >

                            <Avatar
                                sx={{ width: 70, height: 70,mb:1 }}
                                alt={data.firstName}
                                src={data.profileImageUrl}
                               
                            />
                           
                            <Typography variant='body2'>{data.firstName} {data.lastName}</Typography>
                            <Typography variant='caption' style={{ color: "grey" }}>{data.email}</Typography>
                       
                        </Grid>
                  

                    </React.Fragment>
                }>
                <ListItem>
                    <ListItemAvatar sx={{ minWidth: "34px" }}>
                        <Avatar
                            sx={{ width: 26, height: 26 }}
                            alt={`Avatar n°$`}
                            src={data.profileImageUrl}
                        />
                    </ListItemAvatar>

                    <ListItemText primary={<Typography variant='body2'>{data.firstName} {data.lastName}</Typography>} secondary={<Typography variant='caption' style={{ color: "grey" }}>{data.email}</Typography>} />
                </ListItem>
                {children}
            </CustomTooltip>
        </div>
    )
}
