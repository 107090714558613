import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useRouteMatch } from "react-router-dom";
import CachedIcon from '@mui/icons-material/Cached';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { ReactComponent as MySvgFile } from '../../../Images/logs.svg';
import LogTrigger from '../../../LogTrigger';
import { Button, Chip, Grid, Skeleton } from '@mui/material';
import { useEffect } from 'react';
export const LOGS = gql`
query Logs($appName: String!,$region: String){
    getclusterLog(appName:$appName,region: $region)
}
`
export const APP_REGION = gql`
query AppQuery($name: String!){
  app(name: $name){
    id
    status
    regions{
      code
      name
    }
  }
}`

export default function FolderList({isDatabase}) {
  const match = useRouteMatch();

  const { loading: appload, error: apperr, data: appdata } = useQuery(APP_REGION, {
    variables: { name: match.params.id },
    onCompleted: () => {
      getClusters();
    }
  });
  const [getClusters, { loading, error, data }] = useLazyQuery(LOGS, { variables: { appName: match.params.id, region: appdata ? (appdata.app.regions ? appdata.app.regions[0].code : "") : "" } });

  useEffect(() => {
  }, [data]);

  if (loading || appload) return "Loading...";
  if (error) {
    LogTrigger(error.message, "error", window.location.href, "App Log")
  }

  if (data) {
    LogTrigger("App log loaded", "info", window.location.href, "App Log")
  }

  if (appdata && appdata.app && appdata.app.status === "Suspended") {
    return (
      <Typography style={{ margin: "25px", fontWeight: "bold" }}>

        App is Suspended, Cannot show logs.
      </Typography>
    )
  }



  return (
    <div>
      {data && data.getclusterLog ? <div>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
              <Typography variant="h6" style={{ marginBottom: "15px", fontWeight: "bold" }}>
        {isDatabase ? "Database" : "App"} Logs
      </Typography>

          {/* <Chip variant={true ? "filled" : "outlined"} label="Application log " color="primary" /> */}
          <div>
            <Button sx={{my:1}} onClick={()=> {getClusters()}} endIcon={<CachedIcon/>}>Refresh</Button>
          </div>
        </Grid>

        <Paper variant='outlined' style={{ padding: "10px", color: "#fff", background: "#111" }}>
        {loading && <Skeleton height={10} />}
          <span style={{ whiteSpace: "pre-line" }}>
            {data && data.getclusterLog}
          </span>
        </Paper>
      </div> : <div style={{ padding: "0px" }}>
        <h3 style={{ marginBottom: "0px" }}> No data</h3> <div style={{ color: "grey" }}> No logs to show </div>

        <MySvgFile style={{ width: "100%", height: "100%", minWidth: "100px", marginTop: "5px", maxWidth: "600px" }} />
      </div>}

    </div>
  );
}
