import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as MySvgFile } from '../Images/sessionExpired.svg';
import { logout, refresh } from '../actions/index';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Main(props) {


  const { enqueueSnackbar } = useSnackbar();


  let history = useHistory()
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    props.logout()
    props.fun()
    history.push("/")
    setOpen(false);
  }

  const handleRefresh = () => {

    if (localStorage.getItem("refresh")) {
      props.refresh({ "refresh_token": localStorage.getItem("refresh") })
      setOpen(false)
      enqueueSnackbar("Successfully Regenerated!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      return <Redirect to={window.location.pathname} />
    }
    else {
      enqueueSnackbar("Couldn't Regenerate, Please Login Again", {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      handleClose()
    }
  }

  useEffect(() => {
    setOpen(true);
  }, [])
  
  return (

    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={"sm"}
      fullWidth
      PaperProps={{
        elevation: 0,
        sx: {
          filter:"blur 4px",
          borderRadius: "12px",
          border: "solid 1px gray",

        }
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >

      <DialogContent style={{ textAlign: "center" }}>
        <div style={{ textAlign: "center", marginBottom: "0px" }}>
          <img src='https://react-nife-2024.s3.ap-south-1.amazonaws.com/OTP.svg' style={{ width: "50%", height: "100%", minWidth: "100px" }} />
        </div>
        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}>Sorry to interrupt you, session expired.{/*{hours}:{minutes}:{seconds}*/}</DialogTitle>




      </DialogContent>
      {props.isRefresh ? ("ALL good") : (
        <DialogActions>
          {localStorage.getItem("refresh") !== "null" && <Button fullWidth color="primary" onClick={handleRefresh}>regenerate</Button>}
          <Button fullWidth variant="contained" disableElevation onClick={handleClose}> Login Again</Button>
        </DialogActions>
      )}



    </Dialog>)

}



const mapStateToProps = ({ authReducer }) => {
  return { authReducer }
}

export default connect(mapStateToProps, { logout, refresh })(Main)