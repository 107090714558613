import { gql, useLazyQuery } from '@apollo/client';
import { Chip, Divider, Grid, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { AiFillThunderbolt } from 'react-icons/ai';
import { BiExpandVertical } from 'react-icons/bi';
import { TiLocation } from 'react-icons/ti';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

export const SWITCH_LOCATION_LIST = gql`
query{
    getAvailabilityCluster(isLatency: "", first: 25){
        nodes{
            id,
            name,
            regionCode,
            isLatency,
            clustertype
        }
      }
}`

export default function SwitchLocation({ location }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const match = useRouteMatch();
    const open = Boolean(anchorEl);
    let history = useHistory();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const [fetchSwitchOrg, { loading, error, data }] = useLazyQuery(SWITCH_LOCATION_LIST, {
        variables: { userId: localStorage.getItem("userId") },
    });

    React.useEffect(() => {
        fetchSwitchOrg();
    }, [data, location]);

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                {match.params.name}
                {data && data.getAvailabilityCluster.nodes && match.params.name && <div>
                    <IconButton
                        sx={{ ml: 2 }}
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <BiExpandVertical />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 100 * 4.5,
                                borderRadius: "12px",
                            },
                        }}
                    >
                        <MenuItem onClick={() => history.push("/location")}>Locations</MenuItem>
                        <Divider />
                        {data && data.getAvailabilityCluster.nodes && data.getAvailabilityCluster.nodes.map((option) => (
                            <MenuItem key={option.id} selected={option.name === match.params.name}
                                onClick={() => {
                                    handleClose();
                                    history.push(`/location/${option.regionCode}/${option.name}`);
                                }}>
                                <Tooltip title={option.isLatency ? "Latency" : "No latency"}>
                                    <IconButton >{option.isLatency ? <TiLocation style={{ fill: "#bdbdbd" }} /> : <AiFillThunderbolt style={{ fill: "#bdbdbd" }} />}</IconButton>
                                </Tooltip>
                                <Typography sx={{ textTransform: "capitalize", fontWeight: option.name === match.params.name ? "bold" : "normal", color: option.name === match.params.name ? "primary" : "undefined" }}>
                                    {option.name.toString().toLowerCase()}
                                </Typography>
                                <Chip size='small' sx={{ mx: 2 }} variant='outlined' label={option.clustertype === "k8s" ? "Nife" : option.clustertype} />
                            </MenuItem>
                        ))}
                    </Menu>
                </div>}
            </Grid>

        </div>
    );
}
