import { gql, useMutation, useQuery } from '@apollo/client';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Button, Chip, Collapse, LinearProgress, List } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import * as React from 'react';
import { BsArrowRightCircle, BsDot } from 'react-icons/bs';
import { useHistory } from "react-router-dom";
import { selectIcon, selectTypeColor } from '../Actiity';
import { USER_ACTIVITY } from './Nav';
import { CustomTooltip } from './Organization';


export const MARK_READ = gql`
mutation MarkReadMutaion($id : [String]){
  notificationInfo(input: {id:$id,isRead:true})
}`


export default function Notification() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [messageCount, setmessageCount] = React.useState(0);
  const open = Boolean(anchorEl);
  const { data: userdataact, refetch: refetchaact } = useQuery(USER_ACTIVITY, {

    variables: { first: 5 },
    onCompleted: () => {
    }
  });

  const [markRead, { loading: markReadloading }] = useMutation(MARK_READ, {
    refetchQueries: [USER_ACTIVITY],
    onError: (err) => {
      //   setshowApply(false)
    },
    onCompleted: () => {
    }
  });

  let history = useHistory();

  React.useEffect(() => {
    refetchaact()
    setmessageCount(0)
    if (userdataact && userdataact.userActivities) {
      setmessageCount(userdataact.userActivities.filter((e) => {
        return e.isRead === false
      }).length)
    }
    if (messageCount !== 0 && open) {
      handleRead()
    }
  }, [userdataact, open]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleRead = () => {
    var ids = []
    if (userdataact) {
      userdataact.userActivities.forEach(element => {
        ids.push(element.id)
      });
    }

    markRead({ variables: { id: ids } })
  }


  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <CustomTooltip title="Notifications">

          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ mr: 1 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge variant='dot' max={9} badgeContent={messageCount} color="error">
              <NotificationsIcon style={{ color: "grey" }} />
            </Badge>
          </IconButton>
        </CustomTooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

      >



        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >


          <Typography sx={{ mx: 2, fontWeight: "bold" }}>Notification</Typography>
          <Button size='small' onClick={() => history.push("/activity")} style={{ marginRight: "10px" }}>View All</Button>
        </Grid>
        <Collapse in={markReadloading}>
          <LinearProgress />
        </Collapse>

        <List dense={true}>
          {userdataact && userdataact.userActivities && userdataact.userActivities.map((e, i) => (
            <div key={e.id}>
              <ListItem key={e.id} alignItems="flex-start" style={{ marginLeft: "5px" }}>
                <ListItemAvatar>
                  <Avatar>
                    {e.message.slice(0, 1)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  secondary={<React.Fragment>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="end"
                    >
                      <Box sx={{ mt: "5px" }}>
                        <Chip size='small' sx={{ background: selectTypeColor(e.type), color: '#000000' ,  marginRight: "10px" }} label={e.type} />

                        <Chip size='small' variant="outlined" label={e.activities}
                          icon={selectIcon(e.activities)}
                        />
                      </Box>
                      {!e.isRead &&
                        <Tooltip title="Unread message">
                          <BsDot style={{ fontSize: "42px", color: "#2196f3" }} />
                        </Tooltip>}
                    </Grid>
                  </React.Fragment>}
                  primary={
                    <Box style={{ maxWidth: "350px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <Tooltip title={e.message}><Typography noWrap sx={{ fontSize: "13px" }}>{e.message} </Typography></Tooltip>
                        <Typography noWrap style={{ color: "grey", fontSize: "13px" }}> {moment(new Date(e.createdAt), "YYYY MM DD hh:mm:ss").fromNow()}</Typography>
                      </Grid>

                    </Box>

                  }
                />

              </ListItem>
              <Divider />
            </div>

          ))}
        </List>


      </Menu>
    </React.Fragment>
  );
}
