import axios from "axios"
export default function LogTrigger(error,level,location,method) {
    const headers = {
        'Content-Type': 'application/json'
    }
    const data = {'userId' : localStorage.getItem('userId'), 'message':`(url: ${location}) Method : ${method} Error Message: ${error}  `,'level':level}
    try{
        axios.post(`${process.env.REACT_APP_API_LIVE}/api/v1/uilogs`, data, { headers: headers })
    }
    catch{
        console.log("error")
    }

}
