import React from 'react'
import { Link } from 'react-router-dom';
import NotFoundImage from './notfoundimg.svg'
function NotFound() {
        return (
            <div style={{backgroundColor:"cyran", textAlign:"center"}}> 
                <img alt="notfound" src={NotFoundImage} height="300px" width="300px" />
            	<h1 style={{fontSize: "55px",textAlign: "center", fontWeight:"bold", color:"#111"}} className="notfound">404 Error</h1>
                <h1 style={{fontSize: "20px",textAlign: "center", fontWeight:700, color:"rgb(85 85 97)"}}> Oops, looks like you are lost, <Link to="/" >Let's go back to Home</Link></h1>
            </div>
        )
    }


export default NotFound