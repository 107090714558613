import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { SiWheniwork } from 'react-icons/si';
import { RiGitMergeFill } from 'react-icons/ri';
import { CircularProgress, Collapse, DialogContent, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { HISTORY } from '../Activity/ActivityTable';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ADD_VOLUME, CONFIG_DEFINITION, DEPLOY_IMAGE, UPDATE_APP } from '../../Home/CreateNewApp/StepTwo';
import { APPS } from '../../Home/DeploymentTable';
import { APP_LOCATION_LIST } from '../Deploy/Main';
import { USER_ACTIVITY } from '../../Settings/Nav';
import { useSnackbar } from 'notistack';
import { REVERT_APP, VOLUME_DETAILS } from '../Services/Main';
import { APP_LIST } from '../../Home/Apps';
import { ListItemButton } from '@mui/material';

const emails = ['username@gmail.com', 'user02@gmail.com'];




function SimpleDialog(props) {
    let history = useHistory()
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
        setshowMerge(false);
        setAge('')
    };


    const [age, setAge] = React.useState('');
    const [version, setversion] = React.useState('');
    const [showMerge, setshowMerge] = React.useState(false);
    var definitionMap = new Map();
    const handleChange = (event) => {
        setAge(event.target.value);
        handleMerge(event.target.value)
    };

    const handleChangeVersion = (event) => {
        setversion(event.target.value);
    };


    const { enqueueSnackbar } = useSnackbar();
    const { loading: loadvolume, error: errvolume, data: datavolume } = useQuery(VOLUME_DETAILS, { variables: { appId: props.appdata.name ? props.appdata.name : "" } });


    const [revertApp, { data: revertAppdata, loading: revertApploading, error: revertApperror }] = useMutation(REVERT_APP, {
        refetchQueries: [HISTORY, APP_LIST, APPS, APP_LOCATION_LIST, USER_ACTIVITY],
        onError: (err) => {

            setAge('')
            enqueueSnackbar("Someting went wrong!", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        },
        onCompleted: () => {
            history.push(`/deployments/overview/${age.name}`)
            onClose(selectedValue);
            setshowMerge(false);
            setAge('')

            enqueueSnackbar("Merged Successfully!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    });

    const [deployImage, { data: DPData, loading: DPLoad, error: DPErr }] = useMutation(DEPLOY_IMAGE, {
        refetchQueries: [APPS, APP_LOCATION_LIST, USER_ACTIVITY],
        onCompleted: () => {

            history.push(`/deployments/overview/${age.name}`)
            onClose(selectedValue);
            setshowMerge(false);
            setAge('')

            enqueueSnackbar("Merged Successfully!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        },
        onError: () => {
            setAge('')
            enqueueSnackbar("Someting went wrong!", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    });

    const [addVolume] = useMutation(ADD_VOLUME, {
        onCompleted: () => {
            enqueueSnackbar("Attached Volume Successfully!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    });

   
    const [getCongif, { data: appdata, error: apperr, loading: appload }] = useLazyQuery(CONFIG_DEFINITION, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data.app.config) {
                definitionMap.set('definition', data.app.config.definition)
            }

            if(datavolume && datavolume.getVolumeByAppId && datavolume.getVolumeByAppId[0] && datavolume && datavolume.getVolumeByAppId[0].size && !loadvolume){
                addVolume({
                    variables: {
                        appId: age.name,
                        name: age.name,
                        size: datavolume.getVolumeByAppId[0].size,
                        path: `/data/${age.name}`
                    }
                })
            }


            deployImage({
                variables: {
                    appId: age.name, image: appdata.app.imageName, definition: definitionMap.get("definition"),
                    envMapArgs: props.appdata.envArgs.slice(1, -1).replace(/:/g, "=").replace(/"/g, '').toString().split(","), envArgs: "", archiveUrl: ""
                }
            });



        }
    })



    const [updateApp, { data: updateData, loading: updateLoad, error: updateErr }] = useMutation(UPDATE_APP, {
        refetchQueries: [CONFIG_DEFINITION],
        onCompleted: () => {

            getCongif({ variables: { name: age.name } })
        }
    });
    const handleMerge = (e) => {

    }
   
    const handleDeploy = () => {
        // from props to age
        if ((!age.deployed) || (age.deployType === 0)) {
            updateApp({
                variables: {
                    appId: age.name, build: `{"image": "${props.appdata.imageName}", "builder": "Workload", "builtin": ""}`, replicas: props.appdata.replicas
                    , routingPolicy: props.appdata.config.definition.services[0].routing_policy,
                    resource: `{"RequestRequirement":{"cpu":"${props.appdata.config.definition.services[0].requests.cpu ? props.appdata.config.definition.services[0].requests.cpu : ""}","Memory":"${props.appdata.config.definition.services[0].requests.memory ? props.appdata.config.definition.services[0].requests.memory : ""}"},"LimitRequirement":{"cpu":"${props.appdata.config.definition.services[0].limits.cpu ? props.appdata.config.definition.services[0].limits.cpu : ""}","Memory":"${props.appdata.config.definition.services[0].limits.memory ? props.appdata.config.definition.services[0].limits.memory : ""}"}}`,
                    internalPort: props.appdata.config.definition.services[0].internal_port.toString(), externalPort: props.appdata.config.definition.services[0].external_port.toString()
                }
            })
        }
        else {

            revertApp({
                variables: {
                    version: 0,
                    envMapArgs: props.appdata.envArgs.slice(1, -1).replace(/:/g, "=").replace(/"/g, '').toString().split(",")
                    , appName: age.name, appId: age.id, image: props.appdata.imageName, externalPort: props.appdata.config.definition.services[0].external_port.toString(), internalPort: props.appdata.config.definition.services[0].internal_port.toString(), archiveUrl: ""
                }
            })
        }

    }


    return (
        <Dialog maxWidth={"sm"} fullWidth onClose={handleClose} open={open}>
            <DialogTitle>{updateLoad ? "Fetching details.." : appload ? "Fetching details.." : revertApploading ? "Merging.." : DPLoad ? "Deploying.." : "App's Workloads"}


            </DialogTitle>
            {updateLoad && <LinearProgress />}
          
            {appload && <LinearProgress />}
           
            <Box>
                {!showMerge ? <List sx={{ pt: 0 }} dense={true}>
                    {props.workloadData && props.workloadData.appsWorkload.nodes && props.workloadData.appsWorkload.nodes.map((e) => (
                        <ListItemButton selected={e.name === props.name} onClick={() => { history.push(`/deployments/overview/${e.name}`); handleClose(); }} key={e.id}>
                            <ListItemAvatar>
                                <IconButton>
                                    <SiWheniwork />
                                </IconButton>
                            </ListItemAvatar>
                            <ListItemText primary={e.name} secondary={e.status} />
                        </ListItemButton>
                    ))}

                    <ListItemButton disabled={(props.workloadData && props.workloadData.appsWorkload.nodes && props.workloadData.appsWorkload.nodes.length === 1) || (!props.appdata.deployed)} onClick={() => setshowMerge(true)}>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                <RiGitMergeFill />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<span>Merge Workloads {!props.appdata.deployed && " (App not deployed)"} {props.workloadData && props.workloadData.appsWorkload.nodes && props.workloadData.appsWorkload.nodes.length === 1 && " (Add More Workload to this app)"}</span>} />
                    </ListItemButton>
                </List> :
                    <div>
                        <DialogContent>
                            {(props.appdata && props.appdata.deployType === 0) ? "Cannot merge, app is not deployed." :
                                <Grid container>
                                    <Grid itme xs={5} lg={5}>
                                        <Typography variant="body1" component="h1" style={{ textTransform: "capitalize" }}>
                                            Merge <b>{props.name}</b>
                                        </Typography>

                                    </Grid>
                                    <Grid itme xs={2} lg={2}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >{
                                                (revertApploading || DPLoad) ? <CircularProgress size={18} /> :

                                                    <Tooltip open={age ? (!revertApploading) : false} placement="top" arrow title="Click to Merge">
                                                        <IconButton color='primary' disabled={age ? (revertApploading ? true : DPLoad ? true : false) : true} onClick={handleDeploy}><FaArrowAltCircleRight />
                                                        </IconButton>
                                                    </Tooltip>
                                            }
                                        </Grid>

                                    </Grid>
                                    <Grid itme xs={5} lg={5}>
                                        <Typography variant="body1" style={{ textTransform: "capitalize" }} component="h1">To <b>{age && age.name}</b> </Typography>
                                        <Collapse in={(revertApploading || DPLoad) ? false : true}>


                                            <Box sx={{ minWidth: 120, mt: 2 }}>
                                                <FormControl fullWidth size={"small"}>
                                                    <InputLabel id="demo-simple-select-label">Select Workload</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={age}
                                                        disabled={revertApploading || DPLoad}
                                                        label="Select Workload"
                                                        onChange={handleChange}
                                                    >
                                                        {props.workloadData && props.workloadData.appsWorkload.nodes && props.workloadData.appsWorkload.nodes.map((e) => {
                                                            if (e.name !== props.name) return (<MenuItem value={e}>{e.name}{!e.deployed && " (Not deployed)"}</MenuItem>)
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            }

                        </DialogContent>
                    </div>}
            </Box>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function ShowWorkload(props) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            <h1 style={{ fontSize: "22px", margin: "20px 0px 0px 0px" }}>
                <Button size="large" style={{ textTransform: "capitalize", fontWeight: "bold" }} startIcon={<SiWheniwork />} onClick={handleClickOpen}>
                    {props.name.split("-")[0]}.
                </Button>
                {"  "}{props.name.substr(props.name.indexOf("-") + 1)}
            </h1>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                workloadData={props.data}
                name={props.name}
                appdata={props.appdata}
            />
        </div>
    );
}
