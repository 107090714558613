import { gql, useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { Button, DialogActions, DialogContent, Grid, IconButton, InputAdornment, ListItemIcon, Paper, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Moment from "moment";
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { SiWheniwork } from 'react-icons/si';
import UpgradePlan from '../../Containers/UpgradePlan';

export const ADD_WORKLOAD = gql`
mutation createWorkloadManagementMutation($environmentName: String!, $environmentEndpoint: String!,$organizationId: String! ){
  createWorkloadManagement(input: {environmentName: $environmentName, environmentEndpoint : $environmentEndpoint, organizationId: $organizationId})
}
`

export const DELETE_WORKLOAD = gql`
mutation deleteWorkloadManagementMutation($id: String!){
    deleteWorkloadManagement(id: $id)
}
`


export const WORKLOAD_LIST = gql`
query workloadlistQuery($orgId: String!,$subOrgId:String!,$businessUnitId: String!){
    getWorkloadMangementByorgnizationId(orgId: $orgId, subOrgId: $subOrgId, businessUnitId: $businessUnitId){
            id
            environmentName
            environmentEndpoint
            organizationId
            subOrganizationId
            businessUnitId
            userId
            createdAt
          
  }
}
`



export default function Workload(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [opendel, setOpendel] = React.useState(false);
    const [workloadname, setworkloadname] = React.useState("");
    const [endwiths, setendwiths] = React.useState("");
    const [nameValidation, setNameValidation] = React.useState(false)
    const [selectedWL, setselectedWL] = React.useState(null);
    const [searched, setSearched] = React.useState("")
    const handleClose = () => {
        setOpen(false);
    };

    const handleClosedel = () => {
        setOpendel(false);
    };

    const handleOpen = () => {
        setendwiths("")
        setworkloadname("")
        setOpen(true)
    }


    const handleOpendel = (e) => {
        setOpendel(true)
        setselectedWL(e)
    }



    const { data: wldata } = useQuery(WORKLOAD_LIST, {
        variables: { orgId: props.org ? props.org.id : "", subOrgId: props.suborg ? props.suborg.id : "", businessUnitId: props.bu ? props.bu.id : "" },
    });

    const [createWorkload] = useMutation(ADD_WORKLOAD, {
        refetchQueries: [WORKLOAD_LIST],
        onError: (err) => {

            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        },
        onCompleted: (e) => {
            setOpen(false)

            enqueueSnackbar(e.createWorkloadManagement, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })


            //   setOpensuborg(false)
            //   setisProgress(false)
        }
    });

    const [deleteWorkload] = useMutation(DELETE_WORKLOAD, {
        refetchQueries: [WORKLOAD_LIST],
        onError: (err) => {
            setOpendel(false)
            //   setnewOrgName("")
            enqueueSnackbar("Organization already exist!", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        },
        onCompleted: () => {

            enqueueSnackbar("Successfully Deleted!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            setOpendel(false)
            //   setisProgress(false)
        }
    });

    const valuevalidateName = (e) => {
        var regexp = /^([a-z0-9_](?:(?:[a-z0-9_-]|(?:(?!))){0,28}(?:[a-z0-9]))?)$/
        const checkvalue = regexp.test(e.target.value)
        if (checkvalue.length > 0) {
            setNameValidation(false)
        }
        setNameValidation((checkvalue) ? false : true)
    }

    const handleCreateWorload = () => {
        createWorkload({ variables: { environmentName: workloadname, environmentEndpoint: endwiths, organizationId: props.org ? props.org.id : "" } });
    }

    const handleDeleteWL = () => {
        deleteWorkload({ variables: { id: selectedWL.id } })
    }


    return (
        <div>

            {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).workloadManagement) ?
                <div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >

                        <TextField
                            value={searched}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searched !== "" &&
                                            <IconButton onClick={() => setSearched("")}>
                                                <CloseIcon />
                                            </IconButton>}
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}

                            size={"small"}
                            sx={{ mb: 2 }}
                            placeholder='Search for Workload'
                            onChange={(e) => setSearched(e.target.value)}
                        />
                        <Button style={{ borderRadius: "24px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }} variant="contained" onClick={handleOpen}>Create Workload</Button>
                    </Grid>

                    <Paper variant="outlined" style={{
                        borderRadius: "12px",
                        margin: "0px",
                        boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                    }}>
                        {wldata &&
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {wldata && wldata.getWorkloadMangementByorgnizationId && wldata.getWorkloadMangementByorgnizationId.filter((row) => {
                                    return row.environmentName.toLowerCase().includes(searched)
                                }).map((i) => (
                                    <ListItem
                                        secondaryAction={
                                            <IconButton onClick={() => handleOpendel(i)} edge="end" aria-label="comments">
                                                <Delete />
                                            </IconButton>
                                        }

                                    >
                                        <ListItemAvatar>
                                            <ListItemIcon>
                                                <IconButton>
                                                    <SiWheniwork />
                                                </IconButton>

                                            </ListItemIcon>
                                        </ListItemAvatar>
                                        <ListItemText primary={i.environmentName} secondary={Moment(new Date(i.createdAt), "YYYY MM DD hh:mm:ss").fromNow()} />
                                    </ListItem>
                                ))}



                            </List>}
                             {wldata && wldata.getWorkloadMangementByorgnizationId && wldata.getWorkloadMangementByorgnizationId.length === 0 && <>
                                <Typography>No workloads in this organization</Typography>
                            </>}

                        <Dialog maxWidth={"xs"} fullWidth={true} open={open} onClose={handleClose}>
                            <DialogTitle>Create Workload</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    value={workloadname}
                                    size="small"
                                    margin="dense"
                                    id="name"
                                    label="Workload Name"
                                    fullWidth
                                    onChange={(e) => {
                                        setworkloadname(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                                <TextField
                                    autoFocus
                                    value={endwiths}
                                    size="small"
                                    margin="dense"
                                    id="name"
                                    label="Endpoint"
                                    fullWidth
                                    error={nameValidation}
                                    helperText={nameValidation && "Please use lowercase letters and - (hyphen sign) only"}
                                    onChange={(e) => {
                                        setendwiths(e.target.value);
                                        valuevalidateName(e);
                                    }}
                                    variant="outlined"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>close</Button>
                                <Button disabled={workloadname === "" ? true : nameValidation} onClick={handleCreateWorload}>Create</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog maxWidth={"xs"} fullWidth={true} open={opendel} onClose={handleClosedel}>
                            <DialogTitle>Are you sure?</DialogTitle>
                            <DialogContent style={{ color: "grey" }}>
                                Do you want to delete {selectedWL && selectedWL.environmentName} workload?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClosedel}>close</Button>
                                <Button color='error' onClick={handleDeleteWL}>Delete</Button>
                            </DialogActions>
                        </Dialog>
                    </Paper>
                </div>
                : <UpgradePlan />}
        </div>
    );
}
