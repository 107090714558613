import { Check, Close, OpenInNew } from '@mui/icons-material';
import { Alert, AlertTitle, AppBar, Avatar, Box, Chip, CircularProgress, Container, Fab, Grid, IconButton, TextField, Toolbar, Typography, Zoom } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADD_NEW_APP } from '../Home/CreateNewApp/Main';
import { CONFIG_DEFINITION, DEPLOY_IMAGE, UPDATE_APP } from '../Home/CreateNewApp/StepTwo';
import { CustomTooltip, ORG_LIST } from '../Settings/Organization';
import { useSnackbar } from 'notistack';
import { APP_LIST } from '../Home/Apps';
import { AiFillThunderbolt } from 'react-icons/ai';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RiAddLine } from 'react-icons/ri';
import { millisToMinutesAndSeconds } from '../Databases/DatabaseTable';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OneClickDeploy({ appDetails,hideDeployButton }) {
    const theme = useTheme()
    const [beforeTime, setbeforeTime] = React.useState("")
    const { enqueueSnackbar } = useSnackbar();
    const [startLoading, setstartLoading] = React.useState(false);
    const superb = require('superb');
    const [open, setOpen] = React.useState(false);
    const [appName, setappName] = React.useState("");
    const [defaultOrg, setdefaultOrg] = React.useState("");
    let history = useHistory();

    const [inputValues, setInputValues] = React.useState({});
    const [showEnvInputs, setshowEnvInputs] = React.useState(false);
    const handleInputChange = (key) => (event) => {
        setInputValues({
            ...inputValues,
            [key]: event.target.value,
        });
    };

    const handleSubmit = () => {
        if (appDetails.env && defaultOrg) {
            setshowEnvInputs(false)
            handleEnableCreateApp();

        }
    };

    const areInputsFilled = () => {
        return Object.values(inputValues).every((value) => value !== '');
    };

    const handleResetAll = () => {
        setappName("")
        setstartLoading(false)
    }

    var definitionMap = new Map();

    const handleClickOpen = () => {
        setOpen(true);
        if (appDetails.env === null && defaultOrg) {
            handleEnableCreateApp();
        }

    };

    const handleClose = () => {
        setOpen(false);
        if(hideDeployButton){
            history.push("/")
        }
    };

    const [addApp, { data: appdata, loading: apploading, error: apperror }] = useMutation(ADD_NEW_APP, {
        refetchQueries: [APP_LIST],
        onError: (err) => {
            handleResetAll()
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        },
        onCompleted: () => {
            updateApp({
                variables: {
                    appId: appName, build: `{"image": "${appDetails.image}", "builder": "MarketPlace", "builtin": ""}`, replicas: 1
                    , routingPolicy: "Latency", resource: `{"RequestRequirement":{"cpu":"","Memory":""},"LimitRequirement":{"cpu":"","Memory":""}}`, internalPort: `${appDetails.iport}`
                    , externalPort: `${appDetails.eport}`
                }
            })
        }
    });

    const [updateApp, { data: updateData, loading: updateLoad, error: updateErr }] = useMutation(UPDATE_APP, {
        refetchQueries: [CONFIG_DEFINITION],
        onCompleted: () => {
            getCongif({ variables: { name: appName } })
        },
        onError: (err) => {
            handleResetAll()
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    });

    const [fetchOrg] = useLazyQuery(ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
        onCompleted: (data) => {
            if (data && data.organizations.nodes && data.organizations.nodes.length > 0) {
                var item = data.organizations.nodes.find(o => o.type === "true" || o.type === "1")
                if (item.id) {
                    setdefaultOrg(item.id)

                }
            }
        }
    }
    );

    const [getCongif, { loading: resload, error: reserr, data: appdres }] = useLazyQuery(CONFIG_DEFINITION, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data.app.config) {
                definitionMap.set('definition', data.app.config.definition)
            }
            var time = new Date()
            setbeforeTime(time)
            var formattedEnv = [];
            if (appDetails.env) {
                formattedEnv = appDetails.env.map(({ key }) => `${key}=${inputValues[key] || ''}`);
            }

            deployImage({ variables: { appId: appName, image: appDetails.image, definition: definitionMap.get("definition"), envMapArgs: formattedEnv, envArgs: "", archiveUrl: "" } });

        }
    });

    const [deployImage, { data: DPData, loading: DPLoad, error: DPErr }] = useMutation(DEPLOY_IMAGE, {
        onCompleted: (data) => {
            handleResetAll()
            handleClose();
            history.push(`/deployments/overview/${appName}`)

            var time = new Date()

            enqueueSnackbar(`Successfully deployed ${appDetails.name} in ${millisToMinutesAndSeconds(time - beforeTime)} seconds🎉 `, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        },
        onError: (err) => {
            handleResetAll()
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    })

    const formatString = (inputString) => {
        const formattedString = inputString.replace(/\s+/g, '-').toLowerCase();
        const alphanumericString = formattedString.replace(/[^a-z0-9-]/g, '');
        return alphanumericString;
      };

    const handleEnableCreateApp = () => {
        setstartLoading(true)
        let randomAppName = formatString(appDetails.name) + "-app-" + Math.floor(100000 + Math.random() * 900000);
        setappName(randomAppName)
        addApp({ variables: { name: randomAppName, organizationId: defaultOrg, businessUnitId: "", subOrganizationId: "", workloadManagementId: "" } });
    }

    React.useEffect(() => {
        fetchOrg()
        if(hideDeployButton && defaultOrg){
            handleClickOpen()
            localStorage.removeItem("deployLink")
        }
        if (appDetails.env && defaultOrg) {
            setshowEnvInputs(true)
            const initialValues = {};
            appDetails.env.forEach(({ key, value }) => {
                initialValues[key] = value || ''; // Use value if available, otherwise an empty string
            });
            setInputValues(initialValues);
        }

    }, [defaultOrg]);


    return (
        <React.Fragment>
            {!hideDeployButton && <Button startIcon={<RiAddLine />} variant="contained" sx={{ boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", borderRadius: "24px" }} onClick={handleClickOpen}>
                Deploy
            </Button>}

            <Dialog
                open={open}
                fullScreen
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <AppBar sx={{
                    position: 'relative',
                    boxShadow: "none",
                    background: theme.palette.action.hover,
                    color: theme.palette.text.secondary,
                    height: "80px",
                    paddingTop: "10px",
                }}>
                    <Toolbar>
                        <IconButton
                            disabled={startLoading}
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <Close />
                        </IconButton>
                        <Typography sx={{ flex: 1, }}>
                            Market Place deployment
                        </Typography>
                    </Toolbar>
                    {/* {loadorg && <LinearProgress />} */}
                </AppBar>
                <Container sx={{ mt: 10 }}>
                    <Grid container>
                        <Grid item xs={4}>

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >

                                <h3> {appDetails.name}</h3> <div>
                                    {appDetails.home && <CustomTooltip arrow title="Visit homepage">
                                        <IconButton color='info' onClick={() => window.open(appDetails.home, '_blank')}><OpenInNew /></IconButton>
                                    </CustomTooltip>}
                                </div>
                            </Grid>
                            <Typography variant='body2' sx={{ mb: 1 }}>
                                {appDetails.desc}
                            </Typography>
                            <Box sx={{ background: "#160044", color: "#fff", p: 2, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px" }}>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{ p: 2 }}
                                >
                                    <Grid item>
                                        <Chip sx={{ mb: 1 }} variant='filled' color='info' size='small' label={appDetails.type} />
                                    </Grid>
                                    <Grid item>

                                        <Typography variant='body2'>
                                            Image: {appDetails.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body2'>
                                            Ports:  Internal Port: {appDetails.iport} & External Port: {appDetails.eport}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body2'>
                                            {appDetails.env ? <span>
                                                This App requires {appDetails.env && appDetails.env.length} variables
                                                <br />
                                                {appDetails.env && appDetails.env.map((i) => (`${i.key}, `))}
                                            </span>
                                                : <span>
                                                    No Environment Variables Need
                                                </span>}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body2'>
                                            Licence: {appDetails.License}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body2'>
                                            Runtime: {appDetails.runtime}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Grid>
                        <Grid item xs={8}>


                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box sx={{ maxWidth: "400px", m: 2 }}>
                                    {appDetails.env && showEnvInputs && <div>
                                        <Typography> This App requires {appDetails.env && appDetails.env.length} variables</Typography>
                                        {appDetails.env && appDetails.env.map(({ key, value }) => (
                                            <TextField
                                                size='small'
                                                key={key}
                                                label={key}
                                                variant="outlined"

                                                fullWidth
                                                value={inputValues[key] || ''}
                                                onChange={handleInputChange(key)}
                                                margin="normal"
                                            />
                                        ))}
                                        <Button sx={{ mt: 1 }} fullWidth variant='contained' disabled={!areInputsFilled()} onClick={handleSubmit}>Submit</Button>
                                    </div>}
                                    {(updateErr || apperror || DPErr || reserr) && <Zoom in={true}>
                                        <Alert severity="error" sx={{ border: "1px solid grey", borderRadius: "12px" }}>
                                            <AlertTitle>Error</AlertTitle>
                                            {updateErr && updateErr.message}
                                            {apperror && apperror.message}
                                            {DPErr && DPErr.message}
                                            {reserr && reserr.message}
                                        </Alert>
                                    </Zoom>}

                                </Box> </Grid>
                            {startLoading && <div>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ mb: 8, mt: 2 }}
                                >

                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ m: 1, position: 'relative' }}>
                                            <Avatar style={{ width: "56px", height: "56px" }} src={appDetails.logo} >
                                                <Fab
                                                    aria-label="save"
                                                    color="primary"
                                                    sx={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 5px" }}
                                                >
                                                    <AiFillThunderbolt style={{ fontSize: "23px" }} />
                                                </Fab>
                                            </Avatar>


                                            <CircularProgress
                                                size={68}
                                                sx={{
                                                    color: "#00d7ff",
                                                    position: 'absolute',
                                                    top: -6,
                                                    left: -6,
                                                    zIndex: 1,
                                                }}
                                            />

                                        </Box>
                                    </Box>
                                    <Typography sx={{ ml: 1 }} variant='h5'>
                                        {DPLoad && `Deploying ${appDetails.name}...`}
                                        {resload && "Fetching details..."}
                                        {apploading && "Initailizing..."}
                                        {updateLoad && "Updating app..."}
                                    </Typography>
                                </Grid>


                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item>


                                        {DPLoad && <Zoom in={true}>
                                            <Alert severity="info" sx={{ border: "1px solid grey", borderRadius: "12px" }}>
                                                <AlertTitle>Deploying</AlertTitle>
                                                please wait... deploying app with default region(s)
                                            </Alert>
                                        </Zoom>}
                                    </Grid>


                                    <Grid item>
                                        {updateData && appdata && <Zoom in={true}>
                                            <Alert icon={<Check fontSize="inherit" />} severity="success" sx={{ border: "1px solid grey", borderRadius: "12px" }}>
                                                <AlertTitle>Success</AlertTitle>
                                                Configured the app with <strong>{appDetails.name}'s</strong> details.
                                            </Alert>
                                        </Zoom>}
                                    </Grid>


                                    <Grid item>
                                        {appdata && <Zoom in={true}>
                                            <Alert icon={<Check fontSize="inherit" />} severity="success" sx={{ border: "1px solid grey", borderRadius: "12px" }}>
                                                <AlertTitle>Success</AlertTitle>
                                                Created an app <strong>{appName}</strong>.
                                            </Alert>
                                        </Zoom>}
                                    </Grid>
                                </Grid>
                            </div>}
                        </Grid>
                    </Grid>


                </Container>
            </Dialog>
        </React.Fragment>
    );
}
