import { useQuery } from '@apollo/client';
import { Avatar, Fade, Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Slide from '@mui/material/Slide';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { BiBuildings } from "react-icons/bi";
import { USER_INFO } from '../Components/Home/Profile';
import { CustomTooltip } from '../Components/Settings/Organization';
import { NewListItems } from './listItems';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolIcon: {
    position: "fixed",
    left: "226px",
    top: "45px",
  },
  toolIconExpand: {
    position: "fixed",
    left: "60px",
    top: "45px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 3,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    paddingLeft: "10%",
    padding: 5,
    paddingTop: "3%",
  },

  drawerPaper: {
    background:"transparent",
    position: 'relative',
    animation: '$gradient 15s ease infinite',
    whiteSpace: 'nowrap',
  },

  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '99.3vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: "auto",
  },
  header: {
    height: 130,

  },
}));



export default function SideBar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const { data: userdata, refetch } = useQuery(USER_INFO, {
    variables: { userId: localStorage.getItem("userId") }
  })

  useEffect(() => {
    refetch()
  }, [refetch]);

  return <>

    <div className={classes.root}>


      <CssBaseline />
      {useMediaQuery('(min-width:600px)') && <SwipeableDrawer
        className='demo__side_bar'
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      // onMouseOver={handleDrawerOpen}

      >


        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >


          <div className={classes.toolbarIcon}>


            <Fade in={true}
              style={{ transformOrigin: '0 0 0' }}
              {...(true ? { timeout: 500 } : {})}>
              <div>{userdata && <CustomTooltip title={userdata.getUserById.companyName} placement="right-start">

                <Avatar sx={{ borderRadius: "12px", height: "45px", width: "45px", maxHeight: "45px", maxWidth: "45px", margin: "15px", border: "0px solid #160044" }} src={userdata && userdata.getUserById.companylogo}>
                  <BiBuildings style={{ fontSize: "20px", marginTop: "20px", marginLeft: "20px", textAlign: "center" }} />

                </Avatar>

              </CustomTooltip>}
              </div>
            </Fade>

          </div>
          <NewListItems />
        </Grid>
      </SwipeableDrawer>}


      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </div>
  </>;
}
