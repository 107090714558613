import { gql, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CircularProgress, ListItemButton, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { AiFillGithub } from "react-icons/ai";
import { BiGlobe } from 'react-icons/bi';
import { IoIosKey } from "react-icons/io";
import { ReactComponent as MySvgFile } from '../../Images/AddNew.svg';
import { ORG_LIST } from "./SettingsTab";

export const ORG_REGISTRY_TYPE = gql`
query{
    organizationRegistryType{
    
        id
        name
        slug
        isActive
  }
}
`

export const SECRETS_LIST = gql`
query SecretsList($name: String!){
    getSecret(name: $name){
        id
        organizationId
        name
        registryType
        userName
        passWord
        Url
        keyFileContent
        registryName
        isActive
    
  }
}
`
export const CREATE_SECRETS = gql`
mutation addSecret($name: String!, $organizationId: String!, $registryType: String!,$userName:String!,$password: String!, $url: String ){
    createOrganizationSecret(input:{
      name:$name
      organizationId:$organizationId
      registryType:$registryType
      registryInfo:{
        userName:$userName
        passWord:$password
        url:$url
      }   
    }){
      message
      
    }
}`

export const UPDATE_SECRETS = gql`
mutation editSecret($name: String!,$registryType: String!,$userName:String!,$password: String!, $url: String ){
    updateOrganizationSecret(name:$name, input:{
       registryType:$registryType
      registryInfo:{
        userName:$userName
        passWord:$password
        url:$url
      }    
    }){
      message    
    }
}`

export const DELETE_SECRETS = gql`
mutation deleteSecret($name: String!){
    deleteOrganizationSecret(name:$name, id:"not needed"){
      message
    }
}`


function Scerets(props) {
    const role = localStorage.getItem("role")
    const [regType, setregType] = useState("")
    const [name, setname] = useState("")
    const [accesskey, setaccesskey] = useState("")
    const [secretkey, setsecretkey] = useState("")

    const [regTypeupdate, setregTypeupdate] = useState("")
    const [nameupdate, setnameupdate] = useState("")
    const [accesskeyupdate, setaccesskeyupdate] = useState("")
    const [secretkeyupdate, setsecretkeyupdate] = useState("")
    const [urlupdate, seturlupdate] = useState("")
    const [isPrivateRegTypeupdate] = useState(false)
    const [selectdeletesecretupdate, setselectdeletesecretupdate] = useState("")

    const [orgid, setorgid] = useState("")
    const [url, seturl] = useState("")
    const [open, setOpen] = React.useState(false);
    const [openupdate, setOpenupdate] = React.useState(false);
    const [isPrivateRegType, setisPrivateRegType] = useState(false)
    const [selectdeletesecret, setselectdeletesecret] = useState("")

    const [OpenSnack, setOpenSnack] = useState(false)
    const [filter, setFilter] = React.useState(0);
    const [showType, setshowType] = useState("password");
    const [secretError, setsecretError] = useState("");
    const [isEdit] = useState(true);

    const handleChange = (event) => {
        setFilter(event.target.value);
    };

    const { enqueueSnackbar } = useSnackbar();


    var CryptoJS = require("crypto-js");
    function decodeAes(encryptedDataStr) {
        var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
        var encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedDataStr);
        var encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

        var decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
            iv: key,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        decryptedData = decryptedData.toString(CryptoJS.enc.Utf8)
        return decryptedData

    }

    function encodeAes(plaintText) {
        var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
        var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
            iv: key,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        encryptedData = encryptedData.ciphertext.toString();

        return encryptedData

    }



    const handleClickOpen = (e) => {
        setselectdeletesecret(e)
        setOpen(true);
    };

    const handleClickOpenupdate = (e) => {
        setselectdeletesecretupdate(e)
        setnameupdate(e.name)
        setaccesskeyupdate(e.userName)
        setsecretkeyupdate(decodeAes(e.passWord))
        setregTypeupdate(e.registryType)

        setOpenupdate(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    }

    const handleCloseupdate = () => {
        setOpenupdate(false);
    };

    const handleChangeRegTypes = (e) => {
        setisPrivateRegType(false)
        setregType(e.target.value)

        if (e.target.value === "private_registry") {
            setisPrivateRegType(true)

        }
    }

    // const handleChangeRegTypesupdate = (e) => {
    //     setisPrivateRegTypeupdate(false)
    //     setregTypeupdate(e.target.value)

    //     if (e.target.value === "private_registry") {
    //         setisPrivateRegTypeupdate(true)

    //     }
    // }

    const handleChangeOrg = (e) => {
        setorgid(e.target.value)
    }

    const handleChangeName = (e) => {
        setname(e.target.value)
        if (!validateSecret(e.target.value)) {
            setsecretError('Invalid name. It must consist of lowercase alphanumeric characters, "-" or ".", and must start and end with an alphanumeric character.');
        } else {
            setsecretError('');
        }
    }
    const validateSecret = (secret) => {
        const pattern = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/;
        return pattern.test(secret);
    };

    const handleChangeAccess = (e) => {
        setaccesskey(e.target.value)
    }

    const handleChangeUrl = (e) => {
        seturl(e.target.value)
    }
    const handleChangeSecret = (e) => {
        setsecretkey(e.target.value)
    }



    const handleChangeAccessupdate = (e) => {
        setaccesskeyupdate(e.target.value)
    }

    const handleChangeUrlupdate = (e) => {
        seturlupdate(e.target.value)
    }
    const handleChangeSecretupdate = (e) => {
        setsecretkeyupdate(e.target.value)
    }

    const handleupdate = (e) => {
        e.preventDefault()
        updateSecret({
            variables: {
                name: nameupdate,
                registryType: regTypeupdate,
                userName: accesskeyupdate,
                password: encodeAes(secretkeyupdate),
                url: urlupdate,
            }
        });
    }
    const handleDeleteSecret = (e) => {
        deleteSecret({ variables: { name: selectdeletesecret.name, id: "" } })
    }

    const handleReset = () => {
        setsecretError("")
        setFilter(0)
        setname("")
        setaccesskey("")
        setsecretkey("")
        setregType("")
        setorgid("")
    }
    const handlesubmit = (e) => {
        e.preventDefault()
        if (filter === 2) {
            createSecret({
                variables: {
                    name: name,
                    organizationId: orgid,
                    registryType: "PAT",
                    userName: accesskey,
                    password: encodeAes(secretkey),
                    url: url,
                }
            });
        }
        else {
            createSecret({
                variables: {
                    name: name,
                    organizationId: orgid,
                    registryType: regType,
                    userName: accesskey,
                    password: encodeAes(secretkey),
                }
            });
        }


    }
    const { loading: loadorg, data: orgdata } = useQuery(ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
    });

    const { loading: loadregtype, data: dataregtype } = useQuery(ORG_REGISTRY_TYPE);

    const { loading: loadlist, data: datalist } = useQuery(SECRETS_LIST, {
        variables: { name: "" },
    });

    const handleError = () => {
        enqueueSnackbar("Invalid Username and Password", {
            variant: 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    }


    const [createSecret, { loading: addLoad }] = useMutation(CREATE_SECRETS, {
        refetchQueries: [SECRETS_LIST],
        onCompleted: () => {
            setOpenSnack(true)
            setname("")
            setaccesskey("")
            setsecretkey("")
            setregType("")
            setorgid("")

        },
        onError: (err) => {
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            setname("")
            setaccesskey("")
            setsecretkey("")
            setregType("")
            setorgid("")
        }
    });
    const [updateSecret] = useMutation(UPDATE_SECRETS, {
        refetchQueries: [SECRETS_LIST],
        onCompleted: () => {
            enqueueSnackbar("Successfully Updated !", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            setOpenupdate(false)
        },
        onError: (e) => {

            handleError()
        }
    });



    const [deleteSecret] = useMutation(DELETE_SECRETS, {
        refetchQueries: [SECRETS_LIST],
        onCompleted: () => {
            setOpen(false)

        }
    });


    useEffect(() => {

    }, [datalist]);


    return (
        <div style={{ marginTop: "20px" }}>


            <div>
                <Box sx={{ maxWidth: 150 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filter}
                            size="small"
                            label="Filter"
                            onChange={handleChange}
                        >
                            <MenuItem value={0}>
                                Show All
                            </MenuItem>
                            <MenuItem value={1}>Secrets</MenuItem>
                            <MenuItem value={2}>Github PAT</MenuItem>
                            <MenuItem value={3}>Global Variable</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Grid container>
                    {orgdata && orgdata.organizations.nodes &&
                        <Grid item sm={6}>
                            {filter === 0 &&
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <MySvgFile style={{ width: "70%", height: "50%", paddingLeft: "20%", paddingRight: "20%" }} />
                                    <h2 style={{ marginBottom: "5px" }}>Create Variables!</h2>
                                    <p style={{ color: "grey", marginTop: "0px" }}>Set registry type as None to create Global Variable</p>
                                    <Button disabled={role === "Viewer"} startIcon={<AddIcon />} onClick={() => setFilter(1)} color="primary">Create Secret</Button>
                                    <Button disabled={role === "Viewer"} startIcon={<AddIcon />} onClick={() => setFilter(3)} color="primary">Create Global Variable</Button>
                                    <Button disabled={role === "Viewer"} startIcon={<AddIcon />} onClick={() => setFilter(2)} color="primary">Create Github PAT</Button>

                                </Grid>}

                            {filter === 1 && role !== "Viewer" && <Paper style={{ padding: "0px 20px 20px 20px" }} elevation={0} spacing={1}>

                                <Grid container spacing={0}>
                                    <IconButton size='large'><IoIosKey /></IconButton>
                                    <h2 style={{ marginBottom: "5px" }}>Create Secret!</h2>
                                    {/* <p style={{ color: "grey", marginTop: "0px" }}>Set registry type as None to create Global Variable</p> */}

                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                value={name}
                                                name="internalPort"
                                                label="Name"
                                                error={secretError}
                                                helperText={secretError && secretError}
                                                onChange={handleChangeName}
                                            />
                                        </Grid>

                                        <Grid item xs>
                                            {!loadorg &&
                                                <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >
                                                    <InputLabel id="demo-simple-select-filled-label">Choose Organization</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        label="Choose Organization"
                                                        onChange={(e) => handleChangeOrg(e)}
                                                        value={orgid}
                                                    >
                                                        <MenuItem> None </MenuItem>
                                                        {orgdata.organizations.nodes.map((value) => (
                                                            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>}
                                        </Grid>
                                        <Grid item xs>
                                            {!loadregtype &&
                                                <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >
                                                    <InputLabel id="demo-simple-select-filled-label">Choose Registry type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        label="Choose Registry type"
                                                        onChange={(e) => handleChangeRegTypes(e)}
                                                        value={regType}
                                                    >
                                                        <MenuItem> None </MenuItem>
                                                        {dataregtype && dataregtype.organizationRegistryType.filter((e) => {
                                                            return !(e.slug === "mysql") && !(e.slug === "postgres")
                                                        }).map((value) => (
                                                            <MenuItem key={value.id} value={value.slug}>{value.name}</MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>}


                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={2}>
                                        <Grid item xs>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                value={accesskey}
                                                name="internalPort"
                                                label="Access Key"
                                                onChange={handleChangeAccess}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                type='text'
                                                value={secretkey}
                                                name="internalPort"
                                                label="Secret Key"
                                                onChange={handleChangeSecret}
                                            />
                                        </Grid>
                                        {isPrivateRegType && <Grid item xs>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                value={url}
                                                name="internalPort"
                                                label="URL"
                                                onChange={handleChangeUrl}
                                            />
                                        </Grid>}
                                    </Grid>


                                    <Button disabled={secretkey === "" || !orgid || name === "" || accesskey === "" || !regType || secretError !== ""} color="primary" style={{ margin: "10px 0px 10px 0px" }} onClick={handlesubmit} disableElevation variant="contained">  {addLoad ? <CircularProgress style={{ color: "white" }} size={20} /> : "Submit"}</Button>
                                    <Button color="primary" style={{ margin: "10px 0px 10px 10px" }} onClick={handleReset} disableElevation variant="outlined">  {"Cancel"}</Button>
                                </Grid>
                            </Paper>}



                            {filter === 2 && role !== "Viewer" && <Paper style={{ padding: "0px 20px 20px 20px" }} elevation={0} spacing={1}>
                                <Grid container spacing={0}>
                                    <IconButton size='large'><AiFillGithub /></IconButton>
                                    <h2 style={{ marginBottom: "5px" }}>Add Github PAT!</h2>
                                    {/* <p style={{ color: "grey", marginTop: "0px" }}>Set registry type as None to create Global Variable</p> */}
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                value={name}
                                                name="internalPort"
                                                label="Name"
                                                onChange={handleChangeName}
                                            />
                                        </Grid>

                                        <Grid item xs>
                                            {!loadorg &&
                                                <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >
                                                    <InputLabel id="demo-simple-select-filled-label">Choose Organization</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        label="Choose Organization"
                                                        onChange={(e) => handleChangeOrg(e)}
                                                        value={orgid}
                                                    >
                                                        <MenuItem> None </MenuItem>
                                                        {orgdata.organizations.nodes.map((value) => (
                                                            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>}
                                        </Grid>

                                    </Grid>


                                    <Grid container spacing={2}>

                                        <Grid item xs>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                type='text'
                                                value={secretkey}
                                                name="internalPort"
                                                label="Personal access token"
                                                onChange={handleChangeSecret}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Button disabled={secretkey === "" ? true : !orgid ? true : name === "" ? true : false} color="primary" style={{ margin: "10px 0px 10px 0px" }} onClick={handlesubmit} disableElevation variant="contained">  {addLoad ? <CircularProgress style={{ color: "white" }} size={20} /> : "Submit"}</Button>
                                    <Button color="primary" style={{ margin: "10px 0px 10px 10px" }} onClick={handleReset} disableElevation variant="outlined">  {"Cancel"}</Button>
                                </Grid>
                            </Paper>}


                            {filter === 3 && role !== "Viewer" && <Paper style={{ padding: "0px 20px 20px 20px" }} elevation={0} spacing={1}>
                                <Grid container spacing={0}>
                                    <IconButton size='large'><BiGlobe /></IconButton>
                                    <h2 style={{ marginBottom: "5px" }}>Create Global Variable!</h2>
                                    {/* <p style={{ color: "grey", marginTop: "0px" }}>Set registry type as None to create Global Variable</p> */}

                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                value={name}
                                                name="internalPort"
                                                label="Name"
                                                onChange={handleChangeName}
                                            />
                                        </Grid>

                                        <Grid item xs>
                                            {!loadorg &&
                                                <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >
                                                    <InputLabel id="demo-simple-select-filled-label">Choose Organization</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        label="Choose Organization"
                                                        onChange={(e) => handleChangeOrg(e)}
                                                        value={orgid}
                                                    >
                                                        <MenuItem> None </MenuItem>
                                                        {orgdata.organizations.nodes.map((value) => (
                                                            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>}
                                        </Grid>

                                    </Grid>


                                    <Grid container spacing={2}>
                                        <Grid item xs>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                value={accesskey}
                                                name="internalPort"
                                                label="Key"
                                                onChange={handleChangeAccess}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                type='text'
                                                value={secretkey}
                                                name="internalPort"
                                                label="Value"
                                                onChange={handleChangeSecret}
                                            />
                                        </Grid>
                                    </Grid>


                                    <Button disabled={secretkey === "" ? true : !orgid ? true : name === "" ? true : accesskey === "" ? true : false} color="primary" style={{ margin: "10px 0px 10px 0px" }} onClick={handlesubmit} disableElevation variant="contained">  {addLoad ? <CircularProgress style={{ color: "white" }} size={20} /> : "Submit"}</Button>
                                    <Button color="primary" style={{ margin: "10px 0px 10px 10px" }} onClick={handleReset} disableElevation variant="outlined">  {"Cancel"}</Button>
                                </Grid>
                            </Paper>}












                        </Grid>}
                    <Grid item sm={6}>
                        <div style={{ padding: "0px 20px 20px 20px" }} >
                            {!loadlist && datalist && (datalist.getSecret &&
                                <List subheader={
                                    <span style={{ fontWeight: "bold" }}>Your Variables</span>
                                }>
                                    {datalist && datalist.getSecret.filter((e, i, arr) => {
                                        if (filter !== 0) {
                                            if (filter === 3) {
                                                return e.registryType === "env"
                                            }
                                            if (filter === 2) {
                                                return e.registryType === "PAT"
                                            }
                                            if (filter === 1) {
                                                return e.registryType === "docker_hub_registry"
                                            }

                                        }
                                        else {
                                            return arr
                                        }

                                    }).map((sectionId, i) => {
                                        if (sectionId.registryType === "env") {
                                            return (
                                                <ListItem disabled={role === "Viewer"} key={sectionId.id} style={{ cursor: "pointer" }}>
                                                    <ListItemIcon>

                                                        <BiGlobe />
                                                    </ListItemIcon>
                                                    <ListItemText primary={sectionId.name} secondary={<span style={{ fontWeight: "bold" }}>{sectionId.registryType === "env" && "Global Variable"}</span>} />
                                                    <ListItemSecondaryAction>
                                                        <IconButton aria-label="update" size="large">
                                                            <EditIcon onClick={() => handleClickOpenupdate(sectionId)} />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => handleClickOpen(sectionId)}
                                                            size="large"> <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        }
                                        if (sectionId.registryType === "PAT") {
                                            return (
                                                <ListItem disabled={role === "Viewer"} key={sectionId.id} style={{ cursor: "pointer" }}>
                                                    <ListItemIcon>

                                                        <AiFillGithub />

                                                    </ListItemIcon>
                                                    <ListItemText primary={sectionId.name} secondary={<span style={{ fontWeight: "bold" }}>{"Github PAT"}</span>} />
                                                    <ListItemSecondaryAction>
                                                        <IconButton aria-label="update" size="large"> <EditIcon onClick={() => handleClickOpenupdate(sectionId)} /> </IconButton>
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => handleClickOpen(sectionId)}
                                                            size="large"> <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        }

                                        else if (sectionId.registryType !== "mysql") {
                                            return (
                                                <ListItem disabled={role === "Viewer"} key={sectionId.id} style={{ cursor: "pointer" }}>
                                                    <ListItemIcon>
                                                        <IoIosKey />


                                                    </ListItemIcon>
                                                    <ListItemText primary={sectionId.name} secondary={<span style={{ fontWeight: "bold" }}>{"Secret"}</span>} />
                                                    <ListItemSecondaryAction>
                                                        <IconButton aria-label="update" size="large"> <EditIcon onClick={() => handleClickOpenupdate(sectionId)} /> </IconButton>
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => handleClickOpen(sectionId)}
                                                            size="large"> <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        }
                                        else return null;
                                    }





                                    )}
                                </List>
                            )}
                        </div>

                    </Grid>


                </Grid>



            </div>



            <Dialog
                open={open}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: "12px",
                        border: "solid 1px gray",
                        // boxShadow: (true) ? "#160044 5px 5px, #160044 10px 10px" : "undefined"

                    }
                }}
                maxWidth="xs"
                fullWidth
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{<span>Do you want to <b> delete {selectdeletesecret.name}</b></span>}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {filter === 1 && "Once you delete your Secret , there is no going back, Please be certain!"}
                        {/* Once you delete your {filter === 0 ? "Secret" : filter === 2 ? "Github PAT" : "Global Variable"}, there is no going back, Please be certain! */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button fullWidth onClick={handleClose} >
                        Cancel
                    </Button>
                    <Button fullWidth variant='contained' disableElevation onClick={handleDeleteSecret} color="error">
                        delete forever
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openupdate}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: "12px",
                        border: "solid 1px gray",
                        // boxShadow: (true) ? "#160044 5px 5px, #160044 10px 10px" : "undefined"

                    }
                }}
                maxWidth="xs"
                fullWidth
                onClose={handleCloseupdate}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Edit <b>{selectdeletesecretupdate.name}</b></DialogTitle>
                <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={1}>

                        <Grid item xs={6} style={{ marginTop: "10px" }}>

                            <Typography variant='body2' style={{ color: "grey " }}> Name:<Typography color="text.primary" variant="body2" gutterBottom> {nameupdate}</Typography></Typography>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: "10px" }}>
                            {!loadregtype &&
                                <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >
                                    <Typography variant='body2' style={{ color: "grey " }}> Registry Type:<Typography color="text.primary" variant="body2" gutterBottom> {regTypeupdate}</Typography></Typography>
                                </FormControl>}
                        </Grid>

                        {regTypeupdate !== "PAT" && <Grid item xs={6}>
                            {isEdit ? <TextField
                                required

                                fullWidth
                                size='small'

                                value={accesskeyupdate}
                                name="internalPort"
                                label={regTypeupdate === "env" ? "Key" : "Access Key"}
                                onChange={handleChangeAccessupdate}
                            />
                                :
                                <Typography variant='body2' style={{ color: "grey " }}> Access Key:
                                    <br />
                                    <Button size='small' style={{ padding: "0px" }}>{accesskeyupdate}</Button>

                                </Typography>}

                        </Grid>}
                        <Grid item xs={6}>
                            {isEdit ?
                                <TextField
                                    required

                                    fullWidth
                                    size='small'
                                    type={showType}
                                    value={secretkeyupdate}
                                    name="internalPort"
                                    label={regTypeupdate === "env" ? "Vlaue" : regTypeupdate === "PAT" ? "Github Token" : "Secret Key"}
                                    onChange={handleChangeSecretupdate}
                                />
                                :

                                <Typography variant='body2' style={{ color: "grey " }}> Secret Key:
                                    <br />
                                    <Button onClick={() => setshowType("text")} size='small' style={{ padding: "0px" }} endIcon={<VisibilityIcon />}>***************</Button>

                                </Typography>}
                        </Grid>
                        {isPrivateRegTypeupdate && <Grid item xs={6}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={urlupdate}
                                name="internalPort"
                                label="URL"
                                onChange={handleChangeUrlupdate}
                            />
                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth onClick={handleCloseupdate} color="primary">
                        Close
                    </Button>
                    <Button fullWidth onClick={handleupdate} disableElevation variant="contained" color="primary">
                        Save
                    </Button>

                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
                open={OpenSnack} autoHideDuration={3000} onClose={handleCloseSnack}>

                <Alert onClose={handleCloseSnack} severity="success"> Created Successfully</Alert>
            </Snackbar>



        </div>
    );
}

export default Scerets;