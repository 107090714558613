import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Container, FormControl, Grid, Grow, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { MetricsCard } from './SpeedInsightsLayout';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { MdOutlineWarning } from 'react-icons/md';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';


const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;

    // Assuming payload.value is the time in '2024-06-27 09:58:33.081' format
    const dateTime = new Date(payload.value);
    const formattedTime = `${dateTime.getHours().toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}`;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {formattedTime}
            </text>
        </g>
    );
};

const UptimeStatus = () => {
    const [uptimeData, setUptimeData] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [hovered, setHovered] = React.useState(false);
    const [selectedTime, setSelectedTime] = useState(1);
    const [selectedItem, setselectedItem] = useState(null);
    const match = useRouteMatch();

    const handleMouseEnter = (item) => {
        setHovered(true);
        setselectedItem(item)
    };

    const handleChange = (event) => {
        setSelectedTime(event.target.value);
    };

    const handleMouseLeave = () => {
        setHovered(false);
        setselectedItem(null)
    };

    const handlePercentage = (data) => {
        setpingAvg(0)
        var totalPing = 0;

        var totalActive = data.filter((e) => {
            return e.status === true;
        })

        totalActive.forEach(element => {
            totalPing = totalPing + element.ping
        });

        setpingAvg(Math.round(totalPing / data.length) * 100);

        var cal = (totalActive.length / data.length) * 100

        setuptimePercentage(Math.round(cal))
    }

    const [uptimePercentage, setuptimePercentage] = useState(100);
    const [pingAvg, setpingAvg] = useState(0);
    const [token, setToken] = useState(null);
    const [uptimeId, setuptimeId] = useState(null);
    const [error, setError] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    const handleLogin = async () => {
        try {
            const response = await axios.post(
                'https://testuptimekuma.nifetency.com/login/access-token',
                new URLSearchParams({
                    grant_type: '',
                    username: "admin",
                    password: "admin123",
                    scope: '',
                    client_id: '',
                    client_secret: ''
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'accept': 'application/json',
                        'Access-Control-Allow-Origin': "*"
                    }
                }
            );
            setToken(response.data.access_token);
            handleFetchUptimeId(response.data.access_token)
            setError(null);
        } catch (err) {
            enqueueSnackbar(`Login error: ${err.message}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            setError('Login failed');
            setToken(null);
        }
    };

    const handleCreateNewUptimeId = async (token) => {
        const url = 'https://testuptimekuma.nifetency.com/monitors';

        const data = {
            type: "http",
            name: match.params.id,
            interval: 60,
            retryInterval: 60,
            resendInterval: 0,
            maxretries: 0,
            upsideDown: false,
            url: `https://${match.params.id}.clb2.nifetency.com`,
            expiryNotification: false,
            ignoreTls: false,
            maxredirects: 10
        };

        try {
            const response = await axios.post(url, data, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data && response.data.monitorID) {
                const monitorID = response.data.monitorID;
                // Make second API call to add the monitor ID to the database
                const dbResponse = await axios.post('https://getuptime.clb2.nifetency.com/add-uptime', { appName: match.params.id, uptimeId: monitorID });
                if (dbResponse.status === 200) {
                    // If both requests are successful, call handleFetchUptimeId
                    handleFetchUptimeId();
                } else {
                    enqueueSnackbar(`Failed to add monitor ID to the database`, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                    })
                    throw new Error('Failed to add monitor ID to the database');
                }
            } else {
                enqueueSnackbar(`Monitor ID not found in response`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
                throw new Error('Monitor ID not found in response');
            }
        } catch (error) {
            enqueueSnackbar(`Error creating monitor: ${error.message}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            console.error('Error creating monitor:', error);

        }
    }

    const handleFetchUptimeId = async (token) => {
        try {
            const response = await axios.get(`https://getuptime.clb2.nifetency.com/check-uptime?id=${match.params.id}`); // Replace with your API endpoint
            const data = response.data;

            // Validate the response data
            if (data && data.exists) {
                setuptimeId(data.uptimeId);
                fetchUptimeData(token, data.uptimeId)
            }

            if (data && data.exists === false) {
                handleCreateNewUptimeId(token)
            } else {
                throw new Error('Invalid response data');
            }
        } catch (error) {
            setError(error.message);
        }
    }

    const fetchUptimeData = async (token) => {
        setisLoading(true)
        try {
            const response = await fetch(`https://testuptimekuma.nifetency.com/monitors/${uptimeId}/beats?hours=${selectedTime}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setUptimeData(data); // Assuming data structure from your API
            handlePercentage(data.monitor_beats)
            setisLoading(false)

        } catch (error) {
            setisLoading(false)
            console.error('Error fetching uptime data:', error);
        }
    };


    useEffect(() => {

        handleLogin()
        if (token && uptimeId) {
            fetchUptimeData(token)
        }

    }, [selectedTime, uptimeId]);

    if (uptimeId === null) {
        return "Loading..."
    }

    return (
        <>


            <Grid container spacing={0}>
                {/* Grid item for status */}

                <Grid xs={12}>
                <Typography variant="h4" sx={{p:4,pl:0}} component="h1" >Uptime Status</Typography>
                    <Typography variant='body2' sx={{ opacity: "0.8", mt: 2 }}>Real-time updates with visually appealing data of your applications, websites, and APIs.</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }} container direction={"row"} alignItems="center" justifyContent="space-between">
                    <div>
                    {isLoading ?   <Typography sx={{ fontWeight: "bold", }}>Loading..</Typography> : 
                        <Grid container direction={"row"} alignItems="center" justifyContent="space-between">
                            {uptimeData && uptimeData.monitor_beats[uptimeData.monitor_beats.length - 1].ping ? (
                                <CheckCircleIcon style={{ color: '#5cdd8b', fontSize: 25, marginRight: "5px" }} />
                            ) : (
                                <ErrorIcon style={{ color: 'red', fontSize: 25, marginRight: "5px" }} />
                            )}
                            <Typography sx={{ fontWeight: "bold", }}>{uptimeData && uptimeData.monitor_beats[uptimeData.monitor_beats.length - 1].ping === null ? "Not Okay" : "All Good!"}</Typography>

                        </Grid>
                        
                      }
 

                    </div>

                    <FormControl>
                        <InputLabel id="time-select-label"> Time Interval</InputLabel>
                        <Select
                            size="small"
                            labelId="time-select-label"
                            id="time-select"
                            label="Time Interval"
                            value={selectedTime}
                            onChange={handleChange}
                        >
                            <MenuItem value={1}>1 hour</MenuItem>
                            <MenuItem value={3}>3 hours</MenuItem>
                            <MenuItem value={6}>6 hours</MenuItem>
                            <MenuItem value={24}>24 hours</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>


                {isLoading ? <Grid item xs={12}>
                
                    <Skeleton variant="rectangular" height={80} style={{ margin: "10px" }} />
                </Grid> :
                    <>
                        {uptimeData && uptimeData.monitor_beats.map((item, index) => (
                            <Grid item xs={12 / uptimeData.monitor_beats.length} key={index}>
                                <Paper
                                    variant='outlined'
                                    square

                                    sx={{
                                        mt: "12px",
                                        width: "100%",
                                        height: 50,

                                        bgcolor: item.status ? "#5cdd8b" : "red",
                                        border: '0px solid #ccc',
                                        transition: 'transform 0.2s ease, height 0.2s ease',
                                        '&:hover': {
                                            transform: 'translateY(-10px)', // Move up by 20px on hover

                                            mb: 0
                                        },
                                    }}
                                    onMouseEnter={() => handleMouseEnter(item)}
                                    onMouseLeave={handleMouseLeave}
                                />


                            </Grid>
                        ))}
                    </>
                }




                <Grid item xs={12} container alignItems="center" justifyContent="space-between">
                    <Typography sx={{ fontSize: "14px", color: "grey", mb: 2 }}>An hour ago</Typography>
                    <Typography sx={{ fontSize: "14px", color: "grey", mb: 2 }}>Now</Typography>
                </Grid>

                {hovered && <Grid item xs={12}>
                    <Grow in={true} timeout={{ enter: 500, exit: 0 }}>
                        <Paper variant='outlined' sx={{ mb: 2, borderRadius: "12px" }}>
                            <Typography sx={{ px: 2, mt: 2, textAlign: "center", color: "grey" }}>{selectedItem && selectedItem.time && moment(selectedItem.time).add(6, 'hours').fromNow()} • Ping {selectedItem && selectedItem.ping} ms </Typography>
                            {selectedItem && selectedItem.status ? <Typography sx={{ px: 2, mb: 2, textAlign: "center" }}> <CheckCircleIcon style={{ color: '#5cdd8b', fontSize: 15, marginRight: "5px" }} />No downtime reported</Typography> :
                                <Typography sx={{ px: 2, mb: 2, textAlign: "center" }}> <MdOutlineWarning style={{ color: 'red', fontSize: 15, marginRight: "5px" }} />{selectedItem && selectedItem.msg}</Typography>}
                        </Paper>
                    </Grow>
                </Grid>}

                {!hovered && <Grid item xs={12}>
                    <List dense={true} sx={{p:0,m:0}}>
                        {uptimeData && uptimeData.monitor_beats.filter((e, i) => {
                            return e.status === false;
                        }).slice(0, 3).map((e, i) => {
                            return <ListItem sx={{ p: 0,m:0 }}>
                                <ListItemIcon sx={{ minWidth: "auto" }}>
                                    <MdOutlineWarning style={{ color: 'red', fontSize: "20px" }} />
                                </ListItemIcon>
                                <ListItemText primary={<Typography sx={{ px: 2, mt: 2, color: "grey" }}>{e.time && moment(e.time).add(6, 'hours').fromNow()} </Typography>}
                                    secondary={<Typography sx={{ px: 2, mb: 2, }}> {e.msg}</Typography>} />
                            </ListItem>
                        })}
                    </List>
                    <Typography color={"primary"} sx={{ pb: 2, textAlign: "center", fontWeight: "bold" }}>
                        {uptimeData && uptimeData.monitor_beats.filter((e, i) => {
                            return e.status === false;
                        }).length > 3 && `And ${uptimeData && uptimeData.monitor_beats.filter((e, i) => {
                            return e.status === false;
                        }).length - 3} More`}
                    </Typography>

                </Grid>}

                <Grid item xs={12} md={6}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <MetricsCard data={{ value: `${uptimeData && uptimeData.monitor_beats[uptimeData.monitor_beats.length - 1].ping} ms`, status: 0, noLinearProgress: false, title: "Response", subtitle: "Current" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <MetricsCard data={{ value: `${pingAvg} ms`, status: 0, noLinearProgress: false, title: "Avg. Response", subtitle: "(24-hour)" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <MetricsCard data={{ value: `${uptimePercentage}%`, status: 0, noLinearProgress: false, title: "Uptime", subtitle: "(24-hour)" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <MetricsCard data={{ value: "84 Days", status: 0, noLinearProgress: false, title: "Cert Exp.", subtitle: "(2024-09-19)" }} />
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper variant='outlined' sx={{ borderRadius: "12px", p: 2, pb: 0.5, ml: 2 }}>
                        <ResponsiveContainer width="100%" height={230}>
                            <AreaChart data={uptimeData && uptimeData.monitor_beats} margin={{ top: 20, right: 10, left: 0, bottom: 20 }}>
                                <XAxis tick={<CustomXAxisTick />} dataKey="time" label={{ value: 'Time', position: 'insideLeft' }} />
                                <YAxis label={{ value: 'Ping (ms)', angle: -90, position: 'insideLeft' }} />
                                <Tooltip cursor={{ fill: 'transparent' }} />
                                <Area name='Ping' type="monotone" dataKey="ping" stroke="#5cdd8b" fill="#dbf8e6" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

            </Grid>








        </>

    );
};

export default UptimeStatus;
