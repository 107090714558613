import { ErrorOutline } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function CustomErrorMessageBox({type, message}) {
    if (type && message){
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ background: "rgb(253, 246, 246)", color: "rgb(211, 47, 47)", p: 3, borderRadius: "12px", mt: 2 }}
            >
                <ErrorOutline size={5} color='error' />
                <Typography sx={{ ml: 1 }}>
                    {type}: {message}
                </Typography>
            </Grid>
        )
    }
    else{
        return null;
    }
  
}
